import * as React from 'react';
import UploadsManager from '../../manager/uploads.manager';
import { Alert } from '../../services/alert';
import Helper from '../../services/helper';
import Styles from '../../services/styles';
import Translation from '../../services/translation';
import { FileDrop } from '../Shared/FileDrop';
import LoadingModal from '../Shared/LoadingModal';
import Modal from '../Shared/Modal';

interface Props {
  match?: any;
  location?: any;
  history?: any;
  isOpen: boolean;
  toggleModal: any;
  projectId: string;
  reloadData?: any;
  withSending?: any;
}

export function UploadModal(props: Props) {
  const [files, setFiles] = React.useState<
    { uploadName: string; description: string; file: File }[]
  >([]);
  const [filesValid, setFilesValid] = React.useState<boolean>(true);
  const [loading, setLoading] = React.useState<boolean>(false);

  const handleFileUpload = async (withSending?: boolean) => {
    if (files && files.length > 0) {
      setLoading(true);
      await UploadsManager.handleFileUpload(files, props.projectId).then(
        (s: any) => {
          setFiles([]);
          withSending && props.withSending();
          props.toggleModal();
          !withSending && props.reloadData && props.reloadData();
        },
      );
    } else {
      if (withSending) {
        props.withSending();
        props.toggleModal();
      }
    }
    setLoading(true);
  };
  const toggleModal = async () => {
    if (files.length !== 0) {
      Alert.confirm({
        type: 'warning',
        title: Translation.getTranslation('unsaved_files'),
        text: Translation.getTranslation('upload_unsaved_files'),
        confirmButtonText: Translation.getTranslation('save'),
        cancelButtonText: Translation.getTranslation('discard'),
        confirmButtonColor: Styles.sweetalertOptions().warningColor,
        cancelButtonColor: Styles.sweetalertOptions().greyColor,
      }).then((answer: boolean) => {
        if (answer) {
          handleFileUpload();
        } else {
          setFiles([]);
          props.toggleModal();
        }
      });
    } else {
      setFiles([]);
      props.toggleModal();
    }
  };

  React.useEffect(() => {
    if (props.isOpen) {
      setLoading(true);
      Helper.gaRegisterModalView('project_file_upload');
      setLoading(false);
    }
  }, []);

  const renderBody = (additionalElements?: any) => {
    return (
      <div>
        <div className="text-center">{additionalElements}</div>
        {loading && (
          <LoadingModal
            text={Translation.getTranslation('files_are_uploading')}
          />
        )}
        <FileDrop
          xl={'6'}
          lg={'12'}
          isValid={(e: boolean) => {
            if (e !== filesValid) setFilesValid(e);
          }}
          uploadsLabel={Translation.getTranslation('files_to_upload')}
          chosenFiles={(files: any) => {
            setFiles(files);
          }}
        />
      </div>
    );
  };

  return (
    <Modal
      position="top"
      isOpen={props.isOpen}
      toggleModal={() => toggleModal()}
      size="lg"
      header={
        !props.withSending
          ? Translation.getTranslation('upload_file')
          : Translation.getTranslation('complete_order')
      }
      disabled={
        (!props.withSending && (files.length === 0 || loading)) ||
        loading ||
        !filesValid
      }
      saveLabel={
        !props.withSending
          ? files.length > 1
            ? Translation.getTranslation('upload_files')
            : Translation.getTranslation('upload_file')
          : files.length > 0
          ? Translation.getTranslation('upload_and_finish')
          : Translation.getTranslation('send_without_file')
      }
      onSave={() => handleFileUpload(props.withSending)}
    >
      {renderBody()}
    </Modal>
  );
}
