import { Typography } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import React, { useEffect } from 'react';
import Translation from '../../services/translation';
import Icon from './Icon';

const useStyles = makeStyles((theme) => ({
  toggleContainer: {
    margin: theme.spacing(2, 0),
  },
}));

interface Props {
  yesLabel?: string;
  noLabel?: string;
  label?: string;
  initialValue?: boolean;
  onChange: (selected: boolean) => void;
}

export default function BooleanToggleButton(props: Props) {
  const [value, setValue] = React.useState<boolean | null>(null);
  const classes = useStyles();
  const handleToggle = (event: any, newValue: any) => {
    if (newValue !== null) {
      setValue(newValue);
    }
  };

  useEffect(() => {
    typeof props.initialValue !== 'undefined' && setValue(props.initialValue);
  }, [props.initialValue]);

  useEffect(() => {
    value !== null && props.onChange(value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  return (
    <Grid item sm={12} md={6}>
      <div className={classes.toggleContainer}>
        <ToggleButtonGroup
          exclusive
          value={value}
          onChange={handleToggle}
          aria-label="toggle"
        >
          <ToggleButton value={true} aria-label={props.yesLabel || 'yes'}>
            {props.yesLabel || <Icon icon="check" />}
          </ToggleButton>
          <ToggleButton
            disabled
            value={null}
            aria-label={
              props.label || Translation.getTranslation('please_choose')
            }
          >
            <Typography
              variant="subtitle2"
              component="span"
              color="textPrimary"
            >
              <strong>
                {props.label || Translation.getTranslation('please_choose')}
              </strong>
            </Typography>
          </ToggleButton>
          <ToggleButton value={false} aria-label={props.noLabel || 'no'}>
            {props.noLabel || <Icon icon="times" />}
          </ToggleButton>
        </ToggleButtonGroup>
      </div>
    </Grid>
  );
}
