//React Assets
import * as React from 'react';
import RequestManager from '../../manager/request.manager';
import { Alert } from '../../services/alert';
import Helper from '../../services/helper';
import Translation from '../../services/translation';
import Input from '../Shared/Input';
import Modal from '../Shared/Modal';
import SearchableSelect from '../Shared/SearchableSelect';

interface Props {
  match?: any;
  location?: any;
  children?: any;
  isOpen?: boolean;
  history?: any;
  reloadData?: any;
  modalClosed?: any;
  ids: string[];
  currentDesignerId?: string;
}

function BulkAssignModal(props: Props) {
  const [isOpen, setIsOpen] = React.useState(false);
  const [designers, setDesigners] = React.useState<any>([]);
  const [selectedDesigner, setSelectedDesigner] = React.useState<string>('');
  const [comment, setComment] = React.useState<string>('');

  const toggleModal = async () => {
    if (!isOpen) {
      Helper.gaRegisterModalView('faq');
      await getDesigners();
    } else {
      props.modalClosed && props.modalClosed();
    }
    setIsOpen(!isOpen);
  };

  React.useEffect(() => {
    typeof props.isOpen !== 'undefined' &&
      props.isOpen === true &&
      props.isOpen !== isOpen &&
      toggleModal();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.isOpen]);

  const getDesigners = async () => {
    let allDesigners = await RequestManager.getUsersByRole('designer');
    if (allDesigners) {
      setDesigners([...allDesigners]);
    }
  };

  const assignDesigner = async () => {
    if (selectedDesigner && props.ids && props.ids.length > 0) {
      let promises = [];
      for (const id of props.ids) {
        promises.push(
          await RequestManager.putProjectPreferences(
            { designerId: selectedDesigner },
            id,
          )
            .then(async (success: any) => {
              if (props.ids.length === 1 && comment) {
                await RequestManager.postComment(
                  {
                    comment,
                    forDesigner: true,
                  },
                  id,
                );
              }
              return new Promise((resolve, reject) => {
                return resolve(success);
              });
            })
            .catch((error: any) => {
              return new Promise((resolve, reject) => {
                return reject();
              });
            }),
        );
      }
      Promise.all(promises)
        .then((success: any) => {
          Alert.open(
            'success',
            Translation.getTranslation('status_assigned'),
            Translation.getTranslation('designer_assigned'),
          );
        })
        .catch((error: any) => {
          Alert.open(
            'success',
            Translation.getTranslation('error'),
            Translation.getTranslation('designer_not_assigned'),
          );
        });
    }
    props.reloadData && props.reloadData();
    toggleModal();
  };

  const renderModal = () => {
    return (
      <Modal
        disabled={!selectedDesigner}
        isOpen={isOpen}
        toggleModal={() => toggleModal()}
        position="top"
        size="lg"
        header={
          props.ids.length > 1
            ? Translation.getTranslation('order_bulk_assign')
            : Translation.getTranslation('assign_designer')
        }
        saveLabel={
          props.ids.length > 1
            ? `${Translation.getTranslation('bulk_assign')} (${
                props.ids.length
              })`
            : Translation.getTranslation('assign')
        }
        onSave={() => assignDesigner()}
      >
        <SearchableSelect
          data={designers.map((designer: any) => {
            return {
              value: designer.id,
              label: designer.company,
              isDisabled:
                props.currentDesignerId &&
                props.currentDesignerId === designer.id
                  ? true
                  : false,
            };
          })}
          onChange={(e: any) => setSelectedDesigner(e)}
          defaultValue={{
            value: '',
            label: Translation.getTranslation('select_designer'),
            isDisabled: true,
          }}
          value={selectedDesigner}
          hasError={!selectedDesigner}
          className={`text-left`}
        />
        {props.ids.length === 1 && (
          <React.Fragment>
            <h5 className="mt-4 mb-3 text-left">
              {Translation.getTranslation('add_comment')}
            </h5>
            <Input
              id="reasonTextarea"
              multiline
              label={Translation.getTranslation('comment')}
              rows={5}
              value={comment}
              onChange={(e: any) => {
                setComment(e.target.value);
              }}
            />
          </React.Fragment>
        )}
      </Modal>
    );
  };

  return (
    <React.Fragment>
      {props.children && (
        <span onClick={() => toggleModal()}>{props.children}</span>
      )}
      {renderModal()}
    </React.Fragment>
  );
}

export default BulkAssignModal;
