import { CircularProgress, makeStyles } from '@material-ui/core';
import * as React from 'react';
import { primary, secondary } from '../../services/styles';
import '../../styles/Spinner.scss';

const useStyles = makeStyles((_theme) => ({
  loader: {
    borderTop: `1.1em solid ${primary}`,
    borderRight: `1.1em solid ${primary}`,
    borderBottom: `1.1em solid ${primary}`,
    borderLeft: `1.1em solid ${secondary}`,
  },
}));

function Spinner(props: { small?: boolean }) {
  const classes = useStyles();
  return !props.small ? (
    <div className={`loader ${classes.loader}`}>
      <span className="sr-only">Loading...</span>
    </div>
  ) : (
    <CircularProgress color="primary" />
  );
}

export default Spinner;
