import { Typography } from '@material-ui/core';
import React from 'react';
import { Link } from 'react-router-dom';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import toastr from 'toastr';
import Button from '../components/Shared/Button';
import Card from '../components/Shared/Card';
import ComponentHeading from '../components/Shared/ComponentHeading';
import Input from '../components/Shared/Input';
import LoginBox from '../components/Shared/LoginBox';
import { ForgotPasswordModal } from '../components/User/ForgotPasswordModal';
import RequestManager from '../manager/request.manager';
import { userState } from '../services/atoms';
import Static from '../services/static';
import Translation from '../services/translation';

interface Props {
  match?: any;
  location?: any;
  history?: any;
}

export default function Login(props: Props) {
  const [email, setEmail] = React.useState<string>('');
  const setUser = useSetRecoilState(userState);
  const user = useRecoilValue(userState);
  const [password, setPassword] = React.useState<string>('');
  const [passwordModalIsOpen, setPasswordModalIsOpen] = React.useState<boolean>(
    false,
  );

  React.useEffect(() => {
    if (user && user.id && localStorage.getItem('jwt')) {
      props.history.push('/');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const handleChangePassword = (passwordTemp: string) => {
    setPassword(passwordTemp);
  };
  const handleChangeEmail = (emailTemp: string) => {
    setEmail(emailTemp);
  };

  const handleLogin = async (email: string, password: string) => {
    let login = await RequestManager.login(email, password);
    if (login) {
      toastr.info(
        Translation.getTranslation('welcome_back') +
          ' ' +
          login.firstName +
          ' ' +
          login.lastName +
          '!',
      );
      // set language cookie
      Translation.setLanguage(login.language);
      // set user in layout
      setUser(login);
    }
  };

  return (
    <LoginBox>
      <Card elevation={0}>
        <ComponentHeading value={Translation.getTranslation('login')} />
        <Input
          label={Translation.getTranslation('your_email')}
          id="loginEmailInput"
          autocomplete="email"
          icon="envelope"
          type="email"
          className="mb-2"
          onKeyPress={(e: any) =>
            e.key === 'Enter' && handleLogin(email, password)
          }
          value={email}
          onChange={(e: any) => handleChangeEmail(e.target.value)}
        />
        <Input
          label={Translation.getTranslation('your_password')}
          id="loginPwInput"
          autocomplete="current-password"
          icon="lock"
          className="mb-2"
          type="password"
          onChange={(e: any) => handleChangePassword(e.target.value)}
          onKeyPress={(e: any) =>
            e.key === 'Enter' && handleLogin(email, password)
          }
          value={password}
        />
        <div className="text-center py-4 mt-1">
          <Button
            color="primary"
            size="lg"
            className="w-100"
            onClick={() => handleLogin(email, password)}
            disabled={!email || !password}
          >
            Login
          </Button>
          <br />
          <br />
          <Link to={Static.getPageByName('register').url}>
            <Typography color="primary">
              {Translation.getTranslation('not_registered')}
            </Typography>
          </Link>
          <Typography
            className=" mt-2"
            style={{ cursor: 'pointer' }}
            onClick={() => setPasswordModalIsOpen(true)}
          >
            {Translation.getTranslation('forgot_password')}
          </Typography>
        </div>
        <ForgotPasswordModal
          isOpen={passwordModalIsOpen}
          toggleModal={() => {
            setPasswordModalIsOpen(false);
          }}
        ></ForgotPasswordModal>
      </Card>
    </LoginBox>
  );
}
