import { Typography } from '@material-ui/core';
import React, { useState } from 'react';
import Translation from '../../services/translation';
import Button from './Button';

interface PageHeading {
  value: string;
  size?: 'h3' | 'h4' | 'h5' | 'h6';
  explain?: string;
  className?: string;
  addition?: any;
  noMargin?: boolean;
  additionPullRight?: boolean;
  children?: any;
  color?: string;
}

function ComponentHeading(props: PageHeading) {
  const [explain, setExplain] = useState(false);

  return (
    <div className={''}>
      <Typography
        color={props.color as any}
        variant={props.size || 'h5'}
        className={` ${props.className || ''} ${
          !props.noMargin ? 'mb-2 mt-4' : ''
        }`}
        style={{
          fontWeight: 300,
          marginBottom: props.explain ? '0rem' : '',
          maxWidth: '100%',
          display: 'flex',
          justifyContent: props.additionPullRight ? 'space-between' : undefined,
          alignItems: 'center',
        }}
      >
        <div>
          {props.value}
          {props.explain && (
            <Button
              text
              onClick={() => setExplain(!explain)}
              // tooltip={props.explain}
              icon="question-circle"
            >
              {/* <Icon icon="question-circle" size="2x" /> */}
              {Translation.getTranslation('help')}
            </Button>
          )}
        </div>
        <span>{props.addition}</span>
      </Typography>
      {explain && props.explain && (
        <div className="mb-2">
          <Typography variant="subtitle1">{props.explain}</Typography>
        </div>
      )}
      {props.children && <div className="pl-3">{props.children}</div>}
    </div>
  );
}

export default ComponentHeading;
