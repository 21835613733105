import { Typography } from '@material-ui/core';
import * as React from 'react';

interface PageHeading {
  children: any;
}

function PageHeading(props: PageHeading) {
  return (
    <Typography
      variant="h3"
      // className="h1-responsive"
      style={{ overflow: 'overlay' }}
    >
      {props.children}
    </Typography>
  );
}

export default PageHeading;
