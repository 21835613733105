import ReactGA from 'react-ga';
import { FileTypes } from '../interfaces/project';
import { Alert } from '../services/alert';
import Helper from '../services/helper';
import Translation from '../services/translation';
import RequestManager from './request.manager';

export default class UploadsManager {
  static handleFileUpload = async (files: any, projectId: string) => {
    ReactGA.event({
      category: 'Project',
      action: 'file_upload',
    });
    let responses = [];
    for (const file of files) {
      const formData = new FormData();
      formData.append('file', file.file);
      formData.append('projectId', projectId);
      formData.append('name', file.name ?? '');
      formData.append('visibility', file.visibility ?? '');
      formData.append('description', file.description ?? '');
      responses.push(
        await RequestManager.uploadFile(formData)
          .then((s) => {
            return new Promise((resolve: any, reject: any) => resolve(s));
          })
          .catch((e) => {
            return new Promise((resolve: any, reject: any) => reject(e));
          }),
      );
    }
    return Promise.all(responses);
  };

  static reCheckUploadingStatus = async (fileTemp: FileTypes, setter: any) => {
    if (fileTemp?.uploading === true && fileTemp?.id) {
      const makeRequest = async () => {
        const newFileTemp = (await RequestManager.getUpload(
          fileTemp.id + '',
        )) as FileTypes;
        if (newFileTemp?.uploading === false) {
          setter(newFileTemp);
        }
        return newFileTemp;
      };

      for (var i = 0; i < 10; i++) {
        const newFileTemp = await makeRequest();
        if (newFileTemp?.uploading === false) break;
        await Helper.timer(3000);
      }
    }
  };

  static downloadFile(url: string, filename?: string) {
    ReactGA.event({
      category: 'Project',
      action: 'file_download',
    });
    const link = document.createElement('a');

    if (filename) link.setAttribute('download', filename);
    else link.setAttribute('target', '_blank');

    link.href = url;
    document.body.appendChild(link);
    // link.click();
    let clickEvent = undefined;
    if ('ontouchstart' in window) {
      clickEvent = new Event('touchstart');
    } else {
      clickEvent = new MouseEvent(
        'click',
        // 'ontouchstart' in window ? 'touchstart' : 'click',
        {
          view: window,
          bubbles: true,
          cancelable: true,
        },
      );
    }
    link.dispatchEvent(clickEvent);
    document.body.removeChild(link);
  }

  static getAndDownloadFileById = async (fileId: string) => {
    let file = await RequestManager.getDownloadLink(fileId);
    if (file && file.url) {
      UploadsManager.downloadFile(file.url);
    }
  };

  static deleteFileConfirmation = async (id: string): Promise<boolean> => {
    // let response = false;
    return await Alert.confirm({
      type: 'warning',
      title: Translation.getTranslation('delete_file'),
      text: Translation.getTranslation('sure_delete_file'),
      confirmButtonText: Translation.getTranslation('delete'),
    }).then(async (answer: boolean) => {
      if (answer) {
        let file = (await RequestManager.deleteFile(id)) as boolean;
        if (file) {
          Alert.open(
            'success',
            Translation.getTranslation('deleted'),
            Translation.getTranslation('delete_file_confirmation'),
          );
          return true;
        }
        return false;
      } else {
        return false;
      }
    });
  };
}
