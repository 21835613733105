import { AppBar, Toolbar, Typography } from '@material-ui/core';
import * as React from 'react';
import { Link, useHistory } from 'react-router-dom';
import myDentiLogo from '../../images/myDentiLogoTransPlain.png';
import UserInterface from '../../interfaces/user.interface';
import RequestManager from '../../manager/request.manager';
import UserManager from '../../manager/user.manager';
import { checkUserRole } from '../../services/helper';
import Static from '../../services/static';
import Translation from '../../services/translation';
import HelpModal from '../User/HelpModal';
import NotificationsModal from '../User/NotificationsModal';
import Button from './Button';
import MenuButton from './MenuButton';

interface Props {
  match?: any;
  location?: any;
  position?: any;
  user: UserInterface;
  logout: (logout: boolean) => void;
}

function Navigation(props: Props) {
  let history = useHistory();
  const [helpOpen, setHelpOpen] = React.useState(false);
  const [logo, setLogo] = React.useState(myDentiLogo);

  React.useEffect(() => {
    getUserLogo();
  }, []);

  const getUserLogo = async () => {
    const logoUrl = await UserManager.returnUserImage(props.user || undefined);
    if (logoUrl) {
      setLogo(logoUrl);
    }
  };

  const MemoRenderLogo = React.useMemo(() => {
    return (
      <img
        key={'myDentiBrandLogo'}
        src={logo}
        alt="myDenti Logo"
        onError={(e: any) => {
          e.target.src = myDentiLogo;
        }}
        style={{
          maxWidth: '8rem',
          maxHeight: '50px',
          minWidth: '5rem',
        }}
      />
    );
  }, [logo]);

  const handleLogout = async () => {
    let logout = await RequestManager.logout();
    logout && props.logout(true);
  };

  const getItems = () => {
    let items = [] as any;
    if (props.user.role === 'user') {
      items.push({
        label: Translation.getTranslation('add_order'),
        icon: 'plus',
        onClick: () => history.push(Static.getPageByName('order_create').url),
      });
    }
    if (props.user.role === 'admin') {
      items.push({
        label: Translation.getTranslation('analytics'),
        icon: 'chart-pie',
        onClick: () => history.push(Static.getPageByName('analytics').url),
      });
    }
    if (
      UserManager.checkIfPayedForService(props.user, {
        service: 'open_archive',
      })
    ) {
      items.push({
        label: Translation.getTranslation('archive'),
        icon: 'box-open',
        onClick: () => history.push(Static.getPageByName('archive').url),
      });
    }

    if (
      (props.user.role === 'user' &&
        UserManager.checkIfPayedForService(props.user, {
          service: 'open_my_connections',
        })) ||
      props.user.role === 'designer'
    ) {
      items.push({
        label: Translation.getTranslation('connections'),
        icon: 'link',
        onClick: () => history.push(Static.getPageByName('connections').url),
      });
    }
    items.push(
      {},
      {
        label: Translation.getTranslation('help'),
        icon: 'question',
        onClick: () => setHelpOpen(true),
      },
      {
        label: Translation.getTranslation('preferences'),
        icon: 'tools',
        onClick: () => history.push(Static.getPageByName('user_settings').url),
      },
      {
        label: Translation.getTranslation('logout'),
        icon: 'power-off',
        onClick: () => handleLogout(),
      },
    );

    return items;
  };

  return (
    <React.Fragment>
      <AppBar position="static" color={'primary'}>
        <Toolbar className="d-flex justify-content-between align-items-center">
          <div className="d-flex align-items-center mr-2">
            <Link
              key={Math.random()}
              to={{
                pathname: Static.getPageByName('dashboard').url,
                state: { key: Math.random() },
              }}
              className="mr-4"
            >
              {checkUserRole(props.user).isAdmin() ? (
                <Typography
                  variant="h4"
                  className="mb-0 pb-0 text-danger align-self-end font-weight-bolder"
                >
                  Admin
                </Typography>
              ) : (
                MemoRenderLogo
              )}
            </Link>
            {/* <Drawer /> */}
            {/* <Breadcrumb positions={props.position} /> */}
            <Button
              outline
              className="text-white border-white d-none d-sm-block"
              tooltip={Translation.getTranslation('page_back')}
              onClick={() => history.goBack()}
              icon="hand-point-left"
            />
            {props.position.length > 1 && (
              <Button
                outline
                className="text-white border-white d-none d-sm-block"
                tooltip={Translation.getTranslation('back_home')}
                onClick={() =>
                  history.push(Static.getPageByName('dashboard').url)
                }
                icon="home"
              />
            )}
          </div>
          <div className="">
            <NotificationsModal />

            <MenuButton
              outline
              className="text-white border-white"
              label={Translation.getTranslation('menu')}
              items={getItems()}
              hideChevron
              icon={'bars'}
            />
          </div>
        </Toolbar>
      </AppBar>
      <HelpModal
        isOpen={helpOpen}
        toggleModal={() => {
          setHelpOpen(!helpOpen);
        }}
      />
    </React.Fragment>
  );
}

export default Navigation;
