import { Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import Button from '../components/Shared/Button';
import Card from '../components/Shared/Card';
import Checkbox from '../components/Shared/Checkbox';
import ComponentHeading from '../components/Shared/ComponentHeading';
import Input from '../components/Shared/Input';
import LoginBox from '../components/Shared/LoginBox';
import Select from '../components/Shared/Select';
import UserInterface from '../interfaces/user.interface';
import RequestManager from '../manager/request.manager';
import { Alert } from '../services/alert';
import { userState } from '../services/atoms';
import Helper from '../services/helper';
import Static from '../services/static';
import Translation from '../services/translation';

interface Props {
  match?: any;
  location?: any;
  history?: any;
}

export default function Register(props: Props) {
  const [user, setUser] = useState<UserInterface>({
    email: '',
    firstName: '',
    lastName: '',
    company: '',
    street: '',
    zip: '',
    city: '',
    tel: '',
    taxNumber: '',
    language: 'de',
    password: '',
    inviteCode: '',
  });
  const [acceptedTerms, setAcceptedTerms] = useState(false);
  const [acceptedPrivacy, setAcceptedPrivacy] = useState(false);
  const setUserState = useSetRecoilState(userState);
  const getUserState = useRecoilValue(userState);

  useEffect(() => {
    if (props.match.params.inviteId) {
      let userTemp = { ...user };
      userTemp.inviteCode = props.match.params.inviteId;
      setUser(userTemp);
    }
  }, []);

  React.useEffect(() => {
    if (getUserState && getUserState.id && localStorage.getItem('jwt')) {
      props.history.push('/');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getUserState]);

  const handleRegister = async () => {
    if (acceptedPrivacy && acceptedTerms) {
      let register = await RequestManager.register(user);
      if (register) {
        await Alert.open(
          'success',
          Translation.getTranslation('registered'),
          Translation.getTranslation('registered_text') +
            ' ' +
            register.firstName +
            ' ' +
            register.lastName +
            '!',
        );
        setUserState(register);
        // props.history.push('/');
      }
    } else {
      if (!acceptedPrivacy)
        Alert.open('error', Translation.getTranslation('not_accept_privacy'));
      if (!acceptedTerms)
        Alert.open('error', Translation.getTranslation('not_accept_terms'));
    }
  };

  return (
    <LoginBox>
      <Card elevation={0}>
        <ComponentHeading value={Translation.getTranslation('register')} />

        <ComponentHeading
          value={Translation.getTranslation('language')}
          size="h6"
        />
        <Select
          className="mb-4"
          onChange={(e: any) => {
            Translation.setLanguage(e.target.value);
            Helper.handleChangesInObject(
              [
                {
                  value: e.target.value,
                  attribute: 'language',
                },
              ],
              user,
              setUser,
            );
          }}
          value={user.language}
        >
          <option value={'de'} key={'de'}>
            {Translation.getTranslation('german')}
          </option>
          <option value={'en'} key={'en'}>
            {Translation.getTranslation('english')}
          </option>
          <option value={'es'} key={'es'}>
            {Translation.getTranslation('spanish')}
          </option>
        </Select>
        <ComponentHeading
          value={Translation.getTranslation('access')}
          size="h6"
        />
        <Input
          label={Translation.getTranslation('invite_code') + '*'}
          id="registerInviteInput"
          disabled={props.match.params.inviteId ? true : false}
          value={user.inviteCode ?? ''}
          icon="key"
          type="text"
          onChange={(e: any) => {
            Helper.handleChangesInObject(
              [
                {
                  value: e.target.value,
                  attribute: 'inviteCode',
                },
              ],
              user,
              setUser,
            );
          }}
          small
          className="mb-2"
          hasError={
            user.inviteCode === ''
              ? false
              : Helper.validate('invite', user.inviteCode).length > 0
              ? true
              : false
          }
          helperText={
            user.inviteCode !== '' &&
            Helper.validate('invite', user.inviteCode).length > 0 &&
            Helper.validate('invite', user.inviteCode)
          }
        />

        <Input
          label={Translation.getTranslation('email') + '*'}
          id="registerEmailInput"
          autocomplete="email"
          icon="envelope"
          small
          className="mb-2"
          type="email"
          onChange={(e: any) => {
            Helper.handleChangesInObject(
              [
                {
                  value: e.target.value,
                  attribute: 'email',
                },
              ],
              user,
              setUser,
            );
          }}
          value={user.email}
          hasError={
            user.email === ''
              ? false
              : Helper.validate('email', user.email).length > 0
              ? true
              : false
          }
          helperText={
            user.email !== '' &&
            Helper.validate('email', user.email).length > 0 &&
            Helper.validate('email', user.email)
          }
        />

        <Input
          label={Translation.getTranslation('password') + '*'}
          autocomplete="new-password"
          id="registerPasswordInput"
          icon="lock"
          small
          className="mb-2"
          type="password"
          onChange={(e: any) => {
            Helper.handleChangesInObject(
              [
                {
                  value: e.target.value,
                  attribute: 'password',
                },
              ],
              user,
              setUser,
            );
          }}
          value={user.password ? user.password : ''}
          hasError={
            user.password === ''
              ? false
              : Helper.validate('password', user.password).length > 0
              ? true
              : false
          }
          helperText={
            user.password !== '' &&
            Helper.validate('password', user.password).length > 0 &&
            Helper.validate('password', user.password)
          }
        />

        <ComponentHeading
          value={Translation.getTranslation('contact')}
          size="h6"
        />
        <Input
          label={Translation.getTranslation('firstName') + '*'}
          id="registerFirstnameInput"
          autocomplete="given-name"
          icon="user"
          small
          className="mb-2"
          type="text"
          onChange={(e: any) => {
            Helper.handleChangesInObject(
              [
                {
                  value: e.target.value,
                  attribute: 'firstName',
                },
              ],
              user,
              setUser,
            );
          }}
          value={user.firstName ?? ''}
          hasError={
            user.firstName === ''
              ? false
              : Helper.validate('name', user.firstName).length > 0
              ? true
              : false
          }
          helperText={
            user.firstName !== '' &&
            Helper.validate('name', user.firstName).length > 0 &&
            Helper.validate('name', user.firstName)
          }
        />
        <Input
          label={Translation.getTranslation('lastName') + '*'}
          id="registerLastnameInput"
          autocomplete="family-name"
          icon="signature"
          small
          className="mb-2"
          type="text"
          onChange={(e: any) => {
            Helper.handleChangesInObject(
              [
                {
                  value: e.target.value,
                  attribute: 'lastName',
                },
              ],
              user,
              setUser,
            );
          }}
          value={user.lastName ?? ''}
          hasError={
            user.lastName === ''
              ? false
              : Helper.validate('name', user.lastName).length > 0
              ? true
              : false
          }
          helperText={
            user.lastName !== '' &&
            Helper.validate('name', user.lastName).length > 0 &&
            Helper.validate('name', user.lastName)
          }
        />
        <Input
          label={Translation.getTranslation('tel') + '*'}
          id="registerPhoneInput"
          icon="phone"
          autocomplete="tel"
          small
          className="mb-2"
          type="text"
          onChange={(e: any) => {
            Helper.handleChangesInObject(
              [
                {
                  value: e.target.value,
                  attribute: 'tel',
                },
              ],
              user,
              setUser,
            );
          }}
          value={user.tel ?? ''}
          hasError={
            user.tel === ''
              ? false
              : Helper.validate('tel', user.tel).length > 0
              ? true
              : false
          }
          helperText={
            user.tel !== '' &&
            Helper.validate('tel', user.tel).length > 0 &&
            Helper.validate('tel', user.tel)
          }
        />
        <ComponentHeading
          value={Translation.getTranslation('company')}
          size="h6"
        />
        <Input
          label={Translation.getTranslation('company_name') + '*'}
          id="registerCompanyInput"
          icon="briefcase"
          small
          className="mb-2"
          type="text"
          onChange={(e: any) => {
            Helper.handleChangesInObject(
              [
                {
                  value: e.target.value,
                  attribute: 'company',
                },
              ],
              user,
              setUser,
            );
          }}
          value={user.company ?? ''}
          hasError={
            user.company === ''
              ? false
              : Helper.validate('company', user.company).length > 0
              ? true
              : false
          }
          helperText={
            user.company !== '' &&
            Helper.validate('company', user.company).length > 0 &&
            Helper.validate('company', user.company)
          }
        />
        <Input
          label={Translation.getTranslation('street') + '*'}
          id="registerStreetInput"
          icon="road"
          small
          className="mb-2"
          type="text"
          onChange={(e: any) => {
            Helper.handleChangesInObject(
              [
                {
                  value: e.target.value,
                  attribute: 'street',
                },
              ],
              user,
              setUser,
            );
          }}
          value={user.street ?? ''}
          hasError={
            user.street === ''
              ? false
              : Helper.validate('street', user.street).length > 0
              ? true
              : false
          }
          helperText={
            user.street !== '' &&
            Helper.validate('street', user.street).length > 0 &&
            Helper.validate('street', user.street)
          }
        />
        <Input
          label={Translation.getTranslation('zip') + '*'}
          id="registerZipInput"
          icon="street-view"
          small
          className="mb-2"
          type="text"
          onChange={(e: any) => {
            Helper.handleChangesInObject(
              [
                {
                  value: e.target.value,
                  attribute: 'zip',
                },
              ],
              user,
              setUser,
            );
          }}
          value={user.zip ?? ''}
          hasError={
            user.zip === ''
              ? false
              : Helper.validate('zip', user.zip).length > 0
              ? true
              : false
          }
          helperText={
            user.zip !== '' &&
            Helper.validate('zip', user.zip).length > 0 &&
            Helper.validate('zip', user.zip)
          }
        />
        <Input
          label={Translation.getTranslation('city') + '*'}
          id="registerCityInput"
          icon="city"
          small
          className="mb-2"
          type="text"
          onChange={(e: any) => {
            Helper.handleChangesInObject(
              [
                {
                  value: e.target.value,
                  attribute: 'city',
                },
              ],
              user,
              setUser,
            );
          }}
          value={user.city ?? ''}
          hasError={
            user.city === ''
              ? false
              : Helper.validate('city', user.city).length > 0
              ? true
              : false
          }
          helperText={
            user.city !== '' &&
            Helper.validate('city', user.city).length > 0 &&
            Helper.validate('city', user.city)
          }
        />
        <Input
          label={Translation.getTranslation('tax_number')}
          id="registerTaxInput"
          icon="address-card"
          small
          className="mb-2"
          type="text"
          onChange={(e: any) => {
            Helper.handleChangesInObject(
              [
                {
                  value: e.target.value,
                  attribute: 'taxNumber',
                },
              ],
              user,
              setUser,
            );
          }}
          value={user.taxNumber ?? ''}
        />
        <Checkbox
          label={Translation.getTranslation('accept_terms')}
          checked={acceptedTerms}
          onChange={(e: boolean) => {
            setAcceptedTerms(e);
          }}
        />
        <Checkbox
          label={Translation.getTranslation('accept_privacy')}
          checked={acceptedPrivacy}
          onChange={(e: boolean) => {
            setAcceptedPrivacy(e);
          }}
        />
        <div className="text-center py-4 mt-3">
          <Button
            color="primary"
            className="w-100"
            size="lg"
            disabled={!Helper.validateData(user)}
            onClick={() => handleRegister()}
          >
            {Translation.getTranslation('register')}
          </Button>
          <br></br>
          <br></br>
          <Link to={Static.getPageByName('login').url}>
            <Typography color="primary">
              {Translation.getTranslation('already_registered')}
            </Typography>
          </Link>
        </div>
      </Card>
    </LoginBox>
  );
}
