import { Link } from '@material-ui/core';
import React, { useState } from 'react';
import { useRecoilValue } from 'recoil';
import { userState } from '../../services/atoms';
import Helper from '../../services/helper';
import Translation from '../../services/translation';
import Modal from './Modal';

interface Props {
  authorId: string;
  text: string;
}

/**
 *  Extract links from a string and render as <a></a> or preview iframe.
 *  Links are deleted from text and displayed below
 *  - Author sees link
 *  - All others iframe
 */
function TextLinks(props: Props) {
  const user = useRecoilValue(userState);
  const [text, setText] = useState<any>('');
  const [modalOpen, setModalOpen] = useState<string>('');

  // const formatLabel = (
  //   completeText: string,
  //   links: string[],
  //   isAuthor?: boolean,
  // ) => {
  //   let returnValue = [] as any;
  //   if (!links) {
  //     return completeText;
  //   }
  //   for (const link of links) {
  //     returnValue.push(
  //       <span>
  //         {completeText.split(link).reduce((prev: any, current, i) => {
  //           if (!i) {
  //             return [current];
  //           }

  //           return prev.concat(
  //             !isAuthor ? (
  //               <a
  //                 href={link}
  //                 target="_blank"
  //                 rel="noopener noreferrer"
  //                 key={'link_' + link}
  //               >
  //                 ({Translation.getTranslation('external_link')})
  //               </a>
  //             ) : (
  //               <span
  //                 className="heavy-link cursor-pointer"
  //                 onClick={() => {
  //                   setModalOpen(link);
  //                 }}
  //               >
  //                 ({Translation.getTranslation('external_link')})
  //               </span>
  //             ),
  //             current,
  //           );
  //         }, [])}
  //       </span>,
  //     );
  //   }
  //   return returnValue;
  // };

  const extractLinks = (
    completeText: string,
    links: string[],
    isAuthor?: boolean,
  ) => {
    if (!links) {
      return completeText;
    }
    let resultText = [];
    for (let index = 0; index < links.length; index++) {
      completeText = completeText.replace(links[index], `(Link ${index + 1})`);
    }
    resultText.push(
      <React.Fragment key={'text_' + completeText.substring(0, 8)}>
        {completeText}
        <br />
        <strong className="mr-1">
          {Translation.getTranslation('external_links')}:
        </strong>
        {/* <br /> */}
      </React.Fragment>,
    );
    for (let index = 0; index < links.length; index++) {
      resultText.push(
        <React.Fragment key={'span_' + links[index]}>
          <Link
            href={isAuthor ? Helper.optimizeLink(links[index]) : '#'}
            target="_blank"
            rel="noopener noreferrer"
            className="mr-1"
            onClick={
              !isAuthor
                ? (e: any) => {
                    e.preventDefault();
                    setModalOpen(Helper.optimizeLink(links[index]));
                  }
                : undefined
            }
          >
            ({Translation.getTranslation('link')} {index + 1})
          </Link>
        </React.Fragment>,
      );
      // typeof links[index + 1] !== 'undefined' && resultText.push(<br />);
    }
    return resultText;
  };
  // const formatLabel = (
  //   completeText: string,
  //   link: string,
  //   isAuthor?: boolean,
  // ) => {
  //   if (!link) {
  //     return completeText;
  //   }
  //   return (
  //     <span>
  //       {completeText.split(link).reduce((prev: any, current, i) => {
  //         if (!i) {
  //           return [current];
  //         }
  //         return prev.concat(
  //           isAuthor ? (
  //             <a
  //               href={link}
  //               target="_blank"
  //               rel="noopener noreferrer"
  //               key={'link_' + link}
  //             >
  //               ({Translation.getTranslation('external_link')})
  //             </a>
  //           ) : (
  //             <span
  //               className="heavy-link cursor-pointer"
  //               onClick={() => {
  //                 setModalOpen(link);
  //               }}
  //             >
  //               ({Translation.getTranslation('external_link')})
  //             </span>
  //           ),
  //           current,
  //         );
  //       }, [])}
  //     </span>
  //   );
  // };

  const renderModal = () => {
    return (
      <Modal
        isOpen={modalOpen !== ''}
        toggleModal={() => {
          setModalOpen('');
        }}
        size="lg"
        header={''}
      >
        <iframe
          src={modalOpen}
          style={{
            position: 'relative',
            height: '70vh',
            maxHeight: '100%',
            border: 'none',
          }}
          title={'external_link_modal'}
          width={'100%'}
        />
      </Modal>
    );
  };

  React.useEffect(() => {
    let links = Helper.extractUrls(props.text);
    let textTemp = props.text as any;
    if (links) {
      textTemp = extractLinks(props.text, links, user.id === props.authorId);
    }
    setText(textTemp);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.text]);

  return (
    <React.Fragment>
      <span>{text}</span>
      {renderModal()}
    </React.Fragment>
  );
}

export default TextLinks;
