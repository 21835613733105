import { Typography } from '@material-ui/core';
import React, { useEffect } from 'react';
import BootstrapModal from 'react-bootstrap/Modal';
import Translation from '../../services/translation';
import Button from './Button';

interface Modal {
  position?: 'top' | 'bottom' | 'left' | 'right';
  isOpen?: boolean;
  noPadding?: boolean;
  size?: 'sm' | 'lg' | 'xl';
  header?: any;
  body?: any;
  footer?: any;
  hasNoClose?: boolean;
  hasNoFooter?: boolean;
  toggleModal?: any;
  children?: any;
  disabled?: boolean;
  bodyCenter?: boolean;
  onSave?: any;
  saveLabel?: string;
}

function Modal(props: Modal) {
  // const [disabled, setDisabled] = useState(false);

  useEffect(() => {
    // setDisabled(props.disabled ? true : false);
  }, [props]);

  const toggleModal = () => {
    props.toggleModal && props.toggleModal();
  };

  return props.isOpen ? (
    <BootstrapModal
      show={props.isOpen}
      onHide={() => toggleModal()}
      size={props.size}
    >
      {props.header && (
        <BootstrapModal.Header closeButton={props.hasNoClose ? false : true}>
          <Typography variant="h5">{props.header}</Typography>
        </BootstrapModal.Header>
      )}
      <BootstrapModal.Body
        className={` ${props.noPadding ? 'p-0' : ''}${
          props.bodyCenter ? 'align-self-center' : ''
        }`}
      >
        {props.children}
      </BootstrapModal.Body>
      {!props.hasNoFooter && (
        <BootstrapModal.Footer style={{ marginTop: '2rem' }}>
          {!props.hasNoClose && (
            <Button color="default" onClick={() => toggleModal()}>
              {Translation.getTranslation('close')}
            </Button>
          )}
          {props.onSave && (
            <Button
              color="primary"
              disabled={props.disabled}
              onClick={() => props.onSave()}
            >
              {props.saveLabel
                ? props.saveLabel
                : Translation.getTranslation('save')}
            </Button>
          )}
          {props.footer && props.footer}
        </BootstrapModal.Footer>
      )}
    </BootstrapModal>
  ) : (
    <span />
  );
}

export default Modal;
