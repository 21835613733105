import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import * as React from 'react';
import UserInterface from '../../interfaces/user.interface';
import RequestManager from '../../manager/request.manager';
import Helper from '../../services/helper';
import { success } from '../../services/styles';
import Translation from '../../services/translation';
import Button from '../Shared/Button';
import ComponentHeading from '../Shared/ComponentHeading';
import Input from '../Shared/Input';
import Modal from '../Shared/Modal';
import Select from '../Shared/Select';

interface Props {
  match?: any;
  location?: any;
  history?: any;
}

const useStyles = makeStyles((theme) => ({
  heading: {
    color: success,
  },
}));

export function InviteModal(props: Props) {
  const [isOpen, setIsOpen] = React.useState<boolean>(false);
  const [email, setEmail] = React.useState<string>('');
  const [role, setRole] = React.useState<UserInterface['role']>('user');
  const [language, setLanguage] = React.useState<UserInterface['language']>(
    'de',
  );
  const [loading, setLoading] = React.useState<boolean>(false);
  const [invited, setInvited] = React.useState<boolean>(false);
  const [inviteResponse, setInviteResponse] = React.useState<any>(undefined);
  const classes = useStyles();

  const toggleModal = () => {
    let isOpenTemp = !isOpen;
    setIsOpen(isOpenTemp);
    if (!isOpenTemp) reset();
    else {
      Helper.gaRegisterModalView('user_invite');
    }
  };

  const invite = async () => {
    setLoading(true);
    let inviteResponseTemp = await RequestManager.inviteUser(
      email,
      role,
      language,
    );
    if (inviteResponseTemp) {
      setInviteResponse(inviteResponseTemp);
      setInvited(true);
    }
    setLoading(false);
  };

  const reset = () => {
    setEmail('');
    setRole('user');
    setInvited(false);
  };

  const renderModal = () => {
    return (
      <Modal
        isOpen={isOpen}
        toggleModal={() => toggleModal()}
        // size="lg"
        header={Translation.getTranslation('new_invite')}
        disabled={
          !invited && (loading || Helper.validate('email', email).length > 0)
        }
        saveLabel={
          !invited
            ? Translation.getTranslation('invite')
            : Translation.getTranslation('another_invitation')
        }
        onSave={() => (!invited ? invite() : reset())}
      >
        <div className="text-left">
          <ComponentHeading
            value={invited ? '' : Translation.getTranslation('enter_email')}
          >
            <Input
              label={Translation.getTranslation('email') + '*'}
              id="InviteEmailInput"
              value={email}
              disabled={invited}
              icon="envelope"
              small
              className="mb-2"
              type="email"
              onChange={(e: any) => {
                setEmail(e.target.value);
              }}
              hasError={
                email === ''
                  ? false
                  : Helper.validate('email', email).length > 0
                  ? true
                  : false
              }
              helperText={
                email !== '' &&
                Helper.validate('email', email).length > 0 &&
                Helper.validate('email', email)
              }
            />
          </ComponentHeading>
          <ComponentHeading
            value={invited ? '' : Translation.getTranslation('choose_role')}
          >
            <Select
              className="mb-4"
              disabled={invited}
              onChange={(e: any) => {
                setRole(e.target.value);
              }}
              value={role}
            >
              <option value={'user'} key={'user'}>
                {Translation.getTranslation('customer')}
              </option>
              <option value={'designer'} key={'designer'}>
                {Translation.getTranslation('designer')}
              </option>
              <option value={'admin'} key={'admin'}>
                {Translation.getTranslation('admin')}
              </option>
            </Select>
          </ComponentHeading>
          {!invited && (
            <ComponentHeading
              value={
                invited ? '' : Translation.getTranslation('invite_language')
              }
            >
              <Select
                className="mb-4"
                onChange={(e: any) => {
                  setLanguage(e.target.value);
                }}
                value={language}
              >
                <option value={'de'} key={'de'}>
                  {Translation.getTranslation('german')}
                </option>
                <option value={'en'} key={'en'}>
                  {Translation.getTranslation('english')}
                </option>
                <option value={'es'} key={'es'}>
                  {Translation.getTranslation('spanish')}
                </option>
              </Select>
            </ComponentHeading>
          )}

          {invited && (
            <React.Fragment>
              <Typography
                variant="h5"
                component="h5"
                className={classes.heading}
              >
                {Translation.getTranslation('successfully_invited')}
              </Typography>
              <Typography component="p">
                {Translation.getTranslation('user_successfully_invited')}
              </Typography>
              <Typography component="p">
                <strong>{Translation.getTranslation('invite_code')}:</strong>{' '}
                {inviteResponse?.code}
              </Typography>
            </React.Fragment>
          )}
        </div>
      </Modal>
    );
  };

  return (
    <React.Fragment>
      <Button color={'primary'} icon={'plus-square'} onClick={toggleModal}>
        {Translation.getTranslation('new_invite')}
      </Button>
      {renderModal()}
    </React.Fragment>
  );
}
