import {
  FormControl,
  InputLabel,
  makeStyles,
  Select as SelectMUI,
} from '@material-ui/core';
import * as React from 'react';
import { dangerLight } from '../../services/styles';
interface Props {
  disabled?: boolean;
  label?: string;
  children?: any;
  required?: boolean;
  onChange?: any;
  value?: string;
  className?: string;
  style?: any;
  noMargin?: boolean;
}

const useStyles = makeStyles((_theme) => ({
  dangerCell: {
    backgroundColor: dangerLight,
  },
}));

export default function Select(props: Props) {
  const classes = useStyles();

  const renderClasses = (): string => {
    let classesTemp = !props.noMargin ? 'mb-1 ' : '';
    if (props.className) classesTemp += props.className;
    if (props.required && !props.value) classesTemp += ` ${classes.dangerCell}`;
    return classesTemp;
  };

  return (
    <FormControl
      disabled={props.disabled}
      variant="outlined"
      size="small"
      fullWidth
      style={{ width: '100%', minWidth: '8rem', ...props.style }}
      className={renderClasses()}
    >
      {props.label && <InputLabel>{props.label}</InputLabel>}
      <SelectMUI
        fullWidth
        native
        label={props.label}
        value={props.value}
        onChange={(e: any) => {
          props.onChange(e);
        }}
      >
        {props.children}
      </SelectMUI>
    </FormControl>
  );
}
