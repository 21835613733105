import * as React from 'react';
import { useRecoilValue } from 'recoil';
import { userState } from '../services/atoms';
import AdminDashboard from './sub-pages/AdminDashboard';
import { NonAdminDashboard } from './sub-pages/NonAdminDashboard';

interface Props {
  match?: any;
  location?: any;
  history?: any;
}

export default function Dashboard(props: Props) {
  const user = useRecoilValue(userState) as any;
  const [reRender, setReRender] = React.useState(0);

  React.useEffect(() => {
    typeof props.location?.state?.key !== 'undefined' &&
      setReRender(props.location?.state?.key);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.location?.state?.key]);

  const renderDashboard = () => {
    if (user?.role) {
      switch (user.role) {
        case 'user':
          return (
            <NonAdminDashboard
              key={reRender}
              user={user}
              history={props.history}
              location={props.location}
              widgets={[
                {
                  name: 'finished_projects',
                  xl: '12',
                  lg: '12',
                  status: 'finished',
                  bulkMode: ['archive'],
                  conditions: { isArchived: false },
                },
                {
                  name: 'wip_projects',
                  xl: '12',
                  lg: '12',
                  status: 'open',
                },
                {
                  name: 'drafts',
                  xl: '6',
                  lg: '12',
                  status: 'draft',
                },
                {
                  name: 'cancelled_projects',
                  xl: '6',
                  lg: '12',
                  status: 'cancelled',
                  color: 'error',
                  conditions: { isArchived: false },
                },
              ]}
            />
          );
        case 'admin':
          return (
            <AdminDashboard
              key={reRender}
              user={user}
              history={props.history}
              location={props.location}
            />
          );
        case 'designer':
          return (
            <NonAdminDashboard
              key={reRender}
              user={user}
              history={props.history}
              location={props.location}
              widgets={[
                {
                  name: 'not_accepted_projects',
                  xl: '12',
                  lg: '12',
                  status: 'assigned',
                  headerColor: 'danger-color',
                },
                {
                  name: 'wip_projects',
                  xl: '12',
                  lg: '12',
                  status: 'wip_designer',
                  headerColor: 'primary',
                },
                {
                  name: 'wip_corrections',
                  xl: '12',
                  lg: '12',
                  status: 'correction',
                  headerColor: 'primary',
                },
              ]}
            />
          );
        default:
          return <span />;
      }
    }
  };

  return <React.Fragment>{renderDashboard()}</React.Fragment>;
}
