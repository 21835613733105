const rememberMeState: {
  savedState: any[];
} = {
  savedState: [],
};

const staticServices: {
  service: any[];
  material: any[];
  systemService: any[];
} = {
  service: [],
  material: [],
  systemService: [],
};

export class StateManager {
  static setServices(
    id: 'service' | 'material' | 'systemService',
    params: any[],
  ) {
    staticServices[id] = params;
  }

  static getServices(id: 'service' | 'material' | 'systemService') {
    return staticServices[id];
  }

  static setRememberMe(id: string, params: any) {
    let index = rememberMeState.savedState.findIndex(
      (filter: any) => filter.id === id,
    );
    if (index !== -1) {
      this.resetRememberMe(id);
    }
    rememberMeState.savedState.push({ id, ...params });
  }

  static resetRememberMe(id: string) {
    let index = rememberMeState.savedState.findIndex(
      (filter: any) => filter.id === id,
    );
    if (index !== -1) rememberMeState.savedState.splice(index, 1);
  }

  static getRememberMe(id: string) {
    return rememberMeState.savedState.find((filter: any) => filter.id === id);
  }

  static handleFilterChange = async (
    name:
      | 'currentStatus'
      | 'startDate'
      | 'extended'
      | 'endDate'
      | 'query'
      | 'sort'
      | 'designerId'
      | 'userId'
      | 'customerId'
      | 'dateFilter'
      | 'isInternal'
      | 'workType',
    value: any,
    getter: any,
    setter: (filter: any) => void,
    rememberMeName?: string,
  ) => {
    let filterTemp = Object.assign({}, getter as any);
    filterTemp[name] = value;
    filterTemp.page = 0;
    if (rememberMeName) StateManager.setRememberMe(rememberMeName, filterTemp);
    setter(filterTemp);
  };

  static resetFilter = async (
    initialState: any,
    setter: (filter: any) => void,
    rememberMeName: string,
  ) => {
    StateManager.setRememberMe(rememberMeName, initialState);
    setter(initialState);
  };
}
