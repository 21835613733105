import { makeStyles } from '@material-ui/core';
import React, { useState } from 'react';
import Helper from '../../services/helper';
import { danger, grey, warning } from '../../services/styles';
import TextLinks from './TextLinks';

interface Comment {
  message: string;
  date: string;
  authorId: string;
  color?: 'danger' | 'warning';
  prefix?: string;
}

function Comment(props: Comment) {
  const useStyles = makeStyles((_theme) => ({
    comment: {
      maxWidth: '100%',
      textAlign: 'left',
      height: 'fit-content',
      border: `1px solid ${
        props.color === 'danger'
          ? danger
          : props.color === 'warning'
          ? warning
          : grey
      }`,
      position: 'relative',
      padding: '1rem',
      fontSize: '1em',
      // boxShadow: boxShadow,
      marginBottom: '0.5rem',
    },
    small: {
      position: 'absolute',
      top: 0,
      right: '5px',
    },
  }));

  const classes = useStyles();
  const [hover, setHover] = useState(false);

  return (
    <div
      className={`${classes.comment}`}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      <small
        className={classes.small}
        style={{
          display: hover ? '' : 'none',
        }}
      >
        {Helper.getDate(props.date, true)}
      </small>
      <div>
        {props.prefix ? <strong>{props.prefix}: </strong> : ''}
        <TextLinks text={props.message} authorId={props.authorId} />
      </div>
    </div>
  );
}

export default Comment;
