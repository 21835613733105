import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { makeStyles, Typography } from '@material-ui/core';
import React from 'react';
import CookieConsent from 'react-cookie-consent';
import Button from '../components/Shared/Button';
import Footer from '../components/Shared/Footer';
import Icon from '../components/Shared/Icon';
import ConnectionsSettingsImage from '../images/landingpage/connections_settings.png';
import CustomFieldsImage from '../images/landingpage/custom_fields.png';
import OrderCompletionImage from '../images/landingpage/order_completion.png';
import OrderDetailImage from '../images/landingpage/order_detail.svg';
import myDentiLogo from '../images/myDentiLogoTrans.png';
import Static from '../services/static';
import {
  footerHeight,
  landingPageBackground,
  primary,
  semiLightGrey,
} from '../services/styles';

interface Props {
  match?: any;
  location?: any;
  history?: any;
}

const useStyles = makeStyles((_theme) => ({
  root: {
    marginTop: '1rem',
    color: '#ECEDED',
    minHeight: `calc(100vh - ${footerHeight})`,
  },
  primary: { color: primary },
  title: {
    fontSize: 'calc(34px + 2vw)',
    fontWeight: 800,
    lineHeight: 'calc(34px + 2vw)',
    letterSpacing: '-0.3px',
  },
  smallTitle: {
    fontSize: 'calc(30px + 1.5vw)',
    fontWeight: 700,
    lineHeight: '30px',
    letterSpacing: '-0.3px',
  },
  subtitle: {
    fontSize: 'calc(18px + 0.3vw)',
    fontWeight: 300,
    lineHeight: 1.5,
    color: semiLightGrey + ' !important',
  },
  hr: {
    display: 'block',
    height: '1px',
    border: 0,
    borderTop: `1px solid ${'#25282c'}`,
    margin: '1em 0',
    padding: 0,
    marginTop: '4rem',
  },
  text: {
    fontSize: 'calc(18px + 0.1vw)',
    fontWeight: 300,
    lineHeight: 1.5,
    color: semiLightGrey + ' !important',
  },
  testimonial: {
    position: 'relative',

    backgroundColor: '#25282c',
    padding: '2rem 2rem 0.5rem 2rem',
    border: `0.3rem solid ${landingPageBackground}`,
  },
  quoteIcon: {
    position: 'absolute',
    color: primary,
    top: '-1rem',
    left: '-0.5rem',
  },
  starIcon: {
    position: 'absolute',
    color: 'white',
    top: '-1.5rem',
    left: 0,
    right: 0,
    margin: '0 auto',
  },
}));

let bodyCss = `body { background-color: ${landingPageBackground}}`;

export default function LandingPage(props: Props) {
  let classes = useStyles();

  const bullet = (data: { title: string; icon: IconProp; text: string }) => {
    return (
      <div className="d-flex flex-column align-content-center mb-4">
        <span
          className="d-flex flex-column align-content-center justify-content-center align-self-center"
          style={{
            backgroundColor: primary,
            height: '4rem',
            marginBottom: '1rem',
            width: '4rem',
            borderRadius: '2rem',
          }}
        >
          <Icon size="2x" icon={data.icon} />
        </span>
        <Typography variant="h5">{data.title}</Typography>
        <Typography component="p" className={classes.text}>
          {data.text}
        </Typography>
      </div>
    );
  };

  const MyComponents = {
    ColWrapper: function ColWrapper(props: { content: any }) {
      return <div className="col-md-4 col-sm-6 col-xs-12">{props.content}</div>;
    },
    SectionWrapper: function SectionWrapper(props: {
      children: any;
      noHr?: boolean;
    }) {
      return (
        <div style={{ marginBottom: '6rem' }}>
          {props.children}
          {!props.noHr && <hr className={classes.hr} />}
        </div>
      );
    },
  };

  const renderSubtitle = (data: { title: string; subtitle: String }) => {
    return (
      <React.Fragment>
        <Typography
          variant="h2"
          className={classes.smallTitle}
          style={{ marginTop: '2rem', marginBottom: '2rem' }}
        >
          {data.title}
        </Typography>
        <div className="row mb-4">
          <div className="col-xl-8 col-xs-12 offset-xl-2">
            <Typography component="p" className={classes.subtitle}>
              {data.subtitle}
            </Typography>
          </div>
        </div>
      </React.Fragment>
    );
  };

  const renderPictureWithText = (data: {
    direction: 'left' | 'right';
    text: string;
    title: string;
    subtitle: string;
    image: string;
  }) => {
    let textSection = (
      <div className="col-md-6 col-sm-12 text-left">
        <Typography variant="h6" color="primary">
          {data.title}
        </Typography>
        <Typography variant="h4">{data.subtitle}</Typography>
        <Typography variant="h5" className={classes.text}>
          {data.text}
        </Typography>
      </div>
    );
    let picSection = (
      <div className="col-md-6 col-sm-12">
        <img src={data.image} alt="Overview" className="img-fluid" />
      </div>
    );

    return (
      <div
        className="row d-flex align-items-center"
        style={{ marginTop: '3rem' }}
      >
        {data.direction === 'left' ? (
          <React.Fragment>
            {textSection}
            {picSection}
          </React.Fragment>
        ) : (
          <React.Fragment>
            {picSection}
            {textSection}
          </React.Fragment>
        )}
      </div>
    );
  };

  const renderTestimonial = (data: {
    text: string;
    name: string;
    company: string;
    link: string;
  }) => {
    return (
      <div
        className={'col-md-4 col-sm-12 text-left mb-4 ' + classes.testimonial}
      >
        <Icon size="3x" icon="quote-left" className={classes.quoteIcon} />
        <Typography style={{ color: semiLightGrey }}>{data.text}</Typography>
        <div className="mt-4">
          <Typography component="span">{data.name}</Typography>
          <Typography component="span" color="primary" className="ml-2">
            {data.company}
          </Typography>
        </div>
      </div>
    );
  };

  const renderPricing = (data: {
    price: string;
    headingTimes: number[];
    name: string;
    subtitle: string;
    lineThrough?: string;
    features: string[];
    fullWidth?: boolean;
  }) => {
    return (
      <div
        className={`${
          data.fullWidth ? 'col-12' : 'col-xl-3'
        } col-md-6 col-sm-12 text-center mb-4 mt-4 ${classes.testimonial}`}
      >
        <span className={classes.starIcon}>
          {data.headingTimes.map((times: number) => {
            return <Icon size="3x" key={'star' + times} icon="star" />;
          })}
        </span>
        <Typography variant="h4" color="secondary">
          {data.name}
        </Typography>
        <Typography style={{ color: semiLightGrey }}>
          {data.subtitle}
        </Typography>
        <div className="mt-4">
          <Typography
            variant="h5"
            color="primary"
            style={data.lineThrough ? { textDecoration: 'line-through' } : {}}
            className="mb-2"
          >
            {data.price}
          </Typography>
          {data.lineThrough && (
            <Typography variant="h5" color="error">
              {data.lineThrough}
            </Typography>
          )}
          {data.features.map((feature: string, index: number) => {
            return (
              <Typography key={'feature' + index} component="p" className="p-1">
                <span>
                  <Icon icon="check" /> {feature}
                </span>
              </Typography>
            );
          })}
        </div>
      </div>
    );
  };

  return (
    <React.Fragment>
      <div
        id="landing-page"
        className={`container text-center ${classes.root}`}
      >
        <style>{bodyCss}</style>
        <div
          className={'d-flex justify-content-between align-items-center'}
          style={{ marginBottom: '6rem' }}
        >
          <img src={myDentiLogo} alt="myDenti Logo" style={{ width: '8rem' }} />
          <div className="d-flex align-items-center">
            <Button
              color="primary"
              text
              className="mr-2 d-none d-sm-block"
              onClick={() =>
                props.history.push(Static.getPageByName('register').url)
              }
            >
              Registrieren
            </Button>
            <Button
              onClick={() =>
                props.history.push(Static.getPageByName('login').url)
              }
            >
              Login
            </Button>
          </div>
        </div>

        {/* Heading */}
        <MyComponents.SectionWrapper noHr>
          <Typography
            variant="h2"
            className={classes.title}
            style={{
              marginTop: '2rem',
              marginBottom: '2rem',
            }}
          >
            Digitales{' '}
            <span className={classes.primary}>dental-Auftragsmanagement</span>
          </Typography>
          <div className="row">
            <div className="col-xl-8 col-xs-12 offset-xl-2">
              <Typography component="p" className={classes.subtitle}>
                Verwalten und bestellen Sie über die myDenti Cloudlösung all
                Ihre CAD-, CAM- oder Zahnersatz- Aufträge. Verbinden Sie sich
                mit Ihrem eigenen oder einem externen Dentallabor oder lassen
                Sie Ihre Produkte über das Partnernetzwerk von myDenti fertigen.
              </Typography>
            </div>
          </div>
          <div className="d-flex justify-content-center mt-4 ">
            <Button
              color="primary"
              size="lg"
              href="https://forms.gle/p9kjp4doStvYkBocA"
              target="_blank"
            >
              Jetzt Zugang anfordern
            </Button>
            {/* <Button size="lg">Mehr Informationen</Button> */}
          </div>
          <div style={{ marginTop: '3rem' }}>
            <img src={OrderDetailImage} alt="Overview" className="img-fluid" />
          </div>
        </MyComponents.SectionWrapper>

        {/* Section 2 */}
        <MyComponents.SectionWrapper>
          {renderSubtitle({
            title: 'Digitale Verwaltung',
            subtitle:
              'Nutzen Sie die Vorteile von myDenti in der Kommunikation und dem Auftragshändling mit Ihren internen und externen Partnern. Digitalisieren Sie die Bestellung und Fertigung Ihrer dentaltechnischen Produkte',
          })}
          <div className="row" style={{ marginTop: '3rem' }}>
            {[
              {
                title: 'Dateien',
                text:
                  'Hängen Sie Dentalscans, Dokumente oder andere Dateien zentral und wiederauffindbar dem entsprechenden Auftrag an',
                icon: 'save',
              },
              {
                title: 'Klare Kommunikation',
                text:
                  'Wählen Sie durch wenige Klicks Ihr gewünschtes Produkt aus und sorgen Sie so für eine unmissverständliche Kommunikation mit dem dental-Dienstleister',
                icon: 'comments',
              },
              {
                title: 'Archivierung',
                text:
                  'Behalten Sie den Überblick über Ihre Aufträge und Patienten und archivieren Sie Ihre Auftäge DSGVO-konform auf Servern in Deutschland',
                icon: 'folder-open',
              },
              {
                title: 'Home-Office',
                text:
                  'Managen Sie Ihre (Labor-) Mitarbeiter die von zuhause aus arbeiten - wir bieten hierfür auch fertige Home-Office Boxen für jeden Einsatzzweck',
                icon: 'globe-europe',
              },
              {
                title: 'Sprachen',
                text:
                  'myDenti ist auf deutsch, englisch und spanisch verfügbar - Ihre Aufträge werden automatisch übersetzt - dies ermöglicht eine weltweite Auftragsvergabe',
                icon: 'language',
              },
              {
                title: 'Online',
                text:
                  'Verwenden Sie myDenti auf dem Computer, Tablet oder Handy direkt aus dem Browser - immer und von überall aus',
                icon: 'signal',
              },
            ].map((element: any) => {
              return (
                <MyComponents.ColWrapper
                  content={bullet(element)}
                  key={element.title}
                />
              );
            })}
          </div>
        </MyComponents.SectionWrapper>

        {/* Section 3 */}
        <MyComponents.SectionWrapper>
          {renderSubtitle({
            title: 'Aufträge durchführen',
            subtitle:
              'Sie haben die Wahl wer Ihre dentaltechnischen Aufträge abwickeln soll. Egal ob das eigene Dentallabor, ein externes Dentallabor oder eine Fertigung durch myDenti - Sie entscheiden mit einem Klick und weisen Aufträge entsprechend zu.',
          })}
          {renderPictureWithText({
            direction: 'left',
            title: 'Dienstleister- und Kunden-Management',
            subtitle: 'Verbindungen',
            text:
              'Verbinden Sie sich unkompliziert mit Ihren Dienstleistern und wickeln Sie Ihre Aufträge untereinander ab. Ob eigenes oder externes Dentallabor - jeder kann mit einem eigenen Zugang die Ihm zugewiesenen Aufträge einsehen, verwalten und abarbeiten.',
            image: ConnectionsSettingsImage,
          })}
          {renderPictureWithText({
            direction: 'right',
            title: 'Fertigung by myDenti',
            subtitle: 'Entlastung',
            text:
              'In Zusammenarbeit mit unseren langjährigen Partnern bieten wir Ihnen die volle Bandbreite von preiswertem Zahnersatz aus dem internationalen Umfeld bis hin zum höchst-ästhetisch-anspruchsvollem Zahnersatz über unsere nationalen und internationalen Partnerlabore mit teilweise über 30 Jahren Erfahrung.',
            image: OrderCompletionImage,
          })}
          {renderPictureWithText({
            direction: 'left',
            title: 'Benutzerdefinierte Felder',
            subtitle: 'Individuelle Informationen',
            text:
              'Speichern Sie individuelle Informationen an Ihren Aufträgen. So können Sie beispielsweise Zahlungen erfassen, Daten festhalten oder einfach nur Notizen hinterlegen. So lässt sich myDenti an Ihre Bedürfnisse anpassen.',
            image: CustomFieldsImage,
          })}
        </MyComponents.SectionWrapper>

        {/* Section 4 */}
        {/* <MyComponents.SectionWrapper noHr>
          {renderSubtitle({
            title: 'Unsere tollen Kunden',
            subtitle:
              'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua — Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
          })}
          <div className="row">
            {renderTestimonial({
              text: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua — Ut
            enim ad minim veniam, quis nostrud exercitation ullamco laboris
            nisi ut aliquip ex ea commodo consequat`,
              name: 'Dr. Stephan Frank',
              company: 'Ärzte ohne Grenzen e.V.',
              link: '',
            })}
            {renderTestimonial({
              text: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua — Ut
            enim ad minim veniam, quis nostrud exercitation ullamco laboris
            nisi ut aliquip ex ea commodo consequat`,
              name: 'Dr. Stephan Frank',
              company: 'Ärzte ohne Grenzen e.V.',
              link: '',
            })}
            {renderTestimonial({
              text: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua — Ut
            enim ad minim veniam, quis nostrud exercitation ullamco laboris
            nisi ut aliquip ex ea commodo consequat`,
              name: 'Dr. Stephan Frank',
              company: 'Ärzte ohne Grenzen e.V.',
              link: '',
            })}
          </div>
        </MyComponents.SectionWrapper> */}

        {/* Section 5 */}
        <MyComponents.SectionWrapper noHr>
          {renderSubtitle({
            title: 'Preise',
            subtitle:
              'Dienstleister nutzen myDenti immer vollumfänglich kostenlos. Als Praxis haben Sie die Möglichkeit aus drei Preismodellen zu wählen und so myDenti an Ihre Bedürfnisse anzupassen. Derzeit ist myDenti für ALLE Nutzer kostenlos - sichern Sie sich daher schnell einen der limitierten Plätze.',
          })}
          <div className="row">
            {renderPricing({
              headingTimes: [],
              name: 'Basis',
              subtitle: 'Kostenlose Grundversion',
              price: `kostenlos`,
              features: Static.getPricingFeatures('basic'),
              // fullWidth: true,
            })}
            {renderPricing({
              headingTimes: [1],
              name: 'Starter',
              subtitle: '20 Aufträge im Monat inklusive',
              price: `24,95€/Monat`,
              // lineThrough: `kostenlos`,
              features: Static.getPricingFeatures('starter'),
            })}
            {renderPricing({
              headingTimes: [1, 2],
              name: 'Standard',
              subtitle: '200 Aufträge im Monat inklusive',
              price: `79,95€/Monat`,
              // lineThrough: `kostenlos`,
              features: Static.getPricingFeatures('standard'),
            })}
            {renderPricing({
              headingTimes: [1, 2, 3],
              name: 'Premium',
              subtitle: '1000 Aufträge im Monat inklusive',
              price: `199,95€/Monat`,
              // lineThrough: `kostenlos`,
              features: Static.getPricingFeatures('premium'),
            })}
          </div>
        </MyComponents.SectionWrapper>

        {/* Section 6 */}
        <MyComponents.SectionWrapper noHr>
          {renderSubtitle({
            title: 'Bereit für myDenti?',
            subtitle:
              'Sichern Sie sich jetzt einen der limitierten Beta-Plätze und starten Sie noch heute mit myDenti',
          })}
          <Button
            size="lg"
            pulse
            color="secondary"
            href="https://forms.gle/p9kjp4doStvYkBocA"
            target="_blank"
          >
            Jetzt Zugang sichern!
          </Button>
        </MyComponents.SectionWrapper>
      </div>
      <CookieConsent
        buttonText="Akzeptieren"
        cookieName="myDentiCookieConsent"
        style={{ background: '#25282c' }}
        expires={60}
        // onAccept={() => {}}
        // enableDeclineButton
        // onDecline={() => {
        //   alert('nay!');
        // }}
      >
        Diese Seite verwendet nur technisch notwendige Cookies. Es werden
        keinerlei Daten über das Surfverhalten oder zu Marketingzwecken
        gesammelt. Weitere Informationen dazu finden Sie in unserer{' '}
        <a
          href="https://www.mydenti.de/privacy"
          target="_blank"
          rel="noreferrer"
        >
          Datenschutzerklärung
        </a>
        .
      </CookieConsent>
      <Footer />
    </React.Fragment>
  );
}
