import { makeStyles } from '@material-ui/core';
import React from 'react';
import { checkUserRole } from '../../services/helper';
import Translation from '../../services/translation';
import Comment from '../Shared/Comment';

interface CommentSection {
  comments: any;
  userId: string;
  designerId?: string;
  me: any;
}

const useStyles = makeStyles((_theme) => ({
  root: {},
}));

/**
 * Wrap together comments and give them a color if cancellation or correction
 */
function CommentSection(props: CommentSection) {
  const classes = useStyles();
  // const [explain, setExplain] = useState(false);

  const getComments = (): Array<any> => {
    if (checkUserRole(props.me).isDesigner()) {
      let returnValue = props.comments.filter(
        (comment: any) =>
          comment.forDesigner || comment.userId === props.designerId,
      );
      return returnValue;
    } else if (checkUserRole(props.me).isUser()) {
      let returnValue = props.comments.filter(
        (comment: any) => comment.forUser || comment.userId === props.userId,
      );
      return returnValue;
    } else return [];
  };

  return (
    <React.Fragment>
      {getComments().length > 0 ? (
        <div className={classes.root}>
          {getComments().map((comment: any) => (
            <React.Fragment key={'comment_' + comment.id}>
              <div
                className="mb-2"
                style={{ minWidth: '10rem', maxWidth: 'fit-content' }}
              >
                <Comment
                  date={comment.createdAt}
                  prefix={`${
                    comment.userId === props.me.id
                      ? Translation.getTranslation('me')
                      : comment.userId === props.userId
                      ? Translation.getTranslation('customer')
                      : comment.userId === props.designerId
                      ? Translation.getTranslation('designer')
                      : Translation.getTranslation('myDenti')
                  }${
                    comment.reference === 'cancellation'
                      ? ' (' +
                        Translation.getTranslation('project_cancelled') +
                        ')'
                      : comment.reference === 'correction'
                      ? ' (' +
                        Translation.getTranslation('correction_project') +
                        ')'
                      : ''
                  }`}
                  authorId={comment.userId}
                  message={comment.comment}
                  color={
                    comment.reference === 'cancellation'
                      ? 'danger'
                      : comment.reference === 'correction'
                      ? 'warning'
                      : undefined
                  }
                />
              </div>
            </React.Fragment>
          ))}
        </div>
      ) : (
        <span>{Translation.getTranslation('no_comment_available')}</span>
      )}
    </React.Fragment>
  );
}

export default CommentSection;
