import { makeStyles, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import RequestManager from '../../manager/request.manager';
import { userState } from '../../services/atoms';
import Helper from '../../services/helper';
import Static from '../../services/static';
import { borderRadius, boxShadow, primary } from '../../services/styles';
import Translation from '../../services/translation';
import Icon from '../Shared/Icon';

interface NotificationBig {
  notification: {
    id: string;
    entity: string;
    entityId: string;
    notificationType:
      | 'invitation_accepted'
      | 'new_comment'
      | 'new_upload'
      | 'project_finished_designer'
      | 'project_send'
      | 'project_finished'
      | 'project_cancelled'
      | 'project_assigned';
    createdAt: string | Date;
    seenAt: string | Date;
    checkedAt: string | Date;
    userId: string;
  };
  reloadData: any;
  toggleModal: any;
  notificationChecked: any;
}

const useStyles = makeStyles((_theme) => ({
  root: {
    maxWidth: '100%',
    margin: '10px',
    textAlign: 'left',
    marginRight: '20px',
    height: 'fit-content',
    borderRadius: borderRadius,
    padding: '1rem',
    fontSize: '1em',
    boxShadow: boxShadow,
    marginBottom: '0.5rem',
  },
  titleSection: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '0.5rem',
  },

  link: {
    fontWeight: 'bold',
    color: 'inherit',
    textDecoration: 'underline',
  },

  bgNew: {
    backgroundColor: primary + '4c', //30% opacity

    '&-hover': {
      backgroundColor: primary + '7f', //50% opacity
      cursor: 'pointer',
    },
  },
}));

function NotificationBig(props: NotificationBig) {
  const classes = useStyles();

  const user = useRecoilValue(userState);
  const [checked, setChecked] = useState(false);
  const [onceSeen, setOnceSeen] = useState(false);
  const [hover, setHover] = useState(false);

  useEffect(() => {
    let markSeen = async () => {
      await RequestManager.notificationMarkSeen();
      setOnceSeen(true);
      props.reloadData();
    };

    setChecked(props.notification.checkedAt ? true : false);
    if (!props.notification.seenAt && !onceSeen) {
      markSeen();
    }
  }, [onceSeen, props]);

  let checkNotification = async () => {
    await RequestManager.checkNotification(props.notification.id);
  };

  let renderTitle = () => {
    let title = '';
    title = Translation.getTranslation(
      'notification_' + props.notification.notificationType,
    );
    return title;
  };

  let renderMessage = () => {
    let message = [] as any;

    const renderText = () => {
      return (
        <Typography
          component="span"
          key={props.notification.entity + props.notification.id + 'text'}
          className="font-weight-bolder"
        >
          {Translation.getTranslation(
            props.notification.entity === 'project'
              ? 'order'
              : props.notification.entity === 'user'
              ? 'user'
              : props.notification.entity,
          )}
        </Typography>
      );
    };

    if (user.role === 'admin' || props.notification.entity === 'project')
      message.push(
        <Link
          key={'link' + props.notification.id}
          className={classes.link}
          onClick={() => props.toggleModal()}
          to={
            props.notification.entity === 'project'
              ? `${Static.getPageByName('order_detail').url}/${
                  props.notification.entityId
                }`
              : props.notification.entity === 'user'
              ? `${Static.getPageByName('user_detail').url}/${
                  props.notification.entityId
                }`
              : '/'
          }
        >
          {renderText()}
        </Link>,
      );
    else {
      message.push(renderText());
    }

    message.push(
      <Typography
        component="span"
        className="ml-1"
        key={'message' + props.notification.id}
      >
        {Translation.getTranslation(
          'notification_text_' + props.notification.notificationType,
        )}
      </Typography>,
    );
    return message;
  };

  return (
    <div
      key={props.notification.id}
      className={`${classes.root} ${!checked ? classes.bgNew : ''}${
        !checked && hover ? '-hover' : ''
      }`}
      onClick={() => {
        if (!checked) {
          setChecked(true);
          checkNotification();
          props.notificationChecked(props.notification.id);
        }
      }}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      <div className={classes.titleSection}>
        <span>
          <Typography component="span">{renderTitle()}</Typography>
          {!checked && hover && <Icon className="ml-2" icon="eye" />}
        </span>
        <Typography component="span">
          {Helper.getTimeAgo(props.notification.createdAt)}
        </Typography>
      </div>
      <div>{renderMessage()}</div>
    </div>
  );
}

export default NotificationBig;
