import { ToothInterface } from '../interfaces/tooth.interface';
import { PaymentTypes, PriceInterface } from '../interfaces/user.interface';
import RequestManager from '../manager/request.manager';
import { StateManager } from '../manager/states.manager';
import Translation from './translation';

export default class Static {
  static getPageByName(name: string) {
    let result = {
      siteTitle: '',
      url: '',
      name: '',
      standardSearchString: '',
      description: '',
    };
    result = this.getPages().find((list: any) => list.name === name) as any;

    return result;
  }

  static getPages() {
    let pages = [
      {
        name: 'dashboard',
        url: '/',
        siteTitle: Translation.getTranslation('overview'),
      },
      {
        name: 'moin',
        url: '/moin',
        siteTitle: Translation.getTranslation('moin'),
      },
      {
        name: 'login',
        url: '/login',
        siteTitle: Translation.getTranslation('login'),
      },
      {
        name: 'register',
        url: '/register',
        siteTitle: Translation.getTranslation('register'),
      },
      {
        name: 'user_settings',
        url: '/user/settings',
        siteTitle: Translation.getTranslation('user_settings'),
      },
      {
        name: 'analytics',
        url: '/analytics',
        siteTitle: Translation.getTranslation('analytics'),
      },
      {
        name: 'archive',
        url: '/archive',
        siteTitle: Translation.getTranslation('archive'),
      },
      {
        name: 'user_detail',
        url: '/user',
        siteTitle: Translation.getTranslation('customer'),
      },
      {
        name: 'order_detail',
        url: '/project/detail',
        siteTitle: Translation.getTranslation('order_overview'),
      },
      {
        name: 'form_detail',
        url: '/form',
        siteTitle: Translation.getTranslation('form'),
      },
      {
        name: 'connections',
        url: '/user/connections',
        siteTitle: Translation.getTranslation('connections'),
      },
      {
        name: 'order_create',
        url: '/project/create',
        siteTitle: Translation.getTranslation('add_order'),
      },
      {
        name: 'order_edit',
        url: '/project/create',
        siteTitle: Translation.getTranslation('edit_order'),
      },
      {
        name: 'service_preferences',
        url: '/preferences/service',
        siteTitle: Translation.getTranslation('service_preferences'),
      },
    ];

    return pages;
  }
  static getStatus() {
    let status = [
      // 'draft',
      'send',
      'assigned',
      'wip_designer',
      'wip_correction_claim_designer',
      'wip_correction_goodwill_designer',
      'finished_designer',
      'finished',
      'cancelled',
      // 'closed',
    ];
    return status;
  }

  static getProjectWorkTypes() {
    let types = [
      'cad',
      'cam',
      'dentures',
    ] as ToothInterface['projectWorkTypes'][];

    return types;
  }

  static getToothWorkTypes(withSplint?: boolean) {
    let types = [
      {
        label_de: 'Kronen & Brücken',
        label_en: 'Crowns & Bridges',
        label_es: 'Coronas y puentes',
        id: 'crown',
      },
      {
        label_de: 'Implantologie',
        label_en: 'Implantology',
        label_es: 'Implantologia',
        id: 'implant',
      },
      {
        label_de: 'Protetik',
        label_en: 'Denture',
        label_es: 'Dentadura',
        id: 'protese',
      },
    ] as {
      id: ToothInterface['toothWorkTypes'];
      label_de: string;
      label_en: string;
      label_es: string;
    }[];

    if (withSplint) {
      types.push({
        label_de: 'Schiene',
        label_en: 'Splint',
        label_es: 'Entablillar',
        id: 'splint',
      });
    }

    return types;
  }

  static getToothWorkTypeById(
    id: ToothInterface['toothWorkTypes'],
    attribute: string,
  ) {
    let workTypes = this.getToothWorkTypes(true);
    let found = workTypes.find((type: any) => type.id === id) as any;
    if (typeof found !== 'undefined') return found[attribute] as any;
    else return '';
  }

  //which tooth information is needed for which work type
  static getToothInformationTypesByToothWorkType(
    toothWorkType: ToothInterface['toothWorkTypes'],
  ): ToothInterface['toothInformationTypes'][] {
    let types = [
      'work',
      'material',
      'color',
      'service',
    ] as ToothInterface['toothInformationTypes'][];

    if (toothWorkType === 'crown') {
      types.push('bridge');
    }

    if (toothWorkType === 'implant') {
      types.push('material2');
      types.push('producer');
      types.push('ref');
      types.push('diameter');
    }

    return types;
  }

  static getRequiredToothInformationTypes() {
    return [
      'work',
      'material',
      'service',
    ] as ToothInterface['toothInformationTypes'][];
  }

  static getToothInformationTypes() {
    let types = [
      {
        label: 'type_of_work',
        id: 'work',
        explainId: 'explain_work',
      },
      {
        label: 'material',
        id: 'material',
        explainId: 'explain_material',
      },
      {
        label: 'color',
        id: 'color',
        explainId: 'explain_color',
      },
      {
        label: 'service',
        id: 'service',
        explainId: 'explain_service',
      },
      {
        label: 'second_material',
        id: 'material2',
        explainId: 'explain_material2',
      },
      { label: 'ref', id: 'ref', explainId: 'explain_ref' },
      { label: 'producer', id: 'producer', explainId: 'explain_producer' },
      { label: 'bridge', id: 'bridge', explainId: 'explain_bridge' },
      { label: 'diameter', id: 'diameter', explainId: 'explain_diameter' },
    ] as {
      label: string;
      id: ToothInterface['toothInformationTypes'];
      explainId: string;
    }[];

    return types;
  }

  static getToothInformationTypeById(
    id: ToothInterface['toothInformationTypes'],
  ) {
    return this.getToothInformationTypes().find(
      (type: any) => type.id === id,
    ) as { label: string; id: ToothInterface['toothInformationTypes'] };
  }

  static toastrOptions() {
    return {
      closeButton: true,
      newestOnTop: true,
      progressBar: false,
    };
  }

  static getTeethBlocks() {
    let blocks = [
      {
        id: 0,
        label: 'top_left',
        numbers: [18, 17, 16, 15, 14, 13, 12, 11],
        range: [18, 11],
      },
      {
        id: 1,
        label: 'top_right',
        numbers: [21, 22, 23, 24, 25, 26, 27, 28],
        range: [21, 28],
      },
      {
        id: 2,
        label: 'bottom_left',
        numbers: [48, 47, 46, 45, 44, 43, 42, 41],
        range: [48, 41],
      },
      {
        id: 3,
        label: 'bottom_right',
        numbers: [31, 32, 33, 34, 35, 36, 37, 38],
        range: [31, 38],
      },
    ] as {
      id: number;
      label: string;
      numbers: number[];
      range: [number, number];
    }[];

    return blocks;
  }

  static getJaws(scope?: 'upper' | 'lower') {
    switch (scope) {
      case 'upper':
        return [
          ...Static.getTeethBlocks()[0].numbers,
          ...Static.getTeethBlocks()[1].numbers,
        ];
      case 'lower':
        return [
          ...Static.getTeethBlocks()[2].numbers,
          ...Static.getTeethBlocks()[3].numbers,
        ];
      default:
        return [
          ...Static.getTeethBlocks()[0].numbers,
          ...Static.getTeethBlocks()[1].numbers,
          ...Static.getTeethBlocks()[2].numbers,
          ...Static.getTeethBlocks()[3].numbers,
        ];
    }
  }

  static getColors() {
    let types = [
      '0 M1',
      '0 M2',
      '0 M3',
      'A1',
      'A2',
      'A3',
      'A3,5',
      'A4',
      'B1',
      'B2',
      'B3',
      'B4',
      'C1',
      'C2',
      'C3',
      'C4',
      'D2',
      'D3',
      'D4',
    ] as ToothInterface['colors'][];

    return types;
  }

  static getServiceById(
    id: ToothInterface['services'],
  ):
    | {
        shortDe: string;
        id: ToothInterface['services'];
        workType: ToothInterface['toothWorkTypes'];
      }
    | undefined {
    let services = StateManager.getServices('service');
    let service = services.find((service: any) => service.shortEn === id);
    if (service) return service;
    else return undefined;
  }

  static async getSystemServiceById(
    id: ToothInterface['systemServices'],
  ): Promise<any> {
    let services = StateManager.getServices('systemService');

    let service = services.find((service: any) => service.shortEn === id);
    if (service) return service;
    else return undefined;
  }

  static getServicesByWorkType(workType: ToothInterface['toothWorkTypes']) {
    let services = StateManager.getServices('service');
    // let services = this.getServices();
    if (workType) {
      return services.filter((service: any) => service.workType === workType);
    }
    return services;
  }

  static getPricingFeatures(plan?: PaymentTypes['currentPlan']): any {
    const features = {
      basic: [
        'Aufträge die durch einen unserer Partner gefertigt werden, sind in der Übermittlung kostenlos',
      ],
      starter: [
        'Alle Funktionen aus Basis',
        'Grenzenloses Archiv',
        'Aufträge als Entwurf speichern und später absenden',
        'Verbindung mit bis zu einem Dienstleistern',
        'Ein benutzerdefiniertes Feld',
      ],
      standard: [
        'Alle Funktionen aus Starter',
        'Verbindung mit bis zu fünf Dienstleistern',
        'Bis 200 Aufträge im Monat an eigene Dienstleister versenden',
        'Zwei benutzerdefinierte Felder',
      ],
      premium: [
        'Alle Funktionen aus Standard',
        'Unlimitierte Verbindungen zu Dienstleistern',
        'Eigenes Firmenlogo einbinden',
        'Bis zu fünf benutzerdefinierte Felder',
      ],
    };

    if (plan && typeof features[plan] !== 'undefined') {
      return features[plan];
    }
    return features;
  }

  static getPricing = async (
    plan?: PaymentTypes['currentPlan'],
  ): Promise<PriceInterface[]> => {
    const pricingTemp = await RequestManager.getStripePricing();
    const items = [] as PriceInterface[];

    for (const priceIteration of pricingTemp) {
      items.push({
        id: priceIteration?.price?.id || '',
        heading: Translation.getTranslation('plan_' + priceIteration.name),
        name: priceIteration.name,
        amount: priceIteration?.price?.unit_amount || 0,
        currency: priceIteration?.price?.currency || 'eur',
        interval: priceIteration?.price?.recurring?.interval || 'month',
        taxPercentage: priceIteration?.tax?.percentage || 0,
        taxName: priceIteration?.tax?.display_name || '',
        text: Static.getPricingFeatures(priceIteration.name),
      });
    }

    if (plan) {
      const foundPricing = items.find((item) => item.name === plan);
      return foundPricing ? [foundPricing] : [];
    }

    return items as PriceInterface[];
  };
}
