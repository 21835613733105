import * as React from 'react';
import { Redirect } from 'react-router';
import { useRecoilValue, useResetRecoilState, useSetRecoilState } from 'recoil';
import ProjectsManager from '../../manager/projects.manager';
import UserManager from '../../manager/user.manager';
import { loadingState, userState } from '../../services/atoms';
import Helper from '../../services/helper';
import Static from '../../services/static';
import AppLoading from './AppLoading';
import Navigation from './Navigation';

interface Props {
  match?: any;
  location?: any;
  children?: any;
  // newLocation: (location: string) => void;
}

export function Layout(props: Props) {
  const user = useRecoilValue(userState);
  const resetUser = useResetRecoilState(userState);
  const setLoading = useSetRecoilState(loadingState);

  const [redirect, setRedirect] = React.useState(false);
  const [location, setLocation] = React.useState(undefined);

  React.useEffect(() => {
    if (props.location?.pathname && location !== props.location.pathname) {
      Helper.gaRouteRegister(props.location.pathname);
      setLocation(props.location.pathname);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.location?.pathname]);

  React.useEffect(() => {
    // load initial data
    const load = async () => {
      await ProjectsManager.loadStaticServices();

      await checkLoginStatus();

      // check payment, abo etc. and redirect if something is wrong
      // await UserManager.getStripeCustomer(user);
      setLoading(false);
    };

    const checkLoginStatus = async () => {
      let jwt = await UserManager.checkLoginStatus();

      if (!user || !jwt) {
        resetUser();
        setRedirect(true);
      }
    };

    user && load();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const MemoNavigation = React.useMemo(() => {
    return (
      <Navigation
        location={props.location}
        position={Helper.getPosition(props.location?.pathname)}
        logout={() => {
          setRedirect(true);
          resetUser();
        }}
        user={user}
      />
    );
  }, [user]);

  return redirect ? (
    <Redirect to={Static.getPageByName('login').url} />
  ) : (
    <React.Fragment>
      {MemoNavigation}
      <div
        className="container-fluid mt-4 pb-4 mw-100"
        // style={{ overflowX: 'scroll' }}
      >
        <AppLoading>{props.children}</AppLoading>
      </div>
      {/* <Footer></Footer> */}
    </React.Fragment>
  );
}
