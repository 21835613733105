import UserInterface, { PaymentTypes } from '../interfaces/user.interface';
import RequestManager from './request.manager';

export default class UserManager {
  static getToken() {
    return localStorage.getItem('jwt');
  }

  static async checkLoginStatus(): Promise<boolean> {
    let token = localStorage.getItem('jwt');
    if (token) {
      return true;
    } else return false;
  }

  // check payment, abo etc. and redirect if something is wrong
  // static async getStripeCustomer(user: UserInterface) {
  //   if (user && user.role === 'user') {
  //     const customer = await RequestManager.getStripeCustomer();
  //     if (!customer) {
  //       //todo redirec to create customer page
  //       // redirect user to customer creation
  //     } else {
  //       if (customer.subscriptions) {
  //         //todo check subscriptions ansonsten redirect
  //       }
  //     }
  //   }
  // }

  static async returnUserImage(user: UserInterface) {
    if (
      user?.settings?.logoFilename &&
      UserManager.checkIfPayedForService(user, {
        service: 'customization',
      })
    ) {
      return await RequestManager.getDownloadURL(user?.settings?.logoFilename);
    }
  }

  static checkIfPayedForService(
    user: UserInterface,
    request?: {
      service:
        | 'send_order_to_connection'
        | 'edit_connection'
        | 'open_archive'
        | 'edit_subscription'
        | 'customization'
        | 'open_my_connections'
        | 'open_custom_fields';
      amount?: number;
    },
  ) {
    if (user.role === 'user') {
      const currentPlan = (!user.payment
        ? 'basic'
        : user.payment?.currentPlan
        ? user.payment?.currentPlan
        : 'basic') as PaymentTypes['currentPlan'];

      if (!request || (request && !request.service)) return currentPlan;
      switch (currentPlan as PaymentTypes['currentPlan']) {
        case 'basic':
          return false;
        case 'starter':
          if (request.service === 'customization') return false;
          return true;
        case 'standard':
          if (request.service === 'customization') return false;
          return true;
        case 'premium':
          return true;
        default:
          return false;
      }
    }
    return true;
  }

  static async openStripePortal() {
    const stripeResponse = await RequestManager.getStripePortal();
    const link = document.createElement('a');

    if (stripeResponse?.url) {
      link.setAttribute('target', '_blank');
      link.href = stripeResponse?.url;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }
}
