import { FormControlLabel, Switch as SwitchMui } from '@material-ui/core';
import React, { useEffect } from 'react';

interface Props {
  onChange?: any;
  label?: string;
  className?: string;
  name?: string;
  checked?: boolean;
  disabled?: boolean;
  required?: boolean;
  color?: 'primary' | 'secondary' | 'default';
}

export default function Switch(props: Props) {
  const [checked, setChecked] = React.useState(true);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked);
    props.onChange(event.target.checked);
  };

  useEffect(() => {
    setChecked(props.checked ? true : false);
  }, [props]);
  return (
    <FormControlLabel
      // className="mb-0"
      control={
        <SwitchMui
          name={props.name}
          className={props.className}
          checked={checked}
          onChange={handleChange}
          color={props.color}
          disabled={props.disabled}
        />
      }
      // onClick={(e) => handleChange(e)}
      label={props.label}
    />
  );
}
