import * as React from 'react';
import DataFilter from '../components/Shared/DataFilter';
import DataTable, { Column } from '../components/Shared/DataTable';
import PageHeadingRow from '../components/Shared/PageHeadingRow';
import CustomFieldsManager from '../manager/customFields.manager';
import RequestManager from '../manager/request.manager';
import { StateManager } from '../manager/states.manager';
import UserManager from '../manager/user.manager';
import Helper, { checkUserRole } from '../services/helper';
import Static from '../services/static';
import Translation from '../services/translation';

interface Props {
  match?: any;
  location?: any;
  history?: any;
  user: any;
}

interface Filter {
  currentStatus: string;
  designerId: string;
  customerId: string;
  sort: string;
  page: number;
  limit: number;
  query: string;
  dateFilter: string;
  workType: string;
  extended: boolean;
  isInternal: string;
  startDate: Date;
  endDate: Date;
}

export default function Archive(props: Props) {
  const rememberMeName = 'archive';
  const initialState = {
    currentStatus: checkUserRole(props.user).isAdmin() ? 'finished' : '',
    extended: true,
    sort: '-sendAt',
    dateFilter: checkUserRole(props.user).isAdmin() ? 'closedAt' : 'sendAt',
    query: '',
    workType: '',
    designerId: '',
    customerId: '',
    isInternal: '',
    page: 0,
    limit: 50,
    startDate: Helper.getThisMonth()[0],
    endDate: Helper.getThisMonth()[1],
  };
  const [filter, setFilter] = React.useState<Filter>(initialState);

  React.useEffect(() => {
    if (
      checkUserRole(props.user).isDesigner() ||
      !UserManager.checkIfPayedForService(props.user, {
        service: 'open_archive',
      })
    )
      props.history.push('/');
    let existingFilter = StateManager.getRememberMe(rememberMeName);
    if (existingFilter) setFilter(existingFilter);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getTableColumns = (): Column[] => {
    let tableColumns = [
      {
        label: Translation.getTranslation('order_number'),
        type: { id: 'text', key: 'orderNumber' },
      },
      {
        label: Translation.getTranslation('patient_name'),
        type: { id: 'text', key: 'patientName' },
      },
      {
        label: Translation.getTranslation('order_date'),
        type: {
          id: 'individual',
          key: 'sendAt',
          individualObject: (data: any) => {
            return Helper.getDate(
              data.sendAt ? data.sendAt : data.createdAt,
              true,
            );
          },
        },
      },
      {
        label: Translation.getTranslation('close_date'),
        type: {
          id: 'date',
          key: 'closedAt',
        },
      },
      {
        label: Translation.getTranslation('type_of_work'),
        type: {
          id: 'individual',
          individualObject: (data: any) => {
            return (
              data.projectWorkTypes &&
              data.projectWorkTypes.length > 0 &&
              data.projectWorkTypes.map(
                (type: string, index: number) =>
                  `${Translation.getTranslation(
                    `project_work_type_${type}`,
                  )}${Helper.renderComma(data.projectWorkTypes, index)}`,
              )
            );
          },
        },
      },
      {
        label: Translation.getTranslation('status'),
        type: {
          id: 'individual',
          individualObject: (data: any) => {
            return Helper.renderStatusTextForRole(
              data.currentStatus,
              props.user.role,
            );
          },
        },
      },
    ];

    if (checkUserRole(props.user).isAdmin()) {
      tableColumns.push(
        {
          label: Translation.getTranslation('customer'),
          type: {
            id: 'individual',
            individualObject: (data: any) => {
              return data.user ? Helper.renderUserWithCompany(data.user) : '-';
            },
          },
        },
        {
          label: Translation.getTranslation('designer'),
          type: {
            id: 'individual',
            individualObject: (data: any) => {
              return data.designer
                ? Helper.renderUserWithCompany(data.designer)
                : '-';
            },
          },
        },
      );
    }

    tableColumns.push({
      label: Translation.getTranslation('execution'),
      type: {
        id: 'individual',
        individualObject: (data: any) => {
          return data.isInternal
            ? Translation.getTranslation('internal')
            : Translation.getTranslation('myDenti');
        },
      },
    });

    return tableColumns as any;
  };

  // get downloadColumns() {
  //   let data = [];
  //   data.push(
  //     {
  //       name: 'orderNumber',
  //       label: Translation.getTranslation('order_number'),
  //       type: 'string',
  //     },
  //     {
  //       name: 'patientName',
  //       label: Translation.getTranslation('patient'),
  //       type: 'string',
  //     },
  //     {
  //       name: 'sendAt',
  //       label: Translation.getTranslation('order_date'),
  //       type: 'date',
  //     },
  //     {
  //       name: 'closedAt',
  //       label: Translation.getTranslation('close_date'),
  //       type: 'date',
  //     },
  //     {
  //       name: 'isDentalSplint',
  //       label: Translation.getTranslation('is_dental_splint'),
  //       type: 'boolean',
  //     },
  //     {
  //       name: 'isInternal',
  //       label: Translation.getTranslation('internal'),
  //       type: 'boolean',
  //     },
  //     {
  //       name: 'currentStatus',
  //       label: Translation.getTranslation('status'),
  //       type: 'string',
  //       translationPrefix: 'status_',
  //     },
  //     {
  //       name: 'projectWorkTypes',
  //       label: Translation.getTranslation('type_of_work'),
  //       type: 'list',
  //       translationPrefix: 'project_work_type_',
  //     },
  //     {
  //       name: 'chosenTeeth',
  //       label: Translation.getTranslation('chosen_teeth'),
  //       type: 'list',
  //     },
  //   );

  //   if (checkUserRole(this.props.me).isAdmin()) {
  //     data.push(
  //       {
  //         name: 'user',
  //         label: Translation.getTranslation('customer'),
  //         type: 'object',
  //         attribute: 'company',
  //       },
  //       {
  //         name: 'designer',
  //         label: Translation.getTranslation('designer'),
  //         type: 'object',
  //         attribute: 'company',
  //       },
  //     );
  //   }

  //   return data;
  // }

  return (
    <React.Fragment>
      <PageHeadingRow
        siteTitle={Static.getPageByName('archive').siteTitle}
      ></PageHeadingRow>

      <div className="row">
        <div className="col-xl-12">
          <DataFilter
            title={Translation.getTranslation('filter')}
            resetFilter={() =>
              StateManager.resetFilter(
                initialState,
                (e: any) => setFilter(e),
                rememberMeName,
              )
            }
            rememberMe={rememberMeName}
            me={props.user}
            filter={filter}
            handleFilterChange={(name, value) => {
              StateManager.handleFilterChange(
                name,
                value,
                filter,
                (e: any) => {
                  setFilter(e);
                },
                rememberMeName,
              );
            }}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-xl-12 col-lg-12">
          <DataTable
            limit={10}
            sort={['sendAt', 'closedAt']}
            additionalColumns={
              CustomFieldsManager.renderDataTableAdditionalColumn
            }
            onClick={(id: string, data: any) => {
              props.history.push(
                `${Static.getPageByName('order_detail').url}/${id}`,
              );
            }}
            doNotSearch
            title={Translation.getTranslation('results')}
            externalFilterChange={filter}
            manager={(options: any) => {
              return RequestManager.getProjects({
                ...options,
              });
            }}
            columns={getTableColumns()}
          />
        </div>
      </div>
    </React.Fragment>
  );
}
