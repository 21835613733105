import * as React from 'react';
import PageHeading from './PageHeading';

function PageHeadingRow(props: { siteTitle: string; children?: any }) {
  return (
    <div className="row d-flex align-items-center justify-content-between overflow-hidden mb-4 w-100">
      <div className="col fit-content d-flex align-items-center">
        <PageHeading>{props.siteTitle}</PageHeading>
      </div>
      <div className="col text-right" style={{ minWidth: 'fit-content' }}>
        {props.children}
      </div>
    </div>
  );
}

export default PageHeadingRow;
