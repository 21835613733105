import React from 'react';
import myDentiLogo from '../../images/myDentiLogoTrans.png';
import Translation from '../../services/translation';
import Card from './Card';
import LayoutUnauthorized from './LayoutUnauthorized';
import Select from './Select';

interface Props {
  children?: any;
}

const languages = [
  { language: 'en', label: Translation.getTranslation('english') },
  { language: 'es', label: Translation.getTranslation('spanish') },
  { language: 'de', label: Translation.getTranslation('german') },
] as { language: 'en' | 'es' | 'de'; label: string }[];

function LoginBox(props: Props) {
  return (
    <LayoutUnauthorized>
      <div className="">
        <Card>
          <img
            src={myDentiLogo}
            alt="MyDenti Logo"
            style={{
              maxWidth: '70%',
              alignSelf: 'center',
              marginTop: '3rem',
            }}
          ></img>
          <div className="position-absolute" style={{ top: 5, left: 5 }}>
            <Select
              onChange={(e: any) => {
                Translation.setLanguage(
                  languages.find(
                    (lang: any) => lang.language === e.target.value,
                  )?.language as 'de' | 'es' | 'en',
                );

                window.location.reload();
              }}
              value={
                languages.find(
                  (lang: any) => lang.language === Translation.getLanguage(),
                )?.language
              }
            >
              {languages.map((lang: any) => {
                return (
                  <option value={lang.language} key={lang.language}>
                    {lang.label}
                  </option>
                );
              })}
            </Select>
          </div>
          {props.children}
        </Card>
      </div>
    </LayoutUnauthorized>
  );
}

export default LoginBox;
