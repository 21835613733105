import MomentUtils from '@date-io/moment';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import moment from 'moment';
import 'moment/locale/de';
import 'moment/locale/es';
import React, { useEffect, useState } from 'react';
import Translation from '../../services/translation';

interface DatePick {
  initialDate?: Date;
  dateChange: any;
  label?: string;
  maxDate?: Date;
}

function DatePick(props: DatePick) {
  const [selectedDate, handleDateChange] = useState(new Date());
  useEffect(() => {
    if (props.initialDate) {
      handleDateChange(props.initialDate);
    }
  }, [props.initialDate]);

  return (
    <MuiPickersUtilsProvider
      utils={MomentUtils}
      locale={moment.locale(Translation.getLanguage())}
    >
      <DatePicker
        className="w-100"
        onChange={(date: any) => {
          handleDateChange(date);
          props.dateChange(moment(date).format());
        }}
        value={selectedDate}
        format="DD.MM.yyyy"
        autoOk
        label={props.label ? props.label : undefined}
        variant="inline"
        maxDate={props.maxDate}
      />
    </MuiPickersUtilsProvider>
  );
}

export default DatePick;
