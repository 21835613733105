import * as React from 'react';
import { useRecoilValue } from 'recoil';
import myDentiLogo from '../../images/myDentiLogoTrans.png';
import { loadingState } from '../../services/atoms';
import { background } from '../../services/styles';

function AppLoading(props: { children?: any }) {
  const loading = useRecoilValue(loadingState) as any;

  const [loadingLocal, setLoadingLocal] = React.useState(true);

  React.useEffect(() => {
    if (loading === false) {
      setTimeout(() => {
        setLoadingLocal(false);
      }, 1000);
    }
  }, [loading]);
  // const setLoading = useSetRecoilState(loadingState);
  const renderOverlay = () => {
    return (
      <div
        style={{
          opacity: '1',
          background: background,
          width: '100%',
          height: '100%',
          zIndex: 99,
          top: 0,
          left: 0,
          position: 'fixed',
        }}
      >
        <img
          alt="myDenti Logo"
          src={myDentiLogo}
          className="img-fluid"
          style={{
            width: '50%',
            position: 'absolute',
            margin: 'auto',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
          }}
        />
        <span className="sr-only">Loading...</span>
      </div>
    );
  };

  return (
    <React.Fragment>
      {loadingLocal === true && props.children && renderOverlay()}
      {props.children}
    </React.Fragment>
  );
}

export default AppLoading;
