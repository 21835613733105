import * as React from 'react';
import toastr from 'toastr';
import RequestManager from '../../manager/request.manager';
import Helper, { checkUserRole } from '../../services/helper';
import Translation from '../../services/translation';
import Checkbox from '../Shared/Checkbox';
import Input from '../Shared/Input';
import Modal from '../Shared/Modal';

interface Props {
  match?: any;
  location?: any;
  history?: any;
  isOpen: boolean;
  toggleModal: any;
  projectId: string;
  reloadData?: any;
  me: any;
}

interface State {
  loading: boolean;
  forUser: boolean;
  forDesigner: boolean;
  comment: string;
}

export class CommentModal extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      loading: false,
      forUser: false,
      forDesigner: false,
      comment: '',
    };
  }

  toggleModal = async () => {
    this.props.toggleModal();
  };

  saveComment = async () => {
    this.setState({ loading: true });

    let comment = await RequestManager.postComment(
      {
        comment: this.state.comment,
        forUser: this.state.forUser,
        forDesigner: this.state.forDesigner,
      },
      this.props.projectId,
    );
    if (comment) {
      toastr.success(Translation.getTranslation('comment_saved'));
      this.props.reloadData();
      this.props.toggleModal();
    }
    this.setState({
      loading: false,
      comment: '',
      forDesigner: false,
      forUser: false,
    });
  };

  componentDidMount() {
    this.setState({ loading: true });

    if (this.props.isOpen) {
      Helper.gaRegisterModalView('project_comment');
    }

    this.setState({ loading: false });
  }

  componentDidUpdate(prevProps: any) {}

  render() {
    return (
      <Modal
        isOpen={this.props.isOpen}
        toggleModal={() => this.toggleModal()}
        disabled={this.state.loading || !this.state.comment ? true : false}
        onSave={() => this.saveComment()}
        header={Translation.getTranslation('add_comment')}
      >
        <React.Fragment>
          <Input
            id="commentTextarea"
            label={Translation.getTranslation('comment')}
            rows={5}
            multiline
            value={this.state.comment}
            onChange={(e: any) => {
              this.setState({ comment: e.target.value });
            }}
          />
          {this.props.me && checkUserRole(this.props.me).isAdmin() && (
            <React.Fragment>
              <Checkbox
                label={Translation.getTranslation('user_can_see_comment')}
                checked={this.state.forUser}
                onChange={(e: boolean) => {
                  this.setState((prevState: any) => ({
                    forUser: e,
                  }));
                }}
              />
              <Checkbox
                label={Translation.getTranslation('designer_can_see_comment')}
                checked={this.state.forDesigner}
                onChange={(e: boolean) => {
                  this.setState((prevState: any) => ({
                    forDesigner: e,
                  }));
                }}
              />
            </React.Fragment>
          )}
        </React.Fragment>
      </Modal>
    );
  }
}
