import { Elements, useStripe } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import * as React from 'react';
import UserInterface, { PaymentTypes } from '../../interfaces/user.interface';
import RequestManager from '../../manager/request.manager';
import Translation from '../../services/translation';
import Button from '../Shared/Button';

interface Subscriptions {
  user: UserInterface;
  plan: PaymentTypes['currentPlan'] | undefined;
}

const Pay = (props: { plan: PaymentTypes['currentPlan'] }) => {
  const [loading, setLoading] = React.useState(false);
  const stripe = useStripe();

  const handleSubmit = async (event: any) => {
    setLoading(true);
    console.log(props.plan);
    const data = await RequestManager.postSubscription({
      plan: props.plan,
    });

    if (data?.id) {
      stripe
        ?.redirectToCheckout({
          sessionId: data.id,
        })
        .then((e: any) => console.log(e))
        .catch((e) => {
          // todo error
          setLoading(false);
        });
    } else {
      //todo error
    }
    setLoading(false);
  };

  return (
    <Button
      color="primary"
      icon="credit-card"
      onClick={handleSubmit}
      className="mt-2"
      loading={loading || !stripe}
      noMargin
      loadingLabel={
        !stripe
          ? Translation.getTranslation('loading')
          : Translation.getTranslation('proceed_to_payment')
      }
      disabled={!stripe || props.plan === 'basic'}
    >
      {Translation.getTranslation('proceed_to_payment')}
    </Button>
  );
};

function Checkout(props: Subscriptions) {
  const stripePromise = loadStripe(
    process.env.REACT_APP_STRIPE_PUBLIC_KEY || '',
    {
      locale: props.user?.language || 'de',
    },
  );

  return (
    <Elements stripe={stripePromise}>
      {props.plan && <Pay plan={props.plan} />}
    </Elements>
  );
}

export default Checkout;
