import * as React from 'react';
//@ts-ignore
import { ObjViewer } from 'react-obj-viewer';
//@ts-ignore
import { STLViewer } from 'react-stl-obj-viewer';
import RequestManager from '../../manager/request.manager';
import Helper from '../../services/helper';
import Translation from '../../services/translation';
import Modal from './Modal';
import Spinner from './Spinner';

interface Props {
  match?: any;
  location?: any;
  history?: any;
  toggleModal: any;
  file: string | Blob;
  type: 'stl' | 'obj';
  reloadData?: any;
}

export function ModelViewer(props: Props) {
  const [file, setFile] = React.useState<Blob | undefined>(undefined);

  React.useEffect(() => {
    if (typeof props.file !== 'string') {
      setFile(props.file);
    } else getFile();
    Helper.gaRegisterModalView('project_model_preview');
  }, []);

  const getFile = async () => {
    if (typeof props.file === 'string') {
      let fileTemp = await RequestManager.getFile(props.file);
      fileTemp && setFile(fileTemp);
    }
  };

  return (
    <Modal
      isOpen={true}
      toggleModal={() => props.toggleModal && props.toggleModal()}
      size="lg"
      bodyCenter
      header={Translation.getTranslation('model_preview')}
    >
      <React.Fragment>
        {!file ? (
          <div style={{ height: '400px' }}>
            <Spinner></Spinner>
          </div>
        ) : (
          <React.Fragment>
            {props.type === 'obj' ? (
              <ObjViewer
                // model={'https://bohdanbirdie.github.io/stl-obj-demo/bb8.obj'}
                model={
                  typeof file !== 'string' ? URL.createObjectURL(file) : file
                }
                // material="your/material/path/here.jpg"
                width={400}
                height={500}
                // axis={true}
                // boundingBox={false}
              />
            ) : (
              <STLViewer
                sceneClassName="model"
                file={file}
                height={500}
                width={400}
                modelColor="#fff"
                backgroundColor="#000"
              />
            )}
          </React.Fragment>
        )}
      </React.Fragment>
    </Modal>
  );
}
