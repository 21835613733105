import {
  Divider,
  IconButton,
  ListItemSecondaryAction,
} from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import React, { useEffect, useState } from 'react';
import toastr from 'toastr';
import CustomFieldsManager from '../../manager/customFields.manager';
import RequestManager from '../../manager/request.manager';
import { lightGrey } from '../../services/styles';
import Translation from '../../services/translation';
import BooleanToggleButton from '../Shared/BooleanToggleButton';
import Button from '../Shared/Button';
import DatePick from '../Shared/DatePick';
import Icon from '../Shared/Icon';
import Input from '../Shared/Input';
import CustomFieldsList from './CustomFieldsList';
import { UserPreferencesModal } from './UserPreferencesModal';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      backgroundColor: lightGrey,
    },
  }),
);

interface Props {
  data?: any;
  projectId: string;
}

export default function CustomFieldsValuesList(props: Props) {
  const [data, setData] = useState<any[]>([]);
  const [newValue, setNewValue] = useState<any>(null);
  const [editMode, setEditMode] = useState<string>('');
  const [hover, setHover] = useState<string>('');
  const [disabled, setDisabled] = useState<boolean>(false);
  const [preferencesOpen, setPreferencesOpen] = useState<boolean>(false);
  const classes = useStyles();

  useEffect(() => {
    if (props.data) {
      let dataTemp = [] as any;
      for (const dataIteration of props.data) {
        let dataIterationManipulation = { ...dataIteration };
        let newestValue =
          (dataIterationManipulation.projectCustomFieldsValues &&
            dataIterationManipulation.projectCustomFieldsValues[0] &&
            dataIterationManipulation.projectCustomFieldsValues[0]) ||
          null;

        dataIterationManipulation.newestValue = newestValue
          ? {
              value:
                typeof newestValue[dataIterationManipulation.type] !== undefined
                  ? newestValue[dataIterationManipulation.type]
                  : null,
              id: newestValue.id,
              type: dataIterationManipulation.type,
            }
          : { value: null, id: null, type: null };
        dataTemp.push(dataIterationManipulation);
      }
      setData(dataTemp);
    }
  }, [props.data]);

  const toggleEditMode = (item: any) => {
    setEditMode(item.id);
    setNewValue(item.newestValue?.value ?? null);
  };

  const saveEdit = async (item: any) => {
    setDisabled(true);
    let saved = await RequestManager.postCustomFieldValue({
      value: newValue,
      customFieldId: item.id,
      projectId: props.projectId,
    });
    if (saved) {
      let dataTemp = [...data];
      let index = dataTemp.findIndex(
        (itemTemp: any) => itemTemp.id === item.id,
      );
      dataTemp[index].newestValue.value = saved[item.type];
      dataTemp[index].newestValue.type = item.type;
      setData(dataTemp);
      toastr.success(item.label + ' ' + Translation.getTranslation('saved'));
    }
    setEditMode('');
    setDisabled(false);
  };

  const renderInput = (item: any) => {
    switch (item.type) {
      case 'text':
        return (
          <Input
            disabled={disabled}
            small
            label={item.label}
            className="mt-2"
            value={newValue}
            onChange={(e: any) => setNewValue(e.target.value)}
          />
        );
      case 'date':
        return (
          <DatePick
            initialDate={newValue || new Date()}
            label={item.label}
            dateChange={(date: Date) => {
              setNewValue(date);
            }}
          />
        );
      case 'boolean':
        return (
          <BooleanToggleButton
            label={item.label}
            initialValue={
              newValue !== null ? (newValue ? true : false) : undefined
            }
            onChange={(e: boolean) => setNewValue(e)}
          />
        );
    }
  };

  return (
    <React.Fragment>
      <List className={classes.root} component={'div'}>
        {data.length === 0 ? (
          <React.Fragment>
            <CustomFieldsList
              data={[]}
              returnData={(dataTemp: any) => setData(dataTemp)}
            />
          </React.Fragment>
        ) : (
          <React.Fragment>
            {data.map(
              (item) =>
                item && (
                  <div
                    key={item.id + 'value'}
                    onMouseEnter={() => setHover(item.id)}
                    onMouseLeave={() => setHover('')}
                  >
                    <ListItem>
                      <ListItemAvatar>
                        <Avatar>
                          <Icon icon={CustomFieldsManager.getIcon(item.type)} />
                        </Avatar>
                      </ListItemAvatar>
                      <div>
                        {editMode !== item.id ? (
                          <ListItemText
                            primary={item.label}
                            secondary={
                              item?.newestValue?.type
                                ? CustomFieldsManager.renderCustomFieldValue(
                                    item.newestValue.value,
                                    item.newestValue.type,
                                  )
                                : '-'
                            }
                          />
                        ) : (
                          renderInput(item)
                        )}
                      </div>
                      <ListItemSecondaryAction>
                        {editMode === item.id && (
                          <IconButton
                            edge="end"
                            aria-label="edit"
                            disabled={
                              (item.newestValue &&
                                item.newestValue.value !== null &&
                                item.newestValue.value === newValue) ||
                              ((!item.newestValue ||
                                item.newestValue.value === null) &&
                                newValue === null) ||
                              disabled
                            }
                            color={'primary'}
                            onClick={() => {
                              saveEdit(item);
                            }}
                          >
                            <Icon icon={'check'} />
                          </IconButton>
                        )}
                        {(hover === item.id || data.length === 1) &&
                          editMode !== item.id && (
                            <IconButton
                              edge="end"
                              aria-label="edit"
                              color={'default'}
                              onClick={() => {
                                toggleEditMode(item);
                              }}
                            >
                              <Icon icon={'pen'} />
                            </IconButton>
                          )}
                      </ListItemSecondaryAction>
                    </ListItem>
                  </div>
                ),
            )}
            <Divider />
            <Button
              className="mt-2"
              color="primary"
              onClick={() => setPreferencesOpen(true)}
              text
            >
              {Translation.getTranslation('manage_custom_fields')}
            </Button>
          </React.Fragment>
        )}
      </List>

      <UserPreferencesModal
        isOpen={preferencesOpen}
        initialTab="custom_fields"
        toggleModal={() => setPreferencesOpen(!preferencesOpen)}
      />
    </React.Fragment>
  );
}
