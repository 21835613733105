import { SetStateAction } from 'react';
import ReactGA from 'react-ga';
import { ToothInterface } from '../interfaces/tooth.interface';
import UserInterface from '../interfaces/user.interface';
import Static from '../services/static';
import Translation from './translation';

let currentRoute: any = null;

export default class Helper {
  static moveArrayElement(array: any, oldIndex: number, newIndex: number) {
    while (oldIndex < 0) {
      oldIndex += array.length;
    }
    while (newIndex < 0) {
      newIndex += array.length;
    }
    if (newIndex >= array.length) {
      var k = newIndex - array.length + 1;
      while (k--) {
        array.push(undefined);
      }
    }
    array.splice(newIndex, 0, array.splice(oldIndex, 1)[0]);
    return array;
  }

  static makeArrayOfObject(array: any, attribute: string) {
    return array?.map((iteration: any) => iteration[attribute]);
  }

  static getPosition(pathName: string) {
    if (pathName) {
      let path = pathName?.split('/');
      pathName === '/' && path?.shift();
      if (path?.length > 3) {
        path.length = 3;
      }
      let position = [] as any;
      let makePath = '';
      for (const item of path) {
        makePath += `${
          makePath[makePath.length - 1] === '/' ? '' : '/'
        }${item}`;
        let found = Static.getPages().find(
          (page: any) => page.url === makePath,
        );
        if (found) position.push(found);
      }

      return position;
    }
  }

  static getLastMonth() {
    var moment = require('moment');

    return [
      moment(new Date()).subtract(1, 'months').startOf('month').format(),
      moment(new Date()).subtract(1, 'months').endOf('month').format(),
    ];
  }

  static calculateRatings(ratingsObject: any) {
    let sum = 0;
    for (const rating of ratingsObject) {
      sum += rating.value;
    }
    return (sum / ratingsObject.length).toFixed(2);
  }

  static renderCurrency(currency: 'eur') {
    return currency === 'eur' ? '€' : '';
  }

  // static renderPricing(plan: {
  //   // in cent
  //   amount: number;
  //   currency: string;
  //   interval?: string;
  // }) {
  //   const amount = plan.amount / 100;
  //   const currency = this.renderCurrency(plan.currency as any);
  //   return `${amount}${currency}${
  //     plan.interval ? `/${Translation.getTranslation(plan.interval)}` : ''
  //   }`;
  // }

  static renderPricing(plan: {
    // in cent
    amount: number;
    taxPercentage?: number;
    promotionCodePercentage?: number;
    currency?: string;
    interval?: string;
  }): {
    amount: number;
    amountTax: number;
    taxPercentage: number;
    currency?: string;
    interval?: string;
    amountPromotionCode?: number;
    promotionCodePercentage?: number;
    total: number;
    totalCurrency?: string;
    totalCurrencyInterval?: string;
  } {
    const amount = plan.amount / 100;
    let response = {
      amount,
      amountTax: 0,
      total: 0,
      totalCurrency: '',
      totalCurrencyInterval: '',
      taxPercentage: plan.taxPercentage || 0,
      amountPromotionCode: plan.promotionCodePercentage
        ? (amount * plan.promotionCodePercentage) / 100
        : 0,
      promotionCodePercentage: plan.promotionCodePercentage || 0,
      currency: plan.currency ? this.renderCurrency(plan.currency as any) : '',
      interval: plan.interval
        ? '/' + Translation.getTranslation(plan.interval)
        : '',
    };

    const amountTax = ((amount - response.amountPromotionCode) *
      (response.taxPercentage / 100)) as number;
    const total = amount - response.amountPromotionCode + amountTax;
    const totalCurrency = `${total}${response.currency}`;
    const totalCurrencyInterval = `${total}${response.currency}${response.interval}`;

    response = {
      ...response,
      amountTax: amountTax,
      total,
      totalCurrency,
      totalCurrencyInterval,
    };
    return response;
  }

  static getToday() {
    var moment = require('moment');

    return [
      moment(new Date()).startOf('day').format(),
      moment(new Date()).endOf('day').format(),
    ];
  }

  static getTimeAgo(date: string | Date) {
    var moment = require('moment');
    moment.locale(Translation.getLanguage());
    return moment(date).fromNow();
  }

  static addDaysToDate(date: Date | string, addDays: number) {
    var moment = require('moment');
    return moment(date).add(addDays, 'days').format();
  }

  static getThisMonth() {
    var moment = require('moment');
    return [
      moment(new Date()).startOf('month').format(),
      moment(new Date()).format(),
    ];
  }

  static getDate(date: string | Date, withTime?: boolean) {
    let dateFormat = Translation.getLanguage();
    var moment = require('moment');
    if (date && typeof new Date(date).getMonth === 'function') {
      switch (dateFormat) {
        case 'de':
          if (!withTime) return moment(new Date(date)).format('DD.MM.YYYY');
          return moment(new Date(date)).format('DD.MM.YYYY, HH:mm [Uhr]');
        case 'en':
          if (!withTime) return moment(new Date(date)).format('MM-DD-YYYY');
          return moment(new Date(date)).format('MM-DD-YYYY hh:mm a');
        case 'es':
          if (!withTime) return moment(new Date(date)).format('DD/MM/YYYY');
          return moment(new Date(date)).format('DD/MM/YYYY, HH:mm [horas]');
        default:
          if (!withTime) return moment(new Date(date)).format('MM-DD-YYYY');
          return moment(new Date(date)).format('MM-DD-YYYY hh:mm a');
      }
    }

    return '-';
  }

  static getNextBusinessDay(date: Date) {
    let addDays = date.getHours() <= 16 ? 1 : 2;
    // Add days until get not Sat or Sun
    do {
      date.setDate(date.getDate() + addDays);
    } while (!(date.getDay() % 6));
    return this.getDate(date);
  }

  static validate(
    type:
      | 'email'
      | 'name'
      | 'password'
      | 'text'
      | 'description'
      | 'number'
      | 'tel'
      | 'zip'
      | 'company'
      | 'city'
      | 'tax'
      | 'file_size'
      | 'street'
      | 'invite'
      | 'custom_field',
    value: any,
  ) {
    let error = [] as any;
    if (typeof value !== 'undefined') {
      if (type === 'email') {
        var email = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (!email.test(String(value).toLowerCase())) {
          error.push(Translation.getTranslation('no_valid_mail'));
        }
      }
      if (type === 'street') {
        const street = /^\s*\S+(?:\s+\S+){1}/;
        if (!street.test(String(value))) {
          error.push(Translation.getTranslation('no_valid_street'));
        }
      }
      if (type === 'name') {
        if (value.length < 2 || value.length > 100)
          error.push(Translation.getTranslation('no_valid_name'));
      }
      if (type === 'description') {
        if (value.length > 1000)
          error.push(Translation.getTranslation('no_valid_description'));
      }
      if (type === 'invite') {
        if (value.length !== 6)
          error.push(Translation.getTranslation('no_valid_invite'));
      }
      if (type === 'password') {
        if (value.length < 8)
          error.push(Translation.getTranslation('password_too_short'));
      }
      if (type === 'tel') {
        if (value.length < 5)
          error.push(Translation.getTranslation('no_valid_tel'));
      }
      if (type === 'city') {
        if (value.length < 2)
          error.push(Translation.getTranslation('no_valid_city'));
      }
      if (type === 'zip') {
        if (value.length < 4)
          error.push(Translation.getTranslation('no_valid_zip'));
      }
      if (type === 'tax') {
        if (value && value.length <= 5)
          error.push(Translation.getTranslation('no_valid_tax'));
      }
      if (type === 'company') {
        if (value.length <= 4)
          error.push(Translation.getTranslation('no_valid_company'));
      }
      if (type === 'number') {
        if (typeof value !== 'number' && isNaN(parseInt(value + '')))
          error.push(Translation.getTranslation('must_be_number'));
      }
      if (type === 'text') {
        if (value.length > 2000)
          error.push(Translation.getTranslation('text_too_long'));
      }
      if (type === 'custom_field') {
        if (value.length > 30)
          error.push(Translation.getTranslation('text_too_long'));
      }
      if (type === 'file_size') {
        if (value > 150000000)
          error.push(Translation.getTranslation('file_too_big'));
      }
    } else {
      error.push(Translation.getTranslation('no_value'));
    }
    return error;
  }

  static validateData = (data: any): boolean => {
    let valid = true;
    if (
      typeof data.email !== 'undefined' &&
      Helper.validate('email', data.email).length > 0
    )
      valid = false;
    if (
      typeof data.name !== 'undefined' &&
      Helper.validate('name', data.name).length > 0
    )
      valid = false;
    if (
      typeof data.firstName !== 'undefined' &&
      Helper.validate('name', data.firstName).length > 0
    )
      valid = false;
    if (
      typeof data.lastName !== 'undefined' &&
      Helper.validate('name', data.lastName).length > 0
    )
      valid = false;
    if (
      typeof data.password !== 'undefined' &&
      Helper.validate('password', data.password).length > 0
    )
      valid = false;
    if (
      typeof data.city !== 'undefined' &&
      Helper.validate('city', data.city).length > 0
    )
      valid = false;
    if (
      typeof data.zip !== 'undefined' &&
      Helper.validate('zip', data.zip).length > 0
    )
      valid = false;
    if (
      typeof data.street !== 'undefined' &&
      Helper.validate('street', data.street).length > 0
    )
      valid = false;
    if (
      typeof data.tel !== 'undefined' &&
      Helper.validate('tel', data.tel).length > 0
    )
      valid = false;
    if (
      typeof data.company !== 'undefined' &&
      Helper.validate('company', data.company).length > 0
    ) {
      valid = false;
    }
    if (
      typeof data.inviteCode !== 'undefined' &&
      Helper.validate('invite', data.inviteCode).length > 0
    ) {
      valid = false;
    }
    // valid = ['description'].map((entity: any) => {
    //   if (
    //     typeof data[entity] !== 'undefined' &&
    //     Helper.validate(entity, data[entity]).length > 0
    //   ) {
    //     return false;
    //   }
    // });
    if (
      typeof data.description !== 'undefined' &&
      Helper.validate('description', data.description).length > 0
    ) {
      valid = false;
    }
    // if (Helper.validate('tax', data.taxNumber).length > 0)
    //   valid = false;
    return valid;
  };

  static returnSearchResults(searchString: string, items: any) {
    return items.filter((obj: any) =>
      Object.keys(obj).some(
        (key) =>
          typeof obj[key] === 'string' &&
          obj[key].toLowerCase().includes(searchString.toLowerCase()),
      ),
    );
  }

  static handleErrorMessage(message: any): string {
    if (typeof message === 'string') return message;
    else if (message[0] && message[0].constraints) {
      for (var key in message[0].constraints) {
        return message[0].constraints[key];
      }
    }
    return 'error';
  }

  static compareObjects(x: any, y: any) {
    var objectsAreSame = true;
    for (var propertyName in x) {
      if (x[propertyName] !== y[propertyName]) {
        objectsAreSame = false;
        break;
      }
    }
    return objectsAreSame;
  }

  static compareArrays(array1: any, array2: any) {
    return (
      array1.length === array2.length &&
      array1.sort().every(function (value: any, index: number) {
        return value === array2.sort()[index];
      })
    );
  }

  static validateOrderInformation(
    data: any,
    validateFields?: ToothInterface['orderInformation'][],
  ) {
    let fieldsToValidate = [];
    for (var key in data) {
      if (data.hasOwnProperty(key)) {
        if (!validateFields) fieldsToValidate.push(key);
        //if only some fields should be validated
        else if (
          validateFields.indexOf(key as ToothInterface['orderInformation']) !==
          -1
        )
          fieldsToValidate.push(key);
      }
    }

    interface FieldInfos {
      field: ToothInterface['orderInformation'];
      isValid: boolean;
      errors: string[];
    }

    let results = [] as FieldInfos[];

    let validate_ = (
      data: any,
      field: ToothInterface['orderInformation'],
      allData: any,
    ) => {
      let result = {
        field,
        isValid: true,
        errors: [] as string[],
      } as FieldInfos;

      if (field === 'projectWorkTypes') {
        if (data.length < 1) {
          result.isValid = false;
          result.errors.push(
            Translation.getTranslation('no_projectWorkType_selected'),
          );
        }
      }

      if (field === 'patientName') {
        let validation = Helper.validate('name', data) as string[];
        if (validation.length > 0) {
          result.isValid = false;
          for (const error of validation) {
            result.errors.push(error);
          }
        }
      }

      if (field === 'chosenTeeth') {
        if (data.length < 1) {
          result.isValid = false;
          result.errors.push(Translation.getTranslation('no_tooth_selected'));
        }
      }

      if (field === 'isInternal') {
        if (typeof data === 'undefined') {
          result.isValid = false;
          result.errors.push(Translation.getTranslation('choose_assignee'));
        } else if (data === '') {
          result.isValid = false;
          result.errors.push(Translation.getTranslation('choose_designer'));
        }
      }

      if (field === 'isDentalSplint') {
        if (data && !allData.splintType) {
          result.isValid = false;
          result.errors.push(Translation.getTranslation('no_splint_type'));
        }
      }

      if (field === 'files') {
        if (data) {
          if (data.length > 10) {
            result.isValid = false;
            result.errors.push(Translation.getTranslation('too_many_files'));
          }

          for (const file of data) {
            if (file && file.file) {
              if (
                typeof file.file.size === 'undefined' ||
                !file.file.size ||
                file.file.size === 0
              ) {
                result.isValid = false;
                result.errors.push(Translation.getTranslation('no_valid_file'));
              }
              let validation = Helper.validate('name', file.name) as string[];
              validation.push.apply(
                validation,
                Helper.validate('description', file.description),
              );
              validation.push.apply(
                validation,
                Helper.validate('file_size', file.file.size),
              );
              if (validation.length > 0) {
                result.isValid = false;
                for (const error of validation) {
                  result.errors.push(error);
                }
              }
            }
          }
        }
      }

      if (field === 'projectTeeth') {
        if (!allData.isDentalSplint) {
          if (data.length !== allData.chosenTeeth?.length) {
            result.isValid = false;
            result.errors.push(
              Translation.getTranslation('fill_out_teeth_services'),
            );
          }
          //check for each key if mandatory and if empty = error
          let someFieldEmpty = false;
          for (const tooth of data) {
            for (
              let index = 0;
              index < Static.getRequiredToothInformationTypes().length;
              index++
            ) {
              if (
                typeof tooth[
                  Static.getRequiredToothInformationTypes()[index]
                ] === 'undefined' ||
                tooth[Static.getRequiredToothInformationTypes()[index]] === ''
              ) {
                someFieldEmpty = true;
              }
            }
          }
          if (someFieldEmpty) {
            result.isValid = false;
            result.errors.push(
              Translation.getTranslation('required_service_fields_empty'),
            );
          }
        }
      }

      if (result.isValid === false) results.push(result);
    };

    for (const field of fieldsToValidate) {
      validate_(data[field], field as ToothInterface['orderInformation'], data);
    }

    return results;
  }

  static getLatestStatus(obj: any) {
    return obj.reduce((a: any, b: any) => {
      return new Date(a.updatedAt) > new Date(b.updatedAt) ? a : b;
    });
  }

  static humanFileSize(bytes: number, dp = 1) {
    const thresh = 1000;

    if (Math.abs(bytes) < thresh) {
      return bytes + ' B';
    }

    const units = ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    let u = -1;
    const r = 10 ** dp;

    do {
      bytes /= thresh;
      ++u;
    } while (
      Math.round(Math.abs(bytes) * r) / r >= thresh &&
      u < units.length - 1
    );

    return bytes.toFixed(dp) + ' ' + units[u];
  }

  static getSplintTranslation = (splintType: any): string => {
    if (splintType) {
      let service = Static.getServiceById(splintType) as any;
      return service['label_' + Translation.getLanguage()];
    }
    return '';
  };

  static getFirstDate = (obj: any, field: string) => {
    return new Date(
      Math.min.apply(
        null,
        obj.map((e: any) => {
          return new Date(e[field]);
        }),
      ),
    );
  };

  static getFileTypeByName(name: string): string {
    let parts = name.split('.');
    let result = '';
    if (parts.length > 1) result = parts[parts.length - 1].toLowerCase();
    return result;
  }

  // makes link https
  static optimizeLink = (link: string): any => {
    //make https
    let returnValue = link;
    if (returnValue.indexOf('http://') !== -1) {
      returnValue = returnValue.replace('http://', 'https://');
    }
    return returnValue;
  };

  // Returns and array of strings with all found urls in a given string
  static extractUrls = (text: string): any => {
    var urlRegex = new RegExp(
      '(^|[ \t\r\n])((ftp|http|https|gopher|mailto|news|nntp|telnet|wais|file|prospero|aim|webcal):(([A-Za-z0-9$_.+!*(),;/?:@&~=-])|%[A-Fa-f0-9]{2}){2,}(#([a-zA-Z0-9][a-zA-Z0-9$_.+!*(),;/?:@&~=%-]*))?([A-Za-z0-9$_+!*();/?:~-]))',
      'g',
    );
    var urls = text.match(urlRegex) || undefined;
    if (urls && urls.length > 0) {
      for (let index = 0; index < urls.length; index++) {
        urls[index] = urls[index].replace(' ', '');
      }
    }
    return urls;
  };

  static gaRegisterModalView(
    modalName:
      | 'project_file_upload_preferences'
      | 'forgot_password'
      | 'notifications'
      | 'help'
      | 'user_invite'
      | 'user_preferences'
      | 'project_bulk_assign'
      | 'project_file_upload'
      | 'project_preview'
      | 'project_model_preview'
      | 'project_status_history_view'
      | 'project_status_change_to:cancelled'
      | 'project_status_change_to:wip_correction_claim_designer'
      | 'project_status_change_to:wip_correction_goodwill_designer'
      | 'project_status_change_to:'
      | 'project_comment'
      | 'faq',
  ) {
    ReactGA.modalview(modalName);
  }

  static gaRouteRegister(route: string) {
    let parts = route.split('/');
    let setRoute = '/';
    for (const part of parts) {
      if (part !== '') {
        if (part.indexOf('-') !== -1) {
          setRoute += ':id/';
        } else setRoute += `${part}/`;
      }
    }
    if (setRoute !== currentRoute) {
      // delete www. from hostname
      let hostname = window.location.hostname as string;
      if (hostname.indexOf('www.') !== -1) {
        hostname = hostname.split('www.').pop() as string;
      }
      ReactGA.pageview(hostname + ':' + setRoute);
      currentRoute = setRoute;
    }
  }

  static renderStatusTextForRole = (
    currentStatus: string,
    role: 'user' | 'admin' | 'designer',
  ) => {
    let status = '';
    if (role === 'user') {
      if (
        currentStatus === 'assigned' ||
        currentStatus === 'wip_designer' ||
        currentStatus === 'wip_correction_claim_designer' ||
        currentStatus === 'wip_correction_goodwill_designer' ||
        currentStatus === 'finished_designer'
      ) {
        status = 'wip';
      } else {
        status = 'status_' + currentStatus;
      }
    } else {
      status = 'status_' + currentStatus;
    }
    return Translation.getTranslation(`${status}`);
  };

  // save changes on multiple attributes in one object
  static handleChangesInObject = (
    changeData: {
      attribute: string;
      value: string | number | Object | Function | null | undefined;
    }[],
    getter: any,
    setter: SetStateAction<any>,
  ) => {
    let dataTemp = { ...getter } as any;
    for (const change of changeData) {
      dataTemp[change.attribute] = change.value;
    }

    setter(dataTemp);
  };

  // timer function
  static timer = async (ms: number) =>
    new Promise((res) => setTimeout(res, ms));

  // check if comma is needed in list
  static renderComma = (data: any, index: number, separator?: string) => {
    return typeof data[index + 1] !== 'undefined' ? `${separator || ','} ` : '';
  };

  static renderUserWithCompany = (data: UserInterface): string => {
    return `${data.firstName.charAt(0)}. ${data.lastName} (${data.company})`;
  };
}

export const checkUserRole = (user: UserInterface) => {
  function isUser(): boolean {
    return user.role === 'user';
  }
  function isDesigner(): boolean {
    return user.role === 'designer';
  }
  function isAdmin(): boolean {
    return user.role === 'admin';
  }

  return { isUser, isDesigner, isAdmin };
};
