import { makeStyles } from '@material-ui/core';
import * as React from 'react';
import { FileTypes } from '../../interfaces/project';
import UploadsManager from '../../manager/uploads.manager';
import Helper from '../../services/helper';
import { primary } from '../../services/styles';
import Translation from '../../services/translation';
import ComponentHeading from './ComponentHeading';
import { DropZone } from './DropZone';
import FileCard from './FileCard';
import { ModelViewer } from './ModelViewer';

interface Props {
  match?: any;
  location?: any;
  history?: any;
  chosenFiles: any;
  existingFiles?: any;
  resetStateFiles?: boolean;
  isValid?: any;
  uploadsLabel?: string;
  lg?: '1' | '2' | '3' | '4' | '5' | '6' | '7' | '8' | '9' | '10' | '11' | '12';
  xl?: '1' | '2' | '3' | '4' | '5' | '6' | '7' | '8' | '9' | '10' | '11' | '12';
}

const useStyles = makeStyles((theme) => ({
  badges: {
    position: 'absolute',
    top: '0',
    right: '5px',
  },
  root: {
    border: `2px dashed ${primary}`,
    borderRadius: '10px',
    background: 'transparent',
    '&::after': {
      border: `2px solid ${primary}`,
    },
  },
}));

export function FileDrop(props: Props) {
  const classes = useStyles();
  const [files, setFiles] = React.useState<
    {
      name: string;
      description: string;
      visibility: FileTypes['visibility'];
      file: File;
    }[]
  >([]);
  const [existingFiles, setExistingFiles] = React.useState<any[]>([]);
  const [previewModalIsOpen, setPreviewModalIsOpen] = React.useState<
    string | undefined
  >(undefined);
  const [previewModalType, setPreviewModalType] = React.useState<
    'stl' | 'obj' | undefined
  >(undefined);

  React.useEffect(() => {
    if (props.existingFiles) {
      setExistingFiles(props.existingFiles);
    }
  }, []);

  React.useEffect(() => {
    if (props.existingFiles && typeof props.resetStateFiles !== 'undefined') {
      if (!props.resetStateFiles) {
        setFiles([]);
      }
    }
  }, [props.resetStateFiles]);

  React.useEffect(() => {
    if (props.existingFiles) {
      // if (props?.existingFiles?.length !== prevProps?.existingFiles?.length) {
      setExistingFiles(props.existingFiles);
      // }
    }
  }, [props.existingFiles]);

  React.useEffect(() => {
    // check if file inputs are valid
    if (
      props.existingFiles &&
      props.existingFiles.length > 0 &&
      props.isValid
    ) {
      let valid = [] as boolean[];
      valid.push(
        ...files.map((file: any) => {
          return Helper.validateData({
            name: file.name,
            description: file.description,
          });
        }),
      );
      props.isValid(!valid.includes(false));
    }
  }, [files]);
  const onDrop = (acceptedFiles: File[]) => {
    if (!acceptedFiles || acceptedFiles.length === 0) {
      setFiles([]);
      return;
    }
    let filesTemp = [...files];

    for (const file of acceptedFiles) {
      let name = '';
      let description = '';
      const visibility = 'all_involved' as FileTypes['visibility'];
      if (file.name) {
        name = file.name.split('.')[0];
      }
      filesTemp.push({ name, description, visibility, file });
    }
    props.chosenFiles(filesTemp);
    setFiles(filesTemp);
  };

  const clearFile = (index: number) => {
    let filesTemp = [...files];
    filesTemp.splice(index, 1);
    props.chosenFiles(filesTemp);
    setFiles(filesTemp);
  };

  const deleteFile = async (id: string, index: number) => {
    let deleted = await UploadsManager.deleteFileConfirmation(id);
    if (deleted) {
      let existingFilesTemp = [...existingFiles];
      existingFilesTemp.splice(index, 1);
      setExistingFiles(existingFilesTemp);
    }
  };

  const handleChangeInArray = (
    value: string,
    attribute: string,
    index: number,
  ) => {
    let filesTemp = [...files] as any;
    filesTemp[index][attribute] = value;
    setFiles(filesTemp);
  };

  return (
    <React.Fragment>
      <div className="row">
        <div className="col">
          <DropZone onDrop={(e: any) => onDrop(e)} multiple={true} />
        </div>
      </div>
      {((files && files.length > 0) ||
        (existingFiles && existingFiles.length > 0)) && (
        <div className="row">
          <div className="col">
            <ComponentHeading
              value={
                props.uploadsLabel
                  ? props.uploadsLabel
                  : Translation.getTranslation('file_upload')
              }
            >
              <div className="row">
                {existingFiles &&
                  existingFiles.map((file: any, index: number) => (
                    <div
                      className="col-xl-3 col-lg-4 mb-4"
                      key={file.title + 'existingFiles' + index + ''}
                    >
                      <FileCard
                        file={file}
                        type={'existing'}
                        onDeleteFile={() => deleteFile(file.id, index)}
                        onDownloadFile={async () =>
                          await UploadsManager.getAndDownloadFileById(file.id)
                        }
                        onPreviewFile={() => {
                          setPreviewModalIsOpen(file.id);
                        }}
                      />
                    </div>
                  ))}
                {files &&
                  files.map((file: any, index: number) => (
                    <div
                      className={`col-xl-${props.xl || '3'} col-lg-${
                        props.lg || '4'
                      } col-sm-6 mb-4`}
                      key={file.title + 'files' + index + ''}
                    >
                      <FileCard
                        file={file}
                        type={'new'}
                        id={index}
                        onVisibilityChange={(e: any) => {
                          handleChangeInArray(
                            e.target.value,
                            'visibility',
                            index,
                          );
                        }}
                        onTitleChange={(e: any) => {
                          handleChangeInArray(e.target.value, 'name', index);
                        }}
                        onDescriptionChange={(e: any) => {
                          handleChangeInArray(
                            e.target.value,
                            'description',
                            index,
                          );
                        }}
                        onDeleteFile={() => clearFile(index)}
                        onPreviewFile={() => {
                          setPreviewModalIsOpen(file.file);
                          setPreviewModalType(
                            Helper.getFileTypeByName(file.file.name) as
                              | 'stl'
                              | 'obj',
                          );
                        }}
                      />
                    </div>
                  ))}
              </div>
            </ComponentHeading>
          </div>
        </div>
      )}
      {previewModalIsOpen && (
        <ModelViewer
          file={previewModalIsOpen}
          type={previewModalType as 'stl' | 'obj'}
          toggleModal={() => {
            setPreviewModalIsOpen(undefined);
            setPreviewModalType(undefined);
          }}
        />
      )}
    </React.Fragment>
  );
}
