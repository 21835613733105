import * as React from 'react';
import { Link } from 'react-router-dom';
import { ProjectTable } from '../../components/Project/ProjectTable';
import Button from '../../components/Shared/Button';
import ComponentHeading from '../../components/Shared/ComponentHeading';
import DataTable from '../../components/Shared/DataTable';
import MenuButton from '../../components/Shared/MenuButton';
import PageHeadingRow from '../../components/Shared/PageHeadingRow';
import Select from '../../components/Shared/Select';
import { InviteModal } from '../../components/User/InviteModal';
import RequestManager from '../../manager/request.manager';
import Helper, { checkUserRole } from '../../services/helper';
import Static from '../../services/static';
import Translation from '../../services/translation';
interface Props {
  match?: any;
  location?: any;
  history?: any;
  user: any;
}

export default function AdminDashboard(props: Props) {
  // const [openInvites, setOpenInvites] = React.useState(undefined);
  // const [openRequests, setOpenRequests] = React.useState([]);
  const [pageWidgets, setPageWidgets] = React.useState<any>([]);

  // getOpenRequests = async () => {
  //   let openRequests = await UserManager.getRequests();
  //   this.setState({
  //     openRequests,
  //   });
  // };

  const existingWidget = async (name: string) => {
    let index = pageWidgets.indexOf(name);
    if (index === -1) {
      setPageWidgets((oldArray: any) => [...oldArray, name]);
    }
  };

  const getUserSatisfaction = (data: any) => {
    let sum = 0;
    let amount = 0;
    for (const project of data[data.role === 'user' ? 'orders' : 'designs']) {
      if (project.ratings && project.ratings.length > 0) {
        for (const rating of project.ratings) {
          if (
            (data.role === 'user' && rating.userId === data.id) ||
            data.role === 'designer'
          ) {
            sum += rating.value;
            amount++;
          }
        }
      }
    }
    return { value: amount ? (sum / amount).toFixed(2) : '-', amount };
  };

  return (
    <React.Fragment>
      <PageHeadingRow siteTitle={Static.getPageByName('dashboard').siteTitle}>
        <MenuButton
          label={Translation.getTranslation('more_actions')}
          items={[
            {
              label: Translation.getTranslation('analytics'),
              onClick: Static.getPageByName('analytics').url,
            },
          ]}
        />
        <Link to={Static.getPageByName('archive').url}>
          <Button onClick={() => {}} outline color="default" icon="box-open">
            {Translation.getTranslation('archive')}
          </Button>
        </Link>
      </PageHeadingRow>
      {!pageWidgets && (
        <ComponentHeading
          value={Translation.getTranslation('no_order')}
        ></ComponentHeading>
      )}

      <div className="row">
        {[
          {
            name: 'not_assigned_projects',
            xl: '12',
            lg: '12',
            status: 'send',
            bulkMode: ['assign'],
            color: 'error',
          },
          {
            name: 'designed_projects',
            xl: '12',
            lg: '12',
            status: 'finished_designer',
          },
          {
            name: 'wip_projects',
            xl: '12',
            lg: '12',
            status: 'open',
          },
        ].map((widget: any, index: number) => (
          <div
            className={`col-xl-${widget.xl} col-lg-${widget.lg}`}
            key={widget.name + index + 'admin-dashboard'}
            style={
              pageWidgets.indexOf(widget.name) === -1
                ? { display: 'none' }
                : { display: 'block' }
            }
          >
            <ProjectTable
              title={Translation.getTranslation(widget.name)}
              dataExisting={(dataExisting: boolean) => {
                if (dataExisting) existingWidget(widget.name);
              }}
              bulkMode={widget.bulkMode}
              status={widget.status}
              me={props.user}
              color={widget.color}
              history={props.history}
            />
          </div>
        ))}
      </div>
      <div className="row">
        {/* {this.state.openRequests && this.state.openRequests.length > 0 && (
            <div className="col-xl-6">
              <UserTable
                showAcceptAction={true}
                title={Translation.getTranslation('open_requests')}
                headerColor="default-color"
                color="default"
                data={this.state.openRequests}
                history={this.props.history}
                triggerReload={async () => {
                  await this.getOpenRequests();
                }}
              />
            </div>
          )} */}
        <div className="col-xl-6">
          <DataTable
            title={Translation.getTranslation('open_invites')}
            manager={(options: any) => {
              return RequestManager.getInvites({
                status: 'invited',
                ...options,
              });
            }}
            limit={5}
            additionalElement={() => <InviteModal />}
            doNotSearch
            columns={[
              {
                label: Translation.getTranslation('email'),
                type: { id: 'text', key: 'email' },
              },
              {
                label: Translation.getTranslation('role'),
                type: { id: 'text', key: 'role' },
              },
              {
                label: Translation.getTranslation('invite_code'),
                type: { id: 'text', key: 'code' },
              },
              {
                label: Translation.getTranslation('invite_date'),
                type: { id: 'date', key: 'createdAt' },
              },

              {
                label: Translation.getTranslation('expiry_date'),
                type: {
                  id: 'individual',
                  individualObject: (data: any) => {
                    return Helper.getDate(
                      Helper.addDaysToDate(data.createdAt, 14),
                    );
                  },
                },
              },
            ]}
          />
        </div>
        <div className="col-lg-12">
          <DataTable
            additionalElement={(callback: (filter: any) => void) => (
              <Select
                onChange={(e: any) => {
                  callback({ role: e.target.value });
                }}
              >
                <option value={''}>{Translation.getTranslation('all')}</option>
                <option value={'user'}>
                  {Translation.getTranslation('customer')}
                </option>
                <option value={'designer'}>
                  {Translation.getTranslation('designer')}
                </option>
                <option value={'admin'}>
                  {Translation.getTranslation('admin')}
                </option>
              </Select>
            )}
            onClick={(id: string, data: any) => {
              props.history.push(
                `${Static.getPageByName('user_detail').url}/${id}`,
              );
            }}
            title={Translation.getTranslation('all_users')}
            manager={(options: any) => {
              return RequestManager.getAllUsers(options);
            }}
            sort={['createdAt']}
            limit={5}
            columns={[
              {
                label: Translation.getTranslation('customer_number'),
                type: { id: 'text', key: 'customerNumber' },
              },
              {
                label: Translation.getTranslation('email'),
                type: { id: 'text', key: 'email' },
              },
              {
                label: Translation.getTranslation('firstName'),
                type: { id: 'text', key: 'firstName' },
              },
              {
                label: Translation.getTranslation('lastName'),
                type: { id: 'text', key: 'lastName' },
              },
              {
                label: Translation.getTranslation('role'),
                type: { id: 'translation', key: 'role' },
              },
              {
                label: Translation.getTranslation('default_designer'),
                type: {
                  id: 'individual',
                  individualObject: (data: any) => {
                    return data.defaultDesigner?.company || '-';
                  },
                },
              },
              {
                label: Translation.getTranslation('company'),
                type: { id: 'text', key: 'company' },
              },
              {
                label: Translation.getTranslation('tel'),
                type: { id: 'text', key: 'tel' },
              },
              {
                label: Translation.getTranslation('orders'),
                type: {
                  id: 'individual',
                  individualObject: (data: any) => {
                    return checkUserRole(data).isUser()
                      ? data.orders?.length
                      : checkUserRole(data).isDesigner()
                      ? data.designs?.length
                      : '-';
                  },
                },
              },
              {
                label: Translation.getTranslation('satisfaction'),
                type: {
                  id: 'individual',
                  individualObject: (data: any) => {
                    if (!checkUserRole(data).isAdmin()) {
                      let rating = getUserSatisfaction(data);

                      if (rating.amount)
                        return (
                          <span>
                            {rating.value} ({rating.amount}{' '}
                            {Translation.getTranslation(
                              rating.amount !== 1 ? 'ratings' : 'rating',
                            )}
                            )
                          </span>
                        );
                    }
                    return '-';
                  },
                },
              },
            ]}
          />
        </div>
      </div>
    </React.Fragment>
  );
}
