import { makeStyles, Typography } from '@material-ui/core';
import * as React from 'react';
import { borderRadius, darkGrey, grey, warning } from '../../services/styles';
import Translation from '../../services/translation';
import '../../styles/Spinner.scss';
import Badge from './Badge';
import Button from './Button';
import Icon from './Icon';
import NoDataComponent from './NoDataComponent';

const useStyles = makeStyles((_theme) => ({
  suggestion: {
    minHeight: '5rem',
    marginBottom: '.2rem',
    height: 'fit-content',
    position: 'relative',
    backgroundColor: 'white',
    border: '1px solid ' + grey,
    borderRadius: borderRadius,
    padding: '1rem',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    '&:hover': {
      // backgroundColor: lightGrey
      border: '1px solid ' + darkGrey,
    },
  },
  content: { display: 'flex', flexDirection: 'column' },
  stars: { fontSize: '.5em', color: warning, marginLeft: '4px' },
  sponsored: {
    position: 'absolute',
    top: '5px',
    right: '5px',
    display: 'flex',
    alignItems: 'center',
  },
}));

function DesignerSuggestions(props: { small?: boolean }) {
  // const [hover, setHover] = React.useState(false);
  const classes = useStyles();

  const renderStars = () => {
    return (
      <span className={classes.stars}>
        <Icon icon="star" />
        <Icon icon="star" />
        <Icon icon="star" />
        <Icon icon="star" />
        <Icon icon="star" />
      </span>
    );
  };

  const suggestions = [] as any;
  // const suggestions = [1, 2, 3, 4, 5, 6, 7, 8, 9];

  return (
    <div
    // style={hover ? { boxShadow } : {}}
    // onMouseEnter={() => setHover(true)}
    // onMouseLeave={() => setHover(false)}
    >
      {suggestions?.length > 0 ? (
        suggestions?.map((element: any) => {
          return (
            <div key={element} className={classes.suggestion}>
              <div className={classes.content}>
                {element % 2 ? (
                  <div className={classes.sponsored}>
                    <Badge color="warning">
                      <Icon icon="award" className="mr-1" />
                      {Translation.getTranslation('sponsored')}
                    </Badge>
                  </div>
                ) : (
                  <span />
                )}
                <Typography
                  variant="h6"
                  component="div"
                  className="d-flex align-items-center"
                >
                  <div className="d-flex">
                    <span>Designer AB</span>
                    {renderStars()}
                  </div>
                </Typography>
                <div>
                  <Typography variant="body2">
                    Lorem ipsum sit amet del mama ta tutut Lorem ipsum sit amet
                    del mama ta tutut Lorem ipsum sit amet del mama ta tutut
                    Lorem ipsum sit amet del mama ta tutut Lorem ipsum sit amet
                    del mama ta tutut Lorem ipsum sit amet del mama ta tutut
                  </Typography>
                </div>
                <div className="mt-2 d-flex align-items-center">
                  <Badge color="primary" className="mr-1">
                    <Icon icon="map-marker-alt" className="mr-1" />
                    Saigon
                  </Badge>
                  <Badge color="secondary" className="mr-1">
                    {Translation.getTranslation('project_work_type_cad')}
                  </Badge>
                  <Badge color="secondary" className="mr-1">
                    {Translation.getTranslation('project_work_type_cam')}
                  </Badge>
                  <Badge color="secondary" className="mr-1">
                    {Translation.getTranslation('project_work_type_dentures')}
                  </Badge>
                </div>
              </div>
              <div className="d-flex align-items-center">
                <Button outline color="primary">
                  {Translation.getTranslation('connect')}
                </Button>
              </div>
            </div>
          );
        })
      ) : (
        <NoDataComponent />
      )}
    </div>
  );
}

export default DesignerSuggestions;
