import React, { useEffect } from 'react';
import RequestManager from '../../manager/request.manager';
import Helper, { checkUserRole } from '../../services/helper';
import Static from '../../services/static';
import Translation from '../../services/translation';
import Button from './Button';
import Card from './Card';
import ComponentHeading from './ComponentHeading';
import DatePick from './DatePick';
import Icon from './Icon';
import SearchableSelect from './SearchableSelect';
import Select from './Select';
import Spinner from './Spinner';

interface Props {
  me: any;
  resetFilter: () => void;
  title: string;
  rememberMe?: string;
  loading?: boolean;
  filter: {
    currentStatus?: string;
    designerId?: string;
    userId?: string;
    customerId?: string;
    sort?: string;
    page?: number;
    limit?: number;
    query?: string;
    dateFilter?: string;
    workType?: string;
    extended?: boolean;
    isInternal?: string;
    startDate?: Date;
    endDate?: Date;
  };
  handleFilterChange: (
    name:
      | 'currentStatus'
      | 'extended'
      | 'startDate'
      | 'endDate'
      | 'query'
      | 'sort'
      | 'designerId'
      | 'userId'
      | 'customerId'
      | 'dateFilter'
      | 'isInternal'
      | 'workType',
    value: any,
  ) => void;
}

export default function DataFilter(props: Props) {
  const [designers, setDesigners] = React.useState([]);
  const [customers, setCustomers] = React.useState([]);
  const [users, setUsers] = React.useState([]);

  useEffect(() => {
    async function fetchMyAPI() {
      if (typeof props.filter.designerId !== 'undefined') {
        let designers = await RequestManager.getAllUsers({
          role: 'designer',
          limit: 1000,
        });
        setDesigners(designers && designers.rows ? designers.rows : []);
      }
      if (typeof props.filter.customerId !== 'undefined') {
        let customers = await RequestManager.getAllUsers({
          role: 'user',
          limit: 1000,
        });
        setCustomers(customers && customers.rows ? customers.rows : []);
      }
      if (typeof props.filter.userId !== 'undefined') {
        let users = await RequestManager.getAllUsers({ limit: 1000 });
        setUsers(users && users.rows ? users.rows : []);
      }
    }

    checkUserRole(props.me).isAdmin() && fetchMyAPI();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderFilter = () => {
    return (
      <React.Fragment>
        {checkUserRole(props.me).isAdmin() &&
          typeof props.filter.currentStatus !== 'undefined' && (
            <div className="row mb-3">
              <div className="col-12">
                <label>{Translation.getTranslation('current_status')}</label>
              </div>
              <div className="col-12">
                <Select
                  onChange={(e: any) => {
                    if (
                      e.target.value !== 'finished' &&
                      e.target.value !== '' &&
                      e.target.value !== 'cancelled'
                    ) {
                      props.handleFilterChange('dateFilter', 'sendAt');
                    }
                    props.handleFilterChange('currentStatus', e.target.value);
                  }}
                  value={props.filter.currentStatus}
                >
                  <option value={''}>
                    {Translation.getTranslation('all')}
                  </option>
                  {Static.getStatus().map((status: string, index: number) => (
                    <option value={status} key={status + index}>
                      {Translation.getTranslation('status_' + status)}
                    </option>
                  ))}
                </Select>
              </div>
            </div>
          )}
        {(typeof props.filter.dateFilter !== 'undefined' ||
          typeof props.filter.startDate !== 'undefined' ||
          typeof props.filter.endDate !== 'undefined') && (
          <div className="row">
            {typeof props.filter.dateFilter !== 'undefined' && (
              <React.Fragment>
                <div className="col-12">
                  <label>{Translation.getTranslation('period')}</label>
                </div>
                <div
                  className="col-md-12 col-lg-4 mb-3 d-flex"
                  style={{ alignItems: 'flex-end' }}
                >
                  <Select
                    onChange={(e: any) => {
                      props.handleFilterChange('dateFilter', e.target.value);
                    }}
                    value={props.filter.dateFilter}
                  >
                    <option key={'sendAt'} value={'sendAt'}>
                      {Translation.getTranslation('order_date')}
                    </option>
                    <option
                      key={'closedAt'}
                      value={'closedAt'}
                      disabled={
                        !(
                          !props.filter.currentStatus ||
                          props.filter.currentStatus === 'finished' ||
                          props.filter.currentStatus === 'cancelled'
                        )
                      }
                    >
                      {Translation.getTranslation('close_date')}
                    </option>
                  </Select>
                </div>
              </React.Fragment>
            )}
            {typeof props.filter.startDate !== 'undefined' && (
              <div className="col-md-6 col-lg-4 mb-3">
                <DatePick
                  initialDate={props.filter.startDate}
                  label={Translation.getTranslation('from')}
                  dateChange={(date: Date) => {
                    props.handleFilterChange('startDate', date);
                  }}
                  maxDate={new Date()}
                />
              </div>
            )}
            {typeof props.filter.endDate !== 'undefined' && (
              <div className="col-md-6 col-lg-4 mb-3">
                <DatePick
                  maxDate={new Date()}
                  initialDate={props.filter.endDate}
                  label={Translation.getTranslation('until')}
                  dateChange={(date: Date) => {
                    props.handleFilterChange('endDate', date);
                  }}
                />
              </div>
            )}
          </div>
        )}
        {(typeof props.filter.query !== 'undefined' ||
          typeof props.filter.workType !== 'undefined') && (
          <div className="row">
            {typeof props.filter.query !== 'undefined' && (
              <div className="col-lg-6">
                <div className="row mb-3">
                  <div className="col-12">
                    <label>
                      {Translation.getTranslation('free_text_search')}
                    </label>
                  </div>
                  <div className="col-12">
                    <input
                      onChange={(e) => {
                        props.handleFilterChange('query', e.target.value);
                      }}
                      type="text"
                      value={props.filter.query}
                      placeholder={Translation.getTranslation(
                        'enter_order_number_or_patient',
                      )}
                      className="form-control"
                    />
                  </div>
                </div>
              </div>
            )}
            {typeof props.filter.workType !== 'undefined' && (
              <div className="col-lg-6">
                <div className="row mb-3">
                  <div className="col-12">
                    <label>{Translation.getTranslation('type_of_work')}</label>
                  </div>
                  <div className="col-12">
                    <Select
                      onChange={(e: any) => {
                        props.handleFilterChange('workType', e.target.value);
                      }}
                      value={props.filter.workType}
                    >
                      <option value={''}>
                        {Translation.getTranslation('all_work_types')}
                      </option>
                      {Static.getProjectWorkTypes().map((workType: any) => (
                        <option key={workType} value={workType}>
                          {Translation.getTranslation(
                            'project_work_type_' + workType,
                          )}
                        </option>
                      ))}
                    </Select>
                  </div>
                </div>
              </div>
            )}
          </div>
        )}
        {checkUserRole(props.me).isAdmin() &&
          (typeof props.filter.customerId !== 'undefined' ||
            typeof props.filter.designerId !== 'undefined') && (
            <div className="row mb-3">
              <div className="col-12">
                <label>
                  {Translation.getTranslation('customer_or_designer')}
                </label>
              </div>
              <div className="col-6">
                <SearchableSelect
                  data={customers?.map((customer: any) => {
                    return {
                      value: customer.id,
                      label: Helper.renderUserWithCompany(customer),
                    };
                  })}
                  onChange={(e: any) => {
                    props.handleFilterChange('customerId', e);
                  }}
                  defaultValue={{
                    value: '',
                    label: Translation.getTranslation('all_customers'),
                  }}
                  value={props.filter.customerId}
                />{' '}
              </div>
              <div className="col-6">
                <SearchableSelect
                  data={designers?.map((designer: any) => {
                    return {
                      value: designer.id,
                      label: Helper.renderUserWithCompany(designer),
                    };
                  })}
                  onChange={(e: any) => {
                    props.handleFilterChange('designerId', e);
                  }}
                  defaultValue={{
                    value: '',
                    label: Translation.getTranslation('all_designers'),
                  }}
                  value={props.filter.designerId}
                />
              </div>
            </div>
          )}
        {checkUserRole(props.me).isAdmin() &&
          typeof props.filter.userId !== 'undefined' && (
            <div className="row mb-3">
              <div className="col-12">
                <label>{Translation.getTranslation('user')}</label>
              </div>
              <div className="col-12">
                <SearchableSelect
                  data={users?.map((user: any) => {
                    return {
                      value: user.id,
                      label: `${Helper.renderUserWithCompany(
                        user,
                      )} (${Translation.getTranslation(user.role)})`,
                    };
                  })}
                  onChange={(e: any) => {
                    props.handleFilterChange('userId', e);
                  }}
                  defaultValue={{
                    value: '',
                    label: Translation.getTranslation('all_users'),
                  }}
                  value={props.filter.userId}
                />{' '}
              </div>
            </div>
          )}
        {typeof props.filter.isInternal !== 'undefined' && (
          <div className="row">
            <div className="col-12">
              <div className="row mb-3">
                <div className="col-12">
                  <label>{Translation.getTranslation('execution')}</label>
                </div>
                <div className="col-6">
                  <Select
                    onChange={(e: any) => {
                      props.handleFilterChange('isInternal', e.target.value);
                    }}
                    value={props.filter.isInternal}
                  >
                    <option value={''}>
                      {Translation.getTranslation('all')}
                    </option>
                    <option key={'isInternal'} value={'true'}>
                      {Translation.getTranslation('internal_project')}
                    </option>
                    <option key={'isMyDenti'} value={'false'}>
                      {Translation.getTranslation('mydenti_project')}
                    </option>
                  </Select>
                </div>
              </div>
            </div>
          </div>
        )}
      </React.Fragment>
    );
  };

  return (
    <div className="row">
      <div className="col mb-4">
        <Card
          className="w-100"
          footer={
            <div className="text-right">
              {/* <CsvDownload
          show={
            state.data && state.data.rows && state.data.rows.length > 0
          }
          label={`${Translation.getTranslation('download_table')} (${
            state.data.rows.length ? state.data.rows.length : 0
          })`}
          fileName={Translation.getTranslation('orders') + '.csv'}
          //todo send/use button component
          className="btn btn-sm btn-outline-default"
          data={state.data.rows}
          columns={downloadColumns as any}
        /> */}
              <Button
                size="sm"
                color="default"
                onClick={() => props.resetFilter && props.resetFilter()}
              >
                {Translation.getTranslation('reset_filter')}
              </Button>
            </div>
          }
          onHeaderClick={() => {
            props.handleFilterChange('extended', !props.filter.extended);
          }}
          header={
            <React.Fragment>
              <ComponentHeading noMargin value={props.title} />
              <Icon
                icon={props.filter.extended ? 'chevron-down' : 'chevron-up'}
                className="ml-2"
              />
            </React.Fragment>
          }
        >
          {!props.loading ? (
            <React.Fragment>
              {props.filter.extended && (
                <React.Fragment>
                  {props.filter && renderFilter()}
                </React.Fragment>
              )}
            </React.Fragment>
          ) : (
            <Spinner />
          )}
        </Card>
      </div>
    </div>
  );
}
