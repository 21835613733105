import { Box, Typography } from '@material-ui/core';
import {
  Alert,
  AlertTitle,
  IconContainerProps,
  Rating as RatingMui,
} from '@material-ui/lab';
import React, { useEffect } from 'react';
import RequestManager from '../../manager/request.manager';
import Translation from '../../services/translation';
import Button from './Button';
import Icon from './Icon';
import Input from './Input';

interface Rating {
  projectId: string;
}

function Rating(props: Rating) {
  // const [explain, setExplain] = useState(false);
  const [value, setValue] = React.useState<1 | 2 | 3 | 4 | 5 | null>(null);
  const [hover, setHover] = React.useState(-1);
  const [comment, setComment] = React.useState('');
  const [rating, setRating] = React.useState<any>(undefined);
  const [commentSend, setCommentSend] = React.useState(false);

  const sendRating = async () => {};
  useEffect(() => {
    sendRating();
  }, [value]);

  const customIcons: {
    [index: string]: { icon: React.ReactElement; label: string };
  } = {
    1: {
      icon: <Icon icon="angry" className="mr-2" />,
      label: Translation.getTranslation('very_dissatisfied'),
    },
    2: {
      icon: <Icon icon="frown" className="mr-2" />,
      label: Translation.getTranslation('dissatisfied'),
    },
    3: {
      icon: <Icon icon="meh" className="mr-2" />,
      label: Translation.getTranslation('neutral'),
    },
    4: {
      icon: <Icon icon="smile" className="mr-2" />,
      label: Translation.getTranslation('satisfied'),
    },
    5: {
      icon: <Icon icon="laugh" />,
      label: Translation.getTranslation('very_satisfied'),
    },
  };

  function IconContainer(props: IconContainerProps) {
    const { value, ...other } = props;
    return <span {...other}>{customIcons[value].icon}</span>;
  }

  const sendComment = async () => {
    if (rating && rating.id) {
      setCommentSend(true);
      await RequestManager.putRating({
        comment,
        ratingId: rating.id,
      });
    }
  };

  const sendValue = async (newValue: 1 | 2 | 3 | 4 | 5) => {
    setValue(newValue);
    let ratingTemp = await RequestManager.postRating({
      value: newValue,
      projectId: props.projectId,
    });
    setRating(ratingTemp);
  };

  return (
    <Alert
      color={!value ? 'warning' : 'success'}
      icon={<Icon icon={!value ? 'question-circle' : 'check-circle'} />}
      variant="outlined"
      className="w-100 mb-2"
      classes={{ message: 'w-100' }}
    >
      <AlertTitle>
        {!value
          ? Translation.getTranslation('rating_how_satisfied')
          : Translation.getTranslation('rating_thank_you')}
      </AlertTitle>

      <Box component="fieldset" mb={0} width={'100%'} borderColor="transparent">
        {!value && !commentSend && (
          <Typography component="legend">
            {Translation.getTranslation('rating_explain')}
          </Typography>
        )}
        {!commentSend && (
          <div className="d-flex align-items-center">
            <RatingMui
              name="ratingComponent"
              disabled={value ? true : false}
              size="large"
              value={value}
              onChange={(event, newValue: any) => {
                sendValue(newValue);
              }}
              onChangeActive={(event, newHover) => {
                setHover(newHover);
              }}
              // getLabelText={(value: number) => customIcons[value].label}
              IconContainerComponent={IconContainer}
            />
            {(value !== null || hover !== -1) && (
              <Box ml={2} className="mb-1">
                {customIcons[hover !== -1 ? hover : (value as any)].label}
              </Box>
            )}
          </div>
        )}
        {value && rating && !commentSend && (
          <React.Fragment>
            <Input
              id="commentTextarea"
              className="mt-2 w-100"
              label={
                Translation.getTranslation('comment') +
                ': ' +
                Translation.getTranslation('rating_why_are_you') +
                ' ' +
                customIcons[value].label.toLowerCase() +
                '?'
              }
              rows={5}
              multiline
              value={comment}
              onChange={(e: any) => {
                setComment(e.target.value);
              }}
            />
            <Button
              color="primary"
              onClick={() => sendComment()}
              disabled={!comment || !rating}
            >
              {Translation.getTranslation('rating_comment_send')}
            </Button>
          </React.Fragment>
        )}
      </Box>
    </Alert>
  );
}

export default Rating;
