import { makeStyles, Typography } from '@material-ui/core';
import * as React from 'react';
import myDentiLogo from '../../images/myDentiLogoTrans.png';
import { footerHeight } from '../../services/styles';

const useStyles = makeStyles((_theme) => ({
  root: {
    backgroundColor: '#25282c',
    height: footerHeight,
    '& a': {
      color: 'white',
      '&:hover': {
        textDecoration: 'underline',
      },
    },
  },
}));

function Footer(props: any) {
  const classes = useStyles();
  return (
    <div className={'container-fluid ' + classes.root}>
      <div className="row h-100 w-100 d-flex align-items-center">
        <div className="col-md-6  d-none d-md-block ">
          <Typography>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={'https://www.mydenti.de/'}
            >
              <img
                src={myDentiLogo}
                alt="myDenti Logo"
                className="mr-2"
                style={{ width: '5rem' }}
              />
              <span>myDenti UG (haftungsbeschränkt)</span>
            </a>
          </Typography>
        </div>
        <div className="col-md-6 d-flex justify-content-end flex-column flex-xl-row text-right">
          <Typography component="span" className="mr-2">
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={'https://www.mydenti.de/agb'}
            >
              Geschäftsbedingungen
            </a>
          </Typography>
          <Typography component="span" className="mr-2">
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={'https://www.mydenti.de/privacy'}
            >
              Datenschutzerklärung
            </a>
          </Typography>
          <Typography component="span" className="mr-2">
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={'https://www.mydenti.de/impressum'}
            >
              Impressum
            </a>
          </Typography>
        </div>
      </div>
    </div>
  );
}

export default Footer;
