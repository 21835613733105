import * as React from 'react';
import toastr from 'toastr';
import AcceptProjectCard from '../components/Project/AcceptProjectCard';
import AdminCommentList from '../components/Project/AdminCommentList';
import { CommentModal } from '../components/Project/CommentModal';
import CommentSection from '../components/Project/CommentSection';
import ProjectInformation from '../components/Project/ProjectInformation';
import ToothChoose from '../components/Project/ToothChoose';
import ToothService from '../components/Project/ToothService';
import Button from '../components/Shared/Button';
import ComponentHeading from '../components/Shared/ComponentHeading';
import MenuButton from '../components/Shared/MenuButton';
import PageHeadingRow from '../components/Shared/PageHeadingRow';
import Rating from '../components/Shared/Rating';
import Spinner from '../components/Shared/Spinner';
import FileTable from '../components/Upload/FileTable';
import ProjectsManager from '../manager/projects.manager';
import RequestManager from '../manager/request.manager';
import { Alert } from '../services/alert';
import { checkUserRole } from '../services/helper';
import Static from '../services/static';
import Translation from '../services/translation';
import { ProjectDetailAdminPanel } from './sub-pages/ProjectDetailAdminPanel';
import { ProjectDetailUserFileSection } from './sub-pages/ProjectDetailUserFileSection';
interface Props {
  match?: any;
  location?: any;
  history?: any;
  user: any;
}

export default function ProjectDetail(props: Props) {
  const [project, setProject] = React.useState<any>(undefined);
  const [commentModalIsOpen, setCommentModalIsOpen] = React.useState<boolean>(
    false,
  );
  const [isRated, setIsRated] = React.useState<boolean>(true);
  const [loading, setLoading] = React.useState<boolean>(true);

  React.useEffect(() => {
    getProject({ initial: true });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.match.params.projectId]);

  // possible feature
  // saveAsPDF = () => {
  //   const html2canvas = require('html2canvas');
  //   html2canvas(document.getElementById('orderDetails')).then((canvas: any) => {
  //     const imgData = canvas.toDataURL('image/png');
  //     const pdf = new jsPDF({
  //       orientation: 'landscape',
  //     });
  //     const imgProps = pdf.getImageProperties(imgData);
  //     const pdfWidth = pdf.internal.pageSize.getWidth();
  //     const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
  //     pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);
  //     pdf.save('download.pdf');
  //   });
  // };

  const getProject = async (options?: { initial?: boolean }) => {
    let projectTemp = await RequestManager.getProject(
      props.match.params.projectId,
      options ? { initial: options.initial || undefined } : {},
    );
    if (projectTemp) {
      if (
        checkUserRole(props.user).isUser() &&
        projectTemp.currentStatus === 'finished'
      ) {
        getRatings();
      }
      //designer can only see this when wip

      if (checkUserRole(props.user).isDesigner()) {
        if (
          projectTemp.currentStatus !== 'wip_designer' &&
          projectTemp.currentStatus !== 'wip_correction_claim_designer' &&
          projectTemp.currentStatus !== 'wip_correction_goodwill_designer' &&
          projectTemp.currentStatus !== 'assigned'
        ) {
          props.history.push('/');
        }
      }
      projectTemp = await ProjectsManager.getSystemServicesInProjectData(
        projectTemp,
      );
      setProject(projectTemp);
      setLoading(false);
    } else {
      props.history.push('/');
    }
  };

  const getRatings = async () => {
    let ratingsTemp = await RequestManager.getRatings(
      props.match.params.projectId,
    );
    if (!ratingsTemp || ratingsTemp.length === 0) {
      setIsRated(false);
    }
  };

  const archiveProject = async () => {
    let archived = await RequestManager.archiveProject([project.id]);
    if (archived) {
      toastr.success(Translation.getTranslation('project_archived'));
    } else {
      toastr.error(Translation.getTranslation('error'));
    }
  };

  const duplicateProject = async () => {
    let duplicate = await RequestManager.duplicateProject(project.id);

    if (duplicate) {
      Alert.open(
        'success',
        Translation.getTranslation('success'),
        Translation.getTranslation('project_duplicated'),
      ).then((clicked: any) => {
        props.history.push(
          `${Static.getPageByName('order_create').url}/${duplicate.id}`,
        );
      });
    } else {
      Alert.open(
        'error',
        Translation.getTranslation('error'),
        Translation.getTranslation('project_not_duplicated'),
      );
    }
  };

  /**
   * Comment and file row for admin and designer
   */
  const renderCommentAndFileRow = () => {
    return (
      <React.Fragment>
        {((project.projectComments && project.projectComments.length > 0) ||
          (project.files && project.files.length > 0)) && (
          <div className="row">
            {checkUserRole(props.user).isAdmin() &&
              project.projectComments &&
              project.projectComments.length > 0 && (
                <div className="col-lg-4">
                  <ComponentHeading
                    value={Translation.getTranslation('comments')}
                  >
                    <AdminCommentList
                      project={project}
                      reloadData={() => getProject()}
                      comments={project.projectComments}
                      me={props.user}
                    />
                  </ComponentHeading>
                </div>
              )}
            {project.files && project.files.length > 0 && (
              <div className="col-lg-8">
                <ComponentHeading value={Translation.getTranslation('files')}>
                  <FileTable
                    data={project.files}
                    project={project}
                    me={props.user}
                    history={props.history}
                  />
                </ComponentHeading>
              </div>
            )}
          </div>
        )}
      </React.Fragment>
    );
  };

  const renderCommentModal = () => {
    return (
      <CommentModal
        isOpen={commentModalIsOpen}
        projectId={project.id}
        me={props.user}
        reloadData={() => getProject()}
        toggleModal={() => {
          setCommentModalIsOpen(false);
        }}
      ></CommentModal>
    );
  };

  const renderUserMoreActions = () => {
    let actions = [
      {
        label: Translation.getTranslation('duplicate_project'),
        onClick: () => {
          duplicateProject();
        },
      },
    ];

    !project.isArchived &&
      (project.currentStatus === 'finished' ||
        project.currentStatus === 'cancelled' ||
        project.currentStatus === 'closed') &&
      actions.push({
        label: Translation.getTranslation('mark_project_archived'),
        onClick: () => {
          archiveProject();
        },
      });

    return actions;
  };

  const renderUserAndDesignerCommentRow = () => {
    return (
      <div className="row">
        <div className="col-lg-12">
          <ComponentHeading
            value={Translation.getTranslation('comments')}
            addition={
              checkUserRole(props.user).isUser() &&
              project.currentStatus !== 'finished' &&
              project.currentStatus !== 'cancelled' &&
              project.currentStatus !== 'closed' ? (
                <Button
                  color="primary"
                  icon={'plus-square'}
                  onClick={() => {
                    setCommentModalIsOpen(true);
                  }}
                >
                  {Translation.getTranslation('add_comment')}
                </Button>
              ) : undefined
            }
            additionPullRight
          >
            <CommentSection
              userId={project.userId}
              designerId={project.designerId ? project.designerId : undefined}
              comments={project.projectComments}
              me={props.user}
            />
          </ComponentHeading>
        </div>
      </div>
    );
  };

  const renderOrderDetails = () => {
    return (
      <div id="orderDetails">
        <div className="row">
          <div className="col-lg-12 col-xl-4">
            <ComponentHeading
              value={Translation.getTranslation('order_details')}
            >
              <ProjectInformation project={project} user={props.user} />
            </ComponentHeading>
          </div>
          <div className="col-lg-12 col-xl-8">
            <ComponentHeading
              value={Translation.getTranslation('chosen_teeth')}
            >
              <ToothChoose disabled preSelected={project.chosenTeeth} />
            </ComponentHeading>
          </div>
        </div>
        <div className="row">
          {!project.isDentalSplint && (
            <div className="col-xl-12">
              <ComponentHeading
                value={Translation.getTranslation('service_per_tooth')}
              >
                <ToothService
                  preSelected={project.projectTeeth}
                  chosenTeeth={project.chosenTeeth}
                  disabled
                />
              </ComponentHeading>
            </div>
          )}
        </div>
      </div>
    );
  };

  /**
   * Admin panel for admin and designer
   */
  const renderAdminPanel = () => {
    return (
      <div className="row">
        <div className="col">
          <ProjectDetailAdminPanel
            history={props.history}
            reloadData={() => getProject()}
            project={project}
            user={props.user}
          />
        </div>
      </div>
    );
  };

  return !loading ? (
    <React.Fragment>
      {project && (
        <React.Fragment>
          <PageHeadingRow
            siteTitle={
              project?.name
                ? `${Translation.getTranslation('project')} ${project?.name}`
                : Static.getPageByName('order_detail').siteTitle
            }
          >
            {checkUserRole(props.user).isUser() && (
              <MenuButton
                label={Translation.getTranslation('more_actions')}
                items={renderUserMoreActions()}
              />
            )}
          </PageHeadingRow>

          {project.currentStatus === 'assigned' &&
            checkUserRole(props.user).isDesigner() && (
              <AcceptProjectCard
                reloadData={() => getProject()}
                project={project}
                user={props.user}
              />
            )}

          {(checkUserRole(props.user).isAdmin() ||
            checkUserRole(props.user).isDesigner()) &&
            project.currentStatus !== 'finished' &&
            project.currentStatus !== 'cancelled' &&
            project.currentStatus !== 'closed' &&
            renderAdminPanel()}

          {checkUserRole(props.user).isUser() &&
            project.currentStatus === 'finished' &&
            !isRated && <Rating projectId={project.id} />}

          {(checkUserRole(props.user).isUser() ||
            checkUserRole(props.user).isDesigner()) &&
            ((project.projectComments &&
              project.projectComments.length > 0 &&
              (project.currentStatus === 'finished' ||
                project.currentStatus === 'cancelled' ||
                project.currentStatus === 'closed')) ||
              (project.currentStatus !== 'finished' &&
                project.currentStatus !== 'cancelled' &&
                project.currentStatus !== 'closed')) &&
            renderUserAndDesignerCommentRow()}

          {renderOrderDetails()}
          {renderCommentModal()}

          {checkUserRole(props.user).isUser() && (
            <ProjectDetailUserFileSection
              project={project}
              user={props.user}
              history={props.history}
              reloadData={() => getProject()}
            />
          )}
          {props.user.role !== 'user' && renderCommentAndFileRow()}
        </React.Fragment>
      )}
    </React.Fragment>
  ) : (
    <Spinner />
  );
}
