import * as React from 'react';
import UserInterface from '../../interfaces/user.interface';
import RequestManager from '../../manager/request.manager';
import { Alert } from '../../services/alert';
import Helper from '../../services/helper';
import Translation from '../../services/translation';
import Button from '../Shared/Button';
import ComponentHeading from '../Shared/ComponentHeading';
import Input from '../Shared/Input';
import Modal from '../Shared/Modal';
import Select from '../Shared/Select';
import TabNav from '../Shared/Tabs';
import CustomFieldsList from './CustomFieldsList';

interface Props {
  match?: any;
  location?: any;
  isOpen: boolean;
  initialTab?: Tab['name'];
  toggleModal: () => void;
}

interface Tab {
  name:
    | 'general'
    | 'change_password'
    | 'company_information'
    | 'custom_fields'
    | 'delete_account';
  label: string;
  jsx: any;
}

export function UserPreferencesModal(props: Props) {
  // const user = useRecoilValue(userState);
  // const setUser = useSetRecoilState(userState);
  const [initialTab, setInitialTab] = React.useState<number>(0);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [user, setUser] = React.useState<UserInterface>({
    email: '',
    firstName: '',
    lastName: '',
    company: '',
    street: '',
    zip: '',
    city: '',
    tel: '',
    taxNumber: '',
    newPassword: '',
    password: '',
    language: Translation.getLanguage(),
  });

  React.useEffect(() => {
    if (props.initialTab) {
      const index = tabs.findIndex(
        (tabTemp: Tab) => tabTemp.name === props.initialTab,
      );
      if (index !== -1) {
        setInitialTab(index);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.initialTab]);

  React.useEffect(() => {
    const getUser = async () => {
      let userTemp = await RequestManager.getMe();
      if (userTemp) {
        userTemp.password = '';
        userTemp.newPassword = '';
        setUser(userTemp);
      }
    };

    if (props.isOpen) {
      getUser();
      Helper.gaRegisterModalView('user_preferences');
    }
  }, [props.isOpen]);

  const validate = (): boolean => {
    if (user) {
      let valid = true;
      if (Helper.validate('email', user.email).length > 0) {
        valid = false;
      }
      if (Helper.validate('name', user.firstName).length > 0) {
        valid = false;
      }
      if (Helper.validate('name', user.lastName).length > 0) {
        valid = false;
      }
      if (Helper.validate('city', user.city).length > 0) {
        valid = false;
      }
      if (Helper.validate('zip', user.zip).length > 0) {
        valid = false;
      }
      if (Helper.validate('street', user.street).length > 0) {
        valid = false;
      }
      if (Helper.validate('tel', user.tel).length > 0) {
        valid = false;
      }
      if (Helper.validate('company', user.company).length > 0) {
        valid = false;
      }
      if (Helper.validate('tax', user.taxNumber).length > 0) {
        valid = false;
      }
      if (
        user.newPassword &&
        user.newPassword.length > 0 &&
        Helper.validate('password', user.newPassword).length > 0
      ) {
        valid = false;
      }

      if (user.newPassword && !user.password) {
        valid = false;
      }
      return valid;
    }
    return false;
  };

  const saveChanges = async () => {
    setLoading(true);
    let userTemp = Object.assign({}, user);
    // delete pw from object if not used
    if (userTemp.password === '' || userTemp.newPassword === '') {
      delete userTemp.password;
      delete userTemp.newPassword;
    }
    let response = await RequestManager.putMe(userTemp);
    if (response) {
      userTemp.password = '';
      userTemp.newPassword = '';
      setUser(userTemp);
    }
    setLoading(false);
  };

  const deleteAccount = async () => {
    setLoading(true);
    Alert.confirm({
      type: 'warning',
      title: Translation.getTranslation('delete_account'),
      text: Translation.getTranslation('sure_delete_account'),
      confirmButtonText: Translation.getTranslation('delete_account'),
    }).then((answer: boolean) => {
      answer &&
        Alert.open(
          'success',
          Translation.getTranslation('account_deleted'),
        ).then(async (confirmed: any) => {
          let response = await RequestManager.deleteMe();
          if (response) {
            await RequestManager.logout();
          }
        });
    });
    setLoading(false);
  };

  const tabs = [
    {
      name: 'general',
      label: Translation.getTranslation('general'),
      jsx: (
        <React.Fragment>
          <ComponentHeading value={Translation.getTranslation('general')}>
            <Input
              label={Translation.getTranslation('your_email')}
              id="preferencesEmailInput"
              icon="envelope"
              small
              className="mb-3"
              type="email"
              onChange={(e: any) => {
                Helper.handleChangesInObject(
                  [
                    {
                      value: e.target.value,
                      attribute: 'email',
                    },
                  ],
                  user,
                  setUser,
                );
              }}
              value={user.email}
              hasError={
                user.email === ''
                  ? false
                  : Helper.validate('email', user.email).length > 0
                  ? true
                  : false
              }
              helperText={
                user.email !== '' &&
                Helper.validate('email', user.email).length > 0 &&
                Helper.validate('email', user.email)
              }
            />
            <Input
              label={Translation.getTranslation('your_firstName')}
              id="preferencesFirstnameInput"
              icon="user"
              small
              className="mb-3"
              type="text"
              onChange={(e: any) => {
                Helper.handleChangesInObject(
                  [
                    {
                      value: e.target.value,
                      attribute: 'firstName',
                    },
                  ],
                  user,
                  setUser,
                );
              }}
              value={user.firstName ? user.firstName : ''}
              hasError={
                user.firstName === ''
                  ? false
                  : Helper.validate('name', user.firstName).length > 0
                  ? true
                  : false
              }
              helperText={
                user.firstName !== '' &&
                Helper.validate('name', user.firstName).length > 0 &&
                Helper.validate('name', user.firstName)
              }
            />
            <Input
              label={Translation.getTranslation('your_lastName')}
              id="preferencesLastnameInput"
              icon="signature"
              small
              className="mb-3"
              type="text"
              onChange={(e: any) => {
                Helper.handleChangesInObject(
                  [
                    {
                      value: e.target.value,
                      attribute: 'lastName',
                    },
                  ],
                  user,
                  setUser,
                );
              }}
              value={user.lastName ? user.lastName : ''}
              hasError={
                user.lastName === ''
                  ? false
                  : Helper.validate('name', user.lastName).length > 0
                  ? true
                  : false
              }
              helperText={
                user.lastName !== '' &&
                Helper.validate('name', user.lastName).length > 0 &&
                Helper.validate('name', user.lastName)
              }
            />
            <Input
              label={Translation.getTranslation('tel')}
              id="preferencesTelInput"
              icon="phone"
              small
              className="mb-3"
              type="text"
              onChange={(e: any) => {
                Helper.handleChangesInObject(
                  [
                    {
                      value: e.target.value,
                      attribute: 'tel',
                    },
                  ],
                  user,
                  setUser,
                );
              }}
              value={user.tel ? user.tel : ''}
              hasError={
                user.tel === ''
                  ? false
                  : Helper.validate('tel', user.tel).length > 0
                  ? true
                  : false
              }
              helperText={
                user.tel !== '' &&
                Helper.validate('tel', user.tel).length > 0 &&
                Helper.validate('tel', user.tel)
              }
            />
          </ComponentHeading>
          <ComponentHeading value={Translation.getTranslation('language')}>
            <Select
              className="mb-4"
              onChange={(e: any) => {
                Translation.setLanguage(e.target.value);
                Helper.handleChangesInObject(
                  [
                    {
                      value: e.target.value,
                      attribute: 'language',
                    },
                  ],
                  user,
                  setUser,
                );
              }}
              value={user.language}
            >
              <option value={'de'} key={'de'}>
                {Translation.getTranslation('german')}
              </option>
              <option value={'en'} key={'en'}>
                {Translation.getTranslation('english')}
              </option>
              <option value={'es'} key={'es'}>
                {Translation.getTranslation('spanish')}
              </option>
            </Select>
          </ComponentHeading>
        </React.Fragment>
      ),
    },
    {
      name: 'change_password',
      label: Translation.getTranslation('change_password'),
      jsx: (
        <React.Fragment>
          <ComponentHeading
            value={Translation.getTranslation('change_password')}
          >
            <Input
              label={Translation.getTranslation('old_password')}
              id="preferencesOldPwInput"
              icon="lock"
              small
              className="mb-3"
              type="password"
              onChange={(e: any) => {
                Helper.handleChangesInObject(
                  [
                    {
                      value: e.target.value,
                      attribute: 'password',
                    },
                  ],
                  user,
                  setUser,
                );
              }}
              value={user.password ? user.password : ''}
              hasError={
                user.password === ''
                  ? false
                  : Helper.validate('password', user.password).length > 0
                  ? true
                  : false
              }
              helperText={
                user.password !== '' &&
                Helper.validate('password', user.password).length > 0 &&
                Helper.validate('password', user.password)
              }
            />
            <Input
              label={Translation.getTranslation('new_password')}
              id="preferencesNewPwInput"
              icon="lock"
              small
              className="mb-3"
              type="password"
              onChange={(e: any) => {
                Helper.handleChangesInObject(
                  [
                    {
                      value: e.target.value,
                      attribute: 'newPassword',
                    },
                  ],
                  user,
                  setUser,
                );
              }}
              value={user.newPassword ? user.newPassword : ''}
              hasError={
                user.newPassword === ''
                  ? false
                  : Helper.validate('password', user.newPassword).length > 0
                  ? true
                  : false
              }
              helperText={
                user.newPassword !== '' &&
                Helper.validate('password', user.newPassword).length > 0 &&
                Helper.validate('password', user.newPassword)
              }
            />
          </ComponentHeading>
        </React.Fragment>
      ),
    },
    {
      name: 'company_information',
      label: Translation.getTranslation('company_information'),
      jsx: (
        <React.Fragment>
          <ComponentHeading
            value={Translation.getTranslation('company_information')}
          >
            <Input
              label={Translation.getTranslation('company_name')}
              id="preferencesCompanyInput"
              icon="briefcase"
              small
              className="mb-3"
              type="text"
              onChange={(e: any) => {
                Helper.handleChangesInObject(
                  [
                    {
                      value: e.target.value,
                      attribute: 'company',
                    },
                  ],
                  user,
                  setUser,
                );
              }}
              value={user.company ? user.company : ''}
              hasError={
                user.company === ''
                  ? false
                  : Helper.validate('company', user.company).length > 0
                  ? true
                  : false
              }
              helperText={
                user.company !== '' &&
                Helper.validate('company', user.company).length > 0 &&
                Helper.validate('company', user.company)
              }
            />
            <Input
              label={Translation.getTranslation('street')}
              id="preferencesStreetInput"
              icon="road"
              small
              className="mb-3"
              type="text"
              onChange={(e: any) => {
                Helper.handleChangesInObject(
                  [
                    {
                      value: e.target.value,
                      attribute: 'street',
                    },
                  ],
                  user,
                  setUser,
                );
              }}
              value={user.street ? user.street : ''}
              hasError={
                user.street === ''
                  ? false
                  : Helper.validate('street', user.street).length > 0
                  ? true
                  : false
              }
              helperText={
                user.street !== '' &&
                Helper.validate('street', user.street).length > 0 &&
                Helper.validate('street', user.street)
              }
            />
            <Input
              label={Translation.getTranslation('zip')}
              id="preferencesZipInput"
              icon="street-view"
              small
              className="mb-3"
              type="text"
              onChange={(e: any) => {
                Helper.handleChangesInObject(
                  [
                    {
                      value: e.target.value,
                      attribute: 'zip',
                    },
                  ],
                  user,
                  setUser,
                );
              }}
              value={user.zip ? user.zip : ''}
              hasError={
                user.zip === ''
                  ? false
                  : Helper.validate('zip', user.zip).length > 0
                  ? true
                  : false
              }
              helperText={
                user.zip !== '' &&
                Helper.validate('zip', user.zip).length > 0 &&
                Helper.validate('zip', user.zip)
              }
            />
            <Input
              label={Translation.getTranslation('city')}
              id="preferencesCityInput"
              icon="city"
              small
              className="mb-3"
              type="text"
              onChange={(e: any) => {
                Helper.handleChangesInObject(
                  [
                    {
                      value: e.target.value,
                      attribute: 'city',
                    },
                  ],
                  user,
                  setUser,
                );
              }}
              value={user.city ? user.city : ''}
              hasError={
                user.city === ''
                  ? false
                  : Helper.validate('city', user.city).length > 0
                  ? true
                  : false
              }
              helperText={
                user.city !== '' &&
                Helper.validate('city', user.city).length > 0 &&
                Helper.validate('city', user.city)
              }
            />
            <Input
              label={Translation.getTranslation('tax_number')}
              id="preferencesTaxInput"
              icon="address-card"
              small
              className="mb-3"
              type="text"
              onChange={(e: any) => {
                Helper.handleChangesInObject(
                  [
                    {
                      value: e.target.value,
                      attribute: 'taxNumber',
                    },
                  ],
                  user,
                  setUser,
                );
              }}
              value={user.taxNumber ? user.taxNumber : ''}
            />
          </ComponentHeading>
        </React.Fragment>
      ),
    },
    {
      name: 'custom_fields',
      label: Translation.getTranslation('custom_fields'),
      jsx: (
        <React.Fragment>
          <ComponentHeading
            value={Translation.getTranslation('custom_fields')}
            explain={Translation.getTranslation('custom_fields_explain')}
          >
            <CustomFieldsList data={user.projectCustomFields} />
          </ComponentHeading>
        </React.Fragment>
      ),
    },
    {
      name: 'delete_account',
      label:
        user.role !== 'admin'
          ? Translation.getTranslation('delete_account')
          : undefined,
      jsx: (
        <React.Fragment>
          <ComponentHeading
            value={Translation.getTranslation('delete_account')}
          >
            <Button
              // outline
              color="danger"
              onClick={deleteAccount}
              icon={'user-minus'}
            >
              {Translation.getTranslation('delete_account')}
            </Button>
          </ComponentHeading>
        </React.Fragment>
      ),
    },
  ] as Tab[];

  const renderModal = () => {
    if (user)
      return (
        <Modal
          noPadding
          position="top"
          isOpen={props.isOpen}
          toggleModal={() => props.toggleModal()}
          size="lg"
          header={Translation.getTranslation('user_settings')}
          disabled={!validate() || loading}
          onSave={() => saveChanges()}
        >
          <React.Fragment>
            <TabNav initialTab={initialTab} elements={tabs} />
          </React.Fragment>
        </Modal>
      );
  };

  return <React.Fragment>{props.isOpen && renderModal()}</React.Fragment>;
}
