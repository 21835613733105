import * as React from 'react';
import Helper from '../../services/helper';
import Translation from '../../services/translation';
import Button from '../Shared/Button';
import Modal from '../Shared/Modal';
import FaqModal from './FaqModal';

interface HelpModalInterface {
  match?: any;
  location?: any;
  history?: any;
  isOpen: boolean;
  toggleModal: () => void;
}

function HelpModal(props: HelpModalInterface) {
  const [reportBug, setReportBug] = React.useState(false);

  React.useEffect(() => {
    if (props.isOpen) {
      Helper.gaRegisterModalView('help');
    }
  }, [props.isOpen]);

  const renderModal = () => {
    return (
      <Modal
        isOpen={props.isOpen}
        toggleModal={() => props.toggleModal()}
        header={Translation.getTranslation('help')}
      >
        <div className="text-center w-100">
          <FaqModal />
          {!reportBug && (
            <Button
              color="primary"
              className="w-100"
              // href={'https://forms.gle/uVVysruf1LfciT7w6'}
              // target="_blank"
              onClick={() => setReportBug(!reportBug)}
              icon="bug"
            >
              {Translation.getTranslation('report_bug')}
            </Button>
          )}
          {reportBug && (
            <div>
              <iframe
                title={'bug-report'}
                src="https://forms.monday.com/forms/embed/79c0f82f91b30d1c77f52be55b253e40"
                width="100%"
                height="500"
                style={{
                  border: 0,
                  borderRadius: '.125rem',
                  margin: '.375rem',
                  boxShadow:
                    '0 2px 5px 0 rgba(0,0,0,0.16), 0 2px 10px 0 rgba(0,0,0,0.12)',
                }}
              ></iframe>
            </div>
          )}
          <Button
            color="primary"
            className="w-100"
            href={'mailto:support@ibach.xyz'}
            target="_blank"
            icon="envelope"
          >
            {Translation.getTranslation('contact_support')}
          </Button>
          <Button
            color="primary"
            className="w-100"
            href={'https://www.mydenti.de/impressum'}
            target="_blank"
            icon="balance-scale"
          >
            {Translation.getTranslation('imprint')}
          </Button>
        </div>
      </Modal>
    );
  };

  return <React.Fragment>{renderModal()}</React.Fragment>;
}

export default HelpModal;
