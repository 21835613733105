import { Chip, makeStyles } from '@material-ui/core';
import React, { ElementType } from 'react';
import { danger, grey, success, warning } from '../../services/styles';

interface Props {
  children?: any;
  className?: string;
  tag?: ElementType | any;
  color: 'primary' | 'secondary' | 'error' | 'success' | 'warning' | 'grey';
}

function Badge(props: Props) {
  const getColorCode = (color: Props['color']) => {
    switch (color) {
      case 'error':
        return danger;
      case 'success':
        return success;
      case 'warning':
        return warning;
      case 'grey':
        return grey;
    }
  };

  const useStyles = makeStyles((_theme) => ({
    root: {
      height: '20px',
      backgroundColor: getColorCode(props.color),
      color:
        props.color === 'error' || props.color === 'secondary' ? 'white' : '',
    },
  }));
  const classes = useStyles();

  return (
    <Chip
      component={props.tag}
      size="small"
      color={
        props.color !== 'primary' && props.color !== 'secondary'
          ? undefined
          : props.color
      }
      className={props.className + ' ' + classes.root}
      //  icon={<FaceIcon />}
      label={props.children}
    />
  );
}

export default Badge;
