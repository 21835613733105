import { makeStyles, Typography } from '@material-ui/core';
import * as React from 'react';
import { useHistory } from 'react-router';
import { useRecoilValue } from 'recoil';
import toastr from 'toastr';
import ToothChoose from '../components/Project/ToothChoose';
import ToothService from '../components/Project/ToothService';
import Badge from '../components/Shared/Badge';
import Checkbox from '../components/Shared/Checkbox';
import ComponentHeading from '../components/Shared/ComponentHeading';
import { FileDrop } from '../components/Shared/FileDrop';
import Icon from '../components/Shared/Icon';
import Input from '../components/Shared/Input';
import LoadingModal from '../components/Shared/LoadingModal';
import MultiSelect from '../components/Shared/MultiSelect';
import PageHeadingRow from '../components/Shared/PageHeadingRow';
import Select from '../components/Shared/Select';
import Spinner from '../components/Shared/Spinner';
import Stepper from '../components/Shared/Stepper';
import { ToothInterface } from '../interfaces/tooth.interface';
import ProjectsManager from '../manager/projects.manager';
import RequestManager from '../manager/request.manager';
import { StateManager } from '../manager/states.manager';
import UploadsManager from '../manager/uploads.manager';
import UserManager from '../manager/user.manager';
import { Alert } from '../services/alert';
import { userState } from '../services/atoms';
import Helper, { checkUserRole } from '../services/helper';
import Static from '../services/static';
import { dangerLight } from '../services/styles';
import Translation from '../services/translation';

interface Props {
  match?: any;
  location?: any;
  history?: any;
}

interface State {
  dataAttributes:
    | 'patientName'
    | 'projectWorkTypes'
    | 'isDentalSplint'
    | 'chosenTeeth'
    | 'systemServices'
    | 'splintType'
    | 'comment'
    | 'projectTeeth';
  data: {
    id: string | undefined;
    files: { uploadName: string; description: string; file: File }[];
    isDentalSplint: boolean;
    // list of all chosen teeth
    chosenTeeth: number[];
    // list of all services per tooth
    projectTeeth: any[];
    expressCreation: boolean;
    isInternal: string | undefined | null;
    projectWorkTypes: ToothInterface['projectWorkTypes'][];
    splintType: ToothInterface['services'] | '';
    patientName: string;
    systemServices: {
      id: ToothInterface['systemServices'];
      label_de: string;
      label_en: string;
      shortDe: string;
    }[];
    comment: string;
    projectComments?: any;
    updatedAt?: string;
    createdAt?: string;
  };
  loading: boolean;
  noToothSelection: boolean;
  fileUploading: boolean;
  me: any;
  savedFiles: any;
  internalDesigners: any;
  currentStep: number;
  maximumStep: number;
  currentlySaving: boolean;
}

const useStyles = makeStyles((_theme) => ({
  dangerCell: {
    backgroundColor: dangerLight,
  },
}));

export default function ProjectCreate(props: Props) {
  const classes = useStyles();
  const user = useRecoilValue(userState);
  const history = useHistory();
  const [loading, setLoading] = React.useState<boolean>(true);
  const [currentlySaving, setCurrentlySaving] = React.useState<boolean>(false);
  const [fileUploading, setFileUploading] = React.useState<boolean>(false);
  const [internalDesigners, setInternalDesigners] = React.useState<any>([]);
  const [savedFiles, setSavedFiles] = React.useState<any>([]);
  const [originalData, setOriginalData] = React.useState<any>(undefined);
  const [data, setData] = React.useState<State['data']>({
    id: undefined,
    files: [],
    expressCreation: false,
    isDentalSplint: false,
    projectWorkTypes: [],
    systemServices: [],
    splintType: '',
    projectTeeth: [],
    patientName: '',
    chosenTeeth: [],
    comment: '',
    isInternal: undefined,
    // updatedAt: undefined,
    // createdAt: undefined,
  });
  const [currentStep, setCurrentStep] = React.useState<number>(1);
  const [maximumStep, setMaximumStep] = React.useState<number>(1);

  React.useEffect(() => {
    if (!checkUserRole(user).isUser()) {
      history.push(Static.getPageByName('dashboard').url);
    }
    initial();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    //get maximum step touched
    if (maximumStep < currentStep) {
      setMaximumStep(currentStep);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentStep]);

  const initial = async () => {
    setLoading(true);
    setInternalDesigners(
      (await RequestManager.getMyConnections({ status: 'accepted' })) || [],
    );

    if (props.match.params.projectId) {
      await getProject(props.match.params.projectId, true);
    }
    setLoading(false);
  };

  const firstStep = () => {
    return (
      <React.Fragment>
        <ComponentHeading
          value={Translation.getTranslation('order_information')}
          explain={Translation.getTranslation('explain_general')}
        />

        <div className="mt-4 pl-0">
          <ComponentHeading
            size="h5"
            value={Translation.getTranslation('patient')}
          >
            <Input
              key={'patient_name_input'}
              label={Translation.getTranslation('patient_name')}
              id="pcPatientNameInput"
              icon="hand-holding-medical"
              value={data?.patientName || ''}
              type="text"
              onChange={(e: any) => {
                Helper.handleChangesInObject(
                  [
                    {
                      attribute: 'patientName',
                      value: e.target.value,
                    },
                  ],
                  data,
                  setData,
                );
              }}
              hasError={
                data.patientName === ''
                  ? false
                  : Helper.validate('name', data.patientName).length > 0
                  ? true
                  : false
              }
              helperText={
                data.patientName !== '' &&
                Helper.validate('name', data.patientName).length > 0 &&
                Helper.validate('name', data.patientName)
              }
            />
          </ComponentHeading>
          <ComponentHeading
            size="h5"
            value={Translation.getTranslation('order_services_heading')}
          >
            {Static.getProjectWorkTypes().map((type: any) => (
              <Checkbox
                key={type + 'checkbox'}
                label={Translation.getTranslation('project_work_type_' + type)}
                className="pt-2"
                checked={
                  data.projectWorkTypes.indexOf(type) !== -1 ? true : false
                }
                onChange={(e: boolean) => {
                  let projectWorkTypes = [...data.projectWorkTypes];
                  data.projectWorkTypes.indexOf(type) === -1
                    ? projectWorkTypes.push(type)
                    : projectWorkTypes.splice(
                        data.projectWorkTypes.indexOf(type),
                        1,
                      );
                  Helper.handleChangesInObject(
                    [
                      {
                        attribute: 'projectWorkTypes',
                        value: projectWorkTypes,
                      },
                    ],
                    data,
                    setData,
                  );
                }}
              />
            ))}
          </ComponentHeading>
        </div>
      </React.Fragment>
    );
  };

  const secondStep = () => {
    return (
      <React.Fragment>
        <ComponentHeading
          value={Translation.getTranslation('tooth_choose')}
          explain={Translation.getTranslation('explain_tooth_choose')}
        >
          <Checkbox
            label={Translation.getTranslation('dental_splint')}
            checked={data.isDentalSplint}
            onChange={(e: boolean) => {
              let changeArray = [
                {
                  attribute: 'isDentalSplint',
                  value: e,
                },
                // reset specified system services
                {
                  attribute: 'systemServices',
                  value: [],
                },
                // reset specified teeth services
                {
                  attribute: 'projectTeeth',
                  value: [],
                },
              ] as any;
              // reset splint type and chosen teeth if splint is disabled
              if (!e)
                changeArray.push(
                  {
                    attribute: 'splintType',
                    value: '',
                  },
                  {
                    attribute: 'chosenTeeth',
                    value: [],
                  },
                );

              Helper.handleChangesInObject(changeArray, data, setData);
              // setNoToothSelection(e ? false : noToothSelection);
            }}
          />
        </ComponentHeading>
        <ToothChoose
          choice={(e: number[]) => {
            let changeObject = [
              {
                attribute: 'chosenTeeth',
                value: e,
              },
            ] as any;

            // if no tooth is selected - delete all services
            if (e.length === 0) {
              changeObject.push({
                attribute: 'projectTeeth',
                value: [],
              });
            }
            Helper.handleChangesInObject(changeObject, data, setData);
          }}
          // get preSelected from originalData (does not change)
          preSelected={originalData?.chosenTeeth}
          selectAllTeeth={data.isDentalSplint}
        />
        {data.isDentalSplint && (
          <ComponentHeading
            value={Translation.getTranslation('choose_dental_splint')}
            explain={Translation.getTranslation('explain_choose_dental_splint')}
          >
            <Select
              className={`${!data.splintType ? classes.dangerCell : ''}`}
              onChange={(e: any) => {
                Helper.handleChangesInObject(
                  [
                    {
                      attribute: 'splintType',
                      value: e.target.value,
                    },
                  ],
                  data,
                  setData,
                );
              }}
              value={data.splintType}
            >
              <option value={''}>
                {Translation.getTranslation('please_choose')}
              </option>
              {Static.getServicesByWorkType('splint').map(
                (service: any, index: number) => (
                  <option value={service.shortEn} key={service.shortEn + index}>
                    (
                    {Translation.getLanguage() === 'de'
                      ? service.shortDe
                      : Translation.getLanguage() === 'es'
                      ? service.shortEs
                      : service.shortEn}
                    ) {service['label_' + Translation.getLanguage()]}
                  </option>
                ),
              )}
            </Select>
          </ComponentHeading>
        )}
        {data.chosenTeeth?.length > 0 && !data.isDentalSplint && (
          <React.Fragment>
            <ComponentHeading
              value={Translation.getTranslation('choose_service')}
              explain={Translation.getTranslation('explain_choose_service')}
            />
            <ToothService
              chosenTeeth={data.chosenTeeth}
              onChange={(projectTeethTemp: any) => {
                Helper.handleChangesInObject(
                  [
                    {
                      attribute: 'projectTeeth',
                      value: projectTeethTemp,
                    },
                  ],
                  data,
                  setData,
                );
              }}
              preSelected={originalData?.projectTeeth}
            />
            <ComponentHeading
              value={Translation.getTranslation('system_services')}
              explain={Translation.getTranslation('explain_system_services')}
            >
              <MultiSelect
                onChange={async (e: any) => {
                  if (data.systemServices?.length !== e.length) {
                    Helper.handleChangesInObject(
                      [
                        {
                          attribute: 'systemServices',
                          value: e,
                        },
                      ],
                      data,
                      setData,
                    );
                  }
                }}
                preSelectedOptions={data.systemServices || []}
                options={StateManager.getServices('systemService')}
                optionLabel={'label_' + Translation.getLanguage()}
                label={Translation.getTranslation('system_services')}
              />
            </ComponentHeading>
          </React.Fragment>
        )}
        {(data.chosenTeeth?.length > 0 || data.isDentalSplint) && (
          <ComponentHeading
            value={Translation.getTranslation('work_instructions')}
            explain={Translation.getTranslation('explain_work_instructions')}
          >
            <div className="row">
              <div className="col">
                <Input
                  key={'comment_input'}
                  className="mb-2"
                  id="comment"
                  label={Translation.getTranslation('work_instructions')}
                  value={data.comment}
                  onChange={(e: any) => {
                    Helper.handleChangesInObject(
                      [
                        {
                          attribute: 'comment',
                          value: e.target.value,
                        },
                      ],
                      data,
                      setData,
                    );
                  }}
                  multiline
                  rows={4}
                />
              </div>
            </div>
          </ComponentHeading>
        )}
      </React.Fragment>
    );
  };

  const thirdStep = () => {
    return (
      <FileDrop
        chosenFiles={(files: any) => {
          Helper.handleChangesInObject(
            [
              {
                attribute: 'files',
                value: files,
              },
            ],
            data,
            setData,
          );
        }}
        resetStateFiles={currentlySaving}
        existingFiles={savedFiles}
      />
    );
  };

  const fourthStep = () => {
    return (
      <React.Fragment>
        <ComponentHeading
          value={Translation.getTranslation('order_summary')}
          explain={Translation.getTranslation('explain_order_summary')}
        />
        <ComponentHeading
          size="h5"
          value={Translation.getTranslation('order_information')}
          addition={renderBackLink(1)}
        >
          <table className="mb-2">
            <tbody>
              {[
                {
                  label: Translation.getTranslation('patient'),
                  value: data.patientName,
                },
                {
                  label: Translation.getTranslation('order_services'),
                  value: data.projectWorkTypes.map(
                    (
                      type: ToothInterface['projectWorkTypes'],
                      index: number,
                    ) => (
                      <span key={type + '' + index}>
                        {Translation.getTranslation(
                          'project_work_type_' + type,
                        )}
                        {Helper.renderComma(data.projectWorkTypes, index)}
                      </span>
                    ),
                  ),
                },
                data.systemServices?.length > 0 && {
                  label: Translation.getTranslation(`system_services`),
                  value: data.systemServices.map((type: any, index: number) => (
                    <span key={type + '' + index}>
                      {type['label_' + Translation.getLanguage()]}
                      {Helper.renderComma(data.systemServices, index)}
                    </span>
                  )),
                },
              ].map(
                (row: any, index: number) =>
                  row.label && (
                    <tr>
                      <td
                        style={{
                          fontSize: 'initial',
                          display: 'table-cell',
                        }}
                      >
                        <strong>{row.label}:</strong>
                      </td>
                      <td
                        style={{
                          fontSize: 'initial',
                          paddingLeft: '5px',
                        }}
                      >
                        {row.value}
                      </td>
                    </tr>
                  ),
              )}
            </tbody>
          </table>
        </ComponentHeading>
        <ComponentHeading
          size="h5"
          value={Translation.getTranslation('service_in_detail')}
          addition={renderBackLink(2)}
        >
          {!data.isDentalSplint ? (
            <ToothService
              preSelected={data.projectTeeth}
              disabled
              chosenTeeth={data.chosenTeeth}
            />
          ) : (
            <React.Fragment>
              <div style={{ marginBottom: '1rem' }}>
                <strong>{Translation.getTranslation('splint_type')}:</strong>{' '}
                {Helper.getSplintTranslation(data.splintType)}
              </div>
              <ToothChoose disabled preSelected={data.chosenTeeth} />
            </React.Fragment>
          )}
          {data.comment && (
            <div>
              <strong>{Translation.getTranslation('comment')}:</strong>{' '}
              {data.comment}
            </div>
          )}
        </ComponentHeading>
        <ComponentHeading
          size="h5"
          value={Translation.getTranslation('files')}
          addition={renderBackLink(3)}
        >
          <div className="d-flex align-items-center">
            <strong>Hochgeladene Dateien: </strong>
            <div className="d-flex align-items-center">
              {data?.files?.length > 0 || savedFiles?.length > 0 ? (
                [
                  { data: data.files, type: 'new' },
                  { data: savedFiles, type: 'existing' },
                ].map((type: any) =>
                  type.data.map(
                    (file: any, index: number) =>
                      (type.type === 'existing' ||
                        (type.type === 'new' &&
                          typeof file?.file?.id === 'undefined')) && (
                        <Badge
                          key={file.name + '' + index}
                          color="primary"
                          className="mr-1 ml-1"
                        >
                          {`${file.name}${
                            file.size
                              ? ' (' + Helper.humanFileSize(file.size) + ')'
                              : file.file.size
                              ? ' (' +
                                Helper.humanFileSize(file.file.size) +
                                ')'
                              : ''
                          }
                        `}
                        </Badge>
                      ),
                  ),
                )
              ) : (
                <Badge color="error" className="mr-1 ml-1">
                  {Translation.getTranslation('no_files_provided')}
                </Badge>
              )}
            </div>
          </div>
        </ComponentHeading>
        {internalDesigners?.length > 0 && (
          <ComponentHeading
            size="h5"
            value={Translation.getTranslation('choose_assignee')}
          >
            <div>
              <Checkbox
                disabled={
                  !UserManager.checkIfPayedForService(user, {
                    service: 'send_order_to_connection',
                  })
                }
                label={Translation.getTranslation('choose_designer')}
                hasError={typeof data.isInternal === 'undefined'}
                checked={
                  typeof data.isInternal !== 'undefined' &&
                  data.isInternal !== null
                }
                onChange={(e: boolean) => {
                  let isInternal = '';
                  // if just 1 internal - take him
                  if (internalDesigners?.length === 1) {
                    isInternal = internalDesigners[0].designerId;
                  }
                  Helper.handleChangesInObject(
                    [
                      {
                        attribute: 'isInternal',
                        value: e ? isInternal : undefined,
                      },
                    ],
                    data,
                    setData,
                  );
                }}
              />
              <Checkbox
                label={Translation.getTranslation('mydenti_project')}
                checked={data.isInternal === null}
                hasError={typeof data.isInternal === 'undefined'}
                onChange={(e: boolean) => {
                  Helper.handleChangesInObject(
                    [
                      {
                        attribute: 'isInternal',
                        value: e ? null : undefined,
                      },
                    ],
                    data,
                    setData,
                  );
                }}
              />
            </div>
            {typeof data.isInternal !== 'undefined' &&
              data.isInternal !== null && (
                <Select
                  required
                  disabled={internalDesigners?.length === 1}
                  onChange={(e: any) => {
                    Helper.handleChangesInObject(
                      [
                        {
                          attribute: 'isInternal',
                          value: e.target.value,
                        },
                      ],
                      data,
                      setData,
                    );
                  }}
                  value={data.isInternal}
                >
                  <option value={''}>
                    {Translation.getTranslation('choose_designer')}
                  </option>
                  {internalDesigners &&
                    internalDesigners.map((designer: any) => (
                      <option
                        key={'designer' + designer.designerId}
                        value={designer.designerId}
                      >
                        {`${designer.designer.firstName} ${designer.designer.lastName} (${designer.designer.company}) `}
                      </option>
                    ))}
                </Select>
              )}
          </ComponentHeading>
        )}
      </React.Fragment>
    );
  };

  // custom hook to detect changes
  // function usePrevious(valueTemp: any) {
  //   const ref = React.useRef();
  //   React.useEffect(() => {
  //     ref.current = valueTemp;
  //   });
  //   return ref.current;
  // }

  const handleFileUpload = async (
    files: any,
    projectId: string,
    responses: any,
  ) => {
    let responsesTemp = [...responses];
    if (data.files?.length > 0) {
      setFileUploading(true);

      responsesTemp.push(
        await UploadsManager.handleFileUpload(files, projectId)
          .then((s: any) => {
            return new Promise((resolve, reject) => resolve(s));
          })
          .catch((e: any) => {
            return new Promise((resolve, reject) => reject(e));
          }),
      );

      setFileUploading(false);
    }
    return responsesTemp;
  };

  const saveTeeth = async (
    header: 'put' | 'post',
    id: string,
    responses: any,
  ) => {
    const responsesTemp = [...responses];
    const manager = async () => {
      header === 'post'
        ? await RequestManager.postTeeth(data.projectTeeth, id)
        : await RequestManager.putTeeth(data.projectTeeth, id);
    };
    if (typeof data.projectTeeth !== 'undefined' && data.id) {
      responses.push(
        await manager()
          .then((s) => {
            return new Promise((resolve, reject) => resolve(s));
          })
          .catch((e) => {
            return new Promise((resolve, reject) => reject(e));
          }),
      );
    }
    return responsesTemp;
  };

  const getAttributesToSend = (status: string) => {
    if (status === 'draft') return attributesToSendPerStep[currentStep - 1];
    else return [].concat.apply([], attributesToSendPerStep as any);
  };

  const saveProjectData = async (
    header: 'put' | 'post',
    status: 'send' | 'draft',
    projectId?: string,
  ): Promise<any> => {
    //send only changed data
    let changedData = {} as any;
    const dataTemp = { ...data } as any;

    // send attributes of current step or all attributes
    const attributesToSend = getAttributesToSend(status);
    for (const sendAttribute of attributesToSend) {
      let value = dataTemp[sendAttribute];

      if (sendAttribute === 'systemServices') {
        value = Helper.makeArrayOfObject(data.systemServices, 'shortEn');
      }

      changedData = {
        ...changedData,
        [sendAttribute]: value,
      };
    }
    const options = {
      status,
      ...changedData,
    };
    if (header === 'post') return await RequestManager.postProject(options);
    else return await RequestManager.putProject(options, projectId || '');
  };

  const save = async (status: 'draft' | 'send'): Promise<boolean> => {
    setCurrentlySaving(true);
    let valid = validateSteps(status === 'send' ? true : undefined);
    let responses = [] as any;
    if (valid) {
      let projectResponse = undefined as any;
      // PUT
      if (data.id) {
        // save teeth if changed
        if (getAttributesToSend(status).indexOf('projectTeeth') !== -1)
          responses = await saveTeeth('put', data.id, responses);
        //save files
        if (getAttributesToSend(status).indexOf('files') !== -1)
          responses = await handleFileUpload(data.files, data.id, responses);
        //save general data (last because files and teeth are then included in response)
        projectResponse = await saveProjectData('put', status, data.id);
      } else {
        // POST
        // save only general data (because only 1. step is post)
        projectResponse = await saveProjectData('post', status);
      }

      Promise.all(responses).then(async (dataTemp: any) => {
        if (projectResponse) {
          const format = await extractData(projectResponse);
          setData(format);
          setOriginalData(format);
        }
        if (status === 'draft') {
          // place for caching toastr
        } else {
          await Alert.open(
            'success',
            Translation.getTranslation('send'),
            Translation.getTranslation('order_created'),
          );
          props.history.push(
            `${Static.getPageByName('order_detail').url}/${projectResponse.id}`,
          );
        }
      });
    }

    setCurrentlySaving(false);
    return valid;
  };

  const getProject = async (id: string, withStateSave: boolean) => {
    let dataTemp = await RequestManager.getProject(id);
    //redirect if in wrong status
    if (
      dataTemp.id &&
      dataTemp.currentStatus &&
      dataTemp.currentStatus !== 'draft'
    ) {
      props.history.push(
        `${Static.getPageByName('order_detail').url}/${dataTemp.id}`,
      );
    }

    if (dataTemp) {
      dataTemp = await extractData(dataTemp);
      withStateSave && setData(dataTemp);
    }
    setOriginalData(dataTemp);
    return dataTemp;
  };

  const extractData = async (dataTemp: any) => {
    //extract files
    setSavedFiles(dataTemp.files);
    dataTemp.files = [];

    // extract comment
    dataTemp.comment = dataTemp?.projectComments?.[0]?.comment || '';
    // reset isInternal (if user has rights to choose)
    dataTemp.isInternal = !UserManager.checkIfPayedForService(user, {
      service: 'send_order_to_connection',
    })
      ? null
      : undefined;

    // extract systemServices
    dataTemp = await ProjectsManager.getSystemServicesInProjectData(dataTemp);

    return dataTemp;
  };

  const renderBackLink = (to: number) => {
    return (
      <Typography
        color="primary"
        className="ml-2 cursor-pointer align-self-end"
        variant="caption"
        onClick={() => setCurrentStep(to)}
      >
        ({Translation.getTranslation('change')})
      </Typography>
    );
  };

  const renderSteps = () => {
    let jsx = (
      <div>
        {[firstStep(), secondStep(), thirdStep(), fourthStep()].map(
          (step: any, index: number) => (
            <div
              key={'step' + index}
              style={{
                display: currentStep !== index + 1 ? 'none' : undefined,
              }}
            >
              {step}
            </div>
          ),
        )}
      </div>
    );
    return jsx;
  };

  const attributesToSendPerStep = [
    ['patientName', 'projectWorkTypes'],
    [
      'chosenTeeth',
      'isDentalSplint',
      'projectTeeth',
      'comment',
      'splintType',
      'systemServices',
    ],
    ['files'],
    ['isInternal'],
  ];

  const validateSteps = (isSending?: boolean): boolean => {
    let valid = true;
    let errors = undefined as any;
    let entityArray = [] as any;

    // steps to validate
    let firstStep = ['patientName', 'projectWorkTypes'];
    let secondStep = ['chosenTeeth', 'isDentalSplint', 'projectTeeth'];
    let thirdStep = ['files'];
    let fourthStep = ['isInternal'];

    // if order should be send -> validate all steps, otherwise only current step
    if (isSending) {
      if (maximumStep >= 1) {
        entityArray.push(...firstStep);
      }
      if (maximumStep >= 2) {
        entityArray.push(...secondStep);
      }
      if (maximumStep >= 3) {
        entityArray.push(...thirdStep);
      }
      if (maximumStep === 4) {
        internalDesigners?.length > 0 && entityArray.push(...fourthStep);
      }
    } else {
      if (currentStep === 1) {
        entityArray.push(...firstStep);
      } else if (currentStep === 2) {
        entityArray.push(...secondStep);
      } else if (currentStep === 3) {
        entityArray.push(...thirdStep);
      } else {
        entityArray.push(...firstStep);
        entityArray.push(...secondStep);
        entityArray.push(...thirdStep);
        internalDesigners?.length > 0 && entityArray.push(...fourthStep);
      }
    }
    errors = Helper.validateOrderInformation(data, entityArray);
    //display errors
    if (errors?.length > 0) {
      valid = false;
      for (const error of errors) {
        for (const message of error.errors) {
          toastr.error(message, Translation.getTranslation('check_input'));
        }
      }
    }

    return valid;
  };

  const handleNextStep = async () => {
    let nextStep = await save('draft');
    if (nextStep) {
      setCurrentStep(currentStep + 1);
    }
  };

  const renderSavingInfo = (saving: boolean) => {
    let jsx = [];
    if (!saving) {
      if (data && data.id && data.updatedAt) {
        jsx.push(
          <Badge key={'updatedAt' + data.updatedAt} tag="span" color="primary">
            {Translation.getTranslation('last_save')}{' '}
            {Helper.getDate(data.updatedAt, true)}
          </Badge>,
        );
      } else {
        jsx.push(
          <Badge key={'notyetsavedkey'} tag="span" color="primary">
            {Translation.getTranslation('not_yet_saved')}
          </Badge>,
        );
      }
    } else {
      jsx.push(
        <Badge key={'savingBadge'} tag="span" color="warning">
          <Icon icon="sync" spin />
          {Translation.getTranslation('saving')}
        </Badge>,
      );
    }

    return jsx;
  };

  return (
    <React.Fragment>
      {fileUploading && (
        <LoadingModal
          text={Translation.getTranslation('files_are_uploading')}
        />
      )}
      <PageHeadingRow
        siteTitle={Static.getPageByName('order_create').siteTitle}
      >
        {renderSavingInfo(currentlySaving)}
      </PageHeadingRow>
      <Stepper
        minStep={1}
        maxStep={4}
        // save={() => {
        //   this.save('draft');
        // }}
        finishStepLabel={Translation.getTranslation('final_order')}
        currentStep={currentStep}
        nextStep={() => {
          handleNextStep();
        }}
        prevStep={() => {
          setCurrentStep(currentStep > 1 ? currentStep - 1 : 1);
        }}
        disabled={currentlySaving}
        finish={() => {
          save('send');
        }}
        steps={[
          {
            label: 'information',
            position: 1,
          },
          { label: 'tooth_choose', position: 2 },
          { label: 'file_upload', position: 3 },
          { label: 'finish', position: 4 },
        ]}
      >
        {!loading ? renderSteps() : <Spinner />}
      </Stepper>
    </React.Fragment>
  );
}
