import { Typography } from '@material-ui/core';
import * as React from 'react';
import Translation from '../../services/translation';

interface NoDataComponentInterface {
  value?: string;
}

function NoDataComponent(props: NoDataComponentInterface) {
  return (
    <Typography variant="h6" style={{ fontWeight: 300, marginTop: '1rem' }}>
      {props.value || Translation.getTranslation('no_entry')}
    </Typography>
  );
}

export default NoDataComponent;
