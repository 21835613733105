import * as React from 'react';
import toastr from 'toastr';
import BulkAssignModal from '../../components/Project/BulkAssignModal';
import { CommentModal } from '../../components/Project/CommentModal';
import { CommentStatusChangeModal } from '../../components/Project/CommentStatusChangeModal';
import Button from '../../components/Shared/Button';
import Card from '../../components/Shared/Card';
import ComponentHeading from '../../components/Shared/ComponentHeading';
import MenuButton from '../../components/Shared/MenuButton';
import { UploadModal } from '../../components/Upload/UploadModal';
import RequestManager from '../../manager/request.manager';
import { Alert } from '../../services/alert';
import { checkUserRole } from '../../services/helper';
import Styles from '../../services/styles';
import Translation from '../../services/translation';

interface Props {
  match?: any;
  location?: any;
  history?: any;
  project: any;
  user: any;
  reloadData?: any;
}

export function ProjectDetailAdminPanel(props: Props) {
  const [uploadModalIsOpen, setUploadModalIsOpen] = React.useState<boolean>(
    false,
  );
  const [
    uploadSendModalIsOpen,
    setUploadSendModalIsOpen,
  ] = React.useState<boolean>(false);
  const [commentModalIsOpen, setCommentModalIsOpen] = React.useState<boolean>(
    false,
  );
  const [
    commentStatusChangeModalIsOpen,
    setCommentStatusChangeModalIsOpen,
  ] = React.useState<
    | undefined
    | 'wip_correction_claim_designer'
    | 'wip_correction_goodwill_designer'
    | 'cancelled'
  >(undefined);

  const finishDesign = async (byAdmin?: boolean) => {
    try {
      await RequestManager.putProjectPreferences(
        { status: 'finished_designer' },
        props.project.id,
      );
      !byAdmin &&
        Alert.open(
          'success',
          Translation.getTranslation('handed_over'),
          Translation.getTranslation('handover_confirmation'),
        );
    } catch {
      props.reloadData();
      return;
    } finally {
      !byAdmin ? props.history.push('/') : props.reloadData();
    }
  };

  const cancelOrder = async () => {
    try {
      await RequestManager.putProjectPreferences(
        { status: 'cancelled' },
        props.project.id,
      );
      Alert.open(
        'success',
        Translation.getTranslation('project_cancelled'),
        Translation.getTranslation('project_cancelled_confirmation'),
      ).then((e) => props.reloadData && props.reloadData());
    } catch (e) {
      Alert.open('success', Translation.getTranslation('error'));
    }
  };

  const renderUploadsModal = () => {
    return (
      <UploadModal
        isOpen={uploadModalIsOpen || uploadSendModalIsOpen}
        projectId={props.project.id}
        reloadData={() => props.reloadData && props.reloadData()}
        withSending={uploadSendModalIsOpen ? () => finishDesign() : undefined}
        toggleModal={() => {
          setUploadModalIsOpen(false);
          setUploadSendModalIsOpen(false);
        }}
      ></UploadModal>
    );
  };

  const renderCommentModal = () => {
    return (
      <CommentModal
        isOpen={commentModalIsOpen}
        projectId={props.project.id}
        me={props.user}
        reloadData={() => props.reloadData && props.reloadData()}
        toggleModal={() => {
          setCommentModalIsOpen(false);
        }}
      ></CommentModal>
    );
  };

  const renderCommentStatusModal = () => {
    return (
      <CommentStatusChangeModal
        newStatus={commentStatusChangeModalIsOpen}
        projectId={props.project.id}
        me={props.user}
        confirm={(
          e:
            | 'wip_correction_claim_designer'
            | 'wip_correction_goodwill_designer'
            | 'cancelled',
        ) => (e === 'cancelled' ? cancelOrder() : reassignDesigner(e))}
        toggleModal={() => {
          setCommentStatusChangeModalIsOpen(undefined);
        }}
      />
    );
  };

  const finishProject = async () => {
    Alert.confirm({
      type: 'warning',
      title: Translation.getTranslation('handover_to_customer'),
      text: Translation.getTranslation('sure_handover'),
      confirmButtonColor: Styles.sweetalertOptions().warningColor,
      cancelButtonColor: Styles.sweetalertOptions().greyColor,
      confirmButtonText: Translation.getTranslation('handover'),
    }).then(async (result: any) => {
      if (result) {
        try {
          await RequestManager.putProjectPreferences(
            { status: 'finished' },
            props.project.id,
          );
          Alert.open(
            'success',
            Translation.getTranslation('handed_over'),
            Translation.getTranslation('handover_confirmation'),
          ).then((e) => props.reloadData && props.reloadData());
        } catch (e) {
          toastr.success(Translation.getTranslation('error'));
        }
      }
    });
  };

  const reassignDesigner = async (
    status:
      | 'wip_correction_goodwill_designer'
      | 'wip_correction_claim_designer',
  ) => {
    try {
      await RequestManager.putProjectPreferences({ status }, props.project.id);
      await Alert.open(
        'success',
        Translation.getTranslation('success'),
        Translation.getTranslation('status_' + status),
      );
    } catch {
      toastr.success(Translation.getTranslation('error'));
    }
    props.reloadData();
  };

  const renderErrorItems = () => {
    let items = [
      {
        label: Translation.getTranslation('cancellation'),
        onClick: () => {
          setCommentStatusChangeModalIsOpen('cancelled');
        },
      },
    ];
    if (props.project?.currentStatus === 'finished_designer')
      items.push(
        {
          label: Translation.getTranslation('claim'),
          onClick: () => {
            setCommentStatusChangeModalIsOpen('wip_correction_claim_designer');
          },
        },
        {
          label: Translation.getTranslation('goodwill'),
          onClick: () => {
            setCommentStatusChangeModalIsOpen(
              'wip_correction_goodwill_designer',
            );
          },
        },
      );
    if (
      props.project?.currentStatus === 'wip_designer' &&
      !props.project?.isInternal
    )
      items.push({
        label: Translation.getTranslation('set_design_finished'),
        onClick: () => {
          finishDesign(true);
        },
      });

    return items;
  };

  return (
    <Card
      className="mb-4"
      header={
        <ComponentHeading
          noMargin
          value={Translation.getTranslation('order_options')}
        />
      }
    >
      <div className="row">
        <div className="col">
          <Button
            color="primary"
            outline
            icon={'cloud-upload-alt'}
            onClick={() => {
              setUploadModalIsOpen(true);
            }}
          >
            {Translation.getTranslation('add_file')}
          </Button>
          <Button
            outline
            color="primary"
            icon={'comments'}
            onClick={() => {
              setCommentModalIsOpen(true);
            }}
          >
            {Translation.getTranslation('add_comment')}
          </Button>
        </div>
        <div className="col text-right">
          {checkUserRole(props.user).isAdmin() && (
            <React.Fragment>
              <MenuButton
                label={Translation.getTranslation('actions')}
                icon={'exclamation-triangle'}
                color="danger"
                items={renderErrorItems()}
              />
              {(props.project?.currentStatus === 'finished_designer' ||
                props.project?.currentStatus === 'send') && (
                <React.Fragment>
                  <BulkAssignModal
                    currentDesignerId={
                      props.project.designerId
                        ? props.project.designerId
                        : undefined
                    }
                    ids={[props.project.id]}
                    reloadData={() => props.reloadData && props.reloadData()}
                  >
                    <Button
                      pulse={
                        props.project?.currentStatus === 'send' ? true : false
                      }
                      color="primary"
                      icon={'share'}
                    >
                      {Translation.getTranslation('assign')}
                    </Button>
                  </BulkAssignModal>
                  {props.project?.currentStatus === 'finished_designer' && (
                    <Button
                      pulse
                      color={'primary'}
                      icon={'flag-checkered'}
                      onClick={finishProject}
                    >
                      {Translation.getTranslation('handover_to_customer')}
                    </Button>
                  )}
                </React.Fragment>
              )}
            </React.Fragment>
          )}
          {checkUserRole(props.user).isDesigner() && (
            <Button
              pulse
              color="primary"
              icon="flag-checkered"
              onClick={() => setUploadSendModalIsOpen(true)}
            >
              {Translation.getTranslation('complete_order')}
            </Button>
          )}
        </div>
      </div>
      {renderUploadsModal()}
      {renderCommentModal()}
      {renderCommentStatusModal()}
    </Card>
  );
}
