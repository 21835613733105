import * as React from 'react';
import Button from '../../components/Shared/Button';
import ComponentHeading from '../../components/Shared/ComponentHeading';
import { ModelViewer } from '../../components/Shared/ModelViewer';
import NoDataComponent from '../../components/Shared/NoDataComponent';
import FileTable from '../../components/Upload/FileTable';
import { UploadModal } from '../../components/Upload/UploadModal';
import Translation from '../../services/translation';

interface Props {
  match?: any;
  location?: any;
  history?: any;
  user: any;
  project: any;
  reloadData?: any;
}

/**
 * File section for user with his files, admin files and designer files and upload possibility
 * @param props
 *
 */
export function ProjectDetailUserFileSection(props: Props) {
  const [previewModalIsOpen, setPreviewModalIsOpen] = React.useState<
    string | undefined
  >(undefined);
  const [previewModalType, setPreviewModalType] = React.useState<
    'stl' | 'obj' | undefined
  >(undefined);
  const [uploadModalIsOpen, setUploadModalIsOpen] = React.useState<boolean>(
    false,
  );

  return (
    <div className="row">
      <div className="col-xl-12">
        {(props.project?.files?.length > 0 ||
          (props.project.currentStatus !== 'finished' &&
            props.project.currentStatus !== 'cancelled' &&
            props.project.currentStatus !== 'closed')) && (
          <ComponentHeading
            value={Translation.getTranslation('files')}
            addition={
              props.project.currentStatus !== 'finished' &&
              props.project.currentStatus !== 'cancelled' &&
              props.project.currentStatus !== 'closed' ? (
                <Button
                  color="primary"
                  icon={'plus-square'}
                  onClick={() => setUploadModalIsOpen(true)}
                >
                  {Translation.getTranslation('submit_file')}
                </Button>
              ) : undefined
            }
            additionPullRight
          >
            {props.project?.files?.length > 0 ? (
              <FileTable
                data={props.project.files}
                project={props.project}
                me={props.user}
                history={props.history}
              />
            ) : (
              <NoDataComponent value={Translation.getTranslation('no_file')} />
            )}
            <UploadModal
              isOpen={uploadModalIsOpen}
              projectId={props.project.id}
              reloadData={() => props.reloadData && props.reloadData()}
              toggleModal={() => {
                setUploadModalIsOpen(false);
              }}
            ></UploadModal>
          </ComponentHeading>
        )}
      </div>
      {previewModalIsOpen && (
        <ModelViewer
          file={previewModalIsOpen}
          type={previewModalType as 'stl' | 'obj'}
          toggleModal={() => {
            setPreviewModalIsOpen(undefined);
            setPreviewModalType(undefined);
          }}
        />
      )}
    </div>
  );
}
