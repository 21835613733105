import * as React from 'react';
import UserInterface, { PaymentTypes } from '../../interfaces/user.interface';
import UserManager from '../../manager/user.manager';
import Static from '../../services/static';
import Translation from '../../services/translation';
import Button from '../Shared/Button';
import PricingBox from '../Shared/PricingBox';
import Spinner from '../Shared/Spinner';
import Checkout from './Checkout';

interface Subscriptions {
  user: UserInterface;
}

function Subscriptions(props: Subscriptions) {
  const [pricing, setPricing] = React.useState<any>(undefined);
  const [selectedPlan, setSelectedPlan] = React.useState<
    PaymentTypes['currentPlan'] | undefined
  >(undefined);
  const [currentPlan, setCurrentPlan] = React.useState<string>(
    UserManager.checkIfPayedForService(props.user) as string,
  );

  React.useEffect(() => {
    getPlans();
  }, []);

  const getPlans = async () => {
    const pricingTemp = await Static.getPricing();
    if (pricingTemp && pricingTemp?.length > 0) setPricing(pricingTemp);
  };

  return (
    <React.Fragment>
      <div className="row">
        <div className={`col`}>
          <React.Fragment>
            {pricing ? (
              <PricingBox
                selectedPlan={(plan: PaymentTypes['currentPlan']) => {
                  setSelectedPlan(plan);
                }}
                showOnlySelected={
                  props.user.payment &&
                  props.user.payment?.currentPlan !== 'basic'
                    ? true
                    : false
                }
                preSelected={currentPlan}
                items={pricing}
              />
            ) : (
              <Spinner />
            )}
            <div className="row ">
              <div className="col">
                {props.user.payment?.stripeCustomerId && (
                  <Button
                    className="mt-2"
                    icon="shopping-cart"
                    tooltip={Translation.getTranslation(
                      'payment_portal_explain',
                    )}
                    onClick={() => UserManager.openStripePortal()}
                  >
                    {!props.user?.payment?.currentPlan ||
                    props.user?.payment?.currentPlan === 'basic'
                      ? Translation.getTranslation('payment_portal')
                      : Translation.getTranslation('edit_subscription')}
                  </Button>
                )}
              </div>
              <div className="col  text-right">
                {(!props.user?.payment?.currentPlan ||
                  props.user?.payment?.currentPlan === 'basic') &&
                  pricing && <Checkout user={props.user} plan={selectedPlan} />}
              </div>
            </div>
          </React.Fragment>
        </div>
      </div>
    </React.Fragment>
  );
}

export default Subscriptions;
