import { makeStyles, Tooltip, Typography } from '@material-ui/core';
import React from 'react';
import { ToothInterface } from '../../interfaces/tooth.interface';
import { StateManager } from '../../manager/states.manager';
import Static from '../../services/static';
import { dangerLight, grey } from '../../services/styles';
import Translation from '../../services/translation';
import Button from '../Shared/Button';
import Icon from '../Shared/Icon';
import Input from '../Shared/Input';
import Select from '../Shared/Select';
import Switch from '../Shared/Switch';

interface Props {
  match?: any;
  location?: any;
  chosenTeeth?: any;
  onChange?: any;
  preSelected?: ToothInterface['toothService'][];
  disabled?: boolean;
}

interface State {
  teethServices: ToothInterface['toothService'][];
  copyMode: number | undefined;
}

const useStyles = makeStyles((_theme) => ({
  toothServiceWrapper: {
    ' & td,th': {
      border: `1px solid ${grey}`,
      verticalAlign: 'middle',
      textAlign: 'center',
    },

    '& table': {
      borderCollapse: 'collapse',
    },
  },
  dangerCell: {
    backgroundColor: dangerLight,
  },
  headingRow: {
    backgroundColor: grey,

    '& td': {
      height: '1rem',
      paddingTop: 0,
      paddingBottom: 0,
    },
  },
  first: {
    borderTop: '1px solid transparent !important',
    borderBottom: '1px solid transparent !important',
    borderLeft: '1px solid transparent !important',
  },
  last: {
    borderTop: '1px solid transparent !important',
    borderBottom: '1px solid transparent !important',
    borderRight: '1px solid transparent !important',
    width: '50px !important',
  },
}));

export default function ToothService(props: Props) {
  const classes = useStyles();
  const [teethServices, setTeethServices] = React.useState<
    ToothInterface['toothService'][]
  >([]);
  const [copyMode, setCopyMode] = React.useState<State['copyMode']>(undefined);
  const [init, setInit] = React.useState<boolean>(false);

  // on load set previous saved infos once
  React.useEffect(() => {
    if (props.preSelected && props.preSelected.length > 0) {
      setTeethServices(props.preSelected);
    }
    setInit(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if (props.preSelected && props.preSelected.length > 0) {
      setTeethServices(props.preSelected);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.preSelected]);

  // send selected services
  React.useEffect(() => {
    props.onChange && props.onChange(teethServices);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [teethServices]);

  React.useEffect(() => {
    if (init) {
      let teethServicesTemp = [...teethServices];
      for (let index = 0; index < teethServicesTemp.length; index++) {
        let indexOfToothService = props.chosenTeeth.indexOf(
          teethServicesTemp[index].tooth,
        );
        if (indexOfToothService === -1) teethServicesTemp.splice(index, 1);
      }
      setTeethServices(teethServicesTemp);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.chosenTeeth]);

  const handleChangeInput = (
    value: any,
    tooth: number,
    columnType: ToothInterface['toothInformationTypes'],
  ) => {
    let teethServicesTemp = [...teethServices];
    let index = teethServicesTemp.findIndex(
      (toothService: ToothInterface['toothService']) =>
        toothService.tooth === tooth,
    );

    // if worktype changes -> delete other values
    if (columnType === 'work') {
      for (var key in teethServicesTemp[index]) {
        if (teethServicesTemp[index].hasOwnProperty(key)) {
          if (key !== 'work' && key !== 'tooth') {
            delete teethServicesTemp[index][
              key as ToothInterface['toothInformationTypes']
            ];
          }
        }
      }
    }

    if (index !== -1) {
      teethServicesTemp[index][columnType] = value;
    } else {
      teethServicesTemp.push({
        tooth: tooth,
        [columnType]: value,
      } as ToothInterface['toothService']);
    }
    // props.onChange && props.onChange(teethServicesTemp);
    setTeethServices(teethServicesTemp);
  };

  const getColumnHeadings = () => {
    let rows = [] as any;

    //basic columns for each row
    let basicInfoTypes = [
      'work',
      'material',
      'color',
      'service',
    ] as ToothInterface['toothInformationTypes'][];
    basicInfoTypes.map(
      (informationColumns: ToothInterface['toothInformationTypes']) =>
        rows.push(Static.getToothInformationTypeById(informationColumns)),
    );

    //get special columns for some service types
    //get all used column types
    for (const toothService of teethServices) {
      if (toothService.work) {
        Static.getToothInformationTypesByToothWorkType(toothService.work).map(
          (informationType: ToothInterface['toothInformationTypes']) =>
            basicInfoTypes.indexOf(informationType) === -1 &&
            !rows.find((row: any) => row.id === informationType) &&
            rows.push(Static.getToothInformationTypeById(informationType)),
        );
      }
    }

    return rows;
  };

  const validateCell = (
    tooth: number,
    columnType: ToothInterface['toothInformationTypes'],
  ) => {
    if (
      Static.getRequiredToothInformationTypes().indexOf(columnType) !== -1 &&
      getToothWorkValue(tooth, columnType) === ''
    ) {
      return false;
    } else return true;
  };

  const renderCell = (
    columnType: ToothInterface['toothInformationTypes'],
    tooth: number,
  ) => {
    //row work type
    let rowWorkType = getToothWorkType(tooth)
      ?.work as ToothInterface['toothWorkTypes'];

    let dropDownCells = [
      'work',
      'material',
      'color',
      'material2',
      'service',
    ] as ToothInterface['toothInformationTypes'][];

    let inputCells = [
      'diameter',
      'producer',
      'ref',
    ] as ToothInterface['toothInformationTypes'][];

    //if information is required for work type of this tooth
    if (
      (rowWorkType &&
        Static.getToothInformationTypesByToothWorkType(rowWorkType) &&
        Static.getToothInformationTypesByToothWorkType(rowWorkType).indexOf(
          columnType,
        ) !== -1) ||
      columnType === 'work'
    ) {
      //dropdown cells
      if (dropDownCells.indexOf(columnType) !== -1) {
        let options = [] as any;

        if (columnType === 'work') {
          options = [
            { id: '', label: Translation.getTranslation('please_choose') },
          ];
          Static.getToothWorkTypes().map(
            (type: any) =>
              options.push({
                id: type.id,
                label: type['label_' + Translation.getLanguage()],
              }) as {
                id: ToothInterface['toothInformationTypes'] | '';
                label: string;
              }[],
          );
        }

        if (columnType === 'material' || columnType === 'material2') {
          options = [
            { id: '', label: Translation.getTranslation('please_choose') },
          ];
          StateManager.getServices('material').map(
            (material: any) =>
              options.push({
                id: material.shortEn,
                label: material['label_' + Translation.getLanguage()],
              }) as {
                id: ToothInterface['materials'] | '';
                label: string;
              }[],
          );
        }

        if (columnType === 'color') {
          options = [
            { id: '', label: Translation.getTranslation('please_choose') },
            { id: 'none', label: Translation.getTranslation('none') },
          ];
          Static.getColors().map(
            (color: any) =>
              options.push({
                id: color,
                label: color,
              }) as {
                id: ToothInterface['colors'] | '';
                label: string;
              }[],
          );
        }

        if (columnType === 'service') {
          options = [
            { id: '', label: Translation.getTranslation('please_choose') },
          ];
          Static.getServicesByWorkType(rowWorkType).map(
            (service: any) =>
              options.push({
                id: service.shortEn,
                label: `(${
                  Translation.getLanguage() === 'de'
                    ? service.shortDe
                    : Translation.getLanguage() === 'es'
                    ? service.shortEs
                    : service.shortEn
                }) ${service['label_' + Translation.getLanguage()]}`,
              }) as {
                id: ToothInterface['services'] | '';
                label: string;
              }[],
          );
        }

        return (
          <td
            key={tooth + columnType + 'td_first'}
            className={
              !validateCell(tooth, columnType) ? classes.dangerCell : ''
            }
          >
            {!props.disabled ? (
              <Select
                disabled={props.disabled}
                onChange={(e: any) =>
                  handleChangeInput(e.target.value, tooth, columnType)
                }
                value={getToothWorkValue(tooth, columnType)}
              >
                {options.map((option: { id: string; label: string }) => (
                  <option value={option.id} key={option.label + option.id}>
                    {option.label}
                  </option>
                ))}
              </Select>
            ) : (
              <Typography>
                {getOptionLabelByWorkTypeID(
                  options,
                  getToothWorkValue(tooth, columnType),
                )}
              </Typography>
            )}
          </td>
        );
      } else if (columnType === 'bridge') {
        //if is switch
        return (
          <td key={tooth + columnType + 'td_bridge'}>
            {!props.disabled ? (
              <Switch
                disabled={props.disabled}
                checked={getToothWorkValue(tooth, columnType)}
                onChange={() =>
                  handleChangeInput(
                    !getToothWorkValue(tooth, columnType),
                    tooth,
                    columnType,
                  )
                }
                key={tooth + columnType + 'switch'}
                name="bridgeSwitch"
                color="primary"
                label={Translation.getTranslation('bridge')}
              />
            ) : (
              <Typography>
                {getToothWorkValue(tooth, columnType)
                  ? Translation.getTranslation('bridge')
                  : Translation.getTranslation('single')}
              </Typography>
            )}
          </td>
        );
      } else if (inputCells.indexOf(columnType) !== -1) {
        return (
          <td
            key={tooth + columnType + 'td'}
            className={
              !validateCell(tooth, columnType) ? classes.dangerCell : ''
            }
          >
            {!props.disabled ? (
              <React.Fragment>
                <Input
                  type="text"
                  small
                  key={columnType + tooth.toString() + 'input'}
                  placeholder={Translation.getTranslation(columnType)}
                  onChange={(e: any) =>
                    handleChangeInput(e.target.value, tooth, columnType)
                  }
                  value={getToothWorkValue(tooth, columnType)}
                />
              </React.Fragment>
            ) : (
              <Typography>{getToothWorkValue(tooth, columnType)}</Typography>
            )}
          </td>
        );
      } else {
        return <td>-</td>;
      }
    } else {
      //if column type is not for current row
      return <td key={columnType + tooth + 'noEntry'}>-</td>;
    }
  };

  const getOptionLabelByWorkTypeID = (options: any, workTypeValue: any) => {
    let result = '';
    if (workTypeValue !== '') {
      let option = options.find((option: any) => option.id === workTypeValue);
      if (option && option.label) result = option.label;
    }
    return result;
  };

  const getToothWorkType = (tooth: number) => {
    let teethServicesTemp = [...teethServices];
    let foundTooth = teethServicesTemp.find(
      (toothService: ToothInterface['toothService']) =>
        toothService.tooth === tooth,
    );
    return foundTooth;
  };

  const getToothWorkValue = (
    tooth: number,
    type: ToothInterface['toothInformationTypes'],
  ) => {
    let teethServicesTemp = [...teethServices];
    let foundTooth = teethServicesTemp.find(
      (toothService: ToothInterface['toothService']) =>
        toothService.tooth === tooth,
    );
    if (foundTooth) {
      return typeof foundTooth[type] !== 'undefined' ? foundTooth[type] : '';
    } else return '';
  };

  const renderBlock = (title: string, teeth: any) => {
    let jsx = [];
    //dental part
    jsx.push(
      <tr className={classes.headingRow} key={'heading-row' + title + teeth}>
        <td colSpan={100}>
          <Typography>{Translation.getTranslation(title)}</Typography>
        </td>
      </tr>,
    );

    //each tooth
    jsx.push(
      teeth.map((tooth: any, index: number) => (
        <tr key={tooth.toString() + 'row' + index}>
          <td className={classes.first}>
            <Typography>{tooth}</Typography>
          </td>
          {getColumnHeadings().map(
            (column: any) => renderCell(column.id, tooth),
            // <td>{column && column.component && column.component}</td>
          )}
          <td className={classes.last}>
            {!props.disabled && (
              <Button
                tooltip={
                  typeof copyMode === 'undefined'
                    ? Translation.getTranslation('explain_copy_rows')
                    : copyMode === tooth
                    ? Translation.getTranslation('explain_end_copy')
                    : `${Translation.getTranslation(
                        'explain_paste_1',
                      )}${copyMode}${Translation.getTranslation(
                        'explain_paste_2',
                      )}`
                }
                color={
                  typeof copyMode === 'undefined'
                    ? 'default'
                    : copyMode === tooth
                    ? 'default'
                    : 'primary'
                }
                size="sm"
                onClick={() => {
                  handlePasting(tooth);
                }}
              >
                {typeof copyMode === 'undefined'
                  ? Translation.getTranslation('copy')
                  : copyMode === tooth
                  ? Translation.getTranslation('end_copy')
                  : Translation.getTranslation('paste')}
              </Button>
            )}
          </td>
        </tr>
      )),
    );

    return jsx;
  };

  const handlePasting = (tooth: number) => {
    //start pasting mode
    if (typeof copyMode === 'undefined') {
      setCopyMode(tooth);
    } else {
      //end pasting mode
      if (tooth === copyMode) {
        setCopyMode(undefined);
      } else {
        //pasting
        let teethServicesTemp = [...teethServices];

        let toothToPasteToIndex = teethServicesTemp.findIndex(
          (toothToPaste: ToothInterface['toothService']) =>
            toothToPaste.tooth === tooth,
        );

        let toothToCopy = teethServicesTemp.find(
          (toothToPaste: ToothInterface['toothService']) =>
            toothToPaste.tooth === copyMode,
        );

        // let newPreferences = {} as any;

        if (toothToCopy) {
          let newPreferences = Object.assign({}, toothToCopy);
          newPreferences.tooth = tooth;

          if (toothToPasteToIndex === -1 && newPreferences) {
            teethServicesTemp.push(newPreferences);
          }

          if (toothToPasteToIndex !== -1 && newPreferences) {
            //delete old object
            teethServicesTemp.splice(toothToPasteToIndex, 1);
            //insert to position
            teethServicesTemp.splice(toothToPasteToIndex, 0, newPreferences);
          }
          // props.onChange && props.onChange(teethServicesTemp);
          setTeethServices(teethServicesTemp);
        }
      }
    }
  };

  return (
    <div className="table-responsive">
      <table className={`table ${classes.toothServiceWrapper}`}>
        <tbody>
          {/* Heading */}
          <tr>
            <th className={`${classes.first}`} style={{ width: '50px' }}></th>
            {getColumnHeadings().map((column: any, index: number) => (
              <th key={column.label + index}>
                <Typography>
                  {Translation.getTranslation(column.label)}
                </Typography>

                {!props.disabled && (
                  <Tooltip
                    title={Translation.getTranslation(column.explainId)}
                    placement="top"
                    arrow
                    aria-label={Translation.getTranslation(column.explainId)}
                  >
                    <span className="ml-1">
                      <Icon icon="info-circle" />
                    </span>
                  </Tooltip>
                )}
              </th>
            ))}
            <th className={classes.last}></th>
          </tr>

          {/* get blocks */}
          {props.chosenTeeth &&
            Static.getTeethBlocks().map(
              // show only block if teeth of block are chosen
              (block: any) =>
                block.numbers.some(
                  (match: any) => props.chosenTeeth.indexOf(match) >= 0,
                ) &&
                renderBlock(
                  block.label,
                  block.numbers.filter((tooth: any) =>
                    props.chosenTeeth.includes(tooth),
                  ),
                ),
            )}
        </tbody>
      </table>
    </div>
  );
}
