import * as React from 'react';
import { Link } from 'react-router-dom';
import DataFilter from '../components/Shared/DataFilter';
import DataTable from '../components/Shared/DataTable';
import PageHeadingRow from '../components/Shared/PageHeadingRow';
import RequestManager from '../manager/request.manager';
import { StateManager } from '../manager/states.manager';
import Helper from '../services/helper';
import Static from '../services/static';
import { danger } from '../services/styles';
import Translation from '../services/translation';

interface Props {
  match?: any;
  user: any;
  location?: any;
  history?: any;
}

interface Filter {
  userId: string;
  extended: boolean;
  startDate: Date;
  endDate: Date;
}

export default function Analytics(props: Props) {
  const rememberMeName = 'logs';
  const initialState = {
    extended: true,
    userId: '',
    startDate: Helper.getToday()[0],
    endDate: Helper.getToday()[1],
  };
  const [filter, setFilter] = React.useState<Filter>(initialState);

  React.useEffect(() => {
    if (props.user.role !== 'admin') props.history.push('/');
    let existingFilter = StateManager.getRememberMe(rememberMeName);
    if (existingFilter) setFilter(existingFilter);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <React.Fragment>
      <PageHeadingRow
        siteTitle={Static.getPageByName('analytics').siteTitle}
      ></PageHeadingRow>
      <div className="row">
        <div className="col-xl-12 col-lg-12">
          <DataFilter
            title={Translation.getTranslation('filter')}
            resetFilter={() =>
              StateManager.resetFilter(
                initialState,
                (e: any) => setFilter(e),
                rememberMeName,
              )
            }
            me={props.user}
            filter={filter}
            handleFilterChange={(name, value) => {
              StateManager.handleFilterChange(
                name,
                value,
                filter,
                (e: any) => {
                  setFilter(e);
                },
                rememberMeName,
              );
            }}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-xl-12 col-lg-12">
          <DataTable
            doNotSearch
            title={Translation.getTranslation('user_logs')}
            externalFilterChange={filter}
            manager={(options: any) => {
              return RequestManager.getUserLogs({
                ...options,
              });
            }}
            limit={20}
            columns={[
              {
                label: Translation.getTranslation('user'),
                type: {
                  id: 'individual',
                  individualObject: (data: any) => {
                    return Helper.renderUserWithCompany(data.user);
                  },
                },
              },
              {
                label: Translation.getTranslation('entity'),
                type: { id: 'translation', key: 'entity' },
              },
              {
                label: Translation.getTranslation('order'),
                type: {
                  id: 'individual',
                  individualObject: (data: any) => {
                    const generateLink = (
                      projectId: string,
                      orderNumber: string,
                    ) => {
                      return (
                        <Link
                          to={
                            Static.getPageByName('order_detail').url +
                            '/' +
                            projectId
                          }
                        >
                          {orderNumber}
                        </Link>
                      );
                    };

                    if (data.targetProject?.orderNumber) {
                      return generateLink(
                        data.targetProject?.id,
                        data.targetProject?.orderNumber,
                      );
                    } else if (data.targetComment?.projectId) {
                      return generateLink(
                        data.targetComment?.projectId,
                        data.targetComment?.project?.orderNumber,
                      );
                    } else if (data.targetUpload?.projectId) {
                      return generateLink(
                        data.targetUpload?.projectId,
                        data.targetUpload?.project?.orderNumber,
                      );
                    }
                    return '-';
                  },
                },
              },
              {
                label: Translation.getTranslation('user'),
                type: {
                  id: 'individual',
                  individualObject: (data: any) => {
                    return data.targetUser?.company || '-';
                  },
                },
              },
              {
                label: Translation.getTranslation('action'),
                type: {
                  id: 'individual',
                  individualObject: function displayLogType(data: any) {
                    return (
                      <span
                        style={
                          data.category === 'error' ? { color: danger } : {}
                        }
                      >
                        {Translation.getTranslation('log_type_' + data.logType)}
                      </span>
                    );
                  },
                },
              },
              {
                label: Translation.getTranslation('value'),
                type: {
                  id: 'individual',
                  individualObject: (data: any) => {
                    if (data.value) {
                      if (data.logType === 'changed_status') {
                        return Translation.getTranslation(
                          'status_' + data.value,
                        );
                      } else if (data.logType === 'bulk_downloaded_files') {
                        try {
                          return `${Translation.getTranslation('count')}: ${
                            JSON.parse(data.value).length
                          }`;
                        } catch (e) {
                          return '-';
                        }
                      }
                      return data.value || '-';
                    }

                    return '-';
                  },
                },
              },
              {
                label: Translation.getTranslation('date'),
                type: { id: 'date', key: 'createdAt' },
              },
            ]}
          />
        </div>
      </div>
    </React.Fragment>
  );
}
