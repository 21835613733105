import { debounce } from 'lodash';
import * as React from 'react';
import Translation from '../../services/translation';
import Card from './Card';
import ComponentHeading from './ComponentHeading';
import Input from './Input';
import Pagination from './Pagination';
import Spinner from './Spinner';

interface Props {
  match?: any;
  location?: any;
  children?: any;
  history?: any;
  loading?: boolean;
  title: string;
  changeLimit: (limit: number) => void;
  onSearch?: (search: string) => void;
  loadPage?: (page: number) => void;
  metaData?: { currentPage?: number; limit?: number; total?: number };
  additionalElement?: any;
  additionalFooter?: any;
  disabled?: boolean;
  noPagination?: boolean;
  color: string;
}

export default function SearchTableWrapper(props: Props) {
  const throttled = React.useCallback(
    debounce((dataTemp) => props.onSearch && props.onSearch(dataTemp), 500),
    [],
  );
  const [search, setSearch] = React.useState('');

  const handleSearch = (value: string) => {
    if (props.onSearch) {
      // props.onSearch(value);
      setSearch(value);
      throttled(value);
    }
  };
  return (
    <Card
      className={'mb-5'}
      header={
        <React.Fragment>
          <ComponentHeading color={props.color} noMargin value={props.title} />
          <div
            className="mb-2"
            style={{
              maxWidth: 'fit-content',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
            }}
          >
            {props.onSearch && !props.loading && (
              <Input
                small
                disabled={props.disabled}
                className="m-0 w-100"
                label={Translation.getTranslation('search')}
                type="text"
                value={search}
                outline
                onChange={(e: any) =>
                  props.onSearch && handleSearch(e.target.value)
                }
              />
            )}
            {props.additionalElement && (
              <div className="ml-2">{props.additionalElement}</div>
            )}
          </div>
        </React.Fragment>
      }
    >
      <div className="row">
        {!props.loading || typeof props.loading === 'undefined' ? (
          props.children
        ) : (
          <Spinner />
        )}
      </div>
      <div className="row d-flex justify-content-between align-items-center">
        {props.additionalFooter ? props.additionalFooter : <span />}
        {props.loadPage &&
        props.metaData &&
        !props.noPagination &&
        props.metaData.limit &&
        props.metaData.total ? (
          <Pagination
            changeLimit={(limit: number) => {
              props.changeLimit(limit);
            }}
            reloadWithPage={(page: number) =>
              props.loadPage && props.loadPage(page)
            }
            page={props.metaData.currentPage}
            disabled={props.loading}
            limit={props.metaData.limit}
            totalRecords={props.metaData.total}
          />
        ) : (
          <span />
        )}
      </div>
    </Card>
  );
}
