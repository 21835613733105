import Swal from 'sweetalert2';
import Styles from './styles';
import Translation from './translation';

export class Alert {
  static async open(
    type: 'error' | 'success' | 'warning',
    title: string = '',
    text?: string,
  ) {
    return await Swal.fire({
      icon: type,
      title,
      html: text || '',
      confirmButtonColor: Styles.sweetalertOptions().primaryColor,
    });
  }

  static async confirm(data: {
    type: 'error' | 'success' | 'warning';
    title: string;
    text?: string;
    confirmButtonText?: string;
    cancelButtonText?: string;
    confirmButtonColor?: string;
    cancelButtonColor?: string;
  }): Promise<boolean> {
    const result = await Swal.fire({
      icon: data.type,
      title: data.title,
      html: data.text || '',
      showCloseButton: true,

      showCancelButton: true,
      confirmButtonColor:
        data.confirmButtonColor || Styles.sweetalertOptions().warningColor,
      cancelButtonColor:
        data.cancelButtonColor || Styles.sweetalertOptions().greyColor,
      confirmButtonText:
        data.confirmButtonText || Translation.getTranslation('delete'),
      cancelButtonText:
        data.cancelButtonText || Translation.getTranslation('cancel'),
    });
    return result.value ? true : false;
  }
}
