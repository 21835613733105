import { makeStyles, TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import * as React from 'react';
import { dangerLight } from '../../services/styles';

interface Props {
  disabled?: boolean;
  label?: string;
  children?: any;
  options: any[];
  preSelectedOptions: any[];
  required?: boolean;
  onChange: (options: Props['options']) => void;
  value?: string;
  optionLabel: string;
  className?: string;
  placeholder?: string;
  style?: any;
  noMargin?: boolean;
}

const useStyles = makeStyles((_theme) => ({
  dangerCell: {
    backgroundColor: dangerLight,
  },
}));

export default function MultiSelect(props: Props) {
  const classes = useStyles();

  const renderClasses = (): string => {
    // let classes = 'browser-default custom-select ';
    let classesTemp = !props.noMargin ? 'mb-1 ' : '';
    if (props.className) classesTemp += props.className;
    if (props.required && !props.value) classesTemp += ` ${classes.dangerCell}`;
    return classesTemp;
  };

  return (
    <Autocomplete
      multiple
      disabled={props.disabled}
      id="tags-outlined"
      className={renderClasses()}
      options={props.options}
      getOptionLabel={(option) => option[props.optionLabel]}
      // defaultValue={}
      value={props.preSelectedOptions}
      onChange={(e: any, values: Props['options']) => {
        props.onChange(values);
      }}
      filterSelectedOptions
      renderInput={(params) => (
        <TextField {...params} variant="outlined" label={props.label} />
      )}
    />
  );
}
