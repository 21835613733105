import React, { useEffect, useState } from 'react';
import Icon from '../Shared/Icon';

interface SortIndicator {
  selectionChanged: (direction: '' | '-') => void;
  defaultSort?: '' | '-';
  color?: string;
}

function SortIndicator(props: SortIndicator) {
  const [sortDirection, setSortDirection] = useState<undefined | '-' | ''>(
    undefined,
  );

  useEffect(() => {
    props.defaultSort && setSortDirection(props.defaultSort);
  }, [props.defaultSort]);

  return (
    <div
      style={{ display: 'inline', position: 'relative' }}
      className="ml-1"
      onClick={() => {
        switch (sortDirection) {
          case '':
            setSortDirection('-');
            props.selectionChanged('-');
            break;
          case '-':
            setSortDirection('');
            props.selectionChanged('');
            break;
          default:
            setSortDirection('');
            props.selectionChanged('');
            break;
        }
      }}
    >
      <span
        style={{
          position: 'relative',
          cursor: 'pointer',
          zIndex: 1,
          padding: '1em',
          margin: '-1em',
        }}
      >
        <span style={{ position: 'absolute', bottom: '1em' }}>
          <Icon
            icon="sort-up"
            className={`${
              sortDirection === ''
                ? `${props.color ? props.color : 'text-warning'}`
                : ''
            }`}
          />
        </span>
        <span style={{ position: 'absolute', top: '1em' }}>
          <Icon
            icon="sort-down"
            className={`${
              sortDirection === '-'
                ? `${props.color ? props.color : 'text-warning'}`
                : ''
            }`}
          />
        </span>
      </span>
    </div>
  );
}

export default SortIndicator;
