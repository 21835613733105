import { Typography } from '@material-ui/core';
import * as React from 'react';
import { useRecoilValue } from 'recoil';
import { FileTypes } from '../../interfaces/project';
import UploadsManager from '../../manager/uploads.manager';
import { userState } from '../../services/atoms';
import Helper from '../../services/helper';
import Translation from '../../services/translation';
import Badge from './Badge';
import Button from './Button';
import Card from './Card';
import Icon from './Icon';
import Input from './Input';
import Select from './Select';
import Spinner from './Spinner';

interface Props {
  match?: any;
  file: any;
  id?: string | number;
  type: 'existing' | 'new';
  onDeleteFile?: () => void;
  onDownloadFile?: () => void;
  onPreviewFile?: () => void;
  onTitleChange?: (title: any) => void;
  onVisibilityChange?: (type: FileTypes['visibility']) => void;
  onDescriptionChange?: (description: any) => void;
}

export default function FileCard(props: Props) {
  const user = useRecoilValue(userState);
  const [file, setFile] = React.useState<FileTypes>({
    name: '',
    description: '',
    visibility: 'all_involved',
    originalname: '',
    size: 0,
  });

  React.useEffect(() => {
    let fileTemp = {} as FileTypes;
    fileTemp = { ...props.file };
    if (props.type === 'new') {
      // fileTemp.name = props.file.name;
      // fileTemp.description = props.file.description;
      fileTemp.size = props.file.file.size;
      fileTemp.originalname = props.file.file.name;
    }

    setFile(fileTemp);
    UploadsManager.reCheckUploadingStatus(fileTemp, setFile);
  }, [props.file]);

  const renderTitle = () => {
    if (!props.onTitleChange) {
      return (
        <Typography variant="h5" className="mt-3">
          {props.file.name}
        </Typography>
      );
    }
    return (
      <Input
        id={'fileDropNameInput' + props.id}
        label={Translation.getTranslation('name')}
        icon="file-signature"
        small
        className="mb-2 mt-3"
        type="text"
        onChange={(e: any) => {
          props.onTitleChange && props.onTitleChange(e);
        }}
        value={props.file.name}
        hasError={
          Helper.validate('name', props.file.name).length > 0 ? true : false
        }
        helperText={
          Helper.validate('name', props.file.name).length > 0 &&
          Helper.validate('name', props.file.name)
        }
        key={'upload' + props.id || ''}
      />
    );
  };
  const renderDescription = () => {
    if (!props.onTitleChange) {
      return (
        <Typography>
          {props.file.description
            ? props.file.description
            : Translation.getTranslation('no_description')}
        </Typography>
      );
    }
    return (
      <Input
        label={Translation.getTranslation('description')}
        icon="file-alt"
        id={'fileDropDescriptionInput' + props.id}
        small
        className="mb-2 mt-2"
        type="text"
        key={'uploadDescription' + props.id || ''}
        onChange={(e: any) => {
          props.onDescriptionChange?.(e);
        }}
        value={props.file.description}
        hasError={
          Helper.validate('description', props.file.description).length > 0
            ? true
            : false
        }
        helperText={
          Helper.validate('description', props.file.description).length > 0 &&
          Helper.validate('description', props.file.description)
        }
        multiline
        rows={5}
      />
    );
  };

  const renderVisibility = () => {
    if (!props.onVisibilityChange) {
      return (
        <Typography variant="subtitle2" className="mt-2">
          {Translation.getTranslation('visible_to')}:{' '}
          {Translation.getTranslation(props.file.visibility)}
        </Typography>
      );
    }
    return (
      <Select
        onChange={(e: any) => {
          props.onVisibilityChange?.(e);
        }}
        label={Translation.getTranslation('visible_to')}
      >
        <option value="all_involved">
          {Translation.getTranslation('all_involved')}
        </option>
        <option value="only_me">{Translation.getTranslation('only_me')}</option>
        {user?.role === 'admin' && (
          <React.Fragment>
            <optgroup label={Translation.getTranslation('role')}>
              <option value="user">
                {Translation.getTranslation('customer')}
              </option>
              <option value="admin">
                {Translation.getTranslation('admin')}
              </option>
              <option value="designer">
                {Translation.getTranslation('designer')}
              </option>
            </optgroup>
          </React.Fragment>
        )}
      </Select>
    );
  };

  return (
    <Card
      className="h-100 overflow-hidden"
      footer={
        <React.Fragment>
          {!file.uploading ? (
            <React.Fragment>
              {props.onDeleteFile && (
                <Button
                  tooltip={Translation.getTranslation('delete_file')}
                  size="sm"
                  color="danger"
                  onClick={() => props.onDeleteFile && props.onDeleteFile()}
                >
                  <Icon icon="trash-alt" />
                </Button>
              )}
              {props.onDownloadFile && (
                <Button
                  tooltip={Translation.getTranslation('download')}
                  size="sm"
                  color="primary"
                  onClick={() => props.onDownloadFile && props.onDownloadFile()}
                >
                  <Icon icon="download" />
                </Button>
              )}
              {file.originalname &&
                Helper.getFileTypeByName(file.originalname) &&
                (Helper.getFileTypeByName(file.originalname) === 'stl' ||
                  Helper.getFileTypeByName(file.originalname) === 'obj') && (
                  <Button
                    color="primary"
                    tooltip={Translation.getTranslation('preview')}
                    outline
                    size="sm"
                    onClick={() => props.onPreviewFile && props.onPreviewFile()}
                  >
                    <Icon icon="eye"></Icon>
                  </Button>
                )}
            </React.Fragment>
          ) : (
            <Spinner small />
          )}
        </React.Fragment>
      }
    >
      {renderTitle()}
      {renderDescription()}
      {renderVisibility()}

      <div
        className="position-absolute"
        style={{
          top: '1px',
          right: '5px',
        }}
      >
        <Badge tag="div" color="secondary" className="mr-1">
          {file.originalname &&
            Helper.getFileTypeByName(file.originalname) &&
            '.' + Helper.getFileTypeByName(file.originalname)}
        </Badge>
        <Badge tag="div" color="primary">
          {Helper.humanFileSize(file.size)}
        </Badge>
      </div>
      {/* </div> */}
    </Card>
  );
}
