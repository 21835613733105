import { Pagination } from '@material-ui/lab';
import React from 'react';
import Translation from '../../services/translation';
import Select from './Select';

interface Props {
  reloadWithPage: (page: number) => void;
  disabled?: boolean;
  limit: number | string;
  changeLimit: (limit: number) => void;
  totalRecords: number | string;
  page?: number;
}

export default function TablePagination(props: Props) {
  const getPageStart = () => {
    if (props.page) {
      return (
        props.page * parseInt(props.limit + '') + 1 - parseInt(props.limit + '')
      );
    }
  };

  const getPageEnd = () => {
    if (props.page) {
      const pageLimit = props.page * parseInt(props.limit + '');
      if (props.totalRecords < pageLimit) return props.totalRecords;
      return pageLimit;
    }
  };

  const scala = [5, 10, 20, 50];
  const getDisabled = (): any => {
    let returnObject = {} as any;
    scala.map((items_per_page: number, index: number) => {
      returnObject[items_per_page] = true;
      if (
        (index > 0 &&
          props.totalRecords > scala[index - 1] &&
          props.totalRecords < scala[index]) ||
        props.totalRecords >= scala[index]
      ) {
        returnObject[items_per_page] = false;
      } else if (index === 0 && props.totalRecords > 0) {
        returnObject[items_per_page] = false;
      }
    });
    return returnObject;
  };

  return (
    <div className="d-flex align-items-center">
      <Select
        noMargin
        disabled={props.disabled || props.totalRecords === 0}
        onChange={(e: any) => props.changeLimit(e.target.value)}
        className="mr-2 d-none d-sm-block"
        value={props.limit + ''}
        style={{ maxWidth: '10rem' }}
      >
        {scala.map((items_per_page: number, index: number) => (
          <option
            key={items_per_page + 'per_page'}
            value={items_per_page}
            disabled={getDisabled()[items_per_page]}
          >
            {items_per_page} {Translation.getTranslation('per_page')}
          </option>
        ))}
      </Select>
      <div
        className="text-muted text-small mr-3 d-none d-sm-block"
        style={{ minWidth: 'fit-content' }}
      >
        {getPageStart()}-{getPageEnd()} {Translation.getTranslation('of')}{' '}
        {props.totalRecords}
      </div>
      <Pagination
        style={{ minWidth: 'fit-content' }}
        disabled={props.disabled}
        count={Math.ceil(
          parseInt(props.totalRecords + '') / parseInt(props.limit + ''),
        )}
        onChange={(e: any, page: number) => props.reloadWithPage(page - 1)}
        defaultPage={1}
        page={props.page}
      />
    </div>
  );
}
