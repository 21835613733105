import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { InputAdornment } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import * as React from 'react';
import Icon from './Icon';

interface Props {
  className?: string;
  label?: string;
  hasError?: boolean;
  helperText?: string;
  id?: string;
  defaultValue?: string;
  value?: string;
  onKeyPress?: any;
  type?: 'number' | 'text' | 'password' | 'email';
  disabled?: boolean;
  small?: boolean;
  onChange: any;
  icon?: IconProp;
  outline?: boolean;
  multiline?: boolean;
  rows?: number;
  autocomplete?: string;
  noMargin?: boolean;
  placeholder?: string;
  props?: any;
}

function Input(props: Props) {
  const [disabled, setDisabled] = React.useState<boolean>(
    props.disabled || false,
  );
  // const [value, setValue] = React.useState<string>('');

  React.useEffect(() => {
    setDisabled(props.disabled ? true : false);
    // typeof props.value !== 'undefined' && setValue(props.value);
  }, [props]);

  return (
    <TextField
      placeholder={props.placeholder}
      autoComplete={props.autocomplete}
      disabled={disabled}
      error={props.hasError}
      id={props.id}
      defaultValue={props.defaultValue}
      fullWidth
      {...props.props}
      multiline={props.multiline}
      rows={props.rows}
      onKeyPress={(e) => props.onKeyPress && props.onKeyPress(e)}
      InputProps={
        props.icon
          ? {
              endAdornment: (
                <InputAdornment position="end">
                  <Icon icon={props.icon} />
                </InputAdornment>
              ),
            }
          : {}
      }
      helperText={props.helperText}
      label={props.label}
      type={props.type ? props.type : 'text'}
      className={`${props.noMargin ? '' : 'mb-1 '} ${props.className}`}
      variant="outlined"
      size={props.small ? 'small' : undefined}
      value={props.value}
      onChange={(e) => {
        props.onChange(e);
      }}
    />
  );
}

export default Input;
