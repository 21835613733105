import * as React from 'react';
import toastr from 'toastr';
import RequestManager from '../../manager/request.manager';
import Helper from '../../services/helper';
import Translation from '../../services/translation';
import Input from '../Shared/Input';
import Modal from '../Shared/Modal';

interface Props {
  match?: any;
  location?: any;
  history?: any;
  isOpen: boolean;
  toggleModal: any;
}

export function ForgotPasswordModal(props: Props) {
  const [email, setEmail] = React.useState<string>('');

  React.useEffect(() => {
    props.isOpen && Helper.gaRegisterModalView('forgot_password');
  }, []);

  const saveChanges = async () => {
    let reset = await RequestManager.resetPassword(email);
    if (reset) toastr.info(Translation.getTranslation('email_send'));

    props.toggleModal();
  };

  return (
    <Modal
      isOpen={props.isOpen}
      toggleModal={() => props.toggleModal()}
      header={Translation.getTranslation('reset_password')}
      disabled={Helper.validate('email', email).length !== 0}
      saveLabel={Translation.getTranslation('reset_password')}
      onSave={() => saveChanges()}
    >
      <Input
        label={Translation.getTranslation('your_email')}
        id="forgotPasswordEmailInput"
        icon="envelope"
        // small
        className="mb-2"
        type="email"
        onChange={(e: any) => {
          setEmail(e.target.value);
        }}
        value={email}
        hasError={
          email === ''
            ? false
            : Helper.validate('email', email).length > 0
            ? true
            : false
        }
        helperText={
          email !== '' &&
          Helper.validate('email', email).length > 0 &&
          Helper.validate('email', email)
        }
      />
    </Modal>
  );
}
