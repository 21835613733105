import { FormControlLabel, makeStyles } from '@material-ui/core';
import Check from '@material-ui/core/Checkbox';
import React, { useEffect } from 'react';
import { dangerLight } from '../../services/styles';

interface Props {
  small?: boolean;
  onChange?: (e: boolean) => void;
  onChangeTarget?: (e: any) => void;
  label?: string;
  className?: string;
  checked?: boolean;
  disabled?: boolean;
  hasError?: boolean;
  color?: 'primary' | 'secondary' | 'default';
}

const useStyles = makeStyles((_theme) => ({
  dangerCell: {
    backgroundColor: dangerLight,
  },
}));

export default function Checkbox(props: Props) {
  const classes = useStyles();
  const [checked, setChecked] = React.useState(true);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked);
    props.onChange && props.onChange(event.target.checked);
  };

  useEffect(() => {
    setChecked(props.checked ? true : false);
  }, [props.checked]);
  return (
    <FormControlLabel
      className={`mb-0`}
      control={
        <Check
          className={`${props.className} ${
            props.hasError ? classes.dangerCell : ''
          }`}
          checked={checked}
          onChange={handleChange}
          color={props.color}
          disabled={props.disabled}
          size={props.small ? 'small' : undefined}
        />
      }
      // onClick={(e) => handleChange(e)}
      label={props.label}
    />
  );
}
