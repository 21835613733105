import { Typography } from '@material-ui/core';
import * as React from 'react';
import RequestManager from '../../manager/request.manager';
import Helper from '../../services/helper';
import Translation from '../../services/translation';
import Input from '../Shared/Input';
import Modal from '../Shared/Modal';

interface Props {
  match?: any;
  location?: any;
  history?: any;
  // isOpen: undefined | string;
  toggleModal: any;
  projectId: string;
  reloadData?: any;
  confirm: any;
  newStatus:
    | 'wip_correction_goodwill_designer'
    | 'wip_correction_claim_designer'
    | 'cancelled'
    | undefined;
  me: any;
}

export function CommentStatusChangeModal(props: Props) {
  const [loading, setLoading] = React.useState(false);
  const [forUser, setForUser] = React.useState(false);
  const [comment, setComment] = React.useState('');

  const toggleModal = async () => {
    props.toggleModal();
  };

  const saveComment = async () => {
    setLoading(true);

    let commentTemp = await RequestManager.postComment(
      {
        comment: comment,
        forUser: forUser,
        reference:
          props.newStatus === 'cancelled' ? 'cancellation' : 'correction',
      },
      props.projectId,
    );

    if (commentTemp) {
      props.confirm(props.newStatus);
      props.toggleModal();
    }
    setLoading(false);
  };

  React.useEffect(() => {
    if (props.newStatus) {
      Helper.gaRegisterModalView(
        `project_status_change_to:${
          props.newStatus ? props.newStatus : ''
        }` as any,
      );
    }
  }, []);

  return (
    <Modal
      disabled={loading || !comment}
      onSave={saveComment}
      saveLabel={
        props.newStatus === 'cancelled'
          ? Translation.getTranslation('cancellation')
          : props.newStatus === 'wip_correction_claim_designer'
          ? Translation.getTranslation('instruct_correction')
          : props.newStatus === 'wip_correction_goodwill_designer'
          ? Translation.getTranslation('instruct_correction')
          : Translation.getTranslation('save')
      }
      isOpen={props.newStatus ? true : false}
      toggleModal={() => toggleModal()}
      size="lg"
      header={
        props.newStatus === 'cancelled'
          ? Translation.getTranslation('cancellation')
          : props.newStatus === 'wip_correction_claim_designer'
          ? Translation.getTranslation('claim')
          : props.newStatus === 'wip_correction_goodwill_designer'
          ? Translation.getTranslation('goodwill')
          : ''
      }
    >
      <React.Fragment>
        <Typography variant="h5">
          {props.newStatus === 'cancelled'
            ? Translation.getTranslation('sure_cancel_project')
            : props.newStatus === 'wip_correction_claim_designer'
            ? Translation.getTranslation('sure_claim_designer')
            : props.newStatus === 'wip_correction_goodwill_designer'
            ? Translation.getTranslation('sure_goodwill_designer')
            : ''}
        </Typography>
        <Typography variant="h5" className="mt-4 mb-3">
          {Translation.getTranslation('describe_reasons')}
        </Typography>
        <Input
          id="reasonTextarea"
          label={Translation.getTranslation('give_reason')}
          rows={5}
          multiline
          value={comment}
          onChange={(e: any) => {
            setComment(e.target.value);
          }}
        />
      </React.Fragment>
    </Modal>
  );
}
