import { Typography } from '@material-ui/core';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import { withStyles } from '@material-ui/styles';
import * as React from 'react';
import { StateManager } from '../../manager/states.manager';
import Helper from '../../services/helper';
import Static from '../../services/static';
import Translation from '../../services/translation';
import Button from '../Shared/Button';
import Icon from '../Shared/Icon';
import Modal from '../Shared/Modal';

interface Props {
  match?: any;
  location?: any;
  history?: any;
}

interface State {
  isOpen: boolean;
  collapsedId: number | undefined;
}

function FaqModal(props: Props) {
  const [isOpen, setIsOpen] = React.useState(false);
  // const [collapsedId, setCollapsedId] = React.useState<number | undefined>(
  //   undefined,
  // );

  const Accordion = withStyles({
    root: {
      border: '1px solid rgba(0, 0, 0, .125)',
      boxShadow: 'none',
      '&:not(:last-child)': {
        borderBottom: 0,
      },
      '&:before': {
        display: 'none',
      },
      '&$expanded': {
        margin: 'auto',
      },
    },
    expanded: {},
  })(MuiAccordion);

  const AccordionSummary = withStyles({
    root: {
      backgroundColor: 'rgba(0, 0, 0, .03)',
      borderBottom: '1px solid rgba(0, 0, 0, .125)',
      marginBottom: -1,
      minHeight: 56,
      '&$expanded': {
        minHeight: 56,
      },
    },
    content: {
      '&$expanded': {
        margin: '12px 0',
      },
    },
    expanded: {},
  })(MuiAccordionSummary);

  const toggleModal = () => {
    if (!isOpen) {
      Helper.gaRegisterModalView('faq');
    }
    setIsOpen(!isOpen);
  };

  // const handleCollapse = (id: number) => {
  //   let newId = undefined;
  //   if (!collapsedId || collapsedId !== id) {
  //     newId = id;
  //   }
  //   setCollapsedId(newId);
  // };

  const renderServicesTable = () => {
    let inner = [];
    let jsx = [];

    let workTypes = Static.getToothWorkTypes(true) as any;
    for (const workType of workTypes) {
      inner.push(
        <tr key={workType.id + 'faq'}>
          <td colSpan={100} className="text-left">
            <strong>{workType['label_' + Translation.getLanguage()]}</strong>
          </td>
        </tr>,
      );

      let services = Static.getServicesByWorkType(workType.id) as any;

      for (const service of services) {
        inner.push(
          <tr key={service.shortDe + 'faq'}>
            <td>
              <strong>
                {Translation.getLanguage() === 'de'
                  ? service.shortDe
                  : Translation.getLanguage() === 'es'
                  ? service.shortEs
                  : service.shortEn}
              </strong>
            </td>
            <td>{service['label_' + Translation.getLanguage()]}</td>
          </tr>,
        );
      }
    }

    jsx.push(<table key="faq_inner_table">{inner}</table>);

    return jsx;
  };

  const renderSystemServicesTable = () => {
    let inner = [];
    let jsx = [];

    let systemServices = StateManager.getServices('systemService') as any;
    for (const systemService of systemServices) {
      inner.push(
        <tr key={systemService.shortDe + 'faq'}>
          <td>
            <strong>
              {Translation.getLanguage() === 'de'
                ? systemService.shortDe
                : Translation.getLanguage() === 'es'
                ? systemService.shortEs
                : systemService.shortEn}
            </strong>
          </td>
          <td>{systemService['label_' + Translation.getLanguage()]}</td>
        </tr>,
      );
    }

    jsx.push(
      <table key="faq_system_inner" className="text-left">
        {inner}
      </table>,
    );

    return jsx;
  };

  const renderModal = () => {
    return (
      <Modal
        isOpen={isOpen}
        toggleModal={() => toggleModal()}
        position="top"
        size="lg"
        header={Translation.getTranslation('faq')}
      >
        <div className={''}>
          <Accordion className="text-left">
            <AccordionSummary
              expandIcon={<Icon icon="chevron-down" />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography className={''}>
                {Translation.getTranslation('mydenti_services')}
              </Typography>
            </AccordionSummary>
            <MuiAccordionDetails className="d-flex flex-column">
              <p>{Translation.getTranslation('mydenti_services_explain')}</p>
              {renderServicesTable()}
            </MuiAccordionDetails>
          </Accordion>
          <Accordion className="text-left">
            <AccordionSummary
              expandIcon={<Icon icon="chevron-down" />}
              aria-controls="panel2a-content"
              id="panel2a-header"
            >
              <Typography className={''}>
                {Translation.getTranslation('mydenti_system_services')}
              </Typography>
            </AccordionSummary>
            <MuiAccordionDetails className="d-flex flex-column">
              <p>
                {Translation.getTranslation('mydenti_system_services_explain')}
              </p>
              {renderSystemServicesTable()}
            </MuiAccordionDetails>
          </Accordion>
        </div>
      </Modal>
    );
  };

  return (
    <React.Fragment>
      <Button
        className="w-100"
        color="primary"
        onClick={() => toggleModal()}
        icon={'question'}
      >
        {Translation.getTranslation('faq')}
      </Button>
      {renderModal()}
    </React.Fragment>
  );
}

export default FaqModal;
