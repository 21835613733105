import {
  Divider,
  IconButton,
  ListItemSecondaryAction,
  ListSubheader,
} from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import React, { useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import toastr from 'toastr';
import UserInterface from '../../interfaces/user.interface';
import CustomFieldsManager from '../../manager/customFields.manager';
import RequestManager from '../../manager/request.manager';
import { Alert } from '../../services/alert';
import { userState } from '../../services/atoms';
import Helper from '../../services/helper';
import { danger, lightGrey } from '../../services/styles';
import Translation from '../../services/translation';
import Button from '../Shared/Button';
import Icon from '../Shared/Icon';
import Input from '../Shared/Input';
import NoDataComponent from '../Shared/NoDataComponent';
import Select from '../Shared/Select';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      // maxWidth: 360,
      backgroundColor: lightGrey,
    },
  }),
);

interface Props {
  data?: UserInterface['projectCustomFields'];
  returnData?: (data: UserInterface['projectCustomFields']) => void;
}

export default function CustomFieldsList(props: Props) {
  const user = useRecoilValue(userState);
  const [data, setData] = useState<any[]>([]);
  const [title, setTitle] = useState<string>('');
  const [newTitle, setNewTitle] = useState<string>('');
  const [type, setType] = useState<string>('text');
  const [editMode, setEditMode] = useState<string>('');
  const [disabled, setDisabled] = useState<boolean>(false);
  const classes = useStyles();

  useEffect(() => {
    props.data && setData(props.data);
  }, [props.data]);

  const types = ['text', 'boolean', 'date'];

  const saveNewField = async () => {
    setDisabled(true);
    let saved = await RequestManager.postCustomField({
      label: title,
      type,
    });
    if (saved) {
      let dataTemp = [...data];
      dataTemp.push(saved);
      setData(dataTemp);
      setTitle('');
      toastr.success(Translation.getTranslation('custom_field_saved'));
      props.returnData && props.returnData(dataTemp as any);
    }

    setDisabled(false);
  };

  const toggleEditMode = (item: any) => {
    setEditMode(item.id);
    setNewTitle(item.label);
  };

  const saveEdit = async (item: any) => {
    setDisabled(true);
    let saved = await RequestManager.putCustomField({
      label: newTitle,
      id: item.id,
    });
    if (saved) {
      let dataTemp = [...data];
      let index = dataTemp.findIndex(
        (itemTemp: any) => itemTemp.id === item.id,
      );
      dataTemp[index] = saved;
      setData(dataTemp);
      toastr.success(Translation.getTranslation('custom_field_saved'));
    }
    setEditMode('');
    setDisabled(false);
  };

  const deleteField = async (item: any) => {
    setDisabled(true);
    await Alert.confirm({
      type: 'warning',
      title: Translation.getTranslation('confirm_delete_custom_field'),
      text: Translation.getTranslation('confirm_text_delete_custom_field'),
      confirmButtonText: Translation.getTranslation('delete'),
    }).then(async (confirm: boolean) => {
      if (confirm) {
        let deleted = await RequestManager.deleteCustomField(item.id);
        if (deleted) {
          let dataTemp = [...data];
          let index = dataTemp.findIndex(
            (itemTemp: any) => itemTemp.id === item.id,
          );

          dataTemp.splice(index, 1);
          setData(dataTemp);
          toastr.success(Translation.getTranslation('custom_field_deleted'));
        }
        setEditMode('');
      }
    });
    setDisabled(false);
  };

  return (
    <List className={classes.root}>
      {data.length === 0 ? (
        <ListItem>
          <NoDataComponent
            value={Translation.getTranslation('no_custom_field')}
          />
        </ListItem>
      ) : (
        data.map(
          (item) =>
            item && (
              <ListItem key={'listItem' + item.id}>
                <ListItemAvatar>
                  <Avatar>
                    <Icon icon={CustomFieldsManager.getIcon(item.type)} />
                  </Avatar>
                </ListItemAvatar>
                <div>
                  {editMode !== item.id ? (
                    <ListItemText
                      primary={item.label}
                      secondary={Translation.getTranslation(
                        'custom_field_' + item.type,
                      )}
                    />
                  ) : (
                    <Input
                      disabled={disabled}
                      small
                      hasError={
                        Helper.validate('custom_field', newTitle).length > 0
                          ? true
                          : false
                      }
                      helperText={
                        Helper.validate('custom_field', newTitle).length > 0 &&
                        Helper.validate('custom_field', newTitle)
                      }
                      label={Translation.getTranslation('name')}
                      className="mt-2"
                      value={newTitle}
                      onChange={(e: any) => setNewTitle(e.target.value)}
                    />
                  )}
                </div>
                <ListItemSecondaryAction>
                  <IconButton
                    edge="end"
                    aria-label="edit"
                    disabled={
                      (editMode === item.id &&
                        (item.label === newTitle ||
                          !newTitle ||
                          Helper.validate('custom_field', newTitle).length >
                            0)) ||
                      disabled
                    }
                    color={editMode !== item.id ? 'default' : 'primary'}
                    onClick={() => {
                      editMode !== item.id
                        ? toggleEditMode(item)
                        : saveEdit(item);
                    }}
                  >
                    <Icon icon={editMode !== item.id ? 'edit' : 'check'} />
                  </IconButton>
                  {editMode === item.id && (
                    <IconButton
                      disabled={disabled}
                      edge="end"
                      className="ml-2"
                      aria-label="delete"
                      onClick={() => {
                        deleteField(item);
                      }}
                    >
                      <Icon icon="trash" />
                    </IconButton>
                  )}
                </ListItemSecondaryAction>
              </ListItem>
            ),
        )
      )}
      <Divider />
      <ListSubheader component="div" id="custom_field_subheader">
        {Translation.getTranslation('add_custom_field')}
        <span
          style={data.length >= user.maxCustomFields ? { color: danger } : {}}
        >
          {' '}
          ({data.length}/{user.maxCustomFields})
        </span>
      </ListSubheader>
      <div className="d-flex flex-column justify-content-center px-2 pb-2">
        <div className="row">
          <div className="col col-md-6">
            <Input
              small
              disabled={disabled}
              value={title}
              hasError={
                Helper.validate('custom_field', title).length > 0 ? true : false
              }
              helperText={
                Helper.validate('custom_field', title).length > 0 &&
                Helper.validate('custom_field', title)
              }
              label={Translation.getTranslation('name')}
              onChange={(e: any) => setTitle(e.target.value)}
            />
          </div>
          <div className="col col-md-6">
            <Select
              disabled={disabled}
              value={type}
              label={Translation.getTranslation('type')}
              onChange={(e: any) => setType(e.target.value)}
            >
              {types.map((typeIteration: string) => (
                <option
                  value={typeIteration}
                  key={'custom_field_' + typeIteration}
                >
                  {Translation.getTranslation('custom_field_' + typeIteration)}
                </option>
              ))}
            </Select>
          </div>
        </div>

        <Button
          color="primary"
          noMargin
          disabled={
            Helper.validate('custom_field', title).length > 0 ||
            !title ||
            disabled
          }
          onClick={() => saveNewField()}
        >
          {Translation.getTranslation('add')}
        </Button>
      </div>
    </List>
  );
}
