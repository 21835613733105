import { makeStyles } from '@material-ui/core';
import * as React from 'react';
import Dropzone from 'react-dropzone';
import { primary } from '../../services/styles';
import Translation from '../../services/translation';
import Icon from '../Shared/Icon';
import Button from './Button';

interface Props {
  onDrop: (data: any) => void;
  multiple: boolean;
  noMargin?: boolean;
  buttonLabel?: string;
  buttonOnly?: boolean;
  accept?: 'image/*';
}

const useStyles = makeStyles((theme) => ({
  badges: {
    position: 'absolute',
    top: '0',
    right: '5px',
  },
  root: {
    border: `2px dashed ${primary}`,
    borderRadius: '10px',
    background: 'transparent',
    '&::after': {
      border: `2px solid ${primary}`,
    },
  },
}));

export function DropZone(props: Props) {
  const inputFile = React.useRef<any>(null);
  const classes = useStyles();

  return (
    <React.Fragment>
      {!props.buttonOnly ? (
        <Dropzone onDrop={(e) => props.onDrop(e)} multiple={props.multiple}>
          {({ getRootProps, getInputProps, isDragActive }) => (
            <div
              className={`text-center ${props.noMargin ? '' : 'm-5'} p-5 ${
                classes.root
              }`}
              {...getRootProps()}
            >
              <input {...getInputProps()} />
              {isDragActive ? (
                <React.Fragment>
                  <Icon icon="check" />{' '}
                  {Translation.getTranslation('file_drop')}
                </React.Fragment>
              ) : (
                Translation.getTranslation('upload_file_description')
              )}
            </div>
          )}
        </Dropzone>
      ) : (
        <React.Fragment>
          <input
            type="file"
            id="file"
            accept={props.accept}
            ref={inputFile}
            style={{ display: 'none' }}
            onChange={(e: any) => {
              props.onDrop(e.target.files);
            }}
          />
          <Button onClick={() => inputFile.current.click()}>
            {!props.buttonLabel
              ? Translation.getTranslation('select_file')
              : props.buttonLabel}
          </Button>
        </React.Fragment>
      )}
    </React.Fragment>
  );
}
