import { makeStyles, Paper } from '@material-ui/core';
import React from 'react';
import { grey, lightGrey } from '../../services/styles';

interface Props {
  elevation?: 0 | 1 | 2 | 3;
  children?: any;
  className?: string;
  onHeaderClick?: () => void;
  footer?: JSX.Element;
  header?: JSX.Element;
}
const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
  },
  header: {
    // height: '100%',
    // minHeight: '1px',
    // padding: '1.25rem',
    padding: '1rem 1rem 0 1rem',
    maxWidth: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  body: {
    height: '100%',
    minHeight: '1px',
    padding: '1.25rem',
  },
  footer: {
    width: '100%',
    marginTop: 'auto',
    padding: '.75rem 1.25rem',
    backgroundColor: lightGrey,
    borderTop: '1px solid ' + grey,
  },
}));

function Card(props: Props) {
  const classes = useStyles();

  return (
    <Paper
      className={`${classes.root} ${props.className || ''} `}
      elevation={props.elevation ?? 3}
    >
      {props.header && (
        <div
          className={
            classes.header + ` ${props.onHeaderClick ? 'cursor-pointer' : ''}`
          }
          onClick={() => props.onHeaderClick && props.onHeaderClick()}
        >
          {props.header}
        </div>
      )}
      <div className={classes.body}>{props.children || <span />}</div>
      {props.footer && <div className={classes.footer}>{props.footer}</div>}
    </Paper>
  );
}

export default Card;
