import AppBar from '@material-ui/core/AppBar';
import Box from '@material-ui/core/Box';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import Typography from '@material-ui/core/Typography';
import React, { useEffect } from 'react';

interface TabPanelProps {
  children?: React.ReactNode;

  padding?: number;
  index: any;
  value: any;
}
interface Props {
  elements: any;
  initialTab?: number;
  transparent?: boolean;
  fullWidth?: boolean;
  padding?: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={props.padding ?? 3}>
          <Typography component="div">{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: any) {
  return {
    id: `scrollable-auto-tab-${index}`,
    'aria-controls': `scrollable-auto-tabpanel-${index}`,
  };
}

export default function TabNav(props: Props) {
  const [value, setValue] = React.useState(props.initialTab || 0);

  useEffect(() => {
    if (props.initialTab && props.initialTab !== value)
      setValue(props.initialTab);
  }, [props.initialTab]);

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  const elementsToShow =
    props.elements?.length > 0 &&
    props.elements.filter((elem: any) => elem.label);

  return (
    <div>
      <AppBar
        position="static"
        component="div"
        color={!props.transparent ? 'default' : 'transparent'}
        elevation={0}
      >
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant={!props.fullWidth ? 'scrollable' : 'fullWidth'}
          scrollButtons="auto"
          aria-label="scrollable tabs"
        >
          {elementsToShow?.length > 0 &&
            elementsToShow.map(
              (element: any, index: number) =>
                element.label && (
                  <Tab
                    component="div"
                    key={element.label + index}
                    label={element.label}
                    {...a11yProps(index)}
                  />
                ),
            )}
        </Tabs>
      </AppBar>
      {elementsToShow?.length > 0 &&
        elementsToShow.map(
          (element: any, index: number) =>
            element.label && (
              <TabPanel
                key={index + element.label}
                value={value}
                index={index}
                padding={props.padding}
              >
                {element.jsx}
              </TabPanel>
            ),
        )}
    </div>
  );
}
