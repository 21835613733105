//React Imports
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { CssBaseline, ThemeProvider } from '@material-ui/core';
import React, { useState } from 'react';
import ReactGA from 'react-ga';
import {
  BrowserRouter,
  Redirect,
  Route,
  Switch,
  useHistory,
} from 'react-router-dom';
import { useRecoilValue, useResetRecoilState, useSetRecoilState } from 'recoil';
import toastr from 'toastr';
import AppLoading from './components/Shared/AppLoading';
import { Error404 } from './components/Shared/Error404';
import { Layout } from './components/Shared/Layout';
import RequestManager from './manager/request.manager';
import Analytics from './pages/Analytics';
import Archive from './pages/Archive';
import Connections from './pages/Connections';
import Dashboard from './pages/Dashboard';
import LandingPage from './pages/LandingPage';
import Login from './pages/Login';
import ProjectCreate from './pages/ProjectCreate';
import ProjectDetail from './pages/ProjectDetail';
import Register from './pages/Register';
import UserDetail from './pages/UserDetail';
import UserSettings from './pages/UserSettings';
import { userState } from './services/atoms';
import Static from './services/static';
import Styles from './services/styles';

library.add(fas);
toastr.options = Static.toastrOptions() as any;

interface Props {
  location?: any;
  children?: any;
}

export default function App(props: Props) {
  const resetUser = useResetRecoilState(userState);
  const history = useHistory();
  const user = useRecoilValue(userState);
  const setUser = useSetRecoilState(userState);
  const [appLoading, setAppLoading] = useState<boolean>(true);

  React.useEffect(() => {
    onPageLoad();
    getUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getUser = async () => {
    let userTemp = await RequestManager.getMe();
    if (userTemp) {
      setUser(userTemp);
      if (!userTemp.accepted) {
        await RequestManager.logout();
        history.push(Static.getPageByName('login').url);
      }
    } else resetUser();
    setAppLoading(false);
  };

  const onPageLoad = async () => {
    if (process.env.NODE_ENV === 'production') {
      ReactGA.initialize('UA-171306133-1');
      ReactGA.event({
        category: 'User',
        action: 'loading/reloading',
      });
      ReactGA.timing({
        category: 'Page Load Time',
        variable: 'load',
        value: Math.round(performance.now()), // in milliseconds
        label: window.location.pathname,
      });
    }
  };

  return appLoading ? (
    <AppLoading />
  ) : (
    <BrowserRouter>
      <ThemeProvider
        theme={Styles.getMuiTheme({
          user: user || undefined,
        })}
      >
        <CssBaseline />
        <Switch>
          {/* public routes */}
          <Route
            exact
            path={Static.getPageByName('moin').url}
            component={LandingPage}
          />
          <Route
            exact
            path={Static.getPageByName('login').url}
            component={Login}
          />
          <Route
            exact
            path={`${Static.getPageByName('register').url}/:inviteId?`}
            component={Register}
          />
          {/* internal routes */}
          {user && user.id ? (
            <Layout location={window.location}>
              <Switch>
                <Route
                  exact
                  path={Static.getPageByName('archive').url}
                  component={(e: any) => <Archive user={user} {...e} />}
                />
                <Route
                  exact
                  path={Static.getPageByName('analytics').url}
                  component={(e: any) => <Analytics user={user} {...e} />}
                />
                <Route
                  exact
                  path={Static.getPageByName('connections').url}
                  component={(e: any) => <Connections user={user} {...e} />}
                />
                <Route
                  exact
                  path={Static.getPageByName('dashboard').url}
                  component={Dashboard}
                />
                <Route
                  exact
                  path={
                    Static.getPageByName('user_settings').url +
                    '/:initialTab?/:redirectFromPayment?'
                  }
                  component={UserSettings}
                />
                <Route
                  exact
                  path={`${
                    Static.getPageByName('order_create').url
                  }/:projectId?`}
                  component={ProjectCreate}
                />
                <Route
                  exact
                  path={`${
                    Static.getPageByName('order_detail').url
                  }/:projectId`}
                  component={(e: any) => <ProjectDetail user={user} {...e} />}
                />
                <Route
                  exact
                  path={`${Static.getPageByName('user_detail').url}/:userId`}
                  component={(e: any) => <UserDetail user={user} {...e} />}
                />
                <Route component={Error404} />
              </Switch>
            </Layout>
          ) : (
            <Redirect to={Static.getPageByName('moin').url} />
          )}
        </Switch>
      </ThemeProvider>
    </BrowserRouter>
  );
}
