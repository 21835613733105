import { IconProp } from '@fortawesome/fontawesome-svg-core';
import {
  Button as ButtonMUI,
  makeStyles,
  Tooltip,
  Typography,
} from '@material-ui/core';
// import { MDBBtn } from 'mdbreact';
import * as React from 'react';
import { useHistory } from 'react-router-dom';
import {
  danger,
  grey,
  primary,
  secondary,
  success,
} from '../../services/styles';
import Icon from '../Shared/Icon';

interface Props {
  children?: any;
  className?: string;
  tooltip?: string;
  loadingLabel?: string;
  disabled?: boolean;
  noMargin?: boolean;
  pulse?: boolean;
  target?: '_blank';
  href?: string;
  color?:
    | 'default'
    | 'inherit'
    | 'primary'
    | 'danger'
    | 'success'
    | 'grey'
    | 'white'
    | 'secondary';
  size?: 'sm' | 'lg';
  icon?: IconProp;
  loading?: boolean;
  outline?: boolean;
  text?: boolean;
  iconRight?: boolean;
  onClick?: any;
  'aria-label'?: string;
  'aria-haspopup'?:
    | boolean
    | 'dialog'
    | 'menu'
    | 'false'
    | 'true'
    | 'listbox'
    | 'tree'
    | 'grid'
    | undefined;
}

function Button(props: Props) {
  const colorVariableMatching = () => {
    switch (props.color) {
      case 'primary':
        return primary;
      case 'secondary':
        return secondary;
      case 'success':
        return success;
      case 'danger':
        return danger;
      case 'default':
        return grey;
      default:
        return primary;
    }
  };
  const useStyles = makeStyles((_theme) => ({
    root: {},
    danger: {
      backgroundColor: danger,
      color: '#fff',
      '&:hover': { backgroundColor: danger },
    },
    grey: {
      backgroundColor: grey,
      color: '#000',
      '&:hover': { backgroundColor: grey },
    },
    pulse: {
      transform: 'scale(1)',
      animation: '$pulse 2s infinite',
      boxShadow: `0 0 0 0 ${colorVariableMatching()}00`,

      '&:hover': {
        transform: 'none',
        animation: 'none',
        boxShadow: 'none',
      },
    },

    '@keyframes pulse': {
      '0%': {
        transform: 'scale(0.93)',
        boxShadow: `0 0 0 0 ${colorVariableMatching()}B2`, //70% opacity
      },

      '70%': {
        transform: 'scale(1)',
        boxShadow: `0 0 0 10px ${colorVariableMatching()}00`, // 0% opacity
      },

      '100%': {
        transform: 'scale(0.93)',
        boxShadow: `0 0 0 0 ${colorVariableMatching()}00`,
      },
    },
  }));
  const classes = useStyles();
  const [disabled, setDisabled] = React.useState<boolean>(
    props.disabled || false,
  );
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const history = useHistory();

  const action = async (event: any) => {
    if (isLoading === true || disabled === true) {
      return;
    }
    setIsLoading(true);

    if (props.onClick) {
      if (typeof props.onClick === 'function') {
        await props.onClick(event);
        setIsLoading(false);
      } else {
        history.push(props.onClick);
      }
    }
  };

  React.useEffect(() => {
    setDisabled(props.disabled ? true : false);
  }, [props]);

  const renderIcon = () => {
    let ret;
    let className = '';

    if (props.children !== undefined) {
      className = props.iconRight ? 'ml-1' : 'mr-1';
    }

    if (props.icon) {
      ret = <Icon icon={props.icon} className={className}></Icon>;
    }

    return ret;
  };

  const renderClasses = (): string => {
    let classesTemp = !props.noMargin ? 'm-1 ' : '';
    if (props.className) classesTemp += props.className + ' ' + classes.root;

    switch (props.color) {
      case 'danger':
        classesTemp += classes.danger;
        break;
      case 'grey':
        classesTemp += classes.grey;
        break;
      // case 'white':
      //   classes += ' bg-light ';
      // break;
    }

    if (props.pulse) classesTemp += classes.pulse;
    return classesTemp;
  };

  const renderButton = () => {
    return (
      <ButtonMUI
        variant={props.outline ? 'outlined' : props.text ? 'text' : 'contained'}
        disabled={disabled || props.loading}
        className={renderClasses()}
        style={{ cursor: 'pointer' }}
        color={
          props.color !== 'default' &&
          props.color !== 'primary' &&
          props.color !== 'secondary'
            ? 'primary'
            : props.color
        }
        href={props.href || ''}
        target={props.target}
        size={
          props.size === 'sm'
            ? 'small'
            : props.size === 'lg'
            ? 'large'
            : 'medium'
        }
        onClick={props.onClick && action}
        aria-label={props['aria-label']}
        aria-haspopup={props['aria-haspopup']}
      >
        {props.loading ? (
          <React.Fragment>
            <Icon icon="spinner" spin />
            {props.loadingLabel ? (
              <Typography variant="button" className="ml-1" component="span">
                {props.loadingLabel}
              </Typography>
            ) : (
              <span className="sr-only">Loading</span>
            )}
          </React.Fragment>
        ) : (
          <React.Fragment>
            {props.iconRight ? undefined : renderIcon()}
            <Typography variant="button" component="span">
              {props.children}
            </Typography>
            {props.iconRight ? renderIcon() : undefined}
          </React.Fragment>
        )}
      </ButtonMUI>
    );
  };

  return (
    <React.Fragment>
      {props.tooltip ? (
        <Tooltip
          title={props.tooltip}
          placement="bottom"
          arrow
          aria-label={props.tooltip}
        >
          {renderButton()}
        </Tooltip>
      ) : (
        renderButton()
      )}
    </React.Fragment>
  );
}

Button.defaultProps = {
  color: 'default',
};

export default Button;
