import { makeStyles, Typography } from '@material-ui/core';
import * as React from 'react';
import { FileTypes } from '../../interfaces/project';
import UserInterface from '../../interfaces/user.interface';
import RequestManager from '../../manager/request.manager';
import UploadsManager from '../../manager/uploads.manager';
import Helper from '../../services/helper';
import { grey, primary } from '../../services/styles';
import Translation from '../../services/translation';
import Button from '../Shared/Button';
import Icon from '../Shared/Icon';
import { ModelViewer } from '../Shared/ModelViewer';
import Spinner from '../Shared/Spinner';

interface Props {
  match?: any;
  location?: any;
  history?: any;
  me: UserInterface;
  data: FileTypes[];
  reloadData?: () => void;
  project: any;
}

const useStyles = makeStyles((_theme) => ({
  header: { backgroundColor: primary, color: '#fff' },
  roleRow: { backgroundColor: grey },
}));

export default function FileTable(props: Props) {
  const classes = useStyles();
  const [bulkButtonDisabled, setBulkButtonDisabled] = React.useState<boolean>(
    false,
  );
  const [data, setData] = React.useState<FileTypes[]>([]);
  const [previewModalIsOpen, setPreviewModalIsOpen] = React.useState<
    string | undefined
  >(undefined);
  const [previewModalType, setPreviewModalType] = React.useState<
    'stl' | 'obj' | undefined
  >(undefined);

  React.useEffect(() => {
    if (props.data?.length > 0) {
      setData([...props.data]);
    }
  }, [props.data]);

  const renderRoleRow = (title: string, files: any) => {
    return (
      <tr key={'renderRoleRow' + title} className={classes.roleRow}>
        <td colSpan={100} className={`text-center position-relative`}>
          <span>{title}</span>
          <div
            style={{
              position: 'absolute',
              right: 3,
              opacity: 1,
              top: '50%',
              transform: 'translateY(-50%)',
            }}
          >
            <Button
              icon="download"
              loading={bulkButtonDisabled}
              size="sm"
              color="primary"
              onClick={async () => {
                setBulkButtonDisabled(true);
                await RequestManager.zipDownload(
                  files.map((file: any) => {
                    return file.id;
                  }),
                  props.project.orderNumber + '.zip',
                );
                setBulkButtonDisabled(false);
              }}
            >
              {Translation.getTranslation('all')}
            </Button>
          </div>
        </td>
      </tr>
    );
  };

  const saveFile = async (fileId: string) => {
    let file = await RequestManager.getDownloadLink(fileId);
    if (file && file.url) {
      UploadsManager.downloadFile(file.url);
    }
  };

  // change the uploading status in loaded file
  const changeFileLoaded = (fileTemp: FileTypes) => {
    let dataTemp = [...data];
    const index = dataTemp.findIndex(
      (dataIteration: FileTypes) => dataIteration.id === fileTemp.id,
    );
    if (index !== -1) {
      dataTemp[index].uploading = false;
      setData(dataTemp);
    }
  };

  // render the loading spinner and check for uploading status change
  const renderSpinner = (fileTemp: FileTypes) => {
    UploadsManager.reCheckUploadingStatus(fileTemp, () =>
      changeFileLoaded(fileTemp),
    );
    return <Spinner small />;
  };

  const renderRow = (row: any) => {
    return (
      <tr key={'renderRow' + row.id}>
        <td>{row.name}</td>
        {props.me.role === 'admin' && (
          <td>{`${row.user?.firstName ? row.user?.firstName : ''} ${
            row.user?.lastName ? row.user?.lastName : ''
          } ${row.user?.company ? '(' + row.user?.company + ')' : ''}`}</td>
        )}
        <td>{row.description ? row.description : '-'}</td>
        <td>
          {row.originalname
            ? Helper.getFileTypeByName(row.originalname)
              ? Helper.getFileTypeByName(row.originalname)
              : row.mimetype
            : '-'}
        </td>
        <td>{Helper.humanFileSize(row.size)}</td>
        <td>
          {row.userId === props.me.id || props.me.role === 'admin'
            ? Translation.getTranslation(row.visibility)
            : Translation.getTranslation('released_for_me')}
        </td>
        {props.me.role === 'admin' && (
          <td>{Helper.getDate(row.createdAt, true)}</td>
        )}
        <td>
          {!row.uploading ? (
            <React.Fragment>
              <Button
                tooltip={Translation.getTranslation('download')}
                color={'default'}
                size="sm"
                onClick={() => {
                  saveFile(row.id);
                }}
              >
                <Icon icon="download"></Icon>
              </Button>
              {/* todo  */}
              {/* {row.userId === props.me.id && (
                <Button
                  tooltip={Translation.getTranslation('edit')}
                  color={'default'}
                  size="sm"
                  onClick={() => {
                    saveFile(row.id);
                  }}
                >
                  <Icon icon="edit"></Icon>
                </Button>
              )} */}
              <React.Fragment>
                {row.originalname &&
                  Helper.getFileTypeByName(row.originalname) &&
                  (Helper.getFileTypeByName(row.originalname) === 'stl' ||
                    Helper.getFileTypeByName(row.originalname) === 'obj') && (
                    <Button
                      size="sm"
                      tooltip={Translation.getTranslation('preview')}
                      color={'default'}
                      onClick={() => {
                        setPreviewModalIsOpen(row.id);
                        setPreviewModalType(
                          Helper.getFileTypeByName(row.originalname) as any,
                        );
                      }}
                    >
                      <Icon icon="eye"></Icon>
                    </Button>
                  )}
              </React.Fragment>
            </React.Fragment>
          ) : (
            renderSpinner(row)
          )}
        </td>
      </tr>
    );
  };

  const renderRoleSectionAdminAndDesigner = (files: any) => {
    let jsx = [] as any;
    //get files of different roles and by user
    ['', 'user', 'designer', 'admin'].map((role: any): void => {
      const result = files.filter((file: any) =>
        role !== ''
          ? file?.user?.role === role && file.userId !== props.me.id
          : file.userId === props.me.id,
      );
      if (result) {
        const roleName =
          role !== ''
            ? `${Translation.getTranslation(
                'uploaded_by',
              )}: ${Translation.getTranslation(
                role !== 'user' ? role : 'customer',
              )}`
            : Translation.getTranslation('uploaded_by_me');

        // get files of current role iteration
        let fileDataWithRole = [] as any;
        fileDataWithRole.push(...result);
        // if files of this role are found -> render
        if (fileDataWithRole && fileDataWithRole.length > 0) {
          jsx.push(renderRoleRow(roleName, fileDataWithRole));
          fileDataWithRole.map((row: any, index: number) =>
            jsx.push(renderRow(row)),
          );
        }
      }
    });

    return jsx;
  };
  const renderRoleSectionUser = (files: any) => {
    let jsx = [] as any;

    const getMyFiles = (): Array<any> => {
      return data.filter((file: any) => file.userId === props.me.id);
    };

    const getResultFiles = (): Array<any> => {
      if (props.project.currentStatus === 'finished') {
        return data.filter((file: any) => file.userId !== props.me.id);
      } else return [];
    };

    //get files of different roles and by user
    const myFiles = getMyFiles();
    const resultFiles = getResultFiles();

    if (myFiles) {
      const roleName = Translation.getTranslation('uploaded_by_me');

      // get files of current role iteration
      let fileDataWithRole = [] as any;
      fileDataWithRole.push(...myFiles);
      // if files of this role are found -> render
      if (fileDataWithRole && fileDataWithRole.length > 0) {
        jsx.push(renderRoleRow(roleName, fileDataWithRole));
        fileDataWithRole.map((row: any, index: number) =>
          jsx.push(renderRow(row)),
        );
      }
    }

    if (resultFiles) {
      const roleName = Translation.getTranslation('result_files');

      // get files of current role iteration
      let fileDataWithRole = [] as any;
      fileDataWithRole.push(...resultFiles);
      // if files of this role are found -> render
      if (fileDataWithRole && fileDataWithRole.length > 0) {
        jsx.push(renderRoleRow(roleName, fileDataWithRole));
        fileDataWithRole.map((row: any, index: number) =>
          jsx.push(renderRow(row)),
        );
      }
    }

    return jsx;
  };

  return (
    <div
      style={{
        marginBottom: '2rem',
        height: !props.project.id && props.me.id ? '15rem' : '',
      }}
    >
      {!props.project.id && props.me.id ? (
        <Spinner></Spinner>
      ) : (
        <Typography variant="body2" component="div">
          <div className="table-responsive">
            <table className="table table-center">
              <thead className={classes.header}>
                <tr>
                  <th>{Translation.getTranslation('filename')}</th>
                  {props.me.role === 'admin' && (
                    <th>{Translation.getTranslation('uploader')}</th>
                  )}
                  <th>{Translation.getTranslation('description')}</th>
                  <th>{Translation.getTranslation('filetype')}</th>
                  <th>{Translation.getTranslation('size')}</th>
                  <th>{Translation.getTranslation('visibility')}</th>
                  {props.me.role === 'admin' && (
                    <th>{Translation.getTranslation('date')}</th>
                  )}
                  <th>{Translation.getTranslation('actions')}</th>
                </tr>
              </thead>
              <tbody>
                {data && data.length > 0 ? (
                  // list by role if roles are available
                  props.me.role !== 'user' ? (
                    renderRoleSectionAdminAndDesigner(data)
                  ) : (
                    renderRoleSectionUser(data)
                  )
                ) : (
                  <tr>
                    <td colSpan={100}>
                      {Translation.getTranslation('no_file')}
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </Typography>
      )}
      {previewModalIsOpen && (
        <ModelViewer
          file={previewModalIsOpen}
          type={previewModalType as 'stl' | 'obj'}
          toggleModal={() => {
            setPreviewModalType(undefined);
            setPreviewModalIsOpen(undefined);
          }}
        />
      )}
    </div>
  );
}
