import { Step, StepLabel } from '@material-ui/core';
import StepperMui from '@material-ui/core/Stepper';
import * as React from 'react';
import { background } from '../../services/styles';
import Translation from '../../services/translation';
import Button from './Button';

interface Props {
  match?: any;
  children?: any;
  location?: any;
  nextStep: any;
  prevStep: any;
  finish: any;
  save?: any;
  currentStep: number;
  finishStepLabel?: string;

  maxStep: number;
  minStep: number;
  disabled?: boolean;
  steps: {
    position: number;
    label: string;
  }[];
}

export default function Stepper(props: Props) {
  return (
    <div
      style={{
        width: '100%',
      }}
    >
      <StepperMui
        style={{ backgroundColor: background }}
        activeStep={props.currentStep - 1}
      >
        {props.steps &&
          props.steps.length > 0 &&
          props.steps.map((step: any) => (
            <Step key={step.label + 'stepper'}>
              <StepLabel>{Translation.getTranslation(step.label)}</StepLabel>
            </Step>
          ))}
      </StepperMui>

      <div>{props.children}</div>

      <div
        className="mt-4 text-right"
        // style={{ position: 'sticky', bottom: 0, right: 0 }}
      >
        <Button
          color="default"
          disabled={props.disabled || props.currentStep === props.minStep}
          onClick={() => props.prevStep()}
        >
          {Translation.getTranslation('previous_step')}
        </Button>
        {props.save && (
          <Button
            color="primary"
            className="text-left"
            onClick={() => props.save()}
            disabled={props.disabled}
          >
            {Translation.getTranslation('save')}
          </Button>
        )}
        {props.maxStep !== props.currentStep ? (
          <Button
            color="primary"
            disabled={props.disabled}
            onClick={() => props.nextStep()}
          >
            {Translation.getTranslation('next_step')}
          </Button>
        ) : (
          <Button
            color="primary"
            disabled={props.disabled}
            pulse
            onClick={() => props.finish()}
          >
            {props.finishStepLabel
              ? props.finishStepLabel
              : Translation.getTranslation('finish')}
          </Button>
        )}
      </div>
    </div>
  );
}
