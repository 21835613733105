import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { Divider, Typography } from '@material-ui/core';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import React from 'react';
import { useHistory } from 'react-router';
import Button from './Button';
import Icon from './Icon';

interface Item {
  icon?: IconProp;
  label?: string;
  onClick?: ((event: any) => void) | string;
}

interface Props {
  className?: string;
  fullWidth?: boolean;
  hideChevron?: boolean;
  label: string;
  items: Item[];
  disabled?: boolean;
  color?: 'primary' | 'secondary' | 'success' | 'danger' | 'default';
  size?: 'sm' | 'lg';
  icon?: IconProp;
  id?: string;
  outline?: boolean;
  iconRight?: boolean;
}
export default function MenuButton(props: Props) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const history = useHistory();

  const action = async (event: any) => {
    if (isLoading === true || props.disabled === true) {
      return;
    }
    setIsLoading(true);

    if (event) {
      if (typeof event === 'function') {
        await event();
        setIsLoading(false);
      } else {
        history.push(event);
      }
    }
    handleClose();
  };

  const renderClasses = (): string => {
    let classes = '';
    if (props.fullWidth) classes += 'dropDown-button-fullWidth ';
    if (props.className) classes += props.className;
    return classes;
  };

  return (
    <React.Fragment>
      <Button
        className={renderClasses()}
        size={props.size}
        outline={props.outline}
        disabled={props.disabled}
        aria-controls={props.label}
        color={props.color ? props.color : 'primary'}
        aria-haspopup="true"
        onClick={handleClick}
      >
        {props.icon && <Icon icon={props.icon} className="mr-1" />}
        <Typography component="span" variant="button">
          {props.label}
        </Typography>
        {!props.hideChevron && <Icon icon="chevron-down" className="ml-1" />}
      </Button>
      <Menu
        id={'mydenti-menu' + props.label}
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {props.items.map((item: Item, index: number) =>
          item && item.label && item.onClick ? (
            <MenuItem
              onClick={() => action(item.onClick)}
              key={`dropDownButtonItem${item.label}${index}`}
            >
              {item.icon && <Icon icon={item.icon} className="mr-1" />}
              <Typography component="span">{item.label}</Typography>
            </MenuItem>
          ) : (
            <Divider
              key={'divider' + index}
              style={{ marginTop: '.5rem', marginBottom: '.5rem' }}
            />
          ),
        )}
      </Menu>
    </React.Fragment>
  );
}
