import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React, { useEffect } from 'react';
import { useRecoilValue } from 'recoil';
import { PaymentTypes, PriceInterface } from '../../interfaces/user.interface';
import { userState } from '../../services/atoms';
import Helper from '../../services/helper';
import { grey, lightGrey, primary } from '../../services/styles';
import Translation from '../../services/translation';
import Icon from './Icon';

export interface PricingProps {
  preSelected?: string;
  selectedPlan: (plan: PaymentTypes['currentPlan']) => void;
  showOnlySelected?: boolean;
  items: PriceInterface[];
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  box: {
    boxShadow: 'none',
    marginBottom: '5px',
    border: '3px solid ' + grey,
    backgroundColor: 'white',
    display: 'grid',
    gridTemplateColumns: '1fr 4fr 1fr',
    alignItems: 'center',
    position: 'relative',
  },
  heading: {
    height: '5rem',
    backgroundColor: lightGrey,
    padding: '1rem',
    display: 'flex',
    flexDirection: 'column',
    // height: '100%',
    alignItems: 'center',
    justifyContent: 'center',
  },
  text: { padding: '1rem' },
  selected: { border: '3px solid ' + primary },
  price: { padding: '1rem', textAlign: 'center' },
  icon: {
    position: 'absolute',
    backgroundColor: lightGrey,
    left: '-1rem',
    top: 'calc(50% - 1rem)',
    zIndex: 5,
    color: primary,
  },
  boxWrapper: { position: 'relative' },
}));

export default function PricingBox(props: PricingProps) {
  const classes = useStyles();
  const user = useRecoilValue(userState);
  const [selected, setSelected] = React.useState<number | undefined>(undefined);
  const [preSelected, setPreSelected] = React.useState<number | undefined>(
    undefined,
  );
  const [hover, setHover] = React.useState<number | undefined>(undefined);

  useEffect(() => {
    if (typeof props.preSelected !== 'undefined') {
      const foundIndex = props.items.findIndex(
        (item) => item.name === props.preSelected,
      );
      if (foundIndex !== -1) {
        setSelected(foundIndex);
        setPreSelected(foundIndex);
      }
    }
  }, [props.preSelected]);

  useEffect(() => {
    typeof selected !== 'undefined' &&
      typeof props.items[selected]?.name !== 'undefined' &&
      props.selectedPlan(props.items[selected].name as any);
  }, [selected]);

  const returnPricing = (item: any) => {
    const sponsored =
      user.payment &&
      !user.payment?.stripeCustomerId &&
      user.payment?.currentPlan &&
      user.payment?.currentPlan !== 'basic'
        ? true
        : false;

    if (!sponsored) {
      return (
        Helper.renderPricing({
          amount: item.amount,
          currency: item.currency,
          interval: item.interval,
        })?.totalCurrencyInterval || ''
      );
    }
    return <span />;
  };

  return (
    <div className={classes.root}>
      {props.items.map((item, index: number) => (
        <div
          key={item.id}
          className={classes.boxWrapper}
          style={
            props.showOnlySelected && index !== selected
              ? { display: 'none' }
              : {}
          }
        >
          {selected === index && (
            <Icon icon="check-circle" className={classes.icon} size="2x" />
          )}
          <div
            className={`${classes.box} ${
              hover === index || selected === index ? classes.selected : ''
            }`}
            onClick={() => {
              setSelected(index);
            }}
            onMouseEnter={() => setHover(index)}
            onMouseLeave={() => setHover(undefined)}
          >
            <div className={classes.heading}>
              {preSelected === index && (
                <Typography component="div" variant="button">
                  {Translation.getTranslation('current_abo')}
                </Typography>
              )}
              <Typography
                component="div"
                color={selected === index ? 'primary' : 'initial'}
                className={`${
                  selected === index || preSelected === index
                    ? 'font-weight-bold'
                    : ''
                }`}
              >
                {item.heading}
              </Typography>
            </div>
            <div className={classes.text}>
              <Typography>
                {item.text?.map(
                  (feature: string, index: number) =>
                    `${feature}${Helper.renderComma(item.text, index, ';')}`,
                )}
              </Typography>
            </div>
            <div className={classes.price}>
              <Typography
                className={`${
                  selected === index || preSelected === index
                    ? 'font-weight-bold'
                    : ''
                }`}
              >
                {returnPricing(item)}
              </Typography>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}
