import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

interface Props {
  className?: string;
  flip?: 'horizontal' | 'vertical';
  spin?: boolean;
  icon: IconProp;
  size?: '2x' | '3x' | '4x';
}

function Icon(props: Props) {
  // const [explain, setExplain] = useState(false);

  return (
    <span>
      <FontAwesomeIcon
        icon={props.icon}
        size={props.size}
        spin={props.spin}
        flip={props.flip}
        className={props.className}
      />
    </span>
  );
}

export default Icon;
