import { makeStyles } from '@material-ui/core';
import React from 'react';
import ReactSelect from 'react-select';
import { dangerLight, lightSecondary, primary } from '../../services/styles';
import Translation from '../../services/translation';
// const options = ['Option 1', 'Option 2'];

interface Props {
  data: { value: string; label: string; isDisabled?: boolean }[];
  defaultValue?: { value: string; label: string; isDisabled?: boolean };
  value: any;
  placeholder?: string;
  className?: string;
  hasError?: boolean;
  disabled?: boolean;
  loading?: boolean;
  onChange: any;
  multi?: boolean;
}

const useStyles = makeStyles((_theme) => ({
  dangerCell: {
    '& .select__control': { backgroundColor: `${dangerLight} !important` },
  },
}));

export default function SearchableSelect(props: Props) {
  const classes = useStyles();

  const getValue = () => {
    if (props.value && typeof props.value === 'string') {
      let defaultValue = props.data.filter(
        (item: any) => item.value === props.value,
      );
      if (defaultValue) return defaultValue as any;
      else
        return props.defaultValue
          ? props.defaultValue
          : { label: '', value: '' };
    } else if (typeof props.value !== 'string' && props.value.length > 0) {
      let defaultValue = props.data.filter((dataItem: any) =>
        props.value.some((item: any) => dataItem.value === item),
      );
      if (defaultValue) return defaultValue as any;
    } else if (props.value === '') {
      return props.defaultValue ? props.defaultValue : { label: '', value: '' };
    }
  };

  return (
    <ReactSelect
      className={`${props.className} ${
        props.hasError ? classes.dangerCell : ''
      }`}
      classNamePrefix="select"
      placeholder={props.placeholder}
      defaultValue={props.defaultValue}
      isDisabled={props.disabled}
      isLoading={props.loading}
      theme={(theme) => ({
        ...theme,
        zIndex: 9999,
        borderRadius: 0,
        colors: {
          ...theme.colors,
          primary50: lightSecondary,
          primary25: lightSecondary,
          primary: primary,
        },
      })}
      isMulti={props.multi}
      onChange={(e: any) => {
        // if single select
        e &&
          typeof e.value !== 'undefined' &&
          props.onChange(e && e.value ? e.value : '');
        // if multi select
        e &&
          e.length > 0 &&
          props.multi &&
          props.onChange(
            e &&
              e.map((item: any) => {
                return item.value;
              }),
          );
        // if multi select and nothing is selected
        (e === null || e.length === 0) && props.multi && props.onChange([]);
        e === null && !props.multi && props.onChange('');
      }}
      value={getValue()}
      isClearable={true}
      noOptionsMessage={() => Translation.getTranslation('no_entry')}
      isSearchable={true}
      name="search-select-picker"
      options={
        props.defaultValue ? [props.defaultValue, ...props.data] : props.data
      }
    />
  );
}
