import Static from '../services/static';
import RequestManager from './request.manager';
import { StateManager } from './states.manager';

export default class ProjectsManager {
  static async loadStaticServices() {
    let services = StateManager.getServices('service');
    let material = StateManager.getServices('material');
    let systemService = StateManager.getServices('systemService');
    if (
      !services ||
      services.length === 0 ||
      !material ||
      material.length === 0 ||
      !systemService ||
      systemService.length === 0
    ) {
      const services = await RequestManager.getStaticServices().catch();
      if (services) {
        ['service', 'material', 'systemService'].map((entity: any) =>
          StateManager.setServices(
            entity,
            services.filter((service: any) =>
              entity !== 'systemService'
                ? service.type === entity
                : service.type === 'system_service',
            ),
          ),
        );
      }
    }
  }

  static async getSystemServicesInProjectData(data: any) {
    //get systemServices
    let systemServices = [];
    if (data.systemServices && data.systemServices.length > 0) {
      for (const systemServiceFromServer of data.systemServices) {
        let foundService = await Static.getSystemServiceById(
          systemServiceFromServer,
        );
        if (foundService) {
          systemServices.push(foundService);
        }
      }
      data.systemServices = systemServices;
    }

    return data;
  }
}
