import * as React from 'react';
import ComponentHeading from '../components/Shared/ComponentHeading';
import DesignerSuggestions from '../components/Shared/DesignerSuggestions';
import PageHeadingRow from '../components/Shared/PageHeadingRow';
import MyConnectionsList from '../components/User/MyConnectionsList';
import UserInterface, { Invites } from '../interfaces/user.interface';
import RequestManager from '../manager/request.manager';
import UserManager from '../manager/user.manager';
import Static from '../services/static';
import { borderRadius, grey } from '../services/styles';
import Translation from '../services/translation';
interface Props {
  match?: any;
  location?: any;
  user: UserInterface;
}

export default function Connections(props: Props) {
  const [loading, setLoading] = React.useState<boolean>(false);
  const [connections, setConnections] = React.useState<UserInterface[]>([]);
  const [invites, setInvites] = React.useState<{ rows?: Invites[] }>({
    rows: [],
  });
  // const user = useRecoilValue(userState);

  React.useEffect(() => {
    if (
      (props.user.role === 'user' &&
        UserManager.checkIfPayedForService(props.user, {
          service: 'open_my_connections',
        })) ||
      props.user.role === 'designer'
    ) {
      getInitial();
    }
  }, []);

  const getInternalDesigners = async () => {
    let connectionsTemp = await RequestManager.getMyConnections();
    setConnections(connectionsTemp);
  };

  const getInvites = async () => {
    let invitesTemp = await RequestManager.getInvites({
      status: 'invited',
      limit: 1000,
    });
    setInvites(invitesTemp);
  };

  const getInitial = async () => {
    setLoading(true);
    if (props.user.role !== 'admin') {
      getInternalDesigners();
      getInvites();
    }
    setLoading(false);
  };

  return (
    <React.Fragment>
      <PageHeadingRow
        siteTitle={Static.getPageByName('connections').siteTitle}
      />
      <div className="row">
        <div className="col-md-6">
          <ComponentHeading
            // noMargin
            value={Translation.getTranslation('my_connections')}
            explain={Translation.getTranslation('my_connections_explain')}
          >
            <div
              style={{
                backgroundColor: 'white',
                border: '1px solid ' + grey,
                borderRadius: borderRadius,
                padding: '1rem',
              }}
            >
              <MyConnectionsList
                user={props.user}
                connections={connections}
                invites={invites.rows}
              />
            </div>
          </ComponentHeading>
        </div>
        <div className="col-md-6">
          <ComponentHeading
            value={Translation.getTranslation('suggestions')}
            explain={Translation.getTranslation('suggestions_explain')}
          >
            <DesignerSuggestions />
          </ComponentHeading>
        </div>
      </div>
    </React.Fragment>
  );
}
