import { Typography } from '@material-ui/core';
import React from 'react';
import Modal from './Modal';
import Spinner from './Spinner';

interface LoadingModal {
  text?: string;
}

function LoadingModal(props: LoadingModal) {
  // const [explain, setExplain] = useState(false);

  return (
    <Modal isOpen={true} hasNoClose hasNoFooter size="xl">
      <div className="d-flex align-items-center justify-content-center">
        <div className="mr-4">
          <Spinner />
        </div>
        <Typography variant="h4">{props.text}</Typography>
      </div>
    </Modal>
  );
}

export default LoadingModal;
