import { Typography } from '@material-ui/core';
import * as React from 'react';
import UserInterface, { CustomFieldTypes } from '../interfaces/user.interface';
import Helper from '../services/helper';
import Translation from '../services/translation';

export default class CustomFieldsManager {
  static renderCustomFieldHeadings = (
    customFields: UserInterface['projectCustomFields'],
  ) => {
    const jsx = [];
    if (customFields && customFields.length > 0) {
      for (const customField of customFields) {
        jsx.push(
          <th key={'customField' + customField.label}>
            <Typography>{customField.label}</Typography>
          </th>,
        );
      }
    }
    return jsx;
  };

  static renderCustomFieldValues = (
    customFields: UserInterface['projectCustomFields'],
    rowData: any,
  ) => {
    const jsx = [];
    if (customFields && customFields.length > 0) {
      for (const customField of customFields) {
        // find first value of each custom field for the given project id
        const foundValue = customField.projectCustomFieldsValues.find(
          (value: any) => value.projectId === rowData.id,
        );

        jsx.push(
          <td key={'customFieldValue' + customField.label}>
            {foundValue ? (
              <Typography variant="body2">
                {CustomFieldsManager.renderCustomFieldValue(
                  foundValue[customField.type],
                  customField.type,
                )}
              </Typography>
            ) : (
              <Typography variant="body2">-</Typography>
            )}
          </td>,
        );
      }
    }
    return jsx;
  };

  static renderCustomFieldValue = (
    value: any,
    type: CustomFieldTypes['type'],
  ) => {
    switch (type) {
      case 'boolean':
        return Translation.getTranslation(value ? 'yes' : 'no');
      case 'date':
        return Helper.getDate(value);
      case 'text':
        return value;
      default:
        return '';
    }
  };

  static getIcon = (typeTemp: CustomFieldTypes['type']) => {
    switch (typeTemp) {
      case 'boolean':
        return 'toggle-on';
      case 'text':
        return 'font';
      case 'date':
        return 'calendar';
    }
  };

  static renderDataTableAdditionalColumn = (column: {
    data: any;
    rowData?: any;
    headingCallback?: (labels: any) => void;
    valueCallback?: (value: any) => void;
  }) => {
    if (column.headingCallback)
      return column.headingCallback(
        CustomFieldsManager.renderCustomFieldHeadings(
          column.data?.customFields,
        ),
      );
    else if (column.valueCallback)
      return column.valueCallback(
        CustomFieldsManager.renderCustomFieldValues(
          column.data?.customFields,
          column.rowData,
        ),
      );
  };
}
