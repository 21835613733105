import { makeStyles, Typography } from '@material-ui/core';
import * as React from 'react';
import Static from '../../services/static';
import { background, darkGrey, grey, primary } from '../../services/styles';
import Translation from '../../services/translation';
import Checkbox from '../Shared/Checkbox';
import Icon from '../Shared/Icon';

interface Props {
  match?: any;
  location?: any;
  choice?: any;
  preSelected?: any;

  selectAllTeeth?: boolean;
  disabled?: boolean;
}

const useStyles = makeStyles((theme) => ({
  tableWrapper: {
    '& td': {
      borderTop: '0 !important',
    },
    '& table': {
      border: `2px solid ${darkGrey}`,
      borderCollapse: 'collapse',
    },
  },
  checkboxTop: {
    height: '2rem',
    maxWidth: '100%',
    overflowX: 'scroll',
    position: 'absolute',
    marginLeft: 'auto',
    marginRight: 'auto',
    left: 0,
    right: 0,
    top: '-30px',
    textAlign: 'center',
  },
  checkboxBottom: {
    height: '1rem',
    position: 'absolute',
    marginLeft: 'auto',
    marginRight: 'auto',
    left: 0,
    right: 0,
    bottom: '-5px',
    textAlign: 'center',
  },

  numberRow: {
    '& td': {
      height: '1rem !important',

      paddingTop: '0 !important',
      paddingBottom: '0 !important',
      backgroundColor: grey,
    },
    // '& p': {
    //   fontSize: '16px',
    // },
  },

  pointer: { cursor: 'pointer' },
  hover: {
    '&:hover': {
      backgroundColor: grey,
    },
  },
  selected: {
    backgroundColor: primary,
  },
  icon: {
    color: background,
  },

  toothRow: {
    '& td': {
      height: '3rem',
      color: '#fff',
      border: `1px solid ${grey}`,
    },
  },
}));

export default function ToothChoose(props: Props) {
  const classes = useStyles();
  const [itemsMap, setItemsMap] = React.useState<Map<number, boolean>>(
    new Map(),
  );
  const [upperJawSelected, setUpperJawSelected] = React.useState<boolean>(
    false,
  );
  const [mouseUpperJaw, setMouseUpperJaw] = React.useState<boolean>(false);
  const [mouseLowerJaw, setMouseLowerJaw] = React.useState<boolean>(false);
  const [lowerJawSelected, setLowerJawSelected] = React.useState<boolean>(
    false,
  );

  React.useEffect(() => {
    let teethMap = new Map();
    Static.getJaws().map((tooth: number) =>
      teethMap.set(tooth, isPreSelected(tooth)),
    );

    setItemsMap(teethMap);

    setUpperJawSelected(props.selectAllTeeth ? true : false);
    setLowerJawSelected(props.selectAllTeeth ? true : false);
  }, []);

  // if selected came via props
  React.useEffect(() => {
    if (props.preSelected) {
      if (makeArray(itemsMap).length !== props.preSelected.length) {
        let teethMapTemp = new Map(itemsMap);
        Static.getJaws().map((tooth: number) =>
          teethMapTemp.set(tooth, isPreSelected(tooth)),
        );

        setItemsMap(teethMapTemp);
      }
    }
  }, [props.preSelected]);

  React.useEffect(() => {
    let items = makeArray(itemsMap);
    props.choice && props.choice(items);
  }, [itemsMap]);

  /**
   * COnvert Map to Array
   * @param itemsMapTemp
   */
  const makeArray = (itemsMapTemp: Map<number, boolean>) => {
    let items = [] as number[];
    itemsMap.forEach((value, key) => {
      if (value) items.push(key);
    });
    return items;
  };

  /**
   * Check if given tooth is initially selected
   * @param tooth
   */
  const isPreSelected = (tooth: number) => {
    if (props.preSelected && props.preSelected.length > 0) {
      if (props.preSelected.indexOf(tooth) !== -1) return true;
    }

    if (props.selectAllTeeth) {
      if (Static.getJaws().indexOf(tooth) !== -1) return true;
    }

    return false;
  };

  const handleJaw = (select: boolean, scope?: 'upper' | 'lower') => {
    let itemsTemp = new Map(itemsMap);
    Static.getJaws(scope).map(
      (tooth: number) =>
        (tooth + '').indexOf('8') === -1 && itemsTemp.set(tooth, select),
    );
    setItemsMap(itemsTemp);
  };

  //if select jaw has changed
  React.useEffect(() => {
    if (typeof props.selectAllTeeth !== 'undefined') {
      handleJaw(props.selectAllTeeth);
      setLowerJawSelected(props.selectAllTeeth);
      setUpperJawSelected(props.selectAllTeeth);
    }
  }, [props.selectAllTeeth]);

  const handleItem = (toothNumber: number) => {
    let itemsTemp = new Map(itemsMap);
    const selected = itemsTemp.get(toothNumber);
    itemsTemp.set(toothNumber, !selected);
    // props.choice && props.choice(itemsTemp);
    setItemsMap(itemsTemp);
  };

  const toothSection = (scope: number[], displayTop?: boolean) => {
    let toothRow = (
      <tr className={classes.toothRow}>
        {scope.map((rowIndex: number, index: number) => (
          <td
            key={rowIndex + '' + index + 'toothRow'}
            onClick={() => !props.disabled && handleItem(rowIndex)}
            className={`${
              itemsMap.get(rowIndex)
                ? classes.selected
                : !props.disabled && classes.hover
                ? classes.hover
                : null
            } ${!props.disabled && classes.pointer}`}
          >
            <Icon
              icon="tooth"
              className={classes.icon}
              flip={!displayTop ? 'vertical' : undefined}
            />
          </td>
        ))}
      </tr>
    );

    let numberRow = (
      <tr className={classes.numberRow}>
        {scope.map((rowIndex: number, index: number) => (
          <td key={rowIndex + '' + index + 'numberRow'}>
            <Typography>{rowIndex}</Typography>
          </td>
        ))}
      </tr>
    );

    return (
      <div className={`${classes.tableWrapper} table-responsive`}>
        <table className="table text-center">
          {displayTop ? (
            <tbody>
              {numberRow}
              {toothRow}
            </tbody>
          ) : (
            <tbody>
              {toothRow}
              {numberRow}
            </tbody>
          )}
        </table>
      </div>
    );
  };

  return (
    <React.Fragment>
      <div
        className="position-relative"
        style={{
          marginTop: `${!props.disabled ? '2rem' : 'inherit'}`,
        }}
        onMouseEnter={() => setMouseUpperJaw(true)}
        onMouseLeave={() => setMouseUpperJaw(false)}
      >
        <div className={classes.checkboxTop}>
          {mouseUpperJaw && !props.disabled && (
            <Checkbox
              className="pb-0 pt-0"
              label={
                upperJawSelected
                  ? Translation.getTranslation('disselect_upper_jaw')
                  : Translation.getTranslation('select_upper_jaw')
              }
              checked={upperJawSelected}
              onChange={(e: boolean) => {
                setUpperJawSelected(e);
                handleJaw(e, 'upper');
              }}
            />
          )}
        </div>
        <div className="row">
          <div className="col">
            {toothSection(Static.getTeethBlocks()[0].numbers)}
          </div>
          <div className="col">
            {toothSection(Static.getTeethBlocks()[1].numbers)}
          </div>
        </div>
      </div>
      <div
        className="position-relative"
        onMouseEnter={() => setMouseLowerJaw(true)}
        onMouseLeave={() => setMouseLowerJaw(false)}
      >
        <div className="row">
          <div className="col">
            {toothSection(Static.getTeethBlocks()[2].numbers, true)}
          </div>
          <div className="col">
            {toothSection(Static.getTeethBlocks()[3].numbers, true)}
          </div>
        </div>
        <div className={classes.checkboxBottom}>
          {mouseLowerJaw && !props.disabled && (
            <Checkbox
              className="pb-0 pt-0"
              label={
                lowerJawSelected
                  ? Translation.getTranslation('disselect_lower_jaw')
                  : Translation.getTranslation('select_lower_jaw')
              }
              checked={lowerJawSelected}
              onChange={(e: boolean) => {
                setLowerJawSelected(e);
                handleJaw(e, 'lower');
              }}
            />
          )}
        </div>
      </div>
    </React.Fragment>
  );
}
