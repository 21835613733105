import { Divider, makeStyles, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import RequestManager from '../../manager/request.manager';
import Helper, { checkUserRole } from '../../services/helper';
import { grey } from '../../services/styles';
import Translation from '../../services/translation';
import Badge from '../Shared/Badge';
import Modal from '../Shared/Modal';
import TabNav from '../Shared/Tabs';
import CustomFieldsValuesList from '../User/CustomFieldsValuesList';

interface ProjectInformation {
  project: any;
  user: any;
}

const useStyles = makeStyles((theme) => ({
  root: {
    border: `1px solid ${grey}`,
  },
  internalBadge: {
    position: 'absolute',
    right: 5,
    top: -9,
    zIndex: 666,
  },
}));

function ProjectInformation(props: ProjectInformation) {
  const classes = useStyles();
  const [statusModalOpen, setStatusModalOpen] = useState(false);
  const [ratingModalOpen, setRatingModalOpen] = useState(false);
  const [customFields, setCustomFields] = useState<any>([]);

  useEffect(() => {
    if (statusModalOpen === true) {
      Helper.gaRegisterModalView('project_status_history_view');
    }
  }, [statusModalOpen]);

  useEffect(() => {
    getCustomFields();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getCustomFields = async () => {
    let customFieldsTemp = await RequestManager.getProjectCustomFields(
      props.project.id,
    );
    setCustomFields(customFieldsTemp);
  };

  let renderRatingModal = () => {
    return (
      <Modal
        isOpen={ratingModalOpen}
        toggleModal={() => setRatingModalOpen(false)}
        header={Translation.getTranslation('rating')}
        hasNoClose
      >
        <React.Fragment>
          {props.project.ratings.map((rating: any, index: number) => (
            <React.Fragment key={rating.id}>
              <div
                className="d-flex align-items-center"
                key={rating.id + index}
              >
                <strong>{rating.value}*</strong>
                {rating.user && rating.user.id && (
                  <span className="ml-1">{`${Translation.getTranslation(
                    'by',
                  )} ${rating.user?.firstName} ${rating.user?.lastName}`}</span>
                )}
                <Badge className="ml-2" color="primary">
                  {Helper.getDate(rating.updatedAt, true)}{' '}
                </Badge>
              </div>
              {rating.comment ? <em>{rating.comment}</em> : ''}
              {typeof props.project.ratings[index + 1] !== 'undefined' ? (
                <Divider className="my-2" />
              ) : (
                ''
              )}
            </React.Fragment>
          ))}
        </React.Fragment>
      </Modal>
    );
  };
  let renderStatusModal = () => {
    return (
      <Modal
        isOpen={statusModalOpen}
        toggleModal={() => setStatusModalOpen(false)}
        header={Translation.getTranslation('project_status')}
        hasNoClose
      >
        <React.Fragment>
          {props.project.projectStatus.map((status: any, index: number) => (
            <div className="d-flex align-items-center" key={status + index}>
              <strong
                className={
                  typeof props.project.projectStatus[index + 1] !== 'undefined'
                    ? 'text-muted'
                    : ''
                }
              >
                {Translation.getTranslation('status_' + status.type)}
              </strong>
              {status.user && status.user.id && (
                <span className="ml-1">{`${Translation.getTranslation('by')} ${
                  status.user?.firstName
                } ${status.user?.lastName}`}</span>
              )}
              <Badge className="ml-2" color="primary">
                {Helper.getDate(status.updatedAt, true)}{' '}
              </Badge>
            </div>
          ))}
        </React.Fragment>
      </Modal>
    );
  };

  const renderListItem = (item: { label: string; content: any }) => {
    return (
      <div>
        <Typography className="font-weight-bold" component="span">
          {item.label}:
        </Typography>
        <Typography component="span" className="pull-right text-muted ml-2">
          {item.content}
        </Typography>
      </div>
    );
  };

  const renderGeneralInformation = () => {
    return (
      <React.Fragment>
        {renderListItem({
          label: Translation.getTranslation('order_number'),
          content: props.project.orderNumber,
        })}

        {/* only for admins */}
        {checkUserRole(props.user).isAdmin() &&
          renderListItem({
            label: Translation.getTranslation('customer'),
            content: (
              <Link to={`/user/${props.project.userId}`}>
                {`${props.project?.user?.firstName} ${props.project?.user?.lastName}, ${props.project?.user?.company}`}
              </Link>
            ),
          })}
        {renderListItem({
          label: Translation.getTranslation('order_date'),
          content: Helper.getDate(props.project.sendAt, true),
        })}
        {props.project.closedAt &&
          renderListItem({
            label: Translation.getTranslation('close_date'),
            content: Helper.getDate(props.project.closedAt, true),
          })}
        {(props.user.role !== 'designer' || props.project.isInternal) &&
          renderListItem({
            label: Translation.getTranslation('patient_name'),
            content: props.project.patientName,
          })}
        {renderListItem({
          label: Translation.getTranslation(
            props.user.role === 'designer' ? 'customer' : 'designer',
          ),
          content: props.project.isInternal
            ? props.project[
                props.user.role === 'designer' ? 'user' : 'designer'
              ]?.company || '-'
            : Translation.getTranslation('myDenti'),
        })}

        {props.project.projectWorkTypes &&
          props.project.projectWorkTypes.length > 0 &&
          renderListItem({
            label: Translation.getTranslation('type_of_work'),
            content:
              props.project.projectWorkTypes &&
              props.project.projectWorkTypes.length > 0 &&
              props.project.projectWorkTypes.map(
                (type: string, index: number) => (
                  <Badge
                    key={type + index + 'Badge'}
                    color="primary"
                    className={
                      typeof props.project.projectWorkTypes[index + 1] !==
                      'undefined'
                        ? 'mr-1'
                        : ''
                    }
                  >
                    {Translation.getTranslation(`project_work_type_${type}`)}
                  </Badge>
                ),
              ),
          })}

        {props.project.systemServices &&
          props.project.systemServices.length > 0 &&
          renderListItem({
            label: Translation.getTranslation('system_services'),
            content:
              props.project.systemServices &&
              props.project.systemServices.length > 0 &&
              props.project.systemServices.map(
                (type: string, index: number) => (
                  <Badge
                    key={type + index + 'Badge'}
                    color="secondary"
                    className={
                      typeof props.project.systemServices[index + 1] !==
                      'undefined'
                        ? 'mr-1'
                        : ''
                    }
                  >
                    {type[('label_' + Translation.getLanguage()) as any]}
                  </Badge>
                ),
              ),
          })}

        {props.project.isDentalSplint &&
          renderListItem({
            label: Translation.getTranslation('splint'),
            content: (
              <Badge color="error">
                {Helper.getSplintTranslation(props.project.splintType)}
              </Badge>
            ),
          })}

        {checkUserRole(props.user).isAdmin() &&
          props.project.designerId &&
          renderListItem({
            label: Translation.getTranslation('assigned_designer'),
            content: (
              <Link to={`/user/${props.project.designerId}`}>
                {`${props.project?.designer?.firstName} ${props.project?.designer?.lastName}, ${props.project?.designer?.company}`}
              </Link>
            ),
          })}

        {renderListItem({
          label: Translation.getTranslation('current_status'),
          content: (
            <span
              onClick={() =>
                checkUserRole(props.user).isAdmin() && setStatusModalOpen(true)
              }
              style={
                checkUserRole(props.user).isAdmin() ? { cursor: 'pointer' } : {}
              }
            >
              {Helper.renderStatusTextForRole(
                props.project.currentStatus,
                props.user.role,
              )}
            </span>
          ),
        })}

        {checkUserRole(props.user).isAdmin() &&
          props.project.ratings &&
          props.project.ratings.length > 0 &&
          renderListItem({
            label: Translation.getTranslation('rating'),
            content: (
              <span
                onClick={() => setRatingModalOpen(true)}
                style={{ cursor: 'pointer' }}
              >
                {Helper.calculateRatings(props.project.ratings) + '*'}
              </span>
            ),
          })}

        {checkUserRole(props.user).isAdmin() && renderStatusModal()}
        {checkUserRole(props.user).isAdmin() && renderRatingModal()}
      </React.Fragment>
    );
  };

  const renderCustomFields = () => {
    return (
      <CustomFieldsValuesList
        data={customFields}
        projectId={props.project.id}
      />
    );
  };

  return (
    <div className={classes.root}>
      <TabNav
        padding={1}
        fullWidth
        transparent
        elements={[
          {
            label: Translation.getTranslation('general'),
            jsx: renderGeneralInformation(),
          },
          {
            label: Translation.getTranslation('custom_fields'),
            jsx: renderCustomFields(),
          },
        ]}
      />
    </div>
  );
}

export default ProjectInformation;
