import { makeStyles } from '@material-ui/core';
import * as React from 'react';
import { TwitterPicker } from 'react-color';
import { useRecoilValue } from 'recoil';
import myDentiLogo from '../../images/myDentiLogoTransPlain.png';
import UserInterface from '../../interfaces/user.interface';
import RequestManager from '../../manager/request.manager';
import UserManager from '../../manager/user.manager';
import { Alert } from '../../services/alert';
import { userState } from '../../services/atoms';
import Static from '../../services/static';
import Styles, {
  grey,
  primary,
  primaryDefault,
  secondary,
  secondaryDefault,
} from '../../services/styles';
import Translation from '../../services/translation';
import Button from '../Shared/Button';
import ComponentHeading from '../Shared/ComponentHeading';
import { DropZone } from '../Shared/DropZone';

function CustomizationSection(props: {}) {
  const [file, setFile] = React.useState<File[]>([]);
  const [currentImage, setCurrentImage] = React.useState('');
  const [primaryColor, setPrimaryColor] = React.useState(primary);
  const [secondaryColor, setSecondaryColor] = React.useState(secondary);
  const user = useRecoilValue(userState) as UserInterface;
  const useStyles = makeStyles((_theme) => ({
    root: {
      maxWidth: '100%',
      // backgroundColor: 'white',
      border: '1px solid ' + grey,
      padding: '1rem',
    },
    colorPreview: {
      height: '5rem',
      width: '5rem',
    },
    badge: {
      backgroundColor: secondaryColor,
      marginRight: '1.5rem !important',
    },
    previewArea: {
      backgroundColor: primaryColor,
      height: '64px',
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',

      marginBottom: '1rem',
    },
    image: {
      marginLeft: '1.5rem',
      maxWidth: '8rem',
      maxHeight: '60px',
      minWidth: '5rem',
    },
  }));
  const classes = useStyles();

  const saveSettings = async () => {
    const settings = await RequestManager.putSettings({
      primaryColor: primaryColor === primaryDefault ? '' : primaryColor,
      secondaryColor: secondaryColor === secondaryDefault ? '' : secondaryColor,
    });
    const image = await uploadLogo();
    if (settings && image) {
      Styles.changeColor('primaryColor', settings.primaryColor);
      Styles.changeColor('secondaryColor', settings.secondaryColor);
      await Alert.open(
        'success',
        Translation.getTranslation('success'),
        Translation.getTranslation('settings_saved_success'),
      );
      window.location.href =
        Static.getPageByName('user_settings').url + '/customization';
    }
  };

  const uploadLogo = async () => {
    if (file?.length > 0) {
      const formData = new FormData();
      formData.append('file', file[0]);
      return await RequestManager.uploadLogo(formData);
    }
    return true;
  };

  // React.useEffect(() => {
  //   renderImage();
  // }, []);
  React.useEffect(() => {
    renderImage();
  }, [file]);

  const renderColorPicker = (data: {
    label: string;
    color: string;
    defaultColor: string;
    storageName: string;
    colorPalette: string[];
    setter: any;
  }) => {
    return (
      <ComponentHeading
        value={Translation.getTranslation(data.label)}
        additionPullRight
        addition={
          data.color !== data.defaultColor && (
            <React.Fragment>
              <Button text onClick={() => data.setter(data.defaultColor)}>
                {Translation.getTranslation('reset')}
              </Button>
            </React.Fragment>
          )
        }
      >
        <div className="d-flex justify-content-between align-content-center">
          <div
            className={classes.colorPreview}
            style={{ backgroundColor: data.color }}
          />
          <TwitterPicker
            triangle="hide"
            onChangeComplete={(e) => {
              if (e?.hex) {
                // test if valid hex code
                if (/^#([0-9A-F]{3}){1,2}$/i.test(e?.hex)) {
                  data.setter(e.hex);
                }
              }
            }}
            colors={data.colorPalette}
            color={data.color}
          />
        </div>
      </ComponentHeading>
    );
  };

  const renderImage = async () => {
    const logoUrl = await UserManager.returnUserImage(user || undefined);

    setCurrentImage(
      file?.[0]
        ? URL.createObjectURL(file[0])
        : logoUrl
        ? logoUrl
        : myDentiLogo,
    );
  };

  const resetImage = async () => {
    if (!user?.settings?.logoFilename) {
      setFile([]);
    } else {
      const response = await RequestManager.deleteLogo();
      if (response) {
        setCurrentImage(myDentiLogo);
      }
    }
  };

  return (
    <React.Fragment>
      <ComponentHeading
        value={Translation.getTranslation('customization')}
        explain={Translation.getTranslation('customization_explain')}
        additionPullRight
        addition={
          <Button
            color="primary"
            onClick={() => {
              saveSettings();
            }}
          >
            {Translation.getTranslation('apply_settings')}
          </Button>
        }
      >
        <div className={'row'}>
          <div className={'col-xl-6 ' + classes.root}>
            <ComponentHeading
              additionPullRight
              addition={
                currentImage !== myDentiLogo && (
                  <React.Fragment>
                    <Button text onClick={() => resetImage()}>
                      {Translation.getTranslation('reset')}
                    </Button>
                  </React.Fragment>
                )
              }
              noMargin
              value={Translation.getTranslation('company_logo')}
            >
              <div className="d-flex align-items-center justify-content-between mb-2">
                <img
                  src={currentImage}
                  alt="logo"
                  style={{ maxHeight: '5rem' }}
                  className={'img-fluid mt-2'}
                />
                <div>
                  <DropZone
                    accept="image/*"
                    buttonLabel={Translation.getTranslation('select_logo')}
                    noMargin
                    buttonOnly
                    onDrop={(e: any) => {
                      setFile(e);
                    }}
                    multiple={false}
                  />
                </div>
              </div>
            </ComponentHeading>
            {renderColorPicker({
              label: 'primary_color',
              color: primaryColor,
              defaultColor: primaryDefault,
              storageName: 'primaryColor',
              colorPalette: [
                primaryDefault,
                '#0a043c',
                '#222831',
                '#11698e',
                '#108CA1',
                '#6a2c70',
                '#16697a',
              ],
              setter: setPrimaryColor,
            })}
            {renderColorPicker({
              label: 'secondary_color',
              color: secondaryColor,
              defaultColor: secondaryDefault,
              storageName: 'secondaryColor',
              colorPalette: [
                secondaryDefault,
                '#fbe6d4',
                '#76ead7',
                '#dae1e7',
                '#91d18b',
                '#a2d5f2',
                '#ffbb91',
              ],
              setter: setSecondaryColor,
            })}
          </div>
          <div className="col-xl-6">
            <ComponentHeading value={Translation.getTranslation('preview')}>
              <div className="w-100">
                <div className={classes.previewArea}>
                  <img
                    src={currentImage}
                    alt="logo"
                    className={'img-fluid ' + classes.image}
                  />
                  {}
                  <Button color="secondary" className={classes.badge}>
                    {Translation.getTranslation('secondary_color')}
                  </Button>
                </div>
              </div>
            </ComponentHeading>
          </div>
        </div>
      </ComponentHeading>
    </React.Fragment>
  );
}

export default CustomizationSection;
