import { Typography } from '@material-ui/core';
import * as React from 'react';
import { Link } from 'react-router-dom';
import NotFoundImage from '../../images/intern/not_found.svg';
import Static from '../../services/static';
import Translation from '../../services/translation';
import Button from './Button';

interface Props {
  match?: any;
  location?: any;
  history?: any;
}

export function Error404(props: Props) {
  return (
    <div className="row">
      <div className="col">
        <div className="d-flex justify-content-center flex-row align-items-center">
          <div className="p-2">
            <Typography variant="h1" component="h1">
              <img
                className="img-fluid"
                style={{ maxHeight: '10rem' }}
                src={NotFoundImage}
                alt="404 not found"
              />
            </Typography>
          </div>
          <div className="p-2 border-left">
            <div className="row ">
              <div className="col">
                <Typography variant="h4" component="h4">
                  {Translation.getTranslation('page_not_found')}
                </Typography>

                <Typography className="mt-2">
                  <Link
                    to={Static.getPageByName('dashboard').url}
                    className="text-white"
                  >
                    <Button color="primary">
                      {Translation.getTranslation('back_home')}
                    </Button>
                  </Link>
                </Typography>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
