import { atom } from 'recoil';

export const loadingState = atom({
  key: 'loadingState',
  default: true,
});

export const userState = atom({
  key: 'userState',
  default: undefined as any,
});

// export const waitingLoadingState = selector({
//   key: 'waitingLoadingState',
//   get: ({ get }) => {
//     // const loading = get(loadingState);
//       return get(loadingState);
//   },
// });
