import UserInterface from '../interfaces/user.interface';

export default class Translation {
  static getTranslation(keyword: string) {
    let translations = require('./languages/translations.json');
    // let translation = translations["no_translation"][this.getLanguage()];
    let translation = keyword;
    try {
      translation = translations[keyword][this.getLanguage()];
    } catch {}

    return translation;
  }

  static getLanguage(): UserInterface['language'] {
    let lang = localStorage.getItem('lang') as UserInterface['language'];
    if (lang) return lang;
    else return 'de';
  }

  static setLanguage(lang: UserInterface['language']) {
    localStorage.setItem('lang', lang);
  }
}
