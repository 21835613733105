import { Typography } from '@material-ui/core';
import * as React from 'react';
import RequestManager from '../../manager/request.manager';
import Helper from '../../services/helper';
import Translation from '../../services/translation';
import Button from '../Shared/Button';
import Modal from '../Shared/Modal';
import NotificationBig from '../Shared/NotificationBig';
import Spinner from '../Shared/Spinner';

interface Props {}

export default function NotificationsModal(props: Props) {
  const [isOpen, setIsOpen] = React.useState<boolean>(false);
  const [loading, setLoading] = React.useState<boolean>(true);
  const [page, setPage] = React.useState<number>(0);
  const [notifications, setNotifications] = React.useState<any>([]);
  const [notificationsCount, setNotificationsCount] = React.useState<any>([]);

  // const toggleModal = () => {
  //   const loadNotification = async () => {
  //     if (!isOpen) {
  //       Helper.gaRegisterModalView('notifications');
  //       let notificationsTemp = await loadNotifications(0);

  //       setNotifications(notificationsTemp);
  //       setLoading(false);
  //     } else {
  //       setPage(0);
  //     }
  //   };

  //   loadNotification();
  //   setIsOpen(!isOpen);
  // };

  React.useEffect(() => {
    const loadNotification = async () => {
      if (isOpen) {
        Helper.gaRegisterModalView('notifications');
        let notificationsTemp = await loadNotifications(0);

        setNotifications(notificationsTemp);
        setLoading(false);
      } else {
        setPage(0);
      }
    };

    loadNotification();
  }, [isOpen]);

  const getNotificationCount = async () => {
    let notificationCountTemp = await RequestManager.getNewNotificationCount();
    setNotificationsCount(notificationCountTemp ? notificationCountTemp : 0);
  };

  const loadNotifications = async (page: number) => {
    return await RequestManager.getNotifications({ page });
  };

  React.useEffect(() => {
    getNotificationCount();
    const interval = setInterval(() => getNotificationCount(), 60000);
    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const notificationChecked = (id: string) => {
    let notificationsTemp = notifications as any;
    if (notificationsTemp) {
      let index = notificationsTemp?.rows?.findIndex(
        (notification: any) => notification.id === id,
      );
      if (index !== -1) {
        notificationsTemp.rows[index].checkedAt = new Date();
        setNotifications(notificationsTemp);
      }
    }
  };

  const nextPage = async (newPage: number) => {
    setPage(newPage);
    let newData = (await loadNotifications(newPage)) as any;
    let oldData = { ...notifications };
    if (newData && newData.rows && newData.rows.length > 0) {
      oldData.count = newData.count;
      oldData.rows.push(...newData.rows);
      setNotifications(oldData);
      setLoading(false);
    }
  };

  const renderModal = () => {
    return (
      <Modal
        isOpen={isOpen}
        toggleModal={() => setIsOpen(!isOpen)}
        header={Translation.getTranslation('notifications')}
      >
        <React.Fragment>
          {loading ? (
            <div style={{ height: '10rem' }}>
              <Spinner />
            </div>
          ) : (
            <div
              style={{ maxHeight: '50vh', overflowY: 'scroll' }}
              className="w-100"
            >
              {notifications && notifications.rows.length > 0 ? (
                <div className="text-center">
                  {notifications.rows.map((notification: any) => (
                    <NotificationBig
                      key={notification.id}
                      toggleModal={() => setIsOpen(!isOpen)}
                      notification={notification}
                      notificationChecked={(id: string) =>
                        notificationChecked(id)
                      }
                      reloadData={() => {
                        getNotificationCount();
                      }}
                    />
                  ))}
                  {notifications &&
                    notifications.rows.length < notifications.count && (
                      <Button
                        color={'primary'}
                        className="mb-4"
                        size="sm"
                        onClick={() => {
                          nextPage(page + 1);
                        }}
                      >
                        {Translation.getTranslation('load_more')}
                      </Button>
                    )}
                </div>
              ) : (
                <span>{Translation.getTranslation('no_notification')}</span>
              )}
            </div>
          )}
        </React.Fragment>
      </Modal>
    );
  };

  return (
    <React.Fragment>
      <Button
        outline
        className="text-white border-white"
        onClick={() => setIsOpen(true)}
        icon={'bell'}
      >
        <div className="d-flex align-items-center">
          <span>{notificationsCount || ''}</span>
          <Typography
            component="span"
            variant="button"
            className="d-none d-lg-block ml-1"
          >
            {Translation.getTranslation(
              `notification${notificationsCount !== 1 ? 's' : ''}`,
            )}
          </Typography>
        </div>
      </Button>
      {renderModal()}
    </React.Fragment>
  );
}
