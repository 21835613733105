import * as React from 'react';
import { Link } from 'react-router-dom';
import { ProjectTable } from '../../components/Project/ProjectTable';
import Button from '../../components/Shared/Button';
import ComponentHeading from '../../components/Shared/ComponentHeading';
import PageHeadingRow from '../../components/Shared/PageHeadingRow';
import ConnectionInvitesTable from '../../components/User/ConnectionInvitesTable';
import WorkDoneImage from '../../images/intern/work_done.svg';
import UserInterface from '../../interfaces/user.interface';
import UserManager from '../../manager/user.manager';
import { checkUserRole } from '../../services/helper';
import Static from '../../services/static';
import Translation from '../../services/translation';

interface Props {
  match?: any;
  location?: any;
  history?: any;
  user: UserInterface;
  widgets: any;
}

export function NonAdminDashboard(props: Props) {
  const [pageWidgets, setPageWidgets] = React.useState<string[]>([]);
  const [invitesCount, setInvitesCount] = React.useState<number | undefined>(
    undefined,
  );

  const existingWidget = async (name: string) => {
    let index = pageWidgets.indexOf(name);
    if (index === -1) setPageWidgets((oldData: any) => [...oldData, name]);
  };

  return (
    <React.Fragment key={props.user.id + 'dashboard'}>
      <PageHeadingRow siteTitle={Static.getPageByName('dashboard').siteTitle}>
        {checkUserRole(props.user).isUser() && (
          <React.Fragment>
            <Link to={Static.getPageByName('order_create').url}>
              <Button color={'primary'} icon={'plus-square'}>
                {Translation.getTranslation('add_order')}
              </Button>
            </Link>
            {UserManager.checkIfPayedForService(props.user, {
              service: 'open_archive',
            }) && (
              <Link to={Static.getPageByName('archive').url}>
                <Button outline color="default" icon="box-open">
                  {Translation.getTranslation('archive')}
                </Button>
              </Link>
            )}
          </React.Fragment>
        )}
      </PageHeadingRow>

      <div className="row">
        <div
          className="col-xl-6"
          style={invitesCount === 0 ? { display: 'none' } : {}}
        >
          <ConnectionInvitesTable
            dataCount={(count: number) => {
              setInvitesCount(count);
            }}
            user={props.user}
          />
        </div>
        {props.widgets.map((widget: any) => (
          <div
            className={`col-xl-${widget.xl} col-lg-${widget.lg}`}
            key={'userDashboardWidget' + widget.name}
            style={
              pageWidgets.indexOf(widget.name) === -1
                ? { display: 'none' }
                : { display: 'block' }
            }
          >
            <ProjectTable
              bulkMode={widget.bulkMode}
              conditions={widget.conditions ? widget.conditions : undefined}
              title={Translation.getTranslation(widget.name)}
              dataExisting={async (dataExisting: boolean) => {
                if (dataExisting) existingWidget(widget.name);
              }}
              status={widget.status}
              me={props.user}
              color={widget.color}
              history={props.history}
            />
          </div>
        ))}
      </div>
      {!pageWidgets ||
        (pageWidgets.length === 0 && (
          <div className="d-flex flex-column align-items-center">
            <ComponentHeading
              size="h4"
              value={Translation.getTranslation('no_order_open')}
            ></ComponentHeading>
            <img
              className="img-fluid"
              alt="work done"
              style={{ maxHeight: '20rem' }}
              src={WorkDoneImage}
            />
          </div>
        ))}
    </React.Fragment>
  );
}
