import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import ReactGA from 'react-ga';
import toastr from 'toastr';
import UserManager from '../manager/user.manager';
import Config from './config';
import Translation from './translation';

const handleGAExeption = (statusCode: string, message: String) => {
  ReactGA.exception({
    description: `${statusCode}${message ? ':' + message : ''}`,
    fatal: true,
  });
};

const handleToast = (
  message: string,
  title: 'forbidden' | 'unauthorized' | 'bad_request' | 'error' | 'conflict',
) => {
  toastr.error(
    message.includes('server_error_')
      ? Translation.getTranslation(message)
      : '',
    Translation.getTranslation(title),
  );
};

const handle400 = (resp: AxiosError['response']) => {
  handleToast(resp?.data.message || '', 'bad_request');
};

const handle401 = (resp: AxiosError['response']) => {
  if (resp?.data.message.includes('server_error_')) {
    handleToast(resp?.data.message || '', 'unauthorized');
  }
  localStorage.removeItem('jwt');
  // window.location.href = Static.getPageByName('login').url;
};

const handle403 = (resp: AxiosError['response']) => {
  handleToast(resp?.data.message || '', 'forbidden');
};

const handle404 = (resp: AxiosError['response']) => {
  handleToast(resp?.data.message || '', 'error');
};

const handle409 = (resp: AxiosError['response']) => {
  handleToast(resp?.data.message || '', 'conflict');
};

const handleErrorStatus = (resp: AxiosError['response']) => {
  handleToast(resp?.data.message || '', 'forbidden');
  // throw new Error(
  //   Helper.handleErrorMessage(
  //     responseBody.message ? responseBody.message : 'Invalid request',
  //   ),
  // );
};

const request = async (
  options: AxiosRequestConfig,
  settings?: {
    returnFullError?: boolean;
    plainResponse?: boolean;
    responseType?: AxiosRequestConfig['responseType'];
  },
) => {
  const handleSuccess = (response: AxiosResponse) => {
    return settings?.plainResponse ? response : response.data;
  };

  const handleError = (error: AxiosError, returnFullResponse?: boolean) => {
    if (error?.response?.status) {
      handleGAExeption(
        error.response.status + '',
        error.response?.data?.message || '',
      );

      let customErrors = [400, 401, 403, 404, 409];
      if (
        error.response.status > 299 &&
        customErrors.indexOf(error.response.status) === -1
      )
        handleErrorStatus(error.response);
      else {
        if (error.response.status === 400) handle400(error.response);
        else if (error.response.status === 401) handle401(error.response);
        else if (error.response.status === 403) handle403(error.response);
        else if (error.response.status === 404) handle404(error.response);
        else if (error.response.status === 409) handle409(error.response);
      }
    }
    // return nothing if error - all errors need to be handled here
    if (!returnFullResponse) return undefined;
    else return error.response?.data || undefined;
    // return Promise.reject(error);
  };

  const service = axios.create({
    baseURL: Config.getAPIURL(),
    headers: {
      Authorization: `Bearer ${UserManager.getToken()}`,
    },
    responseType: settings?.responseType || undefined,
  });
  return service(options)
    .then(handleSuccess)
    .catch((e) => handleError(e, settings?.returnFullError ? true : false));
};
export default request;
