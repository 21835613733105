import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import React, { useEffect } from 'react';
import toastr from 'toastr';
import UserInterface, { InviteTypes } from '../../interfaces/user.interface';
import RequestManager from '../../manager/request.manager';
import { lightGrey } from '../../services/styles';
import Translation from '../../services/translation';
import Button from '../Shared/Button';
import DataTable from '../Shared/DataTable';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      // maxWidth: 360,
      backgroundColor: lightGrey,
    },
  }),
);

interface Props {
  user: UserInterface;
  // internalDesigners: UserInterface[];
  dataCount?: (count: number) => void;
}

export default function ConnectionInvitesTable(props: Props) {
  const [reloadCounter, setReloadCounter] = React.useState<number | undefined>(
    undefined,
  );
  const [dataCount, setDataCount] = React.useState<number>(0);

  const handleReload = () => {
    setReloadCounter(reloadCounter ? reloadCounter + 1 : 1);
  };

  useEffect(() => {
    props.dataCount && props.dataCount(dataCount);
  }, [dataCount]);

  const changeStatus = async (data: {
    id: string;
    status: InviteTypes['type'];
  }) => {
    let newDataSet = await RequestManager.patchMyConnections(data);
    if (newDataSet) {
      toastr.success(
        Translation.getTranslation(
          data.status === 'accepted' ? 'invite_accepted' : 'invite_declined',
        ),
      );
      handleReload();
    }
  };

  return (
    <DataTable
      limit={10}
      noPagination
      returnData={(data: any) => setDataCount(data.count)}
      title={Translation.getTranslation('open_invites')}
      manager={(options: any) => {
        return RequestManager.getMyConnections({
          status: 'invited',
          initiator: props.user.role === 'user' ? 'designer' : 'user',
        });
      }}
      doNotSearch
      reloadData={reloadCounter}
      columns={[
        {
          label: Translation.getTranslation('company'),
          type: {
            id: 'individual',
            individualObject: (data: any) => {
              const entity = props.user.role === 'user' ? 'designer' : 'user';
              return `${data[entity].company} (${data[entity].firstName} ${data[entity].lastName})`;
            },
          },
        },
        {
          label: Translation.getTranslation('invite_date'),
          type: { id: 'date', key: 'createdAt' },
        },
        {
          label: Translation.getTranslation('action'),
          type: {
            id: 'individual',
            individualObject: function renderButton(data: any) {
              return (
                <React.Fragment>
                  <Button
                    icon="check"
                    onClick={() =>
                      changeStatus({ id: data.id, status: 'accepted' })
                    }
                    tooltip={Translation.getTranslation('accept')}
                    size="sm"
                    color="success"
                  />
                  <Button
                    icon="times"
                    onClick={() =>
                      changeStatus({ id: data.id, status: 'declined' })
                    }
                    tooltip={Translation.getTranslation('decline')}
                    size="sm"
                    color="danger"
                  />
                </React.Fragment>
              );
            },
          },
        },
      ]}
    />
  );
}
