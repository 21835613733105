import { IconProp } from '@fortawesome/fontawesome-svg-core';
import {
  Avatar,
  Divider,
  IconButton,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListSubheader,
  Tooltip,
} from '@material-ui/core';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import React, { useEffect, useState } from 'react';
import toastr from 'toastr';
import UserInterface, {
  Invites,
  InviteTypes,
} from '../../interfaces/user.interface';
import RequestManager from '../../manager/request.manager';
import { Alert } from '../../services/alert';
import Helper, { checkUserRole } from '../../services/helper';
import { danger, success, warning } from '../../services/styles';
import Translation from '../../services/translation';
import Button from '../Shared/Button';
import Icon from '../Shared/Icon';
import Input from '../Shared/Input';
import NoDataComponent from '../Shared/NoDataComponent';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      backgroundColor: 'transparent',
    },
    danger: { backgroundColor: danger },
    warning: { backgroundColor: warning },
    success: { backgroundColor: success },
  }),
);

interface Props {
  user: UserInterface;
  disabled?: boolean;
  invites?: Invites[];
  connections: UserInterface[];
  hideInput?: boolean;
}

export default function MyConnectionsList(props: Props) {
  const [data, setData] = useState<any[]>([]);
  const [invites, setInvites] = useState<any[]>([]);
  const [email, setEmail] = useState<string>('');
  const [disabled, setDisabled] = useState<boolean>(false);
  const classes = useStyles();

  useEffect(() => {
    setData(props.connections);
  }, [props.connections]);

  useEffect(() => {
    props.invites && setInvites(props.invites);
  }, [props.invites]);

  const saveNewConnection = async () => {
    setDisabled(true);
    let saved = await RequestManager.postMyConnections(email);
    if (saved?.id) {
      let dataTemp = [...data];
      dataTemp.push(saved);
      setData(dataTemp);
      setEmail('');
      Alert.open(
        'success',
        Translation.getTranslation('connection_requested'),
        Translation.getTranslation('connection_requested_explain'),
      );
    } else if (saved.statusCode === 404) {
      await Alert.confirm({
        type: 'warning',
        title: Translation.getTranslation('user_not_found'),
        text: Translation.getTranslation('send_user_invite'),
        confirmButtonText: Translation.getTranslation('invite'),
      }).then(async (confirm: boolean) => {
        if (confirm) {
          const inviteResponseTemp = await RequestManager.inviteUser(
            email,
            props.user.role === 'user' ? 'designer' : 'user',
            props.user.language,
          );
          if (inviteResponseTemp) {
            let invitesTemp = [...invites];
            invitesTemp.push(inviteResponseTemp);
            setInvites(invitesTemp);
            setEmail('');
          }
        }
      });
    }

    setDisabled(false);
  };

  const deleteInvite = async (item: any) => {
    setDisabled(true);
    await Alert.confirm({
      type: 'warning',
      title: Translation.getTranslation('confirm_delete_connection'),
      text: Translation.getTranslation('confirm_text_delete_connection'),
      confirmButtonText: Translation.getTranslation('delete'),
    }).then(async (confirm: boolean) => {
      if (confirm) {
        let deleted = await RequestManager.putInvite({
          id: item.id,
          status: 'withdrawn',
        });
        if (deleted) {
          let invitesTemp = [...invites];
          let index = invitesTemp.findIndex(
            (itemTemp: any) => itemTemp.id === item.id,
          );

          invitesTemp.splice(index, 1);
          setInvites(invitesTemp);
          toastr.success(Translation.getTranslation('connection_deleted'));
        }
      }
    });
    setDisabled(false);
  };

  const deleteConnection = async (item: any) => {
    setDisabled(true);
    await Alert.confirm({
      type: 'warning',
      title: Translation.getTranslation('confirm_delete_connection'),
      text: Translation.getTranslation('confirm_text_delete_connection'),
      confirmButtonText: Translation.getTranslation('delete'),
    }).then(async (confirm: boolean) => {
      if (confirm) {
        let deleted = await RequestManager.deleteConnection(item.id);
        if (deleted) {
          let dataTemp = [...data];
          let index = dataTemp.findIndex(
            (itemTemp: any) => itemTemp.id === item.id,
          );

          dataTemp.splice(index, 1);
          setData(dataTemp);
          toastr.success(Translation.getTranslation('connection_deleted'));
        }
      }
    });
    setDisabled(false);
  };

  const getIcon = (
    typeTemp: InviteTypes['type'],
  ): { icon: IconProp; color: any } => {
    switch (typeTemp) {
      case 'invited':
        return { icon: 'hourglass-start', color: classes.warning };
      case 'accepted':
        return { icon: 'check', color: classes.success };
      case 'declined':
        return { icon: 'user-times', color: classes.danger };
      case 'withdrawn':
        return { icon: 'minus-circle', color: classes.danger };
      case 'expired':
        return { icon: 'user-clock', color: classes.danger };
    }
  };

  const getActiveConnections = () => {
    return (
      data.filter(
        (entry) => entry.status === 'invited' || entry.status === 'accepted',
      ).length || 0
    );
  };

  return (
    <List className={classes.root}>
      {invites?.map((invite) => (
        <ListItem key={invite.id}>
          <ListItemAvatar>
            <Tooltip
              title={Translation.getTranslation('' + invite.status)}
              placement="top"
              arrow
              aria-label={Translation.getTranslation('' + invite.status)}
            >
              <Avatar className={'warning-color'}>
                <Icon icon={'envelope'} />
              </Avatar>
            </Tooltip>
          </ListItemAvatar>
          <ListItemText primary={invite.email} secondary={''} />
          {!props.disabled && (
            <ListItemSecondaryAction>
              <IconButton
                disabled={disabled}
                edge="end"
                aria-label="edit"
                onClick={() => {
                  deleteInvite(invite);
                }}
              >
                <Icon icon={'trash'} />
              </IconButton>
            </ListItemSecondaryAction>
          )}
        </ListItem>
      ))}
      {data &&
        data.length > 0 &&
        data.map((internalDesigner: any) => {
          return (
            <ListItem key={internalDesigner.id}>
              <ListItemAvatar>
                <Tooltip
                  title={Translation.getTranslation(internalDesigner.status)}
                  placement="top"
                  arrow
                  aria-label={Translation.getTranslation(
                    internalDesigner.status,
                  )}
                >
                  <Avatar className={getIcon(internalDesigner.status).color}>
                    <Icon icon={getIcon(internalDesigner.status).icon} />
                  </Avatar>
                </Tooltip>
              </ListItemAvatar>
              <ListItemText
                primary={
                  internalDesigner[
                    props.user.role === 'user' ? 'designer' : 'user'
                  ].company
                }
                secondary={
                  internalDesigner[
                    props.user.role === 'user' ? 'designer' : 'user'
                  ].firstName +
                  ' ' +
                  internalDesigner[
                    props.user.role === 'user' ? 'designer' : 'user'
                  ].lastName
                }
              />
              {!props.disabled && (
                <ListItemSecondaryAction>
                  <IconButton
                    disabled={disabled}
                    edge="end"
                    aria-label="edit"
                    onClick={() => {
                      deleteConnection(internalDesigner);
                    }}
                  >
                    <Icon icon={'trash'} />
                  </IconButton>
                </ListItemSecondaryAction>
              )}
            </ListItem>
          );
        })}
      {!invites && !data && (
        <ListItem>
          <NoDataComponent value={Translation.getTranslation('no_entry')} />
        </ListItem>
      )}
      {!props.hideInput && (
        <React.Fragment>
          <Divider />
          <ListSubheader component="div" id="my_connections_subheader">
            {Translation.getTranslation('add_connection')}{' '}
            <span
              style={
                getActiveConnections() >= (props.user?.maxConnections || 0)
                  ? { color: danger }
                  : {}
              }
            >
              {checkUserRole(props.user).isUser() &&
                `(${getActiveConnections()}/${props.user.maxConnections})`}
            </span>
          </ListSubheader>
          <div className="d-flex flex-column">
            <Input
              small
              disabled={disabled}
              value={email}
              hasError={
                email !== '' && Helper.validate('email', email).length > 0
                  ? true
                  : false
              }
              helperText={
                email !== '' &&
                Helper.validate('email', email).length > 0 &&
                Helper.validate('email', email)
              }
              label={Translation.getTranslation('email')}
              onChange={(e: any) => setEmail(e.target.value)}
            />
            <Button
              noMargin
              color="primary"
              disabled={
                Helper.validate('email', email).length > 0 || !email || disabled
              }
              onClick={() => saveNewConnection()}
            >
              {Translation.getTranslation('add')}
            </Button>
          </div>
        </React.Fragment>
      )}
    </List>
  );
}
