import { makeStyles, Typography } from '@material-ui/core';
import React from 'react';
import { Link } from 'react-router-dom';
import Static from '../../services/static';
import { footerHeight } from '../../services/styles';
import Translation from '../../services/translation';
import Footer from './Footer';
import Icon from './Icon';
interface Props {
  children?: any;
}

const useStyles = makeStyles((_theme) => ({
  root: { minHeight: `calc(100vh - ${footerHeight})`, marginBottom: '3rem' },
}));

function LayoutUnauthorized(props: Props) {
  const classes = useStyles();
  const [isOpen, setIsOpen] = React.useState(false);
  return (
    <React.Fragment>
      <div className={classes.root}>
        {/* <CookieConsent
        location="top"
        enableDeclineButton
        declineButtonText="Nur notwendige Cookies"
        flipButtons
        buttonText="Erlauben"
        cookieName="myAwesomeCookieName2"
        style={{ background: '#2B373B' }}
        // buttonStyle={{ color: '#4e503b', fontSize: '13px' }}
        expires={150}
      >
        Diese Webseite verwendet Cookies{' '}
        <span style={{ fontSize: '10px' }}>This bit of text is smaller :O</span>
      </CookieConsent> */}
        <div className="container">
          <Link to={Static.getPageByName('moin').url}>
            <Typography
              className="font-weight-bold d-flex align-items-center mt-4 "
              color="primary"
            >
              <Icon icon="arrow-circle-left" className="mr-2" />{' '}
              {Translation.getTranslation('go_to_homepage')}
            </Typography>
          </Link>
          <div className="align-items-top mt-4 justify-content-center">
            <div style={{ maxWidth: '40rem' }}>{props.children}</div>
          </div>
        </div>
      </div>
      <Footer />
    </React.Fragment>
  );
}

export default LayoutUnauthorized;
