import { makeStyles, Typography } from '@material-ui/core';
import React from 'react';
import { Link } from 'react-router-dom';
import RequestManager from '../../manager/request.manager';
import Helper from '../../services/helper';
import { boxShadow, primary } from '../../services/styles';
import Translation from '../../services/translation';
// import '../../styles/AcceptProjectCard.scss';
import Badge from '../Shared/Badge';
import Button from '../Shared/Button';

interface AcceptProjectCard {
  project: any;
  user: any;
  reloadData: any;
}

const useStyles = makeStyles((_theme) => ({
  root: {
    position: 'fixed',
    width: '100%',
    paddingRight: '2rem',
    overflow: 'hidden',
  },
  blur: {
    zIndex: 1000,
    content: '',
    position: 'fixed',
    left: '0',
    right: '0',
    top: '0',
    bottom: '0',
    backdropFilter: 'blur(8px)',
    overflow: 'hidden',
    margin: '0',
  },
  internal: {
    position: 'fixed',
    top: '0',
    left: '0',
    zIndex: 1010,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    height: '100vh',
    width: '100vw',
    overflow: 'hidden',
    margin: '0',
  },
  decisionBox: {
    textAlign: 'center',
    width: '80%',
    minWidth: '300px',
    maxWidth: '70%',
    height: 'fit-content',
    padding: '1rem',
    backgroundColor: primary,
    boxShadow: boxShadow,
    color: '#fff',
    borderRadius: '1rem',
  },
}));

function AcceptProjectCard(props: AcceptProjectCard) {
  const classes = useStyles();
  const acceptOrder = async () => {
    await RequestManager.putProjectPreferences(
      { status: 'wip_designer' },
      props.project.id,
    );
    props.reloadData();
  };

  return (
    <React.Fragment>
      <div className={classes.blur}></div>
      <div className={classes.internal}>
        <div className={classes.decisionBox}>
          <Typography variant="h4">
            {Translation.getTranslation('new_order_arrived')}
          </Typography>
          <Typography variant="h6">
            {Translation.getTranslation('new_order_arrived_2')}
          </Typography>
          <Typography component="p">
            {props.project.isDentalSplint && (
              <Badge color="error" className=" mr-2">
                {Helper.getSplintTranslation(props.project.splintType)}
              </Badge>
            )}
            {props.project.projectWorkTypes &&
              props.project.projectWorkTypes.length > 0 &&
              props.project.projectWorkTypes.map(
                (type: string, index: number) => (
                  <Badge
                    key={type + index}
                    color="primary"
                    className={
                      typeof props.project.projectWorkTypes[index + 1] !==
                      'undefined'
                        ? 'mr-2'
                        : ''
                    }
                  >
                    {Translation.getTranslation(`project_work_type_${type}`)}
                  </Badge>
                ),
              )}
            <Badge color="secondary" className="ml-2">
              {props.project.chosenTeeth.length}{' '}
              {Translation.getTranslation(`teeth`)}
            </Badge>
          </Typography>
          <Typography variant="h6">
            {Translation.getTranslation('new_order_arrived_3')}
          </Typography>

          <Button color="secondary" onClick={acceptOrder}>
            {Translation.getTranslation('accept_project')}
          </Button>
          <Link to={'/'}>
            <Button color="grey">
              {Translation.getTranslation('back_to_dashboard')}
            </Button>
          </Link>
        </div>
      </div>
    </React.Fragment>
  );
}

export default AcceptProjectCard;
