import * as React from 'react';
import toastr from 'toastr';
import UserInterface from '../../interfaces/user.interface';
import CustomFieldsManager from '../../manager/customFields.manager';
import RequestManager from '../../manager/request.manager';
import { Alert } from '../../services/alert';
import Helper, { checkUserRole } from '../../services/helper';
import Static from '../../services/static';
import Translation from '../../services/translation';
import DataTable, { Column } from '../Shared/DataTable';
import BulkAssignModal from './BulkAssignModal';

interface Props {
  me: UserInterface;
  match?: any;
  location?: any;
  conditions?: any;
  bulkMode?: ['archive' | 'assign'];
  history: any;
  title: string;
  status?: string;
  color: 'primary' | 'secondary' | 'danger';
  dataExisting?: (existing: boolean) => void;
  searchable?: boolean;
}

interface State {
  sort?:
    | 'createdAt'
    | '-createdAt'
    | 'sendAt'
    | '-sendAt'
    | 'closedAt'
    | '-closedAt';
}

export function ProjectTable(props: Props) {
  const [bulkAssignModalOpen, setBulkAssignModalOpen] = React.useState(false);
  const [bulkIds, setBulkIds] = React.useState([]);
  const [reloadCounter, setReloadCounter] = React.useState<number | undefined>(
    undefined,
  );

  const handleReload = () => {
    setReloadCounter(reloadCounter ? reloadCounter + 1 : 1);
  };

  const bulkArchive = async (): Promise<boolean> => {
    await Alert.confirm({
      type: 'warning',
      title: Translation.getTranslation('confirm_mark_projects_archived'),
      text: Translation.getTranslation('confirm_text_mark_projects_archived'),
      confirmButtonText: Translation.getTranslation('archiving'),
    }).then(async (confirm: boolean) => {
      if (confirm) {
        await RequestManager.archiveProject(bulkIds).then(async (s) => {
          toastr.success(Translation.getTranslation('projects_archived'));
          handleReload();
          return true;
        });
      }
    });
    return false;
  };

  const renderBulkOptions = () => {
    let options = [];

    if (props.bulkMode?.includes('assign')) {
      options.push({
        label: Translation.getTranslation('bulk_assign'),
        onClick: () => {
          setBulkAssignModalOpen(true);
        },
      });
    }
    if (props.bulkMode?.includes('archive')) {
      options.push({
        label: Translation.getTranslation('mark_projects_archived'),
        onClick: async () => {
          await bulkArchive();
        },
      });
    }
    return options;
  };

  const renderBulkAssignModal = () => {
    return (
      <BulkAssignModal
        ids={bulkIds}
        reloadData={async () => {
          // reloadData();
          // todo
          // toggleBulkMode();
        }}
        modalClosed={() => {
          setBulkAssignModalOpen(false);
        }}
        isOpen={bulkAssignModalOpen}
      />
    );
  };

  const getTableColumns = () => {
    let columns = [
      {
        label: Translation.getTranslation('order_number'),
        type: {
          id: 'individual',
          individualObject: function individualObject(data: any): any {
            return <React.Fragment>{data.orderNumber}</React.Fragment>;
          },
        },
      },
    ];

    if (props.status && props.status === 'draft') {
      columns.push({
        label: Translation.getTranslation('create_date'),
        type: { id: 'date', key: 'createdAt' } as any,
      });
    }

    if (props.status && props.status !== 'draft') {
      columns.push({
        label: Translation.getTranslation('order_date'),
        type: { id: 'date', key: 'sendAt' } as any,
      });
      if (props.me.role !== 'admin') {
        columns.push({
          label: Translation.getTranslation(
            props.me.role === 'designer' ? 'customer' : 'designer',
          ),
          type: {
            id: 'individual',
            individualObject: (data: any) => {
              return data.isInternal
                ? data[props.me.role === 'designer' ? 'user' : 'designer']
                    ?.company || '-'
                : Translation.getTranslation('myDenti');
            },
          },
        });
      }
    }

    if (
      props.status &&
      (props.status === 'finished' ||
        props.status === 'cancelled' ||
        props.status === 'closed')
    ) {
      columns.push({
        label: Translation.getTranslation('close_date'),
        type: { id: 'date', key: 'closedAt' } as any,
      });
    }

    columns.push({
      label: Translation.getTranslation('type_of_work'),
      type: {
        id: 'individual',
        individualObject: (data: any) => {
          return (
            //todo auslagern projectWorkTypes.map( kommt 4x mal im code vor
            data.projectWorkTypes &&
            data.projectWorkTypes.length > 0 &&
            data.projectWorkTypes.map(
              (type: string, index: number) =>
                `${Translation.getTranslation(
                  `project_work_type_${type}`,
                )}${Helper.renderComma(data.projectWorkTypes, index)}`,
            )
          );
        },
      },
    });

    //   // don't show status on draft and cancelled
    if (props.status !== 'draft' && props.status !== 'cancelled') {
      columns.push({
        label: Translation.getTranslation('status'),
        type: {
          id: 'individual',
          individualObject: (data: any) => {
            return (
              props.me?.role &&
              (Helper.renderStatusTextForRole(
                data.currentStatus,
                props.me.role,
              ) as any)
            );
          },
        },
      });
    }

    columns.push({
      label: Translation.getTranslation('patient'),
      type: {
        id: 'individual',
        individualObject: (data: any) => {
          return !checkUserRole(props.me).isDesigner() || data.isInternal
            ? data.patientName
            : '-';
        },
      } as any,
    });

    if (checkUserRole(props.me).isAdmin()) {
      columns.push(
        {
          label: Translation.getTranslation('customer'),
          type: {
            id: 'individual',
            individualObject: (data: any) => {
              return data.user ? Helper.renderUserWithCompany(data.user) : '-';
            },
          },
        },
        {
          label: Translation.getTranslation('designer'),
          type: {
            id: 'individual',
            individualObject: (data: any) => {
              return data.designer
                ? (Helper.renderUserWithCompany(data.designer) as any)
                : '-';
            },
          },
        },
      );
    }

    return columns as Column[];
  };
  return (
    <React.Fragment>
      <DataTable
        title={Translation.getTranslation(props.title)}
        additionalColumns={CustomFieldsManager.renderDataTableAdditionalColumn}
        manager={(options: any) => {
          return RequestManager.getProjects({
            ...options,
            ...props.conditions,
            currentStatus: props.status ? props.status : undefined,
          });
        }}
        returnData={(data: any) =>
          props.dataExisting && props.dataExisting(data.count || 0)
        }
        reloadData={reloadCounter}
        onMultiSelect={
          props.bulkMode
            ? (data: any) => {
                setBulkIds(
                  data.map((iterator: any) => {
                    return iterator.id;
                  }),
                );
              }
            : undefined
        }
        renderMultiSelectOptions={renderBulkOptions()}
        onClick={(id: string, data: any) => {
          data.currentStatus === 'draft'
            ? props.history.push(
                `${Static.getPageByName('order_create').url}/${id}`,
              )
            : props.history.push(
                `${Static.getPageByName('order_detail').url}/${id}`,
              );
        }}
        sort={['closedAt', 'createdAt', 'sendAt']}
        defaultSort={
          (props.status === 'cancelled'
            ? '-closedAt'
            : props.status === 'draft'
            ? '-createdAt'
            : '-sendAt') as State['sort']
        }
        limit={5}
        color={props.color ? props.color : undefined}
        columns={getTableColumns() as any}
      />
      {renderBulkAssignModal()}
    </React.Fragment>
  );
}
