import * as React from 'react';
import { useHistory } from 'react-router';
import { useRecoilValue } from 'recoil';
import Button from '../components/Shared/Button';
import Checkbox from '../components/Shared/Checkbox';
import ComponentHeading from '../components/Shared/ComponentHeading';
import Input from '../components/Shared/Input';
import PageHeadingRow from '../components/Shared/PageHeadingRow';
import SearchableSelect from '../components/Shared/SearchableSelect';
import Select from '../components/Shared/Select';
import Spinner from '../components/Shared/Spinner';
import MyConnectionsList from '../components/User/MyConnectionsList';
import UserInterface, { PaymentTypes } from '../interfaces/user.interface';
import RequestManager from '../manager/request.manager';
import { Alert } from '../services/alert';
import { userState } from '../services/atoms';
import Helper, { checkUserRole } from '../services/helper';
import Static from '../services/static';
import Translation from '../services/translation';

interface Props {
  match?: any;
}

export default function UserDetail(props: Props) {
  const [loading, setLoading] = React.useState<boolean>(true);
  const [user, setUser] = React.useState<undefined | UserInterface | any>(
    undefined,
  );
  const history = useHistory();
  const me = useRecoilValue(userState);
  const [designers, setDesigners] = React.useState<any[]>([]);
  const [connections, setConnections] = React.useState<UserInterface[]>([]);
  // const [users, setUsers] = React.useState<UserInterface[]>([]);
  const [editMode, setEditMode] = React.useState<boolean>(false);

  React.useEffect(() => {
    getInitialData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.match.params.userId]);

  const getInitialData = async () => {
    let userTemp = await RequestManager.getUser(props.match.params.userId);
    if (userTemp && me && checkUserRole(me).isAdmin()) {
      setUser(userTemp);
      if (checkUserRole(userTemp).isUser()) {
        let designersTemp = await RequestManager.getUsersByRole('designer');
        setDesigners(designersTemp);
      }

      if (
        checkUserRole(userTemp).isDesigner() ||
        checkUserRole(userTemp).isUser()
      ) {
        let connectionsTemp = await RequestManager.getUserConnections(
          userTemp?.id || '',
        );

        setConnections(connectionsTemp);
      }
    } else {
      history.push('/');
    }
    setLoading(false);
  };

  const saveChanges = async () => {
    if (user) {
      let userTemp = { ...user };
      userTemp.setConnections = connections.map((designersTemp: any) => {
        return designersTemp.id;
      });
      let response = await RequestManager.putUser(userTemp);
      if (response) {
        setUser(userTemp);
        setEditMode(!editMode);
      }
    }
  };

  const handleChangePlan = async (value: PaymentTypes['currentPlan']) => {
    if (value) {
      await Alert.confirm({
        type: 'warning',
        title: 'Abo des Nutzers ändern?',
        confirmButtonText: 'Ja, ändern',
      }).then(async (confirm: boolean) => {
        if (confirm) {
          const userTemp = { ...user };

          const resp = await RequestManager.patchPlan({
            userId: user?.id,
            plan: value,
          });
          if (resp) {
            userTemp['payment']['currentPlan'] = resp;
            setUser(userTemp);
          }
        }
      });
    }
  };

  const renderDefaultDesigner = () => {
    return (
      <SearchableSelect
        data={designers.map((designer: any) => {
          return {
            value: designer.id,
            label: designer.company,
          };
        })}
        disabled={!editMode}
        onChange={(e: any) => {
          Helper.handleChangesInObject(
            [
              {
                attribute: 'defaultDesignerId',
                value: e.target.value.target.value,
              },
            ],
            user,
            setUser,
          );
        }}
        defaultValue={{
          value: '',
          label: Translation.getTranslation('no'),
        }}
        value={user.defaultDesignerId ? user.defaultDesignerId : ''}
      />
    );
  };

  // const renderInternalUsers = () => {
  //   return (
  //     <SearchableSelect
  //       data={
  //         users &&
  //         users.map((userTemp: any) => {
  //           return {
  //             value: userTemp.id,
  //             label: userTemp.company,
  //           };
  //         })
  //       }
  //       defaultValue={{
  //         value: '',
  //         label: Translation.getTranslation('no'),
  //       }}
  //       value={
  //         user.isInternalDesignerForUserId
  //           ? user.isInternalDesignerForUserId
  //           : ''
  //       }
  //       onChange={(e: any) => {
  //         Helper.handleChangesInObject(
  //           [
  //             {
  //               attribute: 'isInternalDesignerForUserId',
  //               value: e.target.value,
  //             },
  //           ],
  //           user,
  //           setUser,
  //         );
  //       }}
  //       disabled={!editMode}
  //     />
  //   );
  // };

  return (
    <React.Fragment>
      <PageHeadingRow
        siteTitle={
          user?.company
            ? `${
                user &&
                user.role &&
                Translation.getTranslation(
                  user.role !== 'user' ? user.role : 'customer',
                )
              } ${user?.company}`
            : Static.getPageByName('user_detail').siteTitle
        }
      />
      {loading && <Spinner />}
      {user && (
        <React.Fragment>
          <div className="row">
            <div className="col-lg-12">
              {!editMode ? (
                <Button
                  onClick={() => {
                    setEditMode(!editMode);
                  }}
                >
                  {editMode
                    ? Translation.getTranslation('disable_edit')
                    : Translation.getTranslation('enable_edit')}
                </Button>
              ) : (
                <Button color="primary" onClick={saveChanges}>
                  {Translation.getTranslation('save_changes')}
                </Button>
              )}
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12 col-xl-6">
              <ComponentHeading value={Translation.getTranslation('contact')}>
                <Input
                  label={Translation.getTranslation('email')}
                  id="userEmailInput"
                  disabled={!editMode}
                  icon="envelope"
                  small
                  className="mb-2"
                  type="email"
                  onChange={(e: any) => {
                    Helper.handleChangesInObject(
                      [{ attribute: 'email', value: e.target.value }],
                      user,
                      setUser,
                    );
                  }}
                  value={user.email}
                  hasError={
                    user.email === ''
                      ? false
                      : Helper.validate('email', user.email).length > 0
                      ? true
                      : false
                  }
                  helperText={
                    user.email !== '' &&
                    Helper.validate('email', user.email).length > 0 &&
                    Helper.validate('email', user.email)
                  }
                />
                <Input
                  label={Translation.getTranslation('firstName')}
                  disabled={!editMode}
                  id="userFirstnameInput"
                  icon="user"
                  small
                  className="mb-2"
                  type="text"
                  onChange={(e: any) => {
                    Helper.handleChangesInObject(
                      [{ attribute: 'firstName', value: e.target.value }],
                      user,
                      setUser,
                    );
                  }}
                  value={user.firstName ? user.firstName : ''}
                  hasError={
                    user.firstName === ''
                      ? false
                      : Helper.validate('name', user.firstName).length > 0
                      ? true
                      : false
                  }
                  helperText={
                    user.firstName !== '' &&
                    Helper.validate('name', user.firstName).length > 0 &&
                    Helper.validate('name', user.firstName)
                  }
                />
                <Input
                  label={Translation.getTranslation('lastName')}
                  id="userLastnameInput"
                  disabled={!editMode}
                  icon="signature"
                  small
                  className="mb-2"
                  type="text"
                  onChange={(e: any) => {
                    Helper.handleChangesInObject(
                      [{ attribute: 'lastName', value: e.target.value }],
                      user,
                      setUser,
                    );
                  }}
                  value={user.lastName ? user.lastName : ''}
                  hasError={
                    user.lastName === ''
                      ? false
                      : Helper.validate('name', user.lastName).length > 0
                      ? true
                      : false
                  }
                  helperText={
                    user.lastName !== '' &&
                    Helper.validate('name', user.lastName).length > 0 &&
                    Helper.validate('name', user.lastName)
                  }
                />
                <Input
                  label={Translation.getTranslation('tel')}
                  id="userPhoneInput"
                  disabled={!editMode}
                  icon="phone"
                  small
                  className="mb-2"
                  type="text"
                  onChange={(e: any) => {
                    Helper.handleChangesInObject(
                      [{ attribute: 'tel', value: e.target.value }],
                      user,
                      setUser,
                    );
                  }}
                  value={user.tel ? user.tel : ''}
                  hasError={
                    user.tel === ''
                      ? false
                      : Helper.validate('tel', user.tel).length > 0
                      ? true
                      : false
                  }
                  helperText={
                    user.tel !== '' &&
                    Helper.validate('tel', user.tel).length > 0 &&
                    Helper.validate('tel', user.tel)
                  }
                />
              </ComponentHeading>
              <ComponentHeading value={Translation.getTranslation('company')}>
                <Input
                  label={Translation.getTranslation('company_name')}
                  id="userCompanyInput"
                  disabled={!editMode}
                  icon="briefcase"
                  small
                  className="mb-2"
                  type="text"
                  onChange={(e: any) => {
                    Helper.handleChangesInObject(
                      [{ attribute: 'company', value: e.target.value }],
                      user,
                      setUser,
                    );
                  }}
                  value={user.company ? user.company : ''}
                  hasError={
                    user.company === ''
                      ? false
                      : Helper.validate('company', user.company).length > 0
                      ? true
                      : false
                  }
                  helperText={
                    user.company !== '' &&
                    Helper.validate('company', user.company).length > 0 &&
                    Helper.validate('company', user.company)
                  }
                />
                <Input
                  label={Translation.getTranslation('street')}
                  id="userStreetInput"
                  disabled={!editMode}
                  icon="road"
                  small
                  className="mb-2"
                  type="text"
                  onChange={(e: any) => {
                    Helper.handleChangesInObject(
                      [{ attribute: 'street', value: e.target.value }],
                      user,
                      setUser,
                    );
                  }}
                  value={user.street ? user.street : ''}
                  hasError={
                    user.street === ''
                      ? false
                      : Helper.validate('street', user.street).length > 0
                      ? true
                      : false
                  }
                  helperText={
                    user.street !== '' &&
                    Helper.validate('street', user.street).length > 0 &&
                    Helper.validate('street', user.street)
                  }
                />
                <Input
                  label={Translation.getTranslation('zip')}
                  id="userZipInput"
                  disabled={!editMode}
                  icon="street-view"
                  small
                  className="mb-2"
                  type="text"
                  onChange={(e: any) => {
                    Helper.handleChangesInObject(
                      [{ attribute: 'zip', value: e.target.value }],
                      user,
                      setUser,
                    );
                  }}
                  value={user.zip ? user.zip : ''}
                  hasError={
                    user.zip === ''
                      ? false
                      : Helper.validate('zip', user.zip).length > 0
                      ? true
                      : false
                  }
                  helperText={
                    user.zip !== '' &&
                    Helper.validate('zip', user.zip).length > 0 &&
                    Helper.validate('zip', user.zip)
                  }
                />
                <Input
                  label={Translation.getTranslation('city')}
                  id="userCityInput"
                  disabled={!editMode}
                  icon="city"
                  small
                  className="mb-2"
                  type="text"
                  onChange={(e: any) => {
                    Helper.handleChangesInObject(
                      [{ attribute: 'city', value: e.target.value }],
                      user,
                      setUser,
                    );
                  }}
                  value={user.city ? user.city : ''}
                  hasError={
                    user.city === ''
                      ? false
                      : Helper.validate('city', user.city).length > 0
                      ? true
                      : false
                  }
                  helperText={
                    user.city !== '' &&
                    Helper.validate('city', user.city).length > 0 &&
                    Helper.validate('city', user.city)
                  }
                />
                <Input
                  label={Translation.getTranslation('tax_number')}
                  id="userTaxInput"
                  disabled={!editMode}
                  icon="address-card"
                  small
                  className="mb-2"
                  type="text"
                  onChange={(e: any) => {
                    Helper.handleChangesInObject(
                      [{ attribute: 'taxNumber', value: e.target.value }],
                      user,
                      setUser,
                    );
                  }}
                  value={user.taxNumber ? user.taxNumber : ''}
                />
              </ComponentHeading>
            </div>
            {/* </div> */}
            <div className="col-lg-12 col-xl-6">
              <ComponentHeading value={Translation.getTranslation('role')}>
                <Select
                  value={user.role}
                  onChange={(e: any) => {
                    Helper.handleChangesInObject(
                      [{ attribute: 'role', value: e.target.value }],
                      user,
                      setUser,
                    );
                  }}
                  disabled={!editMode}
                >
                  <option value={'user'}>User</option>
                  <option value={'admin'}>Admin</option>
                  <option value={'designer'}>Designer</option>
                </Select>
              </ComponentHeading>
              {checkUserRole(user).isAdmin() && (
                <ComponentHeading
                  value={Translation.getTranslation('receives_emails')}
                >
                  <Checkbox
                    label={Translation.getTranslation('yes')}
                    disabled={!editMode}
                    checked={user.receivesEmails}
                    onChange={() => {
                      Helper.handleChangesInObject(
                        [{ attribute: 'receivesEmails', value: true }],
                        user,
                        setUser,
                      );
                    }}
                  />
                  <Checkbox
                    label={Translation.getTranslation('no')}
                    disabled={!editMode}
                    checked={!user.receivesEmails}
                    onChange={() => {
                      Helper.handleChangesInObject(
                        [{ attribute: 'receivesEmails', value: false }],
                        user,
                        setUser,
                      );
                    }}
                  />
                </ComponentHeading>
              )}
              <ComponentHeading
                value={Translation.getTranslation('is_confirmed')}
              >
                <Checkbox
                  label={Translation.getTranslation('yes')}
                  disabled={!editMode}
                  checked={user.accepted}
                  onChange={() => {
                    Helper.handleChangesInObject(
                      [{ attribute: 'accepted', value: true }],
                      user,
                      setUser,
                    );
                  }}
                />
                <Checkbox
                  label={Translation.getTranslation('no')}
                  disabled={!editMode}
                  checked={!user.accepted}
                  onChange={() => {
                    Helper.handleChangesInObject(
                      [{ attribute: 'accepted', value: false }],
                      user,
                      setUser,
                    );
                  }}
                />
              </ComponentHeading>
              {checkUserRole(user).isUser() && (
                <React.Fragment>
                  <ComponentHeading
                    value={Translation.getTranslation('default_designer_text')}
                  >
                    {renderDefaultDesigner()}
                  </ComponentHeading>
                </React.Fragment>
              )}
              <ComponentHeading value={Translation.getTranslation('premium')}>
                <Input
                  label={Translation.getTranslation('custom_fields')}
                  id="customFieldsInput"
                  disabled={!editMode}
                  icon="list"
                  small
                  className="mb-2"
                  type="number"
                  onChange={(e: any) => {
                    Helper.handleChangesInObject(
                      [
                        {
                          attribute: 'maxCustomFields',
                          value: e.target.value,
                        },
                      ],
                      user,
                      setUser,
                    );
                  }}
                  value={user.maxCustomFields}
                  hasError={
                    user.maxCustomFields === ''
                      ? false
                      : Helper.validate('number', user.maxCustomFields).length >
                        0
                      ? true
                      : false
                  }
                  helperText={
                    user.maxCustomFields !== '' &&
                    Helper.validate('number', user.maxCustomFields).length >
                      0 &&
                    Helper.validate('number', user.maxCustomFields)
                  }
                />
                {checkUserRole(user).isUser() && (
                  <React.Fragment>
                    <Input
                      label={Translation.getTranslation('connections')}
                      id="connectionsInput"
                      disabled={!editMode}
                      icon="network-wired"
                      small
                      className="mb-2"
                      type="number"
                      onChange={(e: any) => {
                        Helper.handleChangesInObject(
                          [
                            {
                              attribute: 'maxConnections',
                              value: e.target.value,
                            },
                          ],
                          user,
                          setUser,
                        );
                      }}
                      value={user.maxConnections}
                      hasError={
                        user.maxConnections === ''
                          ? false
                          : Helper.validate('number', user.maxConnections)
                              .length > 0
                          ? true
                          : false
                      }
                      helperText={
                        user.maxConnections !== '' &&
                        Helper.validate('number', user.maxConnections).length >
                          0 &&
                        Helper.validate('number', user.maxConnections)
                      }
                    />
                    <Select
                      value={user?.payment?.currentPlan || ''}
                      onChange={(e: any) => {
                        handleChangePlan(e.target.value);
                      }}
                      disabled={!editMode}
                    >
                      <option key={'no_plan'} value={''} disabled>
                        {Translation.getTranslation('no_abo')}
                      </option>
                      {['basic', 'starter', 'standard', 'premium'].map(
                        (plan) => {
                          return (
                            <option key={plan} value={plan}>
                              {Translation.getTranslation('plan_' + plan)}
                            </option>
                          );
                        },
                      )}
                    </Select>
                  </React.Fragment>
                )}{' '}
              </ComponentHeading>
              {/* {checkUserRole(user).isDesigner() && (
                <ComponentHeading
                  value={Translation.getTranslation('is_internal_designer')}
                >
                  {renderInternalUsers()}
                </ComponentHeading>
              )} */}
              {(checkUserRole(user).isDesigner() ||
                checkUserRole(user).isUser()) &&
                connections &&
                connections.length > 0 && (
                  <ComponentHeading
                    value={Translation.getTranslation('has_connections')}
                  >
                    <MyConnectionsList
                      disabled={true}
                      user={user}
                      connections={connections}
                      hideInput
                    />
                  </ComponentHeading>
                )}
            </div>
          </div>
        </React.Fragment>
      )}
    </React.Fragment>
  );
}
