import toastr from 'toastr';
import UserInterface, {
  InviteTypes,
  PaymentTypes,
} from '../interfaces/user.interface';
import request from '../services/AxiosAPI';
import { checkUserRole } from '../services/helper';
import Translation from '../services/translation';
import UploadsManager from './uploads.manager';

export default class RequestManager {
  static async getProjects(options?: any) {
    return await request({
      method: 'get',
      url: 'projects',
      params: options,
    });
  }

  static async postSubscription(data: {
    plan: PaymentTypes['currentPlan'];
    coupon?: string;
  }) {
    return await request({
      method: 'post',
      url: 'user_payment/subscription_checkout',
      data: {
        coupon: data.coupon,
        plan: data.plan,
      },
    });
  }

  static async patchPlan(data: {
    plan: PaymentTypes['currentPlan'];
    userId?: string;
  }) {
    return await request({
      method: 'patch',
      url: 'user_payment/change_plan/' + data.userId,
      data: {
        plan: data.plan,
      },
    });
  }

  static async getUserConnections(userId: string) {
    return await request({
      method: 'get',
      url: 'user_designers/' + userId,
    });
  }

  static async getStripeCustomer() {
    return await request({
      method: 'get',
      url: 'user_payment/customer',
    });
  }

  static async getCoupon(coupon: string) {
    return await request({
      method: 'get',
      url: 'user_payment/coupon/' + coupon,
    });
  }

  static async getStripePricing() {
    return await request({
      method: 'get',
      url: 'user_payment/pricing',
    });
  }
  static async getStripePortal() {
    return await request({
      method: 'get',
      url: 'user_payment/portal',
    });
  }
  static async getProjectCustomFields(projectId: string) {
    return await request({
      method: 'get',
      url: 'project_custom_fields/' + projectId,
    });
  }

  static async getRatings(projectId?: any) {
    return await request({
      method: 'get',
      url: 'ratings/project/' + projectId,
    });
  }

  static async archiveProject(projectIds: string[]) {
    return await request({
      method: 'patch',
      url: 'projects/archive',
      data: projectIds,
    });
  }

  static async getStaticServices(options?: {
    type?: 'material' | 'service' | 'system_service';
  }) {
    return await request({
      method: 'get',
      url: 'services',
      params: options,
    });
  }

  static async postRating(data: {
    value: 1 | 2 | 3 | 4 | 5;
    projectId: string;
  }) {
    return await request({
      method: 'post',
      url: 'ratings/',
      data: data,
    });
  }

  static async putSettings(data: {
    primaryColor?: string;
    secondaryColor?: string;
  }) {
    return await request({
      method: 'put',
      url: `user_settings/`,
      data,
    });
  }

  static async putRating(data: { comment: string; ratingId: string }) {
    return await request({
      method: 'put',
      url: `ratings/${data.ratingId}`,
      data: data,
    });
  }

  static async postTeeth(teethServices: any, projectId: string) {
    return await request({
      method: 'post',
      url: `project_teeth/${projectId}`,
      data: teethServices,
    });
  }

  static async duplicateProject(projectId: string) {
    const ReactGA = require('react-ga');
    ReactGA.event({
      category: 'Project',
      action: 'duplicate',
    });
    return await request({
      method: 'post',
      url: `projects/duplicate/${projectId}`,
    });
  }

  static async putTeeth(teethServices: any, projectId: string) {
    return await request({
      method: 'put',
      url: `project_teeth/${projectId}`,
      data: teethServices,
    });
  }

  static async putComment(commentId: string, data: any) {
    return await request({
      method: 'put',
      url: `project_comments/${commentId}`,
      data,
    });
  }

  static async postProject(projectData: any) {
    return await request({
      method: 'post',
      url: `projects/`,
      data: projectData,
    });
  }

  static async postComment(
    commentData: {
      comment: string;
      forUser?: boolean;
      forDesigner?: boolean;
      reference?: 'correction' | 'cancellation';
    },
    projectId: string,
  ) {
    return await request({
      method: 'post',
      url: `project_comments/${projectId}`,
      data: commentData,
    });
  }

  static async putProject(projectData: any, projectId: string) {
    return await request({
      method: 'put',
      url: `projects/${projectId}`,
      data: projectData,
    });
  }

  static async putProjectPreferences(projectData: any, projectId: string) {
    return await request({
      method: 'put',
      url: `projects/preferences/${projectId}`,
      data: projectData,
    });
  }

  static async getProject(projectId: string, options?: any) {
    return await request({
      method: 'get',
      url: `projects/${projectId}`,
      params: options,
    });
  }

  static async zipDownload(fileIds: string[], filename: string) {
    return await request(
      {
        method: 'get',
        url: `uploads/multiple/`,
        params: {
          fileIds: fileIds.join(','),
        },
      },
      { responseType: 'blob' },
    ).then((response: any) => {
      if (response) {
        const url = window.URL.createObjectURL(response);
        UploadsManager.downloadFile(url, filename);
      }
    });
  }

  static async deleteFile(id: string) {
    return await request({
      method: 'delete',
      url: `uploads/${id}`,
    });
  }

  static async deleteLogo() {
    return await request({
      method: 'delete',
      url: `user_settings/logo`,
    });
  }
  static async uploadLogo(formData: FormData) {
    return await request({
      method: 'post',
      url: `user_settings/logo`,
      data: formData,
      headers: { 'content-type': 'multipart/form-data' },
    });
  }

  static async uploadFile(formData: FormData) {
    return await request({
      method: 'post',
      url: `uploads/`,
      data: formData,
    });
  }

  static async getDownloadLink(fileId: string) {
    return await request({
      method: 'get',
      url: `uploads/download/${fileId}`,
    });
  }

  static async getDownloadURL(filename: string) {
    return await request({
      method: 'get',
      url: `uploads/url/${filename}`,
    });
  }

  static async getFile(fileId: string) {
    return await request(
      {
        method: 'get',
        url: `uploads/file/${fileId}`,
      },
      { responseType: 'blob' },
    );
  }

  static async getUpload(fileId: string) {
    return await request({
      method: 'get',
      url: `uploads/upload/${fileId}`,
    });
  }

  static async login(email: string, password: string): Promise<any> {
    const ReactGA = require('react-ga');
    ReactGA.event({
      category: 'User',
      action: 'login',
    });

    return await request({
      method: 'post',
      url: `users/login`,
      data: { email: email, password: password },
    }).then((response: any) => {
      if (response?.token) {
        localStorage.setItem('jwt', response.token);
        return response;
      } else return undefined;
    });
  }

  static async getMe(): Promise<UserInterface> {
    return await request({
      method: 'get',
      url: `users/me`,
    });
  }

  static async deleteMe(): Promise<UserInterface> {
    return await request({
      method: 'delete',
      url: `users/me`,
    });
  }

  static async getUser(id: string): Promise<UserInterface> {
    return await request({
      method: 'get',
      url: `users/user/${id}`,
    });
  }

  static async getUserLogs(params?: any): Promise<any> {
    return await request({
      method: 'get',
      url: `user_logs`,
      params,
    });
  }

  static async getNotifications(params: any): Promise<any> {
    return await request({
      method: 'get',
      url: `user_notifications`,
      params,
    });
  }

  static async getNewNotificationCount(): Promise<any> {
    return await request({
      method: 'get',
      url: `user_notifications/count`,
    }).catch((error: any) => {
      window.location.reload();
    });
  }

  static async notificationMarkSeen(): Promise<any> {
    return await request({
      method: 'get',
      url: `user_notifications/seen`,
    });
  }

  static async checkNotification(id: string): Promise<any> {
    return await request({
      method: 'put',
      url: `user_notifications/check/${id}`,
    });
  }

  static async getUsersByRole(
    role: UserInterface['role'],
  ): Promise<UserInterface[]> {
    return await request({
      method: 'get',
      url: `users/role/${role}`,
    });
  }

  static async getRequests(): Promise<any> {
    return await request({
      method: 'get',
      url: `users/requests`,
    });
  }

  static async postMyConnections(email: string): Promise<any> {
    return await request(
      {
        method: 'post',
        url: `user_designers`,
        data: { email },
      },
      { returnFullError: true },
    );
  }

  static async getMyConnections(data?: {
    status?: InviteTypes['type'];
    initiator?: UserInterface['role'];
  }): Promise<any> {
    return await request({
      method: 'get',
      url: `user_designers`,
      params: data,
    });
  }

  static async patchMyConnections(data?: {
    status?: InviteTypes['type'];
    id: string;
  }): Promise<any> {
    return await request({
      method: 'patch',
      url: `user_designers`,
      data,
    });
  }

  static async getInvites(options: {
    status?: string;
    query?: string;
    page?: number;
    limit?: number;
  }): Promise<any> {
    return await request({
      method: 'get',
      url: `user_invites`,
      params: options,
    });
  }

  static async getAllUsers(params?: any): Promise<any> {
    return await request({
      method: 'get',
      url: `users`,
      params,
    });
  }

  static async putUser(user: UserInterface | any): Promise<any> {
    return await request({
      method: 'put',
      url: `users/${user.id}`,
      data: {
        language: user.language ?? undefined,
        email: user.email ?? undefined,
        firstName: user.firstName ?? undefined,
        lastName: user.lastName ?? undefined,
        company: user.company ?? undefined,
        city: user.city ?? undefined,
        zip: user.zip ?? undefined,
        street: user.street ?? undefined,
        tel: user.tel ?? undefined,
        taxNumber: user.taxNumber ?? undefined,
        role: user.role ?? undefined,
        maxConnections: parseInt(user.maxConnections) ?? undefined,
        maxCustomFields: parseInt(user.maxCustomFields) ?? undefined,
        defaultDesignerId:
          typeof user.defaultDesignerId === 'undefined'
            ? undefined
            : user.defaultDesignerId
            ? user.defaultDesignerId
            : '',
        setInternalDesigners:
          typeof user.setInternalDesigners === 'undefined'
            ? undefined
            : user.setInternalDesigners
            ? user.setInternalDesigners
            : '',
        isInternalDesignerForUserId:
          checkUserRole(user).isDesigner() &&
          typeof user.isInternalDesignerForUserId !== 'undefined'
            ? user.isInternalDesignerForUserId
              ? user.isInternalDesignerForUserId
              : ''
            : undefined,
        receivesEmails: user.receivesEmails ? true : false,
        accepted: user.accepted ? true : false,
      },
    }).then((success: any) => {
      toastr.success(Translation.getTranslation('updated'));
      return success;
    });
  }

  static async putMe(user: UserInterface): Promise<any> {
    return await request({
      method: 'put',
      url: `users/me`,
      data: {
        language: user.language,
        newPassword: user.newPassword,
        email: user.email,
        firstName: user.firstName,
        lastName: user.lastName,
        company: user.company,
        city: user.city,
        zip: user.zip,
        street: user.street,
        tel: user.tel,
        taxNumber: user.taxNumber,
        password: user.password,
      },
    }).then((success: any) => {
      toastr.success(Translation.getTranslation('updated'));
      return success;
    });
  }

  static async acceptUser(id: string): Promise<any> {
    return await request({
      method: 'put',
      url: `users/${id}`,
      data: {
        accepted: true,
      },
    }).then((success: any) => {
      toastr.success(Translation.getTranslation('user_accepted'));
      return success;
    });
  }

  static async register(user: UserInterface): Promise<any> {
    return await request({
      method: 'post',
      url: `users/register`,
      data: {
        email: user.email,
        firstName: user.firstName,
        lastName: user.lastName,
        company: user.company,
        language: user.language,
        city: user.city,
        zip: user.zip,
        street: user.street,
        tel: user.tel,
        taxNumber: user.taxNumber,
        password: user.password,
        code: user.inviteCode,
      },
    }).then((success: any) => {
      if (success.token) {
        localStorage.setItem('jwt', success.token);

        return success;
      } else return undefined;
    });
  }

  static async resetPassword(email: string): Promise<any> {
    const ReactGA = require('react-ga');
    ReactGA.event({
      category: 'User',
      action: 'password_reset',
    });
    return await request({
      method: 'post',
      url: `users/forgot_password`,
      data: {
        email,
      },
    });
  }

  static async inviteUser(
    email: string,
    role: UserInterface['role'],
    language: UserInterface['language'],
  ): Promise<any> {
    return await request({
      method: 'post',
      url: `user_invites`,
      data: {
        email,
        role,
        language,
      },
    });
  }

  static async putInvite(options: {
    id: string;
    status: InviteTypes['type'];
  }): Promise<any> {
    return await request({
      method: 'put',
      url: `user_invites`,
      data: options,
    });
  }

  static async logout(): Promise<any> {
    const ReactGA = require('react-ga');
    ReactGA.event({
      category: 'User',
      action: 'logout',
    });

    return await request({
      method: 'post',
      url: `users/logout`,
    })
      .then((success: any) => {
        localStorage.removeItem('jwt');
        toastr.info(Translation.getTranslation('logged_out'));
        return true;
      })
      .catch((error: any) => {
        toastr.info(Translation.getTranslation('error'));
        return false;
      });
  }

  static async postCustomFieldValue(data: {
    value: any;
    customFieldId: string;
    projectId: string;
  }) {
    return await request({
      method: 'post',
      url: `users/forgot_password`,
      data: {
        value: data.value,
        customFieldId: data.customFieldId,
        projectId: data.projectId,
      },
    });
  }

  static async putCustomField(data: { label: string; id: string }) {
    return await request({
      method: 'put',
      url: `project_custom_fields/${data.id}`,
      data: {
        label: data.label,
      },
    });
  }

  static async deleteConnection(id: string) {
    return await request({
      method: 'delete',
      url: `user_designers/${id}`,
    });
  }

  static async deleteCustomField(id: string) {
    return await request({
      method: 'delete',
      url: `project_custom_fields/${id}`,
    });
  }

  static async postCustomField(data: { label: string; type: string }) {
    return await request({
      method: 'post',
      url: `project_custom_fields/`,
      data,
    });
  }
}
