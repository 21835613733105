import { createMuiTheme, responsiveFontSizes } from '@material-ui/core';
import UserInterface from '../interfaces/user.interface';
import UserManager from '../manager/user.manager';

export const primaryDefault = '#00695c';
export const secondaryDefault = '#2bbbad';
export const primary = localStorage.getItem('primaryColor') || primaryDefault;
export const secondary =
  localStorage.getItem('secondaryColor') || secondaryDefault;
export const lightSecondary = secondary + '66'; // 40% opacity
// export const lightSecondary = '#99D2CC';
// https://gist.github.com/lopspower/03fb1cc0ac9f32ef38f4 for transparency
export const danger = '#ff3547';
export const dangerLight = '#ffebee';
export const success = '#00695c';
export const warning = '#fb3';

export const lightGrey = '#F8F8F8';
export const semiLightGrey = '#9ca9b3';
export const grey = '#dee2e6';
export const darkGrey = '#616161';

export const boxShadow =
  '0 2px 5px 0 rgba(0,0,0,0.16), 0 2px 10px 0 rgba(0,0,0,0.12)';
export const borderRadius = '0.25rem';
export const footerHeight = '80px';
export const background = lightGrey;
export const landingPageBackground = '#151719';

export default class Styles {
  static changeColor = (
    color: 'primaryColor' | 'secondaryColor',
    colorCode: string,
  ) => {
    if (colorCode) {
      localStorage.setItem(color, colorCode);
    } else {
      localStorage.removeItem(color);
    }
  };

  static getMuiTheme(data?: { user?: UserInterface }) {
    const getUserColors = () => {
      const colors = ['primaryColor', 'secondaryColor'] as (
        | 'primaryColor'
        | 'secondaryColor'
      )[];

      colors.map((color) => {
        if (
          data?.user?.settings?.[color] &&
          UserManager.checkIfPayedForService(data?.user, {
            service: 'customization',
          })
        ) {
          Styles.changeColor(
            color,
            data?.user?.settings?.[color] || ('' as any),
          );
          // if no color set  but one is in storage - delete
        } else if (
          !data?.user?.settings?.[color] &&
          localStorage.getItem(color)
        ) {
          Styles.changeColor(color, '');
        }
      });
    };

    getUserColors();

    let theme = createMuiTheme({
      typography: {
        fontFamily: `"Inter", sans-serif`,
        // fontSize: 14,
        body2: { fontWeight: 300 },
        h3: { fontWeight: 200 },
        h5: { fontWeight: 300 },
        h6: { fontWeight: 300 },
      },
      palette: {
        primary: {
          main: primary,
        },
        secondary: {
          main: secondary,
        },
        error: {
          main: danger,
        },
        warning: {
          main: warning,
        },
        background: {
          default: background,
        },
      },
    });
    theme = responsiveFontSizes(theme);

    return theme;
  }

  static sweetalertOptions() {
    return {
      successColor: success,
      dangerColor: danger,
      primaryColor: primary,
      // primaryColor: '#4285f4',
      greyColor: darkGrey,
      warningColor: warning,
    };
  }
}
