import { makeStyles } from '@material-ui/core';
import * as React from 'react';
import RequestManager from '../../manager/request.manager';
import Helper from '../../services/helper';
import { grey } from '../../services/styles';
import Translation from '../../services/translation';
import Badge from '../Shared/Badge';
import TextLinks from '../Shared/TextLinks';

interface Props {
  match?: any;
  location?: any;
  history?: any;
  comments: any;
  project: any;
  me: any;
  reloadData: () => void;
}

const useStyles = makeStyles((_theme) => ({
  item: {
    border: `1px solid ${grey}`,
    marginBottom: '5px',
  },
}));

export default function AdminCommentList(props: Props) {
  const classes = useStyles();
  const [loading, setLoading] = React.useState(false);

  const toggleVisibility = async (
    entity: 'forUser' | 'forDesigner',
    newValue: boolean,
    commentId: string,
  ) => {
    setLoading(true);
    await RequestManager.putComment(commentId, {
      [entity]: newValue,
    });
    props.reloadData && props.reloadData();

    setTimeout(() => {
      setLoading(false);
    }, 1000);
  };

  return (
    <div>
      {props.comments &&
        props.comments.length > 0 &&
        props.comments.map((comment: any) => (
          <div
            // MDBListGroupItem
            key={comment.id}
            className={'d-flex justify-content-between p-2 ' + classes.item}
            color={
              comment.reference === 'cancellation'
                ? 'danger'
                : comment.reference === 'correction'
                ? 'warning'
                : undefined
            }
          >
            <div className="overflow-hidden d-flex flex-column">
              <div className=" d-flex flex-row justify-content-between align-items-center">
                <strong>{comment?.user?.company || ''}</strong>
                <div
                  className="mb-0 ml-2"
                  style={{ fontSize: '0.75rem', minWidth: 'fit-content' }}
                >
                  {Helper.getDate(comment.createdAt, true)}
                </div>
              </div>
              <TextLinks
                text={comment.comment}
                authorId={comment.userId}
              ></TextLinks>
            </div>
            <div className="align-self-center pl-1">
              {comment.userId !== props.project.userId && (
                <span
                  className="float-right mr-1"
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    !loading &&
                      toggleVisibility('forUser', !comment.forUser, comment.id);
                  }}
                >
                  <Badge color={comment.forUser ? 'primary' : 'grey'}>
                    {Translation.getTranslation('customer_info')}
                  </Badge>
                </span>
              )}
              {comment.userId !== props.project.designerId && (
                <span
                  className={`float-right  mr-1 ${
                    loading || comment.initial ? 'disabled' : 'cursor-pointer'
                  }`}
                  onClick={() => {
                    !loading &&
                      !comment.initial &&
                      toggleVisibility(
                        'forDesigner',
                        !comment.forDesigner,
                        comment.id,
                      );
                  }}
                >
                  <Badge color={comment.forDesigner ? 'primary' : 'grey'}>
                    {Translation.getTranslation('designer_info')}
                  </Badge>
                </span>
              )}
              {comment.reference === 'cancellation' && (
                <span
                  className={`float-right mr-1 ${loading ? 'disabled' : ''}`}
                >
                  <Badge color="error">
                    {Translation.getTranslation('status_cancelled')}
                  </Badge>
                </span>
              )}
              {comment.reference === 'correction' && (
                <span className="float-right mr-1">
                  <Badge color="warning">
                    {Translation.getTranslation('correction')}
                  </Badge>
                </span>
              )}
            </div>
          </div>
        ))}
    </div>
  );
}
