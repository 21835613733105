import { makeStyles, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import Helper from '../../services/helper';
import { lightSecondary } from '../../services/styles';
import Translation from '../../services/translation';
import Checkbox from './Checkbox';
import MenuButton from './MenuButton';
import NoDataComponent from './NoDataComponent';
import SearchTableWrapper from './SearchTableWrapper';
import SortIndicator from './SortIndicator';

export interface Column {
  label: string;
  type:
    | { id: 'text'; key: string }
    | { id: 'date'; key: string }
    | { id: 'translation'; key: string; prefix?: string }
    | {
        id: 'individual';
        key?: string;
        individualObject: any;
      };
}

interface Sort {
  sort?:
    | 'createdAt'
    | '-createdAt'
    | 'sendAt'
    | '-sendAt'
    | 'closedAt'
    | '-closedAt';
  fields?: 'createdAt' | 'sendAt' | 'closedAt';
}

interface Filter {
  limit?: number;
  query?: string;
  page?: number;
  sort?: Sort['sort'];
}

interface DataTableProps {
  columns: Column[];
  data: {
    count: number;
    rows: any[];
  };
}

interface Props {
  title: string;
  limit: number;
  columns: Column[];
  sort?: Sort['fields'][];
  defaultSort?: Sort['sort'];
  manager: any;
  color?: any;
  doNotSearch?: boolean;
  noPagination?: boolean;
  externalFilterChange?: any;
  renderMultiSelectOptions?: { label: string; onClick: (event: any) => void }[];
  onClick?: (id: string, moreData?: any) => void;
  reloadData?: number | undefined;
  onMultiSelect?: (data: any[]) => void;
  additionalElement?: (filter?: any) => void;
  additionalColumns?: (column: {
    //all data from response
    data: any;
    // data from the current row
    rowData?: any;
    headingCallback?: (labels: any) => void;
    valueCallback?: (value: any) => void;
  }) => void;
  returnData?: (data: any) => void;
}

const useStyles = makeStyles((_theme) => ({
  rowSelected: {
    backgroundColor: `${lightSecondary} !important`,
  },
  tableHeader: {
    '& th': {
      borderTop: 0,
    },
  },
  root: {
    '& td': {
      verticalAlign: 'middle',
    },
  },
}));

export default function DataTable(props: Props) {
  const getInitialFilter = () => {
    return {
      limit: props.limit,
      sort: (props.sort && props.defaultSort) || undefined,
      page: 0,
    } as Filter;
  };

  const classes = useStyles();
  const [filter, setFilter] = useState<Filter>(getInitialFilter());
  const [loading, setLoading] = useState<boolean>(true);
  const [multiSelectedRows, setMultiSelectedRows] = useState<any[]>([]);
  const [disabled, setDisabled] = useState<boolean>(false);
  const [data, setData] = useState<DataTableProps['data'] | undefined>(
    undefined,
  );

  /**
   * Saves filter in state and requests data
   *
   * @param filterDataTemp
   * @param returnData
   */
  const loadTable = async (filterDataTemp: Filter, returnData?: boolean) => {
    let filterTemp = { ...filter, ...filterDataTemp };
    setFilter(filterTemp);

    let dataTemp = await getData(filterTemp);
    // return data
    props.returnData && props.returnData(formatData(dataTemp));
    if (!returnData) setData(formatData(dataTemp));
    else return dataTemp;
  };

  /**
   * if endpoint has no rows/count structure it is reformatted
   * @param data
   */
  const formatData = (data: any) => {
    if (typeof data?.count === 'undefined') {
      return { count: data?.length || 0, rows: data };
    }
    return data;
  };

  /**
   * Get initial data on mount if table is not affected by external filter
   */
  useEffect(() => {
    !props.externalFilterChange && loadTable(filter);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * Listen for reload
   * todo optimieren
   */
  useEffect(() => {
    if (props.reloadData) {
      loadTable(filter);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.reloadData]);

  /**
   * Get data update if external filter changes
   */
  useEffect(() => {
    const loadData = async () => {
      const filterTemp = { ...getInitialFilter(), ...filter };

      setData(undefined);
      setLoading(true);
      await loadTable({ ...filterTemp, ...props.externalFilterChange });
      setLoading(false);
    };

    if (props.externalFilterChange) {
      loadData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.externalFilterChange]);

  /**
   * Make api-request with the specified manager prop
   * @param params
   */
  const getData = async (params?: Filter) => {
    if (params && params.query === '') delete params.query;
    const dataTemp = await props.manager(params);
    setLoading(false);
    if (dataTemp) {
      return dataTemp;
    }
  };

  const handleSortChange = async (
    selection: '' | '-',
    entity: Sort['fields'],
  ) => {
    let sort = `${selection === '-' ? '-' : ''}${entity}` as Sort['sort'];
    loadTable({ sort, page: 0 });
  };

  const handleClick = (
    event: any,
    scope: 'checkbox' | 'onClick',
    rowData?: any,
  ) => {
    event.stopPropagation();
    if (scope === 'checkbox') {
      event.preventDefault();
      handleMultiSelect(rowData);
    } else {
      event.preventDefault();
      props.onClick && props.onClick(rowData.id, rowData);
    }
  };

  const renderRow = (rowData: any) => {
    let cells = [];
    let index = 0;

    if (props.onMultiSelect) {
      cells.push(
        <td
          key={'multiSelectColumn'}
          onClick={(e: any) => {
            handleClick(e, 'checkbox', rowData);
          }}
        >
          <Checkbox
            className="pb-0 pt-0"
            checked={findIndex(multiSelectedRows, rowData, 'id') !== -1}
          />
        </td>,
      );
    }

    for (const column of props.columns) {
      cells.push(
        <td
          key={column.type.id + index}
          style={props.onClick ? { cursor: 'pointer' } : {}}
        >
          <Typography variant="body2">{renderCell(rowData, column)}</Typography>
        </td>,
      );
      index++;
    }
    return cells;
  };

  const renderCell = (cellData: any, column: Column) => {
    let output = '' as any;

    switch (column.type.id) {
      case 'text':
        output = cellData[column.type.key] || '-';
        break;

      case 'translation':
        output = Translation.getTranslation(
          cellData[column.type.key]
            ? `${column.type.prefix ? column.type.prefix : ''}${
                cellData[column.type.key]
              }`
            : '-',
        );
        break;

      case 'date':
        output = Helper.getDate(cellData[column.type.key], true);
        break;

      case 'individual':
        output = column.type.individualObject(cellData, (e: any) => {
          return e || '-';
        });
        break;

      default:
        output = '';
        break;
    }
    return output;
  };

  /**
   * Save index of clicked row and send data via props
   * @param index
   * @param rowData
   */
  const handleMultiSelect = (rowData: any) => {
    let multiSelectedRowsTemp = [...multiSelectedRows];
    const alreadyClickedIndex = findIndex(multiSelectedRowsTemp, rowData, 'id');
    if (alreadyClickedIndex === -1) {
      multiSelectedRowsTemp.push(rowData);
    } else {
      multiSelectedRowsTemp.splice(alreadyClickedIndex, 1);
    }
    setMultiSelectedRows(multiSelectedRowsTemp);
    props.onMultiSelect && props.onMultiSelect(multiSelectedRowsTemp);
  };

  /**
   * Generic function to find an index in an array of objects
   * @param toSearch
   * @param provider
   * @param searchByAttribute
   */
  const findIndex = (
    toSearch: any,
    provider: any,
    searchByAttribute: string,
  ) => {
    return toSearch.findIndex(
      (row: any) => row[searchByAttribute] === provider[searchByAttribute],
    );
  };

  return (
    // <Card className={'mb-5'}>
    <SearchTableWrapper
      noPagination={props.noPagination}
      changeLimit={(limit: number) => {
        loadTable({ limit, page: 0 });
      }}
      additionalElement={
        <div className="w-100 d-flex flex-row align-items-center justify-content-end">
          {props.additionalElement &&
            (props.additionalElement(
              (filterCallback?: (callback: any) => void) => {
                filterCallback &&
                  loadTable({ ...(filterCallback as any), page: 0 });
              },
            ) as any)}
        </div>
      }
      disabled={loading || disabled}
      loading={loading}
      title={`${props.title}`}
      // onLoadMore={(amount?: number) => nextPage(amount)}
      additionalFooter={
        props.onMultiSelect &&
        props.renderMultiSelectOptions && (
          <MenuButton
            disabled={!multiSelectedRows || multiSelectedRows.length === 0}
            label={Translation.getTranslation('actions')}
            items={props.renderMultiSelectOptions}
          />
        )
      }
      //todo debounce
      onSearch={
        !props.doNotSearch
          ? (e) => {
              loadTable({ query: e, page: 0 });
            }
          : undefined
      }
      // showLoadMore={data && data?.rows?.length < data?.count ? true : false}
      metaData={{
        currentPage: filter.page ? filter.page + 1 : 1,
        limit: filter.limit,

        total: data?.count,
      }}
      loadPage={(page: number) => {
        loadTable({ ...filter, page });
      }}
      color={props.color}
    >
      <div className="table-responsive">
        <table className={'table table-striped ' + classes.root}>
          <thead className={classes.tableHeader}>
            <tr>
              {props.onMultiSelect && (
                <th key={'multiSelectColumnHeading'}></th>
              )}
              {props.columns &&
                props.columns.map((column: Column, index: number) => (
                  <th key={column.label + index + 'dataTable'}>
                    <div className="d-flex align-content-center">
                      <Typography>{column.label}</Typography>
                      {props.sort &&
                        column.type.key &&
                        props.sort.includes(column.type.key as any) && (
                          <SortIndicator
                            selectionChanged={(selection: '' | '-') => {
                              handleSortChange(
                                selection,
                                column.type.key as any,
                              );
                            }}
                            defaultSort={
                              filter.sort?.replace('-', '') === column.type.key
                                ? filter.sort && filter.sort[0] === '-'
                                  ? '-'
                                  : ''
                                : undefined
                            }
                          />
                        )}
                    </div>
                  </th>
                ))}
              {props.additionalColumns &&
                props.additionalColumns({
                  data,
                  headingCallback: (heading: any) => {
                    return heading;
                  },
                })}
            </tr>
          </thead>
          <tbody>
            {!data || !data.rows || data.rows.length === 0 ? (
              <tr>
                <td colSpan={100}>
                  <NoDataComponent
                    value={Translation.getTranslation('no_entry')}
                  ></NoDataComponent>
                </td>
              </tr>
            ) : (
              data &&
              data.rows.map((rowData: any, index: number) => (
                <tr
                  className={`
                    ${props.onClick ? 'cursor-pointer' : ''}
                    ${
                      props.onMultiSelect &&
                      findIndex(multiSelectedRows, rowData, 'id') !== -1
                        ? classes.rowSelected
                        : ''
                    }
                    `}
                  onClick={(e: any) => {
                    handleClick(e, 'onClick', rowData);
                  }}
                  key={'tablerow' + rowData.id + index}
                >
                  {renderRow(rowData)}
                  {/* render valueCallback of additionalColumns */}
                  {props.additionalColumns &&
                    props.additionalColumns({
                      data,
                      rowData,
                      valueCallback: (value: any) => {
                        return value;
                      },
                    })}
                </tr>
              ))
            )}
          </tbody>
        </table>
      </div>
    </SearchTableWrapper>
    // </Card>
  );
}
